import React from 'react'
import classNames from 'classnames'

import { Preloader } from './loader'
import styles from '../styles/workspace-logo.module.scss'

interface WorkspaceLogoProps {
  className?: string
  logoLink: string
  loading: boolean
}

export function WorkspaceLogo({
  className,
  logoLink,
  loading,
}: WorkspaceLogoProps) {
  if (loading) {
    return (
      <div className={classNames(className, styles.logoWrapper)}>
        <Preloader />
      </div>
    )
  }
  return (
    <div className={classNames(className, styles.logoWrapper)}>
      <img src={logoLink} alt="Account logo" />
    </div>
  )
}
