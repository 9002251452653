import React, { useState, useMemo } from 'react'
import numeral from 'numeraljs'
import moment from 'moment'
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  DiscreteColorLegend,
  VerticalBarSeries,
  Hint,
} from 'react-vis'
import { nanoid } from 'nanoid'

import NoDataMessage from './no-data-message'
import Input from './input'
import { Preloader } from './loader'
import { GraphDataTable } from './performance-report-graph'
import Tooltip from './tooltip'
import graphIcon from '../assets/icon-graph.svg'
import linesIcon from '../assets/icon-table.svg'
import { dateFormatShort, graphColours } from '../core/constants'
import styles from '../styles/usage-report-graph.module.scss'
import { DashboardGraphData } from '../types/report-module'

const getColour = (index: number) => {
  return graphColours[index % graphColours.length]
}

interface UsageReportGraphProps {
  width: number
  graphWidth: number
  offset: number
  granularity: string
  isMobile: boolean
  graphDataToShow: {
    graphDateRange: string[]
    graphData: DashboardGraphData[]
  } | null
  graphTableTotals: number[] | null
  stackDimension: string
  successMetricDisplayName: string
  children: React.ReactChild
  loading?: boolean
  title: string
}

export default function UsageReportGraph({
  width,
  graphWidth,
  offset,
  granularity,
  isMobile,
  graphDataToShow,
  graphTableTotals,
  stackDimension,
  successMetricDisplayName,
  children,
  loading = false,
  title,
}: UsageReportGraphProps): React.ReactElement {
  const [expanded, setExpanded] = useState(false)
  const [heighligtedIndex, setHeighligtedIndex] = useState<null | number>(null)
  const graphHeight = (graphWidth >= 800 ? 320 : 200) + offset

  const [hintData, setHintData] = useState<
    {
      position: {
        x: number
        y: number
      }
      date: string
      right: boolean
      colour: string
      title: string
      value: number
    }[]
  >([])

  const desktopMargin = { bottom: 30 + offset, left: 60, right: 20, top: 20 }
  const mobileMargin = { bottom: 30 + offset, left: 40, right: 10, top: 20 }

  const graphDataGrouped = useMemo(() => {
    if (!graphDataToShow) {
      return []
    }

    const result = {}
    graphDataToShow.graphData.forEach((item) => {
      const { dimensionName, dimensionValues } = item
      result[dimensionName] = dimensionValues
    })
    const mergedResult: any[] = []
    const keys = Object.keys(result)
    keys.forEach((key, index) => {
      mergedResult.push({
        title: key,
        colour: getColour(index),
        data: result[key].map((val, valIndex) => {
          return {
            x: valIndex,
            y: val,
            y0: 0,
            yearMonth: graphDataToShow.graphDateRange[valIndex],
          }
        }),
      })
    })

    return mergedResult
  }, [graphDataToShow])

  const tickValues = useMemo(() => {
    if (
      graphDataToShow &&
      graphDataToShow.graphData.length > 0 &&
      graphDataToShow.graphDateRange.length > 0
    ) {
      if (granularity === 'daily') {
        const starts: string[] = []
        const startString =
          isMobile && graphDataToShow.graphDateRange.length > 35
            ? 'month'
            : 'week'
        graphDataToShow.graphDateRange.forEach((date) => {
          const item = moment(date, 'YYYY-MM-DD')
          const weekStart = item
            .startOf(startString)
            .add(1, 'day')
            .format('YYYY-MM-DD')
          if (starts.indexOf(weekStart) === -1) {
            starts.push(weekStart)
          }
        })
        return starts
          .map((item) => {
            return graphDataToShow.graphDateRange.indexOf(item)
          })
          .filter((item) => item !== -1)
      }
      return undefined
    }
    return undefined
  }, [graphDataToShow, granularity, isMobile])

  const xAxisLabel = useMemo(() => {
    const longAxis =
      graphDataToShow && graphDataToShow.graphDateRange.length > 35

    if (
      granularity === 'monthly' ||
      (isMobile && granularity === 'daily' && longAxis)
    ) {
      return 'Month'
    }

    if (granularity === 'quarterly') {
      return 'Quarter'
    }

    return 'Week commencing Monday'
  }, [granularity, isMobile, graphDataToShow])

  if (graphDataToShow === null || loading) {
    return (
      <div
        className={styles.graphPaper}
        style={{
          height: `${graphHeight}px`,
          width: `auto`,
        }}
      >
        {children}
        <div className={styles.graphContainer}>
          <Preloader
            style={{
              width: 60,
              height: 50,
              marginTop: graphHeight / 2 - 30,
              marginBottom: graphHeight / 2 - 30,
            }}
          />
        </div>
      </div>
    )
  }

  if (graphDataToShow.graphData.length === 0) {
    return (
      <div
        className={styles.graphPaper}
        style={{
          height: `${graphHeight}px`,
          width: `${graphWidth}px`,
        }}
      >
        {children}
        <div className={styles.graphContainer}>
          <NoDataMessage />
        </div>
      </div>
    )
  }
  return (
    <div className={styles.graphPaper}>
      <div className={styles.titleRow}>
        <div>{children}</div>
        <div>
          <div className={styles.tabsWrapper} data-html2canvas-ignore>
            <Input
              className={styles.tabs}
              name="expand"
              id={nanoid()}
              label="&nbsp;"
              type="radio"
              value="expand"
              checked={expanded}
              onClick={(e): any => {
                e.preventDefault()
                setExpanded(false)
              }}
            >
              <img src={graphIcon} alt="graph icon" />
            </Input>
            <Input
              className={styles.tabs}
              name="expand"
              id={nanoid()}
              label="&nbsp;"
              type="radio"
              value="collapse"
              checked={!expanded}
              onClick={(e): any => {
                e.preventDefault()
                setExpanded(true)
              }}
            >
              <img src={linesIcon} alt="lines icon" />
            </Input>
          </div>
        </div>
      </div>
      {expanded ? (
        <div>
          <GraphDataTable
            title={title}
            width={width}
            granularity={granularity}
            isMobile={isMobile}
            graphDataToShow={graphDataToShow}
            totalsData={graphTableTotals}
          />
        </div>
      ) : (
        <div className={styles.graphContainer}>
          {graphDataGrouped.length > 1 && (
            <div className={styles.colorLegendWrapper}>
              <DiscreteColorLegend
                orientation="horizontal"
                items={graphDataGrouped.map((item, index) => {
                  const { title: itemTitle } = item
                  const isOther = index === 0 && itemTitle === '(Other)'
                  const useTitle = itemTitle
                  const colour = getColour(index)

                  let tooltipMessage = ''

                  if (useTitle === 'Dormant users') {
                    tooltipMessage = `Unique users who have not logged in in this ${
                      xAxisLabel.toLowerCase().split(' ')[0]
                    }.`
                  }
                  if (useTitle === 'Active Users') {
                    tooltipMessage = `Unique users who have logged in in this ${
                      xAxisLabel.toLowerCase().split(' ')[0]
                    }.`
                  }

                  return (
                    <div
                      className={styles.colorLegendDot}
                      onMouseEnter={() => {
                        setHeighligtedIndex(index)
                      }}
                      onMouseLeave={() => setHeighligtedIndex(null)}
                    >
                      {isOther && (
                        <Tooltip
                          id="other-dimensions-tooltip"
                          className={styles.tooltip}
                          maxWidth={500}
                          tooltipPosition="right"
                          tooltipMessage={`Other (non top 20 ${title.toLowerCase()}(s))`}
                        >
                          <>
                            <div
                              className={styles.dot}
                              style={{ backgroundColor: colour, color: colour }}
                            />
                            <span className={styles.dotTitle}>
                              {itemTitle === '' ? '(empty)' : useTitle}
                            </span>
                          </>
                        </Tooltip>
                      )}
                      {!isOther && (
                        <>
                          <div
                            className={styles.dot}
                            style={{ backgroundColor: colour, color: colour }}
                          />
                          <Tooltip
                            id={`${
                              useTitle || 'empty'
                            }-dimension-label-tooltip`}
                            className={styles.tooltip}
                            tooltipMessage={tooltipMessage}
                          >
                            <span className={styles.dotTitle}>
                              {itemTitle === '' ? '(empty)' : useTitle}
                            </span>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  )
                })}
              />
            </div>
          )}
          <XYPlot
            onMouseLeave={() => setHintData([])}
            className={styles.graphWrapper}
            height={graphHeight}
            width={graphWidth}
            margin={isMobile ? mobileMargin : desktopMargin}
            stackBy="y"
          >
            <HorizontalGridLines />
            <VerticalGridLines />
            {graphDataToShow.graphDateRange.length > 0 &&
              graphDataGrouped.map((item, index) => {
                let colour = getColour(index)
                let opacity = 1
                if (heighligtedIndex !== null && heighligtedIndex !== index) {
                  opacity = 0.4
                  colour = '#718096'
                }
                return (
                  // @ts-ignore
                  <VerticalBarSeries
                    key={item.title}
                    onSeriesMouseOut={() => setHintData([])}
                    onValueMouseOver={(datapoint) => {
                      const { x, y0 } = datapoint
                      const dim = graphDataGrouped[index]
                      const value = dim.data[x].y
                      setHintData([
                        {
                          position: {
                            // @ts-ignore
                            x,
                            y: y0 + Math.round(value / 2),
                          },
                          title: dim.title,
                          colour: getColour(index),
                          right: dim.data.length * 0.8 >= (x as number),
                          date: graphDataToShow.graphDateRange[x],
                          value,
                        },
                      ])
                    }}
                    opacity={opacity}
                    data={item.data}
                    color={colour}
                  />
                )
              })}
            {hintData.length > 0 && (
              <Hint
                align={{
                  horizontal: hintData[0].right ? 'right' : 'left',
                  vertical: 'top',
                }}
                key={nanoid()}
                value={hintData[0].position}
                className={styles.hint}
              >
                <div className={styles.hintBox}>
                  {hintData.map((item, itemIndex) => {
                    const d = moment(item.date)
                    let showDate = d.format(dateFormatShort)
                    if (granularity === 'quarterly') {
                      const month = d.month() + 1
                      const q = Math.floor((month + 3) / 3)
                      showDate = `Q${q} ${d.format('YYYY')}`
                    }
                    return (
                      <div key={nanoid()}>
                        <h3 className={styles.hintTitle}>
                          <div
                            className={styles.hintColour}
                            style={{ backgroundColor: item.colour }}
                          />
                          <span>
                            {stackDimension !== '' ? `${stackDimension}, ` : ''}
                            {item.title === 'NOT_STACKED'
                              ? 'All'
                              : item.title}:{' '}
                            {numeral(item.value).format('0,0.[0]a')}
                          </span>
                        </h3>
                        {hintData.length - 1 === itemIndex && (
                          <h4 className={styles.hintDate}>{showDate}</h4>
                        )}
                      </div>
                    )
                  })}
                </div>
              </Hint>
            )}
            <XAxis
              tickSizeOuter={10}
              tickLabelAngle={
                granularity === 'monthly' || granularity === 'quarterly'
                  ? 0
                  : -45
              }
              tickValues={tickValues}
              // @ts-ignore
              tickFormat={(i: number, index: number): string => {
                // check if number is decimal
                if (
                  i % 1 !== 0 ||
                  i === -1 ||
                  graphDataGrouped[0].data.length <= i
                ) {
                  return ''
                }
                const d = moment(
                  graphDataGrouped[0].data[i].yearMonth,
                  'YYYY-MM-DD',
                )
                // leave out ever second number
                if (width && width < 768) {
                  return index % 2 ? '' : d.format('MMM YY')
                }
                if (isMobile) {
                  return `${d.format('DD/MM')}`
                }
                if (granularity === 'daily' || granularity === 'weekly') {
                  return `${d.format('DD MMM YY')}`
                }
                if (granularity === 'quarterly') {
                  const month = d.month() + 1
                  const q = Math.floor((month + 3) / 3)
                  return `Q${q} ${d.format('YYYY')}`
                }
                return `${d.format('MMM YY')}`
              }}
            />
            <YAxis
              // 'Active users' tab shows active and dormant as a stacked chart
              // So title should be 'Total'
              title={
                successMetricDisplayName === 'Active users'
                  ? 'Total users'
                  : successMetricDisplayName
              }
              tickFormat={(i: string): string => {
                return numeral(i).format('0,0.[0]a')
              }}
            />
          </XYPlot>
          <p className={styles.xAxislabel}>{xAxisLabel}</p>
        </div>
      )}
    </div>
  )
}
