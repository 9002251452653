import React, { useMemo } from 'react'
import moment from 'moment'

import ReportSummary, { FilterListItem } from './report-summary'
import { NoteText } from './typography'
import UsageReportCallouts from './usage-report-callouts'
import UsageReportGraph from './usage-report-graph'
import { NewOptions } from '../api/types'
import {
  dateFormatShort,
  siteContainerWidth,
  usageReportSuccessMetrics,
} from '../core/constants'
import { getDateRangeLabel } from '../helpers/report-module'
import useResize from '../hooks/useResize'
import styles from '../styles/usage-report-page.module.scss'
import {
  DashboardGraphData,
  UsageReportDataConfig,
} from '../types/report-module'
import { GetAccountUsageDataQuery } from '../__gql-types__/graphql'

interface UsageReportTabProps {
  loading?: boolean
  dataConfig: UsageReportDataConfig
  availableDimensionsTotals: { [dimensionParameterID: string]: number }
  updateFilters: ({
    accountFilter,
    userFilter,
  }: {
    accountFilter?: NewOptions[]
    userFilter?: NewOptions[]
  }) => Promise<void>
  accountUsageData: GetAccountUsageDataQuery['report'] | null
  graphDataToShow: {
    graphDateRange: string[]
    graphData: DashboardGraphData[]
  } | null
  graphTableTotals: number[] | null
  width: number
  usePDFDims: boolean
}

const UsageReportTab = React.forwardRef<HTMLDivElement, UsageReportTabProps>(
  (
    {
      loading = false,
      dataConfig,
      availableDimensionsTotals,
      updateFilters,
      accountUsageData,
      graphDataToShow,
      graphTableTotals,
      width,
      usePDFDims,
    },
    ref,
  ) => {
    const {
      startDate,
      endDate,
      granularity,
      successMetric,
      stackDimension,
    } = dataConfig

    const screenWidth = useResize()
    const isMobile = screenWidth <= 768

    const graphWidth: number = useMemo(() => {
      let res: number
      if (width) {
        res = width
      } else if (screenWidth > 1260) {
        res = 1108
      } else if (screenWidth < siteContainerWidth) {
        res = screenWidth - 40
      } else if (screenWidth <= siteContainerWidth + 150) {
        res = screenWidth - 150
      } else {
        res = 960
      }
      return res - 34 - 40
    }, [width, screenWidth])

    let offset =
      granularity === 'monthly' || granularity === 'quarterly' ? 0 : 40

    if (isMobile && offset > 0) {
      offset = 20
    }

    const dataBasedDateRange = useMemo(() => {
      if (loading) return ''

      let queryStartDate = ''
      let queryEndDate = ''

      if (
        accountUsageData &&
        accountUsageData[successMetric] &&
        accountUsageData[successMetric]?.queryStartDate
      ) {
        queryStartDate = accountUsageData[successMetric]?.queryStartDate || ''
        queryEndDate = accountUsageData[successMetric]?.queryEndDate || ''
      }

      if (!queryEndDate) {
        return ''
      }

      const start = moment(queryStartDate, 'YYYY-MM-DD').format(dateFormatShort)
      const end = moment(queryEndDate, 'YYYY-MM-DD').format(dateFormatShort)

      return `${start} - ${end}`
    }, [accountUsageData, loading])

    const dateRangeLabel = useMemo(() => {
      return getDateRangeLabel(startDate, endDate)
    }, [startDate, endDate])

    const {
      successMetricDisplayName,
      successMetricDescription,
    } = usageReportSuccessMetrics[successMetric]

    const reportFilters = useMemo(() => {
      const filterList: FilterListItem[] = []

      if (
        dataConfig.accountFilter &&
        availableDimensionsTotals.account > dataConfig.accountFilter.length
      ) {
        dataConfig.accountFilter.forEach(({ optionName, optionValue }) => {
          filterList.push({
            dimensionName: 'workspace',
            dimensionID: 'account',
            optionName,
            optionID: optionValue,
          })
        })
      }

      if (
        dataConfig.userFilter &&
        availableDimensionsTotals.user > dataConfig.userFilter.length
      ) {
        dataConfig.userFilter.forEach(({ optionName, optionValue }) => {
          filterList.push({
            dimensionName: 'user',
            dimensionID: 'user',
            optionName,
            optionID: optionValue,
          })
        })
      }

      return filterList
    }, [dataConfig])

    return (
      <div
        ref={ref}
        className={styles.paper}
        style={
          usePDFDims
            ? {
                borderWidth: 0,
              }
            : undefined
        }
      >
        <ReportSummary
          loading={loading}
          reportTitle={successMetricDisplayName}
          dateRange={dataBasedDateRange}
          successMetric={{
            name: 'Description',
            tooltip: successMetricDescription,
          }}
          filterList={reportFilters}
          onRemoveFilterOption={async (dimensionParameterID, optionValue) => {
            const { accountFilter, userFilter } = dataConfig

            if (accountFilter && dimensionParameterID === 'account') {
              await updateFilters({
                accountFilter: accountFilter.filter(
                  (item) => item.optionValue !== optionValue,
                ),
              })
            } else if (userFilter && dimensionParameterID === 'user') {
              await updateFilters({
                userFilter: userFilter.filter(
                  (item) => item.optionValue !== optionValue,
                ),
              })
            }
          }}
          logoIsEditable={false}
        >
          {successMetric === 'logins' ? (
            <NoteText className={styles.metricNote}>
              <> Data not available from 16th Feb - 4th Apr 2023</>
            </NoteText>
          ) : (
            <></>
          )}
        </ReportSummary>
        <div className={styles.metricDataBannerWrapper}>
          <div className={styles.metricDataBanner}>
            <UsageReportCallouts
              successMetric={
                successMetric === 'activeUsers'
                  ? 'users'
                  : successMetricDisplayName
              }
              dateRange={dataBasedDateRange}
              data={
                accountUsageData && accountUsageData[successMetric]
                  ? accountUsageData[
                      successMetric === 'activeUsers'
                        ? 'totalUsers'
                        : successMetric
                    ]?.total
                  : null
              }
              loading={loading}
            />
            {successMetric === 'activeUsers' &&
              accountUsageData &&
              accountUsageData.activeUsers &&
              accountUsageData.totalUsers && (
                <>
                  {/* Active users callout */}
                  <UsageReportCallouts
                    successMetric="active users"
                    dateRange={dataBasedDateRange}
                    data={accountUsageData.activeUsers.total}
                    loading={loading}
                  />
                  {/* Dormant users callout */}
                  <UsageReportCallouts
                    successMetric="dormant users"
                    dateRange={dataBasedDateRange}
                    data={
                      accountUsageData.totalUsers.total -
                      accountUsageData.activeUsers.total
                    }
                    loading={loading}
                  />
                </>
              )}
          </div>
        </div>
        <div className={styles.graphWrapper}>
          <UsageReportGraph
            title={stackDimension || 'No breakdown'}
            loading={loading}
            width={width}
            graphWidth={graphWidth}
            offset={offset}
            granularity={granularity}
            isMobile={isMobile}
            graphDataToShow={graphDataToShow}
            graphTableTotals={graphTableTotals}
            stackDimension={stackDimension || ''}
            successMetricDisplayName={successMetricDisplayName}
          >
            {loading ? (
              <></>
            ) : (
              <h1 className={styles.graphTitle}>
                <span>
                  {successMetricDisplayName} {dateRangeLabel}{' '}
                  {!!stackDimension && <>split by {stackDimension}</>}
                </span>
              </h1>
            )}
          </UsageReportGraph>
        </div>
      </div>
    )
  },
)

export default UsageReportTab
