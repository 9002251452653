import React, { useEffect, useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import classNames from 'classnames'

import Button from './button'
import { BiLine } from './counter'
import { FormField, FormLabel, FormRow } from './form'
import { RadioGroup } from './input-v2'
import { CustomLinkFull } from './custom-link-fields'
import { RequestShortLinksModal } from './upgrade-modals'
import { currentUserDetails, CustomDomainType } from '../api/apollo/variables'
import { messages } from '../core/constants'
import { getUserData, saveUserData } from '../helpers/local-client'
import useLogAction from '../hooks/useLogAction'
import { CustomLinkDomainDetails } from '../hooks/useTrackCreateSubmit'
import styles from '../styles/track-create-submit.module.scss'
import { GetCampaignCodeGeneratorQuery } from '../__gql-types__/graphql'

export type SubmitLinkType = 'basic' | CustomDomainType

interface TrackCreateSubmitFieldsProps {
  submitLinkType?: SubmitLinkType
  canUseLinkType: boolean
  fetchingAliases: boolean
  validationChecks:
    | GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['validationChecks']
    | null
  setLinkType: React.Dispatch<React.SetStateAction<'full' | 'short' | null>>
  customLinkKey: string
  customLinkDomainDetails?: CustomLinkDomainDetails
  setCustomLinkDomainDetails?: React.Dispatch<
    React.SetStateAction<CustomLinkDomainDetails>
  >
  customLinkAlias: string
  setCustomLinkAlias: React.Dispatch<React.SetStateAction<string>>
  customLinkError: boolean
  submitText?: string
  submitLoading?: boolean
  /** Allows submit button to be clickable, showing errors */
  softDisable?: boolean
  submitDisabled?: boolean
  children?: React.ReactNode
}

/** Submit button for Track>Create forms, plus shortLink fields if required */
const TrackCreateSubmitFields = ({
  submitLinkType = 'basic',
  canUseLinkType,
  fetchingAliases,
  validationChecks,
  setLinkType,
  customLinkKey,
  customLinkDomainDetails,
  setCustomLinkDomainDetails,
  customLinkAlias,
  setCustomLinkAlias,
  customLinkError,
  submitText,
  submitLoading,
  softDisable,
  submitDisabled,
  children,
}: TrackCreateSubmitFieldsProps) => {
  const { companySubscriptionLevel } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const [customLinkStatus, setCustomLinkStatus] = useState<UrlStatus>('')
  const [showShortLinkModal, setShowShortLinkModal] = useState(false)

  // Set initial value for linkTo if not found
  // Also set linkType when linkTo is not 'app'
  useEffect(() => {
    // No need to set link type
    if (submitLinkType === 'appLink') return

    // Don't allow short links from local storage for unpaid accounts
    if (companySubscriptionLevel && !canUseLinkType) {
      setLinkType('full')
      saveUserData({ linkType: 'full' })
      return
    }

    // Set linkType based on saved rule, if present
    const forceShortLinkRule = validationChecks?.find(
      (check) => check.name === 'FORCE_SHORT_LINK',
    )

    let shortLinkValue: any = null
    let selectedRule: string | null = null

    if (
      forceShortLinkRule &&
      forceShortLinkRule.enabled &&
      forceShortLinkRule.value
    ) {
      shortLinkValue = JSON.parse(forceShortLinkRule.value)

      selectedRule =
        shortLinkValue?.find((option: any) => option.selected)?.optionValue ||
        null
    }

    if (selectedRule === 'force-short-links') {
      setLinkType('short')
      saveUserData({ linkType: 'short' })
      return
    }

    if (selectedRule === 'force-long-links') {
      setLinkType('full')
      saveUserData({ linkType: 'full' })
    }

    const savedState = getUserData()
    const initialState = savedState?.linkType

    if (initialState) {
      setLinkType(initialState)
      return
    }

    switch (selectedRule) {
      case 'recommend-short-links':
        setLinkType('short')
        saveUserData({ linkType: 'short' })
        return
      case 'recommend-long-links':
        setLinkType('full')
        saveUserData({ linkType: 'full' })
        return
      default:
        // If no set rule, set based on account level
        setLinkType(canUseLinkType ? 'short' : 'full')
    }
  }, [
    submitLinkType,
    companySubscriptionLevel,
    canUseLinkType,
    validationChecks,
  ])

  // Options and order for URL link presentation: 'short' or 'full'
  const shortLinkOptions = useMemo(() => {
    const options = [
      {
        label: (
          <>
            Short link <span className={styles.recommended}>(recommended)</span>
          </>
        ),
        value: 'short',
      },
      {
        label: 'Basic link',
        value: 'full',
      },
    ]

    if (!canUseLinkType) {
      options.reverse()

      return options
    }

    const forceShortLinkRule = validationChecks?.find(
      (check) => check.name === 'FORCE_SHORT_LINK',
    )

    if (
      forceShortLinkRule &&
      forceShortLinkRule.enabled &&
      forceShortLinkRule.value
    ) {
      const shortLinkValue = JSON.parse(forceShortLinkRule.value)

      const selectedRule = shortLinkValue.find((option: any) => option.selected)
        .optionValue

      switch (selectedRule) {
        case 'force-short-links':
        case 'force-long-links':
          return []
        case 'recommend-long-links':
          return [
            {
              label: (
                <>
                  Basic link{' '}
                  <span className={styles.recommended}>(recommended)</span>
                </>
              ),
              value: 'full',
            },
            {
              label: 'Short link',
              value: 'short',
            },
          ]
        default:
          break
      }
    }

    return options
  }, [canUseLinkType, validationChecks])

  let tooltip = ''

  if (submitLinkType === 'shortLink') {
    tooltip = messages.customiseShortLink
  } else if (
    submitLinkType === 'appLink' &&
    customLinkDomainDetails?.appLinkDomainID
  ) {
    tooltip = messages.customiseDeepLink
  }

  return (
    <>
      {/* First field should only be visible if not using appLinks and user can choose short/basic link type */}
      {shortLinkOptions.length > 0 && submitLinkType !== 'appLink' && (
        <FormRow
          heading="Presentation"
          bottomBorder={false}
          includePaddingBottom={submitLinkType === 'shortLink'}
        >
          <FormLabel id="link-type" tooltip={messages.linkTypes}>
            Link type
          </FormLabel>
          <FormField>
            <RadioGroup
              horizontal
              options={shortLinkOptions}
              optionsClassName={styles.radioButton}
              selectedValue={submitLinkType === 'shortLink' ? 'short' : 'full'}
              onChange={(option) => {
                // Block short link if not available on workspace
                if (option === 'short' && !canUseLinkType) {
                  if (window.dataLayer && window.dataLayer.push) {
                    window.dataLayer.push({
                      event: 'click-shortlink-upgrade-blocker',
                    })
                  }

                  logAction({
                    variables: {
                      action: 'click-shortlink-upgrade-blocker',
                      websiteSection: 'track',
                      pagePath: window.location.pathname,
                      functionName: 'clickUpgrade',
                      extra: 'form',
                    },
                  })

                  setShowShortLinkModal(true)

                  return
                }

                setLinkType(option as 'short' | 'full')
                saveUserData({ linkType: option })
              }}
            />
          </FormField>
        </FormRow>
      )}
      <FormRow
        heading={
          (submitLinkType === 'appLink' &&
            !!customLinkDomainDetails?.appLinkDomainID) ||
          (submitLinkType === 'shortLink' && shortLinkOptions.length === 0)
            ? 'Presentation'
            : undefined
        }
        className={
          !submitLinkType ||
          (submitLinkType === 'basic' && shortLinkOptions.length === 0) ||
          (submitLinkType === 'shortLink' && shortLinkOptions.length > 0) ||
          (submitLinkType === 'appLink' &&
            !customLinkDomainDetails?.appLinkDomainID)
            ? styles.noPaddingTop
            : undefined
        }
        bottomBorder={false}
      >
        <FormLabel id="custom-link" tooltip={tooltip}>
          {submitLinkType !== 'basic' &&
            !(
              submitLinkType === 'appLink' &&
              !customLinkDomainDetails?.appLinkDomainID
            ) && <>{submitLinkType === 'shortLink' ? 'Short' : 'App'} link</>}
        </FormLabel>
        <FormField>
          {submitLinkType !== 'basic' &&
            !(
              submitLinkType === 'appLink' &&
              !customLinkDomainDetails?.appLinkDomainID
            ) && (
              <div className={styles.linkInputWrapper}>
                <CustomLinkFull
                  customLinkType={submitLinkType}
                  customLinkKey={customLinkKey}
                  onDomainChange={
                    submitLinkType === 'shortLink'
                      ? (domain) => {
                          if (setCustomLinkDomainDetails) {
                            setCustomLinkDomainDetails((curr) => ({
                              ...curr,
                              // App link domain is not controlled here, so we don't need to update it
                              shortLinkDomainID: domain,
                            }))
                          }
                        }
                      : undefined
                  }
                  staticDomainToUse={
                    customLinkDomainDetails?.appLinkDomainID
                      ? {
                          id: customLinkDomainDetails?.appLinkDomainID,
                          label: customLinkDomainDetails?.appLinkDomainLabel,
                        }
                      : null
                  }
                  onStatusChange={(status) => setCustomLinkStatus(status)}
                  onAliasChange={(newAlias) => setCustomLinkAlias(newAlias)}
                >
                  <BiLine arrowTop className={styles.editableLinkBiLine}>
                    Make me <strong>memorable</strong>!
                  </BiLine>
                </CustomLinkFull>
              </div>
            )}
          <Button
            type="submit"
            className={classNames(styles.submitButton, {
              [styles.softDisableButton]: softDisable,
            })}
            loading={submitLoading}
            isDisabled={
              submitDisabled ||
              fetchingAliases ||
              ['invalid', 'refetching', 'validating'].indexOf(
                customLinkStatus || '',
              ) > -1 ||
              // TODO: Accomodate batch alias creation
              (submitLinkType !== 'basic' && !customLinkAlias)
            }
          >
            {submitText || 'Create link'}
          </Button>
          {customLinkError && (
            <p className={styles.inputError}>
              There was an error creating your{' '}
              {submitLinkType === 'shortLink' ? 'short' : 'deep'} link. Please
              refresh it and try again.
            </p>
          )}
          {children}
        </FormField>
      </FormRow>
      {showShortLinkModal && (
        <RequestShortLinksModal onHideModal={setShowShortLinkModal} />
      )}
    </>
  )
}

export default TrackCreateSubmitFields
