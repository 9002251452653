import React, { useEffect, useState } from 'react'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import classNames from 'classnames'

import Button from './button'
import Link from './link'
import { Preloader } from './loader'
import { SuccessText } from './typography'
import { supportEmail } from '../core/constants'
import styles from '../styles/file-drag-and-drop.module.scss'

interface FileDragAndDropProps {
  className?: string
  uploadButtonText?: string
  disabled?: boolean
  maxFiles?: number
  maxFileSize?: number
  acceptedFileTypes?: string | string[]
  onDrop: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => Promise<void>
  success?: string | React.ReactElement
  uploadError?: string | React.ReactElement
}

const FileDragAndDrop = ({
  className,
  uploadButtonText = 'Upload file',
  maxFiles = 1,
  maxFileSize,
  acceptedFileTypes,
  disabled = false,
  onDrop,
  success = '',
  uploadError = '',
}: FileDragAndDropProps) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | React.ReactElement | null>(null)

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    disabled,
    maxFiles,
    maxSize: maxFileSize,
    accept: acceptedFileTypes,
    onDrop: async (files, rejectedFiles, event) => {
      if (rejectedFiles.length > 0) {
        if (rejectedFiles[0].errors[0].code === 'too-many-files') {
          setError(
            `Maximum ${maxFiles} file${maxFiles === 1 ? '' : 's'} allowed.`,
          )
        } else if (
          maxFileSize &&
          rejectedFiles[0].errors[0].code === 'file-too-large'
        ) {
          setError(`Max file size is ${Math.floor(maxFileSize / 1024)}KB.`)
        } else if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
          setError(`Invalid file type. Accepted types: ${acceptedFileTypes}`)
        }

        return
      }

      setError(null)
      setLoading(true)

      try {
        await onDrop(files, rejectedFiles, event)
      } catch {
        setError(
          <p>
            We encountered an error while uploading your file. Please contact{' '}
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
          </p>,
        )
      } finally {
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    setError(uploadError)
  }, [uploadError])

  return (
    <>
      <div className={className}>
        {loading ? (
          <div className={classNames(styles.dropZoneWrapper)}>
            <div className={styles.dropZone}>
              <Preloader />
            </div>
          </div>
        ) : (
          <div className={classNames(styles.dropZoneWrapper)}>
            <div
              {...getRootProps({
                className: classNames(styles.dropZone, {
                  [styles.dropZoneActive]: isDragActive,
                  [styles.dropZoneAccept]: isDragAccept,
                  [styles.dropZoneReject]: isDragReject,
                }),
              })}
            >
              <input {...getInputProps()} />
              <div className={styles.dropzoneEmpty}>
                {!isDragActive && (
                  <>
                    <p>Drag &amp; drop or </p>
                    <Button onPress={open}>{uploadButtonText}</Button>
                  </>
                )}
                {isDragActive && !isDragReject && <p>Drop your file here...</p>}
                {isDragActive && isDragReject && (
                  <p style={{ textAlign: 'center' }}>
                    {acceptedFileTypes
                      ? `Accepted file types: ${acceptedFileTypes}`
                      : "Sorry, we don't support multiple files..."}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {success && (
        <SuccessText className={styles.success}>{success}</SuccessText>
      )}
      {error && <p className={styles.error}>{error}</p>}
    </>
  )
}

export default FileDragAndDrop
