import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import classNames from 'classnames'
import { nanoid } from 'nanoid'

import Button from './button'
import Input, { Label } from './input'
import { FormRow, LabelSlot, FieldSlot } from './row'
import SelectBox, { SelectBoxChecklist } from './select-box'
import Tooltip from './tooltip'
import { SuccessText } from './typography'
import { currentUserDetails } from '../api/apollo/variables'
import { addUsersToAccountsBulk } from '../api/graphql/custom-queries'
import { getCompanyDetails } from '../api/graphql/company-client'
import { getUserAccounts } from '../api/graphql/user-client'
import { messages } from '../core/constants'
import { returnUnique, getItemByKeyValue } from '../helpers'
import { validateEmail } from '../helpers/forms'
import useLogAction from '../hooks/useLogAction'
import useOnboarding from '../hooks/useOnboarding'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import { teamTierMaxUsers } from '../static-copy/subscription-features'
import styles from '../styles/add-by-email.module.scss'
import { GetAccountUsersQuery } from '../__gql-types__/graphql'

const permissionList = [
  { value: 'regular', label: 'Regular' },
  { value: 'admin', label: 'Admin' },
]

interface PermissionSelectorProps {
  permission: string
  className?: string
  onChange: (permission: PermissionLevel) => void
}

export const PermissionSelector = ({
  permission,
  className,
  onChange,
}: PermissionSelectorProps) => {
  return (
    <SelectBox
      id="permission-selector"
      name="permission"
      className={className}
      options={permissionList}
      value={permissionList.find(({ value }) => value === permission)}
      onChange={(newValue) => {
        if (!newValue) return

        onChange(newValue.value as PermissionLevel)
      }}
    />
  )
}

interface AddByEmailProps {
  accountUsers: GetAccountUsersQuery['account']['userAccountProfiles']
  currentLicenceCount: number
  maxToAdd: number | null
  setShowAddUsersModal: Dispatch<SetStateAction<boolean>>
  setShowUpgradeUsersModal: Dispatch<SetStateAction<boolean>>
  setWarningMessage?: Dispatch<SetStateAction<string>>
}

export default function AddByEmail({
  accountUsers,
  currentLicenceCount,
  maxToAdd,
  setShowAddUsersModal,
  setShowUpgradeUsersModal,
  setWarningMessage,
}: AddByEmailProps) {
  const { workspaceID, companyID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const { isTeam } = useSubscriptionLevel()

  const { data: accountProfilesData } = useQuery(getUserAccounts)
  const [refetchCompanyData] = useLazyQuery(getCompanyDetails)

  const { fullOnboardingSections, updateOnboardingSection } = useOnboarding()

  // Get accounts the current user has access to
  // Used to display all accounts they can add users to
  const currentUserAccountProfiles = useMemo(() => {
    return accountProfilesData
      ? accountProfilesData.currentUser.userAccountProfiles
      : []
  }, [accountProfilesData])

  const hasInvitedUsers = useMemo(() => {
    const inviteUsersSection = fullOnboardingSections.account.find(
      (section) => section.onboardingSectionID === 'inviteUsers',
    )

    return !!(inviteUsersSection && inviteUsersSection.sectionCompleted)
  }, [fullOnboardingSections])

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [error, setError] = useState<string[]>([])
  const [success, setSuccess] = useState('')
  const [permission, setPermission] = useState<PermissionLevel>('regular')
  const [accountIDs, setAccountIDs] = useState<string[]>([])

  const handleSubmit = async () => {
    setError([])
    setSuccess('')

    if (email === '') {
      setError([messages.notValidEmail])
      return
    }

    const emails = email.replace(/ /g, '').split(/[;,]/gi)

    if (emails.length === 0) {
      setError([messages.notValidEmail])
      return
    }

    const alreadyExists = accountUsers
      ? emails.filter((eml) => {
          const pendingUserList = accountUsers.filter((user) => user.inviteOpen)
          const existingUserList = accountUsers?.filter(
            (user) => !user.inviteOpen,
          )

          if (pendingUserList.length > 0) {
            const isPendingUser = getItemByKeyValue(
              pendingUserList,
              'userEmail',
              eml,
              false,
              true,
            )
            if (isPendingUser !== -1) {
              return true
            }
          }
          if (existingUserList.length > 0) {
            const isExistingUser = getItemByKeyValue(
              existingUserList,
              'userEmail',
              eml,
              false,
              true,
            )
            if (isExistingUser !== -1) {
              return true
            }
          }
          return false
        })
      : []

    const useEmails = emails.filter(
      (eml) => eml !== '' && alreadyExists.indexOf(eml) === -1,
    )

    if (alreadyExists.length > 0) {
      setError((msg) => {
        return [
          ...msg,
          `${alreadyExists.join(', ')} ${
            alreadyExists.length > 1 ? 'are already users' : 'is already a user'
          } on this account.`,
        ]
      })

      if (useEmails.length === 0) {
        return
      }
    }

    const { valid, invalid } = validateEmail(useEmails, true)

    if (invalid.length > 0) {
      setError((msg) => {
        return [...msg, messages.notValidEmail]
      })
    }

    if (valid.length === 0) return

    // 'Team' plan can't add more users than available licences
    if (maxToAdd !== null && valid.length > maxToAdd) {
      if (setWarningMessage)
        setWarningMessage(
          `You do not have enough remaining licences to add ${
            valid.length
          } new user${
            valid.length === 1 ? '' : 's'
          }. Remove some or purchase more licences.`,
        )

      if (isTeam && currentLicenceCount < teamTierMaxUsers) {
        // @ts-ignore
        if (window.dataLayer && window.dataLayer.push) {
          // @ts-ignore
          window.dataLayer.push({
            event: 'click-buy-more-licences',
          })
        }

        logAction({
          variables: {
            action: 'click-buy-more-licences',
            websiteSection: 'settings',
            pagePath: window.location.pathname,
            functionName: 'clickUpgrade',
          },
        })

        setShowAddUsersModal(true)
      } else {
        // @ts-ignore
        if (window.dataLayer && window.dataLayer.push) {
          // @ts-ignore
          window.dataLayer.push({
            event: 'click-add-users-upgrade-blocker',
            is_paddle: isTeam,
            number_to_add: valid.length,
          })
        }

        logAction({
          variables: {
            action: 'click-add-users-upgrade-blocker',
            websiteSection: 'settings',
            pagePath: '/settings',
            functionName: 'clickUpgrade',
            extra: JSON.stringify({
              isTeam,
              numberToAdd: valid.length,
            }),
          },
        })

        setShowUpgradeUsersModal(true)
      }

      return
    }

    try {
      setLoading(true)

      const uniqueUsers = [...new Set(valid)]
      const permissionLevelList = new Array(accountIDs.length).fill(permission)

      const { data, errors } = await addUsersToAccountsBulk({
        workspaceID,
        users: uniqueUsers,
        accountIDs,
        permissionLevelList,
      })

      logAction({
        variables: {
          action: 'add-users',
          websiteSection: 'settings',
          pagePath: '/settings',
          functionName: 'addUsers',
          extra: JSON.stringify({
            users: uniqueUsers,
            accountIDs,
            permission: permissionLevelList,
          }),
        },
      })

      if (errors) {
        throw new Error()
      }

      const successes: string[] = []

      const addedAccounts = data.userAccountSettings

      Object.keys(addedAccounts).forEach((key) => {
        if (key === '__typename') return

        const user = addedAccounts[key]

        const { email: newEmail } = user
        if (successes.indexOf(newEmail) === -1) {
          successes.push(newEmail)
        }
      })

      let successMsg = ''

      if (successes.length === 1) {
        // All values in data are the same
        // Same user has been added to one or more accounts

        // @ts-ignore
        successMsg = Object.values(addedAccounts)[0].inviteOpen
          ? `${successes[0]} has been sent a registration email and has 14 days to set a password.`
          : `${successes[0]} has been added to the selected account${
              accountIDs.length > 1 ? 's' : ''
            } as a${permission === 'admin' ? 'n admin' : ' regular'} user.`
      } else {
        successMsg = `${successes.length} users (${
          successes.length > 3
            ? `${successes.slice(0, 3).join(', ')}, ${
                successes.length - 3
              } other${successes.length - 3 > 1 ? 's' : ''}`
            : successes.join(', ')
        }) have been given access to the selected account${
          accountIDs.length > 1 ? 's' : ''
        } as ${permission === 'admin' ? 'admin' : 'regular'} users.`
      }

      setSuccess(successMsg)
      setEmail('')

      await refetchCompanyData({
        fetchPolicy: 'network-only',
      })

      if (!hasInvitedUsers) {
        // Updates the backend cache for onboarding state
        updateOnboardingSection('inviteUsers', 'account')
      }
    } catch (err) {
      setError((msg) => {
        return [...msg, 'Unable to add - please try again or contact support.']
      })
    } finally {
      setLoading(false)
    }
  }

  const accounts = useMemo(() => {
    if (currentUserAccountProfiles.length > 0) {
      const selectedAccounts = (returnUnique(
        currentUserAccountProfiles,
        'accountID',
      ) as typeof currentUserAccountProfiles)
        .filter(
          (account) =>
            account.companyID === companyID &&
            (account.userPermission === 'admin' ||
              account.userPermission === 'support'),
        )
        .map((account) => {
          return {
            optionName: account.accountName,
            optionValue: account.accountID,
          }
        })

      setAccountIDs(selectedAccounts.map((item) => item.optionValue))

      return selectedAccounts
    }
    return []
  }, [currentUserAccountProfiles])

  return (
    <div className={styles.subsection}>
      <h3>Add users</h3>
      <p className={styles.byline}>
        Enter an email address to send a new user a registration email or to add
        an existing user to additional accounts.
      </p>
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        <FormRow last className={styles.formRow}>
          <LabelSlot noPadding column>
            <Label id="addEmail" small>
              <Tooltip
                id="add-email-tooltip"
                useIcon
                tooltipMessage="Add multiple users by separating email addresses with commas or semicolons."
              >
                Email
              </Tooltip>
            </Label>
          </LabelSlot>
          <FieldSlot noPadding column>
            <Input
              name="addEmail"
              type="text"
              label="Email"
              value={email}
              onPaste={(e) => {
                const newPastedText = e.clipboardData
                  .getData('Text')
                  .replaceAll(/(\r|\n)/g, ',')

                setEmail(newPastedText)
              }}
              onValueChange={(nextEmail: string) => {
                setEmail(nextEmail)
              }}
              style={{ maxWidth: 420 }}
              required
            />
          </FieldSlot>
        </FormRow>
        {!(accounts.length === 1) && (
          <FormRow last className={styles.formRow}>
            <LabelSlot noPadding column>
              <Label id="account-select" small>
                <Tooltip
                  id="account-select-tooltip"
                  useIcon
                  tooltipMessage="Select which workspaces the users(s) should have access to."
                >
                  Workspaces
                </Tooltip>
              </Label>
            </LabelSlot>
            <FieldSlot noPadding column>
              <SelectBoxChecklist
                key={JSON.stringify(accounts)}
                id="account-select"
                className={styles.multiSelect}
                isLoading={accounts.length === 0}
                placeholder="Select workspaces"
                allLabel="All workspaces"
                labelKey="optionName"
                valueKey="optionValue"
                value={accounts.filter(
                  (acc) => accountIDs.indexOf(acc.optionValue) > -1,
                )}
                options={accounts}
                onChange={(newValue) =>
                  setAccountIDs(newValue.map(({ optionValue }) => optionValue))
                }
              />
            </FieldSlot>
          </FormRow>
        )}
        <FormRow last className={styles.formRow}>
          <LabelSlot noPadding column>
            <Label id="permission" small>
              <Tooltip
                id="permission-tooltip"
                useIcon
                tooltipMessage="**Regular** users can create and view campaign codes. **Admin** users can also edit your campaign code structure and account details."
              >
                Permission
              </Tooltip>
            </Label>
          </LabelSlot>
          <FieldSlot noPadding column>
            <PermissionSelector
              permission={permission}
              onChange={(nextPermission) => setPermission(nextPermission)}
            />
          </FieldSlot>
        </FormRow>
        <FormRow
          last
          className={classNames(styles.formRow, styles.formRowButton)}
        >
          <FieldSlot noPadding column>
            <Button
              loading={loading}
              isDisabled={email === '' || accountIDs.length === 0 || loading}
              type="submit"
            >
              Add
            </Button>
          </FieldSlot>
        </FormRow>
      </form>
      <div className={styles.formMessages}>
        {success && (
          <SuccessText className={styles.success}>{success}</SuccessText>
        )}
        {error.length > 0 &&
          error.map((err) => (
            <p className={styles.error} key={nanoid()}>
              {err}
            </p>
          ))}
      </div>
    </div>
  )
}
