import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import { ArcherContainer, ArcherElement } from 'react-archer'
import classNames from 'classnames'
import numeral from 'numeraljs'
import _ from 'lodash'

import { Tag } from './add-multi-values-tags'
import Button, { ClearButton, DeleteButton } from './button'
import NoDataMessage from './no-data-message'
import FileDragAndDrop from './file-drag-and-drop'
import { FormField, FormLabel, FormRow } from './form'
import Input from './input'
import Link from './link'
import { Preloader } from './loader'
import Modal from './modal'
import ReportSummary, { FilterListItem } from './report-summary'
import { SelectBoxChecklist } from './select-box'
import { Heading } from './typography'
import { UrlStatus } from './url-validation-message'
import client from '../api/apollo'
import {
  currentUserDetails,
  RecentlyValidatedUrl,
} from '../api/apollo/variables'
import {
  deleteMarketingBoardCard,
  fetchPageScreenshots,
  getMarketingFunnelReport,
  getTrackValidationResultsByLandingPage,
  listSavedMarketingFunnelBoards,
  updateMarketingBoardCard,
  updateMarketingBoardLane,
  updateMarketingFunnelBoard,
} from '../api/graphql/report-client'
import { getCreativeList } from '../api/graphql/track-create-client'
import { marketingJourneysReportAddImageToCard } from '../api/REST/report-client'
import EditIcon from '../assets/edit.svg'
// import PlusIcon from '../assets/show-more-icon-white.svg'
import { messages, supportEmail } from '../core/constants'
import { hexToRgb, isValidUrl } from '../helpers'
import {
  getReportDateRange,
  mergeConnections,
  transformConnections,
} from '../helpers/report-module'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/report-marketing-journeys-flow.module.scss'
import {
  CardConnectionsRefObject,
  MarketingJourneyDataConfig,
  SavedMarketingJourneyResponse,
} from '../types/report-module'
import { GetMarketingFunnelReportQuery } from '../__gql-types__/graphql'

const initialLaneColours = ['#e61969', '#0fc3f0', '#ffd70f']
const maxCardsPerLane = 20
const screenshotFetchLimit = 10

// ! Unused feature for now
// interface AddCardModalProps {
//   setAddCardToLane: React.Dispatch<React.SetStateAction<boolean>>
//   boardID: string
//   lane: GetMarketingFunnelReportQuery['report']['marketingFunnel']['getMarketingFunnelReport']['laneList'][0]
//   saveNewBoard?: (boardID: string, options: Partial<SavedMarketingJourneyResponse>) => Promise<void>
// }

// const AddCardModal = ({
//   setAddCardToLane,
//   boardID,
//   lane,
//   saveNewBoard,
// }: AddCardModalProps) => {
//   const logAction = useLogAction()

//   const [addCard] = useMutation(addMarketingBoardCard)

//   const [addingCard, setAddingCard] = useState(false)
//   const [addCardError, setAddCardError] = useState(false)
//   const [cardTitle, setCardTitle] = useState('')

//   const { laneID, cardList } = lane

//   const cardOrder = cardList.map(({ cardID }) => cardID)

//   return (
//     <Modal
//       setIsOpen={setAddCardToLane}
//       modalHeader="Add custom card to lane"
//       onYes={async () => {
//         try {
//           setAddingCard(true)
//           setAddCardError(false)

//           if (saveNewBoard) {
//             await saveNewBoard(boardID, {boardTitle: 'Board with custom cards'})
//           }

//           await addCard({
//             variables: {
//               boardID,
//               laneID,
//               cardTitle,
//               curCardOrder: cardOrder,
//             },
//           })

//           logAction({
//             variables: {
//               action: 'marketingJourneys-add-card-to-board',
//               extra: JSON.stringify({
//                 boardID,
//                 laneID,
//                 cardTitle,
//               }),
//               websiteSection: 'report',
//               functionName: 'marketingJourneysAddCard',
//               pagePath: window.location.pathname,
//             },
//           })

//           setAddCardToLane(false)
//         } catch {
//           setAddCardError(true)
//         } finally {
//           setAddingCard(false)
//         }
//       }}
//       yesText="Add card"
//       yesButtonLoading={addingCard}
//       yesButtonDisabled={!cardTitle}
//       footerContent={
//         addCardError ? (
//           <p className={styles.footNoteError}>
//             Error adding card. Please contact{' '}
//             <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>.
//           </p>
//         ) : undefined
//       }
//     >
//       <div className={styles.editModal}>
//         <p>Add your own custom information to this report.</p>
//         <FormRow>
//           <LabelSlot topAlign>
//             <Label modalHeading>Card title</Label>
//           </LabelSlot>
//           <FieldSlot>
//             <Input
//               name="cardTitle"
//               id="cardTitle"
//               type="text"
//               value={cardTitle}
//               placeholder="Add card title"
//               onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
//                 const { value: val } = event.target

//                 setCardTitle(val)
//               }}
//             />
//           </FieldSlot>
//         </FormRow>
//       </div>
//     </Modal>
//   )
// }

interface DeleteCardModalProps {
  boardID: string
  laneID: string
  cardID: string
  cardType?: string
  setDeleteCardModal: React.Dispatch<React.SetStateAction<boolean>>
  saveNewBoard?: (
    boardID: string,
    options: Partial<SavedMarketingJourneyResponse>,
  ) => Promise<void>
}

const DeleteCardModal = ({
  boardID,
  laneID,
  cardID,
  cardType,
  setDeleteCardModal,
  saveNewBoard,
}: DeleteCardModalProps) => {
  const logAction = useLogAction()

  const [deleteCard] = useMutation(deleteMarketingBoardCard)
  const [hideCard] = useMutation(updateMarketingBoardCard)

  const [deletingCard, setDeletingCard] = useState(false)
  const [deleteCardError, setDeleteCardError] = useState(false)

  return (
    <Modal
      setIsOpen={setDeleteCardModal}
      modalHeader="Delete card from board"
      onYes={async () => {
        try {
          setDeletingCard(true)
          setDeleteCardError(false)

          if (saveNewBoard) {
            await saveNewBoard(boardID, {
              boardTitle: 'Board with updated cards',
            })
          }

          if (cardType !== 'metric') {
            await deleteCard({
              variables: {
                boardID,
                laneID,
                cardID,
              },
            })
          } else {
            await hideCard({
              variables: {
                boardID,
                laneID,
                cardID,
                hideCard: true,
              },
            })
          }

          logAction({
            variables: {
              action: 'marketingJourneys-delete-card-from-board',
              extra: JSON.stringify({
                boardID,
                laneID,
              }),
              websiteSection: 'report',
              functionName: 'marketingJourneysDeleteCard',
              pagePath: window.location.pathname,
            },
          })

          setDeleteCardModal(false)
        } catch {
          setDeleteCardError(true)
        } finally {
          setDeletingCard(false)
        }
      }}
      yesText="Delete card"
      yesButtonLoading={deletingCard}
      footerContent={
        deleteCardError ? (
          <p className={styles.footNoteError}>
            Error removing card. Please contact{' '}
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>.
          </p>
        ) : undefined
      }
    >
      <div className={styles.editModal}>
        <p>Are you sure? The card will be hidden from the board.</p>
      </div>
    </Modal>
  )
}

interface EditCardModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  boardID: string
  laneID: string
  showImgUploader?: boolean
  card: GetMarketingFunnelReportQuery['report']['marketingFunnel']['getMarketingFunnelReport']['laneList'][0]['cardList'][0]
  cardTitlePrefix?: string
  saveNewBoard?: (
    boardID: string,
    options: Partial<SavedMarketingJourneyResponse>,
  ) => Promise<void>
}

const EditCardModal = ({
  setIsOpen,
  boardID,
  laneID,
  showImgUploader = false,
  card,
  cardTitlePrefix = 'Edit card name',
  saveNewBoard,
}: EditCardModalProps) => {
  const {
    cardID,
    cardTitle,
    updatedTitle,
    additionalImages,
    additionalMetrics,
  } = card

  const logAction = useLogAction()

  const [getReportData] = useLazyQuery(getMarketingFunnelReport, {
    fetchPolicy: 'network-only',
  })
  const [updateCard] = useMutation(updateMarketingBoardCard)

  const [updatingCard, setUpdatingCard] = useState(false)
  const [updateCardError, setUpdateCardError] = useState(false)
  const [updatedCard, setUpdatedCard] = useState({
    cardTitle: updatedTitle || cardTitle,
    additionalImages,
    metrics:
      additionalMetrics && additionalMetrics.length > 0
        ? additionalMetrics.map(({ metricName, metricValue }) => ({
            metricName,
            metricValue: metricValue || 0,
            metricFormat: 'float',
          }))
        : [{ metricName: '', metricValue: 0, metricFormat: 'float' }],
    hasBeenUpdated: false,
  })
  const [imageUploadStatus, setImageUploadStatus] = useState<{
    file: File | null
    error: string
  }>({
    file: null,
    error: '',
  })

  return (
    <Modal
      setIsOpen={setIsOpen}
      modalHeader="Edit card"
      yesText="Update"
      yesButtonLoading={updatingCard}
      yesButtonDisabled={!updatedCard.hasBeenUpdated && !imageUploadStatus.file}
      onYes={async () => {
        try {
          setUpdatingCard(true)
          setUpdateCardError(false)

          // Upload file to card
          if (imageUploadStatus.file) {
            await marketingJourneysReportAddImageToCard({
              boardID,
              laneID,
              cardID,
              file: imageUploadStatus.file,
            })

            // Need to refetch board
            if (!updatedCard.hasBeenUpdated) {
              getReportData({ variables: { boardID } })
            }
          }

          if (saveNewBoard) {
            await saveNewBoard(boardID, {
              boardTitle: 'Board with edited card',
            })
          }

          if (updatedCard.hasBeenUpdated) {
            await updateCard({
              variables: {
                boardID,
                laneID,
                cardID,
                cardTitle: updatedCard.cardTitle,
                additionalImages: updatedCard.additionalImages,
                additionalMetrics: updatedCard.metrics,
              },
            })
          }

          logAction({
            variables: {
              action: 'marketingJourneys-update-card',
              extra: JSON.stringify({
                boardID,
                laneID,
                cardID,
                cardTitle: updatedCard.cardTitle,
                additionalImages: updatedCard.additionalImages,
                additionalMetrics: updatedCard.metrics,
              }),
              websiteSection: 'report',
              functionName: 'marketingJourneysUpdateCard',
              pagePath: window.location.pathname,
            },
          })

          setIsOpen(false)
        } catch {
          setUpdateCardError(true)
        } finally {
          setUpdatingCard(false)
        }
      }}
      footerContent={
        updateCardError ? (
          <p className={styles.footNoteError}>
            Error updating card. Please contact{' '}
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>.
          </p>
        ) : undefined
      }
    >
      <div className={styles.editModal}>
        <FormRow className={styles.modalFormRow} includePaddingBottom>
          <FormLabel
            id="report-card-name"
            tooltip={`Name of the card (Original name: ${cardTitle}).`}
          >
            {cardTitlePrefix}
          </FormLabel>
          <FormField>
            <Input
              id="card-name"
              name="card-name"
              type="text"
              value={updatedCard.cardTitle}
              placeholder="Update lane title"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { value: val } = event.target

                setUpdatedCard((curr) => ({
                  ...curr,
                  cardTitle: val,
                  hasBeenUpdated: true,
                }))
              }}
            />
          </FormField>
        </FormRow>

        {showImgUploader && (
          <div className={styles.cardImagesContainer}>
            <Heading type={3}>Add card images</Heading>
            {updatedCard.additionalImages &&
              updatedCard.additionalImages.length > 0 && (
                <DragDropContext
                  onDragEnd={(result) => {
                    if (!updatedCard.additionalImages) return

                    const { destination, source } = result

                    if (!destination) return

                    if (
                      destination.droppableId === source.droppableId &&
                      destination.index === source.index
                    ) {
                      return
                    }

                    const newOrder = Array.from(updatedCard.additionalImages)
                    const imgToMove = newOrder.splice(source.index, 1)
                    newOrder.splice(destination.index, 0, imgToMove[0])

                    setUpdatedCard((curr) => {
                      return {
                        ...curr,
                        additionalImages: newOrder,
                        hasBeenUpdated: true,
                      }
                    })
                  }}
                >
                  <Droppable droppableId={cardID} direction="horizontal">
                    {(droppableProvided) => {
                      return (
                        <div
                          className={styles.existingCardImages}
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                        >
                          {/* @ts-ignore */}
                          {updatedCard.additionalImages.map((img, imgIndex) => {
                            return (
                              <Draggable
                                key={img}
                                draggableId={img}
                                index={imgIndex}
                              >
                                {(draggableProvided) => {
                                  return (
                                    <div
                                      className={styles.editCardImageContainer}
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      {...draggableProvided.dragHandleProps}
                                    >
                                      <img
                                        className={styles.editCardImage}
                                        src={img}
                                        alt={`Asset #${imgIndex} for card "${cardTitle}"`}
                                      />
                                      <DeleteButton
                                        className={styles.deleteImageButton}
                                        onPress={() => {
                                          setUpdatedCard((curr) => {
                                            if (!curr.additionalImages) {
                                              return curr
                                            }

                                            const updatedImages = [
                                              ...curr.additionalImages,
                                            ]

                                            updatedImages.splice(imgIndex, 1)

                                            return {
                                              ...curr,
                                              additionalImages: updatedImages,
                                              hasBeenUpdated: true,
                                            }
                                          })
                                        }}
                                      />
                                    </div>
                                  )
                                }}
                              </Draggable>
                            )
                          })}
                          {droppableProvided.placeholder}
                        </div>
                      )
                    }}
                  </Droppable>
                </DragDropContext>
              )}
            {!updatedCard.additionalImages ||
              (updatedCard.additionalImages.length < 3 && (
                <div className={styles.fileUploader}>
                  <FileDragAndDrop
                    className={styles.dragAndDrop}
                    uploadButtonText="Upload image"
                    disabled={!!imageUploadStatus.file}
                    success={
                      imageUploadStatus.file
                        ? `Ready to upload file ${imageUploadStatus.file.name}.`
                        : ''
                    }
                    onDrop={async (files) => {
                      setImageUploadStatus({
                        file: null,
                        error: '',
                      })

                      if (files.length > 0) {
                        const file = files.pop()

                        if (file) {
                          const { type, size } = file

                          if (
                            type &&
                            size &&
                            type.indexOf('image/') !== -1 &&
                            size < 1038383
                          ) {
                            setImageUploadStatus({
                              file,
                              error: '',
                            })
                          } else {
                            setImageUploadStatus({
                              file: null,
                              error: messages.fileUploadErrorImageOnly,
                            })
                          }
                        } else {
                          setImageUploadStatus({
                            file: null,
                            error: '',
                          })
                        }
                      } else {
                        setImageUploadStatus({
                          file: null,
                          error: messages.fileUploadError,
                        })
                      }
                    }}
                    uploadError={imageUploadStatus.error}
                  />
                  {imageUploadStatus.file && (
                    <ClearButton
                      className={styles.cancelButton}
                      onPress={() =>
                        setImageUploadStatus({
                          file: null,
                          error: '',
                        })
                      }
                    >
                      Cancel
                    </ClearButton>
                  )}
                </div>
              ))}
          </div>
        )}

        <div className={styles.addCustomMetricsContainer}>
          <Heading type={3}>Add metric manually</Heading>
          <div>
            {updatedCard.metrics.map((metric, metricIndex) => {
              return (
                <div
                  className={styles.addMetricBlock}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${cardID}-metric-${metricIndex}`}
                >
                  <Input
                    name={`metricName-${metricIndex}`}
                    id={`metricName-${metricIndex}`}
                    className={styles.metricRowNameField}
                    type="text"
                    value={metric.metricName}
                    placeholder="Metric name"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const { value: val } = event.target

                      setUpdatedCard((curr) => {
                        const newMetrics = [...curr.metrics]

                        newMetrics[metricIndex].metricName = val

                        return {
                          ...curr,
                          metrics: newMetrics,
                          hasBeenUpdated: true,
                        }
                      })
                    }}
                  />
                  <Input
                    name={`metricValue-${metricIndex}`}
                    id={`metricValue-${metricIndex}`}
                    className={styles.metricRowValueField}
                    type="number"
                    value={metric.metricValue || ''}
                    placeholder="Metric value"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const { value: val } = event.target

                      setUpdatedCard((curr) => {
                        const newMetrics = [...curr.metrics]

                        newMetrics[metricIndex].metricValue = parseFloat(val)

                        return {
                          ...curr,
                          metrics: newMetrics,
                          hasBeenUpdated: true,
                        }
                      })
                    }}
                  />
                  {metricIndex > 0 && (
                    <DeleteButton
                      className={styles.deleteMetricButton}
                      onPress={() => {
                        setUpdatedCard((curr) => {
                          const newMetrics = [...curr.metrics]

                          newMetrics.splice(metricIndex, 1)

                          return {
                            ...curr,
                            metrics: newMetrics,
                            hasBeenUpdated: true,
                          }
                        })
                      }}
                    />
                  )}
                </div>
              )
            })}
          </div>
          {updatedCard.metrics.length <= 3 &&
            updatedCard.metrics[updatedCard.metrics.length - 1].metricName !==
              '' && (
              <Button
                className={styles.addAnotherMetric}
                variant="secondary"
                type="submit"
                onPress={() => {
                  setUpdatedCard((curr) => {
                    const newMetrics = [...curr.metrics]

                    newMetrics.push({
                      metricName: '',
                      metricValue: 0,
                      metricFormat: 'float',
                    })

                    return {
                      ...curr,
                      metrics: newMetrics,
                      hasBeenUpdated: true,
                    }
                  })
                }}
              >
                Add another
              </Button>
            )}
        </div>
      </div>
    </Modal>
  )
}

// ! Unused feature for now
// type CardProps = Omit<
//   GetMarketingFunnelReportQuery['report']['marketingFunnel']['getMarketingFunnelReport']['laneList'][0]['cardList'][0],
//   '__typename'
// >

// interface ConnectionSourceCard extends CardProps {
//   laneID: string
//   laneIndex: number
//   side: 'left' | 'right'
// }

// ! Component not currently used
// interface AddConnectionModalProps {
//   boardID: string
//   connectionSourceCard: ConnectionSourceCard
//   setConnectionSourceCard: React.Dispatch<
//     React.SetStateAction<ConnectionSourceCard | null>
//   >
//   targetCard: { cardID: string; cardTitle: string }
//   setTargetCard: React.Dispatch<
//     React.SetStateAction<{ cardID: string; cardTitle: string } | null>
//   >
//   saveNewBoard?: (boardID: string, options: Partial<SavedMarketingJourneyResponse>) => Promise<void>
// }

// const AddConnectionModal = ({
//   boardID,
//   connectionSourceCard,
//   setConnectionSourceCard,
//   targetCard,
//   setTargetCard,
//   saveNewBoard,
// }: AddConnectionModalProps) => {
//   const logAction = useLogAction()

//   const [addConnection] = useMutation(updateMarketingBoardCard)

//   const {
//     cardID,
//     laneID,
//     cardType,
//     additionalConnections: existingAdditionalConnections,
//   } = connectionSourceCard

//   const [addingConnection, setAddingConnection] = useState(false)
//   const [addConnectionError, setAddConnectionError] = useState(false)

//   const [metrics, setMetrics] = useState<
//     { metricName: string; metricValue: number }[]
//   >([{ metricName: '', metricValue: 0 }])

//   return (
//     <Modal
//       setIsOpen={() => {
//         setConnectionSourceCard(null)
//         setTargetCard(null)
//       }}
//       modalHeader="Add new connection"
//       yesText="Create connection"
//       yesButtonLoading={addingConnection}
//       onYes={async () => {
//         try {
//           setAddConnectionError(false)
//           setAddingConnection(true)

//           const newConnections = metrics.map((metric) => {
//             return {
//               connectedFrom: cardID,
//               connectedTo: targetCard.cardID,
//               connectionMetricName: metric.metricName,
//               connectionMetricValue: metric.metricValue,
//               connectionType: cardType,
//               hideConnection: false,
//               updatedConnectionMetricName: metric.metricName,
//               updatedConnectionMetricValue: metric.metricValue,
//             }
//           })

//           if (saveNewBoard) {
//             await saveNewBoard(boardID, {boardTitle: 'Board with custom connections'})
//           }

//           await addConnection({
//             variables: {
//               boardID,
//               laneID,
//               cardID,
//               additionalConnections: existingAdditionalConnections
//                 ? [...existingAdditionalConnections, ...newConnections]
//                 : newConnections,
//             },
//           })

//           logAction({
//             variables: {
//               action: 'marketingJourneys-add-connection-to-board',
//               extra: JSON.stringify({
//                 boardID,
//                 laneID,
//                 cardID,
//                 additionalConnections: existingAdditionalConnections
//                   ? [...existingAdditionalConnections, ...newConnections]
//                   : newConnections,
//               }),
//               websiteSection: 'report',
//               functionName: 'marketingJourneysAddConnection',
//               pagePath: window.location.pathname,
//             },
//           })

//           setConnectionSourceCard(null)
//           setTargetCard(null)
//         } catch {
//           setAddConnectionError(true)
//         } finally {
//           setAddingConnection(false)
//         }
//       }}
//       footerContent={
//         addConnectionError ? (
//           <p className={styles.footNoteError}>
//             Error adding connection. Please contact{' '}
//             <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>.
//           </p>
//         ) : undefined
//       }
//     >
//       <div className={styles.editModal}>
//         <p>
//           You are adding a connection from "{connectionSourceCard.cardTitle}" to
//           "{targetCard.cardTitle}".
//         </p>
//         <p>You can add metrics to this connection here.</p>
//       </div>
//       <div>
//         {metrics.map((metric, metricIndex) => {
//           return (
//             <div
//               className={styles.addMetricBlock}
//               // eslint-disable-next-line react/no-array-index-key
//               key={`${cardID}-${targetCard.cardID}-metric-${metricIndex}`}
//             >
//               <Input
//                 name={`metricName-${metricIndex}`}
//                 id={`metricName-${metricIndex}`}
//                 className={styles.metricRowNameField}
//                 type="text"
//                 value={metric.metricName}
//                 placeholder="Metric name"
//                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
//                   const { value: val } = event.target

//                   setMetrics((curr) => {
//                     const newMetrics = [...curr]

//                     newMetrics[metricIndex].metricName = val

//                     return newMetrics
//                   })
//                 }}
//               />
//               <Input
//                 name={`metricValue-${metricIndex}`}
//                 id={`metricValue-${metricIndex}`}
//                 className={styles.metricRowValueField}
//                 type="number"
//                 value={metric.metricValue || ''}
//                 placeholder="Metric value"
//                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
//                   const { value: val } = event.target

//                   setMetrics((curr) => {
//                     const newMetrics = [...curr]

//                     newMetrics[metricIndex].metricValue = parseFloat(val)

//                     return newMetrics
//                   })
//                 }}
//               />
//               {metricIndex > 0 && (
//                 <DeleteButton
//                   className={styles.deleteMetricButton}
//                   onPress={() => {
//                     setMetrics((curr) => {
//                       const newMetrics = [...curr]

//                       newMetrics.splice(metricIndex, 1)

//                       return newMetrics
//                     })
//                   }}
//                 />
//               )}
//             </div>
//           )
//         })}
//       </div>
//       {metrics.length <= 3 && metrics[metrics.length - 1].metricName !== '' && (
//         <Button
//           className={styles.addAnotherMetric}
//           variant="secondary"
//           type="submit"
//           onPress={() =>
//             setMetrics((curr) => {
//               const newMetrics = [...curr]

//               newMetrics.push({ metricName: '', metricValue: 0 })

//               return newMetrics
//             })
//           }
//         >
//           Add another
//         </Button>
//       )}
//     </Modal>
//   )
// }

interface EditLaneModalProps {
  setEditLaneModal: React.Dispatch<React.SetStateAction<boolean>>
  boardID: string
  laneToEdit: GetMarketingFunnelReportQuery['report']['marketingFunnel']['getMarketingFunnelReport']['laneList'][0]
  laneIndex: number
  saveNewBoard?: (
    boardID: string,
    options: Partial<SavedMarketingJourneyResponse>,
  ) => Promise<void>
}

const EditLaneModal = ({
  setEditLaneModal,
  boardID,
  laneToEdit,
  laneIndex,
  saveNewBoard,
}: EditLaneModalProps) => {
  const {
    laneID,
    laneTitle,
    laneMetrics,
    selectedMetricList,
    primaryColour,
  } = laneToEdit

  const logAction = useLogAction()

  const [updateLaneTitle] = useMutation(updateMarketingBoardLane)

  const [updatingLane, setUpdatingLane] = useState(false)
  const [laneUpdateError, setLaneUpdateError] = useState(false)
  const [updatedLane, setUpdatedLane] = useState({
    laneTitle,
    laneColour: primaryColour || initialLaneColours[laneIndex % 3],
    metricList:
      selectedMetricList.length > 0
        ? selectedMetricList
        : [laneMetrics[0].metricName],
    hasBeenUpdated: false,
  })

  return (
    <Modal
      setIsOpen={setEditLaneModal}
      modalHeader="Edit lane"
      yesText="Update"
      yesButtonLoading={updatingLane}
      yesButtonDisabled={!updatedLane.hasBeenUpdated}
      onYes={async () => {
        try {
          setUpdatingLane(true)
          setLaneUpdateError(false)

          if (saveNewBoard) {
            await saveNewBoard(boardID, {
              boardTitle: 'Board with edited lane',
            })
          }

          await updateLaneTitle({
            variables: {
              boardID,
              laneID,
              laneTitle: updatedLane.laneTitle,
              primaryColour: updatedLane.laneColour,
              selectedMetricList: updatedLane.metricList,
            },
          })

          logAction({
            variables: {
              action: 'marketingJourneys-update-board-lane',
              extra: JSON.stringify({
                boardID,
                laneID,
                laneTitle: updatedLane.laneTitle,
                primaryColour: updatedLane.laneColour,
                selectedMetricList: updatedLane.metricList,
              }),
              websiteSection: 'report',
              functionName: 'marketingJourneysUpdateLane',
              pagePath: window.location.pathname,
            },
          })

          setEditLaneModal(false)
        } catch {
          setLaneUpdateError(true)
        } finally {
          setUpdatingLane(false)
        }
      }}
      footerContent={
        laneUpdateError ? (
          <p className={styles.footNoteError}>
            Error updating lane. Please contact{' '}
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>.
          </p>
        ) : undefined
      }
    >
      <div className={styles.editModal}>
        <p>Change the lane title, colour and the metrics shown.</p>
        <FormRow className={styles.modalFormRow} includePaddingBottom>
          <FormLabel>Lane title</FormLabel>
          <FormField>
            <Input
              name="laneName"
              id="laneName"
              type="text"
              value={updatedLane.laneTitle}
              placeholder="Update lane title"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { value: val } = event.target

                setUpdatedLane((curr) => ({
                  ...curr,
                  laneTitle: val,
                  hasBeenUpdated: true,
                }))
              }}
            />
          </FormField>
        </FormRow>
        <FormRow
          className={styles.modalFormRow}
          includePaddingBottom={laneIndex !== 2}
          bottomBorder={laneIndex !== 2}
        >
          <FormLabel>Lane colour (hex code)</FormLabel>
          <FormField>
            <Input
              name="laneColour"
              id="laneColour"
              type="text"
              value={updatedLane.laneColour}
              beforeChange={(value) => `#${value.replace(/[^a-zA-Z0-9]/g, '')}`}
              onValueChange={(val) => {
                setUpdatedLane((curr) => ({
                  ...curr,
                  laneColour: val,
                  hasBeenUpdated: val.length > 3 && val.length < 10,
                }))
              }}
            />
          </FormField>
        </FormRow>
        {laneIndex !== 2 && (
          <FormRow className={styles.modalFormRow} bottomBorder={false}>
            <FormLabel>Metrics to show</FormLabel>
            <FormField>
              <SelectBoxChecklist
                excludeAny
                excludeNone
                isClearable={false}
                labelKey="metricName"
                valueKey="metricName"
                required
                menuPosition="fixed"
                options={laneMetrics}
                value={laneMetrics.filter(({ metricName }) =>
                  updatedLane.metricList.includes(metricName),
                )}
                onChange={(newValue) => {
                  if (!newValue || newValue.length === 0) return

                  setUpdatedLane((curr) => ({
                    ...curr,
                    metricList: newValue.map(({ metricName }) => metricName),
                    hasBeenUpdated: true,
                  }))
                }}
              />
            </FormField>
          </FormRow>
        )}
      </div>
    </Modal>
  )
}

// ! Component not currently used
// interface AddConnectionButtonProps {
//   cardID: string
//   side: 'left' | 'right'
//   connectionSourceCard: ConnectionSourceCard | null
//   clickAddConnectionButton: (side: 'left' | 'right') => void
// }

// const AddConnectionButton = ({
//   cardID,
//   side,
//   connectionSourceCard,
//   clickAddConnectionButton,
// }: AddConnectionButtonProps) => {
//   const {
//     cardID: sourceCardID,
//     side: sourceCardSide,
//   } = connectionSourceCard || { cardID: null, side: null }

//   return (
//     <Tooltip
//       id={`${cardID}-add-connection-${side}`}
//       className={classNames(styles.addConnectionTooltip, styles[side])}
//       tooltipMessage={
//         sourceCardID === cardID && sourceCardSide === side
//           ? 'Select a card to connect to'
//           : 'Add connection'
//       }
//       forceOpen={
//         (sourceCardID === cardID && sourceCardSide === side) || undefined
//       }
//     >
//       <Button
//         variant="iconOnly"
//         icon={{
//           src: PlusIcon,
//           alt: `Add connection ${side}`,
//         }}
//         onPress={() => clickAddConnectionButton(side)}
//       />
//     </Tooltip>
//   )
// }

interface VideoModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  cardTitle: string
  src: string
}

const VideoModal = ({ setIsOpen, cardTitle, src }: VideoModalProps) => {
  return (
    <Modal setIsOpen={setIsOpen} modalHeader={`Video for "${cardTitle}"`}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video className={styles.creativeVideo} src={src} controls />
    </Modal>
  )
}

interface LandingPageCardRefObject {
  [cardUrl: string]: {
    screenshot?: {
      imgSrc: string | null
      loading?: boolean
      error?: boolean
    }
    validationData?: Pick<
      RecentlyValidatedUrl,
      | 'badUrl'
      | 'noAnalyticsTag'
      | 'redirectedLandingPage'
      | 'slowLandingPage'
      | 'statusCode'
    > | null
  }
}

interface SelectedCard {
  id: string
  title: string
  index: number
  laneTitle: string
  laneIndex: 0 | 1 | 2
  yOffset?: number
  isFocussed?: boolean
}

interface ParameterCreatives {
  [optionName: string]: {
    creativeID: string
    blobURL?: string | null
  }[]
}

interface BoardCardProps {
  selectedCard: SelectedCard | null
  setSelectedCard: React.Dispatch<React.SetStateAction<SelectedCard | null>>
  boardID: string
  laneID: string
  laneTitle: string
  laneIndex: 0 | 1 | 2
  card: GetMarketingFunnelReportQuery['report']['marketingFunnel']['getMarketingFunnelReport']['laneList'][0]['cardList'][0]
  cardIndex: number
  cardColour: { r: number; g: number; b: number }
  selectedMetricList: string[]
  showConnections?: boolean
  connectionRefs: CardConnectionsRefObject
  stackDimensionName?: string
  landingPageCardRefs?: LandingPageCardRefObject
  parameterCreatives?: ParameterCreatives
  freezeView: boolean
  setFreezeView: React.Dispatch<React.SetStateAction<boolean>>
  saveNewBoard?: (
    boardID: string,
    options: Partial<SavedMarketingJourneyResponse>,
  ) => Promise<void>
}

const BoardCard = ({
  selectedCard,
  setSelectedCard,
  boardID,
  laneID,
  laneTitle,
  laneIndex,
  card,
  cardIndex,
  cardColour,
  selectedMetricList = [],
  connectionRefs,
  stackDimensionName,
  landingPageCardRefs = {},
  parameterCreatives,
  freezeView,
  setFreezeView,
  saveNewBoard,
}: BoardCardProps) => {
  const { workspaceHomepage } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  /** Used to check the offset of the hovered card and push its connected cards to be level with it */
  const cardRef = useRef<HTMLDivElement>(null)

  const {
    cardID,
    cardType,
    updatedTitle,
    cardTitle,
    hideCard,
    cardMetrics,
    additionalImages,
    additionalMetrics,
  } = card

  const [showEditCardButton, setShowEditCardButton] = useState(false)
  const [editCardModal, setEditCardModal] = useState(false)
  const [deleteCardModal, setDeleteCardModal] = useState(false)
  // const [
  //   addConnectionModalTargetCard,
  //   setAddConnectionModalTargetCard,
  // ] = useState<{ cardID: string; cardTitle: string } | null>(null)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [videoSrc, setVideoSrc] = useState('')

  const metricsToShow = useMemo(() => {
    const filteredMetrics = cardMetrics.filter((metric) =>
      selectedMetricList.includes(metric.metricName),
    )

    if (filteredMetrics.length === 0 && cardMetrics[0]) {
      filteredMetrics.push(cardMetrics[0])
    }

    if (additionalMetrics) {
      filteredMetrics.push(...additionalMetrics)
    }

    if (filteredMetrics.length > 0) {
      return filteredMetrics
    }

    return []
  }, [cardMetrics, additionalMetrics, selectedMetricList])

  /**
   * Logic to determine if card should be highlighted, normal, invisible or hidden
   * invisible !== hidden => invisible preserves the yOffsets of cards, hidden does not
   */
  const cardState = useMemo(() => {
    // If no card is hovered, show normal state
    if (!selectedCard) return 'default'

    // Hovered card is always active
    if (selectedCard.id === cardID) return 'active'

    const selectedCardRef = connectionRefs[selectedCard.id]

    // If card is not hovered but in same column as hovered card, do not show it
    if (laneIndex === selectedCardRef.laneIndex) {
      return selectedCard.isFocussed ? 'hidden' : 'greyedOut'
    }

    // Check if current card has connections referencing the hovered card
    const selectedCardIsInConnections = connectionRefs[cardID].connections.find(
      ({ connectedTo, secondaryConnectionIDs }) =>
        connectedTo === selectedCard.id ||
        (secondaryConnectionIDs &&
          secondaryConnectionIDs.includes(selectedCard.id)),
    )

    if (selectedCardIsInConnections) return 'active'

    // Check if the hovered card is connected to current card
    const currentCardIsConnectedToSelectedCard = selectedCardRef.connections.find(
      ({ connectedTo, secondaryConnectionIDs }) => {
        if (
          connectedTo === cardID ||
          (secondaryConnectionIDs && secondaryConnectionIDs.includes(cardID))
        ) {
          return true
        }

        return false
      },
    )

    if (currentCardIsConnectedToSelectedCard) return 'active'

    return 'hidden'
  }, [selectedCard, connectionRefs])

  const cardConnections = useMemo(() => {
    // If no card is currently hovered
    if (!selectedCard || cardState !== 'active') {
      return []
    }

    const currentCardRef = connectionRefs[cardID]

    return mergeConnections(selectedCard.id, currentCardRef, metricsToShow)
  }, [selectedCard, cardID, cardState, connectionRefs, metricsToShow])

  const cardRelations = useMemo(() => {
    return transformConnections(cardConnections)
  }, [cardConnections])

  /**
   * Determine whether 'Add connection' buttons should show.
   * Should only be possible if a card is hovered or a connection source has been selected.
   */
  // ! Unused feature for now
  // const showConnectionButtons = useMemo(() => {
  //   // Hovered card should only show the button on a side if not already connected to all cards on that side
  //   if (selectedCard && selectedCard.id === cardID) {
  //     let shouldShow: boolean | 'left' | 'right' = true

  //     const leftCards = Object.keys(connectionRefs).filter(
  //       (connectionRef) =>
  //         connectionRefs[connectionRef].laneIndex === laneIndex - 1,
  //     )
  //     const rightCards = Object.keys(connectionRefs).filter(
  //       (connectionRef) =>
  //         connectionRefs[connectionRef].laneIndex === laneIndex + 1,
  //     )

  //     if (
  //       leftCards.every((leftCard) => {
  //         const adjacentConnections = connectionRefs[cardID].connections.concat(
  //           connectionRefs[cardID].additionalConnections,
  //         )

  //         return adjacentConnections.find(
  //           ({ connectedTo, connectedFrom }) =>
  //             connectedTo === leftCard || connectedFrom === leftCard,
  //         )
  //       })
  //     ) {
  //       // Should not show on the left
  //       shouldShow = 'right'
  //     }

  //     if (
  //       rightCards.every((rightCard) => {
  //         const adjacentConnections = connectionRefs[cardID].connections.concat(
  //           connectionRefs[cardID].additionalConnections,
  //         )

  //         return adjacentConnections.find(
  //           ({ connectedTo, connectedFrom }) =>
  //             connectedTo === rightCard || connectedFrom === rightCard,
  //         )
  //       })
  //     ) {
  //       // Should not show on the right
  //       shouldShow = shouldShow === 'right' ? false : 'left'
  //     }

  //     return shouldShow
  //   }

  //   if (connectionSourceCard) {
  //     const {
  //       laneIndex: sourceCardLaneIndex,
  //       cardID: sourceCardID,
  //       side: sourceCardSide,
  //     } = connectionSourceCard

  //     // Make sure the source button stays visible (it's on the opposite side for the source card)
  //     if (cardID === sourceCardID) {
  //       return sourceCardSide
  //     }

  //     // Only buttons in an adjacent lane and on the correct side should show
  //     if (
  //       Math.abs(sourceCardLaneIndex - laneIndex) !== 1 ||
  //       (sourceCardSide === 'right' && laneIndex < sourceCardLaneIndex) ||
  //       (sourceCardSide === 'left' && laneIndex > sourceCardLaneIndex)
  //     ) {
  //       return false
  //     }

  //     const currentCardAdjacentConnections = connectionRefs[
  //       cardID
  //     ].connections.concat(connectionRefs[cardID].additionalConnections)

  //     if (
  //       currentCardAdjacentConnections.find(
  //         ({ connectedFrom, connectedTo }) =>
  //           connectedFrom === sourceCardID || connectedTo === sourceCardID,
  //       )
  //     ) {
  //       return false
  //     }

  //     // State which side they should show on
  //     return sourceCardLaneIndex < laneIndex ? 'left' : 'right'
  //   }

  //   return false
  // }, [connectionSourceCard, laneIndex, cardID, selectedCard, connectionRefs])

  // Action to be taken when 'Add connection' button is clicked
  // ! Unused feature for now
  // const clickAddConnectionButton = useCallback(
  //   (side: 'left' | 'right') => {
  //     setSelectedCard(null)

  //     if (connectionSourceCard) {
  //       // Connector is being ended
  //       setAddConnectionModalTargetCard({
  //         cardID,
  //         cardTitle: updatedTitle || cardTitle,
  //       })
  //     } else {
  //       // Connector is being started
  //       setConnectionSourceCard({
  //         ...card,
  //         additionalConnections:
  //           card.additionalConnections?.map(
  //             ({
  //               connectedFrom,
  //               connectedTo,
  //               connectionMetricName,
  //               connectionMetricValue,
  //               connectionType,
  //               hideConnection,
  //               updatedConnectionMetricName,
  //               updatedConnectionMetricValue,
  //             }) => ({
  //               connectedFrom,
  //               connectedTo,
  //               connectionMetricName,
  //               connectionMetricValue,
  //               connectionType,
  //               hideConnection,
  //               updatedConnectionMetricName,
  //               updatedConnectionMetricValue,
  //             }),
  //           ) || [],
  //         laneID,
  //         laneIndex,
  //         side,
  //       })
  //     }
  //   },
  //   [card, connectionSourceCard, updatedTitle, cardTitle],
  // )

  /** "(Other)" cards should be hidden if all their shown metric values are 0 */
  const notRendered = useMemo(() => {
    if (hideCard) return true

    // User-added cards should always show (unless hidden)
    if (cardType === 'userAnnotation') return false

    if (
      (cardTitle === '(Other)' || laneIndex === 2) &&
      metricsToShow.every((metric) => metric.metricValue === 0)
    ) {
      return true
    }

    return (
      metricsToShow.length > 0 &&
      metricsToShow.every((metric) => metric.metricValue === 0)
    )
  }, [hideCard, cardTitle, metricsToShow])

  const optionCreatives = useMemo(() => {
    if (!parameterCreatives || !parameterCreatives[cardTitle]) return []

    return parameterCreatives[cardTitle]
  }, [parameterCreatives, cardTitle])

  if (notRendered) return null

  return (
    <>
      <Draggable
        draggableId={cardID}
        index={cardIndex}
        isDragDisabled={freezeView}
      >
        {(draggableProvided) => {
          let cardTitleToShow = updatedTitle || cardTitle || '(empty)'

          // Strip home domain out of URLs if present
          if (laneIndex === 1) {
            if (
              workspaceHomepage &&
              cardTitleToShow !== workspaceHomepage &&
              cardTitleToShow !== `${workspaceHomepage}/`
            ) {
              cardTitleToShow = cardTitleToShow.replace(workspaceHomepage, '')
            }

            cardTitleToShow = cardTitleToShow.replace(/^https:\/\/(www\.)*/, '')
          }

          return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              ref={(el) => {
                // Assign innerRef to cardRef
                draggableProvided.innerRef(el)

                // @ts-ignore
                cardRef.current = el
              }}
              {...draggableProvided.draggableProps}
              {...draggableProvided.dragHandleProps}
              className={styles.canvasCard}
              style={{
                ...draggableProvided.draggableProps.style,
                backgroundColor: `rgba(${cardColour.r},${cardColour.g},${
                  cardColour.b
                }, ${cardState === 'active' ? '0.6' : '0.3'})`,
                // Disconnected cards in different lanes should be removed to remove gaps between the connected cards
                display:
                  selectedCard && cardState === 'hidden' ? 'none' : 'block',
                // Disconnected cards in the selected lane should be greyed out instead of display: none.
                // Prevents issues with card flicker on hover and D&D
                opacity: cardState === 'greyedOut' ? 0.2 : undefined,
              }}
              // Allows board to update to focus only on current card and its connections
              onClick={() => {
                if (freezeView) {
                  if (cardID === selectedCard?.id) {
                    setSelectedCard(null)
                    setFreezeView(false)

                    logAction({
                      variables: {
                        action: `unfreeze-view`,
                        extra: JSON.stringify({
                          boardID,
                        }),
                        websiteSection: 'report-marketingJourneys',
                        functionName: 'unfreezeView',
                        pagePath: window.location.pathname,
                      },
                    })
                  }

                  return
                }

                setSelectedCard({
                  id: cardID,
                  title: cardTitleToShow,
                  index: cardIndex,
                  laneTitle,
                  laneIndex,
                  isFocussed: true,
                })

                setFreezeView(true)

                logAction({
                  variables: {
                    action: `freeze-view`,
                    extra: JSON.stringify({
                      boardID,
                      cardID,
                    }),
                    websiteSection: 'report-marketingJourneys',
                    functionName: 'unfreezeView',
                    pagePath: window.location.pathname,
                  },
                })
              }}
              onMouseEnter={() => {
                setShowEditCardButton(true)

                if (
                  freezeView
                  // || connectionSourceCard
                )
                  return

                const cardRect =
                  cardRef.current?.getBoundingClientRect().top || 0
                const containerRect =
                  cardRef.current?.parentElement?.getBoundingClientRect().top ||
                  0

                setSelectedCard({
                  id: cardID,
                  title: cardTitleToShow,
                  index: cardIndex,
                  laneTitle,
                  laneIndex,
                  yOffset: cardRect - containerRect,
                })
              }}
              onMouseLeave={() => {
                setShowEditCardButton(false)

                if (!freezeView && selectedCard && !selectedCard.isFocussed) {
                  setSelectedCard(null)
                }
              }}
              // ! 'Add connections' feature not currently used
              // onBlur={(e) => {
              //   // Ignore blur when modal is open
              //   if (addConnectionModalTargetCard) return

              //   // Allow deselecting 'Add connection' by clicking away
              //   const focusedElement = e.relatedTarget || document.activeElement

              //   if (!focusedElement?.className.includes('button_iconOnly')) {
              //     setConnectionSourceCard(null)
              //   }
              // }}
            >
              {/* {(showConnectionButtons === true ||
                showConnectionButtons === 'left') &&
                laneIndex !== 0 && (
                  <AddConnectionButton
                    cardID={cardID}
                    side="left"
                    connectionSourceCard={connectionSourceCard}
                    clickAddConnectionButton={(side) =>
                      clickAddConnectionButton(side)
                    }
                  />
                )} */}
              <ArcherElement id={cardID} relations={cardRelations}>
                <div className={classNames(styles.canvasCardInner)}>
                  <div className={styles.cardTitleContainer}>
                    {laneIndex === 1 && (
                      <UrlStatus
                        url={cardTitle}
                        validatedUrl={
                          landingPageCardRefs[cardTitle]?.validationData
                        }
                        hasMetricData={false}
                        hasNoLandingPage={!isValidUrl(cardTitle)}
                      />
                    )}
                    <p className={styles.cardTitle}>{cardTitleToShow}</p>
                  </div>
                  {showEditCardButton && (
                    <Button
                      className={styles.editCardButton}
                      variant="iconOnly"
                      icon={{
                        alt: 'Edit card',
                        src: EditIcon,
                      }}
                      onPress={() => setEditCardModal(true)}
                    />
                  )}
                  <div className={styles.cardContent}>
                    {metricsToShow.map((metric) => {
                      if (!metric || !metric.metricName) return null

                      const { metricName, metricValue } = metric

                      let relationMetricTotal = 0

                      if (cardState === 'active' && selectedCard) {
                        const connectionsToReference =
                          // If card is right of the selected cards, it will have the relevant connections (they run r-l)
                          laneIndex > connectionRefs[selectedCard.id].laneIndex
                            ? cardConnections
                            : // If left, we have to check the selected card's connections (unavailable on the current card)
                              mergeConnections(
                                selectedCard.id,
                                connectionRefs[selectedCard.id],
                                metricsToShow,
                              ).filter(
                                ({ connectedTo }) => connectedTo === cardID,
                              )

                        relationMetricTotal = connectionsToReference.reduce(
                          (total, connection) => {
                            const foundConnectionMetric = connection.metrics.find(
                              (connectionMetric) =>
                                connectionMetric.connectionMetricName ===
                                metricName,
                            )

                            return foundConnectionMetric
                              ? total +
                                  foundConnectionMetric.connectionMetricValue
                              : total
                          },
                          0,
                        )
                      }

                      return (
                        <span key={metricName}>
                          {metricName}:{' '}
                          {numeral(
                            // If a card is selected, only show the aggregated values from the connection cards
                            relationMetricTotal > 0
                              ? relationMetricTotal
                              : metricValue,
                          ).format('0,0')}
                        </span>
                      )
                    })}
                  </div>
                  {/* Card-specific images */}
                  {laneIndex === 0 && (
                    <div className={styles.cardImagesContainer}>
                      {additionalImages &&
                        additionalImages.length > 0 &&
                        additionalImages.map((img, index) => {
                          if (index > 2) return null

                          return (
                            <img
                              className={styles.additionalCardImage}
                              src={img}
                              alt={`Asset #${index} for card "${cardTitle}"`}
                            />
                          )
                        })}
                      {optionCreatives.length > 0 &&
                        optionCreatives?.map(({ creativeID, blobURL }) => {
                          if (blobURL) {
                            const fileExtension = blobURL.split('.').pop() || ''

                            let isImage = true

                            if (
                              ['mp4', 'webm', 'ogg'].includes(fileExtension)
                            ) {
                              isImage = false
                            }

                            if (!isImage) {
                              return (
                                // eslint-disable-next-line jsx-a11y/media-has-caption
                                <video
                                  key={creativeID}
                                  className={styles.creative}
                                  src={blobURL}
                                  onClick={() => {
                                    setVideoSrc(blobURL)
                                    setShowVideoModal(true)
                                  }}
                                />
                              )
                            }

                            return (
                              <img
                                key={creativeID}
                                className={styles.additionalCardImage}
                                src={blobURL}
                                alt={`Asset for card "${cardTitle}"`}
                              />
                            )
                          }

                          return null
                        })}
                    </div>
                  )}
                  {/* Landing page screenshots */}
                  {landingPageCardRefs[cardTitle] &&
                    landingPageCardRefs[cardTitle].screenshot && (
                      <>
                        {landingPageCardRefs[cardTitle].screenshot?.imgSrc ===
                          null ||
                        landingPageCardRefs[cardTitle].screenshot?.error ? (
                          <span className={styles.imgLoadingError}>
                            Error fetching page preview
                          </span>
                        ) : (
                          <>
                            {landingPageCardRefs[cardTitle].screenshot
                              ?.loading ? (
                              <Preloader
                                className={styles.fetchingPagePreview}
                              />
                            ) : (
                              <Link href={cardTitle}>
                                <img
                                  className={styles.cardImage}
                                  src={
                                    landingPageCardRefs[cardTitle].screenshot
                                      ?.imgSrc as string
                                  }
                                  alt={`Screenshot for ${cardTitle}`}
                                />
                              </Link>
                            )}
                          </>
                        )}
                      </>
                    )}
                </div>
              </ArcherElement>
              {/* {(showConnectionButtons === true ||
                showConnectionButtons === 'right') &&
                laneIndex !== 2 && (
                  <AddConnectionButton
                    cardID={cardID}
                    side="right"
                    connectionSourceCard={connectionSourceCard}
                    clickAddConnectionButton={(side) =>
                      clickAddConnectionButton(side)
                    }
                  />
                )} */}
            </div>
          )
        }}
      </Draggable>
      {editCardModal && (
        <EditCardModal
          setIsOpen={setEditCardModal}
          boardID={boardID}
          laneID={laneID}
          showImgUploader={laneIndex === 0}
          card={card}
          cardTitlePrefix={(() => {
            if (laneIndex === 1) return 'Landing page name'

            if (laneIndex === 2) return 'Conversion name'

            if (laneIndex === 0) {
              return stackDimensionName
                ? `${stackDimensionName} name`
                : undefined
            }

            return undefined
          })()}
          saveNewBoard={saveNewBoard}
        />
      )}
      {deleteCardModal && (
        <DeleteCardModal
          boardID={boardID}
          laneID={laneID}
          cardID={cardID}
          cardType={cardType}
          setDeleteCardModal={setDeleteCardModal}
          saveNewBoard={saveNewBoard}
        />
      )}
      {/* Feature not currently used */}
      {/* {addConnectionModalTargetCard && connectionSourceCard && (
        <AddConnectionModal
          boardID={boardID}
          connectionSourceCard={connectionSourceCard}
          setConnectionSourceCard={setConnectionSourceCard}
          targetCard={addConnectionModalTargetCard}
          setTargetCard={setAddConnectionModalTargetCard}
          saveNewBoard={saveNewBoard}
        />
      )} */}
      {showVideoModal && (
        <VideoModal
          setIsOpen={setShowVideoModal}
          cardTitle={cardTitle}
          src={videoSrc}
        />
      )}
    </>
  )
}

interface BoardLaneProps {
  reportControlsRef?: React.RefObject<{
    toggleFilter: (state?: boolean) => void
  }>
  boardID: string
  lane: GetMarketingFunnelReportQuery['report']['marketingFunnel']['getMarketingFunnelReport']['laneList'][0]
  laneIndex: 0 | 1 | 2
  reportIsFiltered: boolean
  selectedCard: SelectedCard | null
  setSelectedCard: React.Dispatch<React.SetStateAction<SelectedCard | null>>
  connectionRefs: CardConnectionsRefObject
  stackDimension?: {
    name: string
    value: string
  }
  landingPageCardRefs?: LandingPageCardRefObject
  freezeView: boolean
  setFreezeView: React.Dispatch<React.SetStateAction<boolean>>
  saveNewBoard?: (
    boardID: string,
    options: Partial<SavedMarketingJourneyResponse>,
  ) => Promise<void>
  setLaneUpdateError: React.Dispatch<React.SetStateAction<boolean>>
  setShowEditMetricsModal: React.Dispatch<React.SetStateAction<boolean>>
}

const BoardLane = ({
  reportControlsRef,
  boardID,
  lane,
  laneIndex,
  reportIsFiltered,
  selectedCard,
  setSelectedCard,
  connectionRefs,
  stackDimension,
  landingPageCardRefs = {},
  freezeView,
  setFreezeView,
  saveNewBoard,
  setLaneUpdateError,
  setShowEditMetricsModal,
}: BoardLaneProps) => {
  const {
    laneID,
    laneTitle,
    laneMetrics,
    hasBeenReordered,
    selectedMetricList,
    cardList,
    primaryColour,
  } = lane

  const logAction = useLogAction()

  const [fetchParameterCreatives, { data: creativesData }] = useLazyQuery(
    getCreativeList,
  )

  const [updateLane] = useMutation(updateMarketingBoardLane)

  const [editLaneModal, setEditLaneModal] = useState(false)
  // const [addCardToLane, setAddCardToLane] = useState(false)

  const metricToShow = useMemo(() => {
    const filteredMetrics = laneMetrics.filter((metric) =>
      selectedMetricList.includes(metric.metricName),
    )

    return filteredMetrics.length > 0 ? filteredMetrics[0] : laneMetrics[0]
  }, [laneMetrics, selectedMetricList])

  const laneColour = useMemo(() => {
    return hexToRgb(primaryColour || initialLaneColours[laneIndex % 3])
  }, [primaryColour, laneIndex])

  const sortedCardList = useMemo(() => {
    const sortedList = [...cardList].sort((a, b) => {
      // Sort by saved card order
      if (hasBeenReordered) {
        return a.cardOrder - b.cardOrder
      }

      // Sort by metricToShow
      // But only if the user has not manually reordered the cards
      const metricValueA =
        a.cardMetrics.find(
          ({ metricName }) => metricName === metricToShow.metricName,
        )?.metricValue || 0
      const metricValueB =
        b.cardMetrics.find(
          ({ metricName }) => metricName === metricToShow.metricName,
        )?.metricValue || 0

      return metricValueB - metricValueA
    })

    // Lane 1 (Landing pages) should show a max number of cards if report is unfiltered
    if (laneIndex === 1) {
      return sortedList.slice(0, maxCardsPerLane)
    }

    return sortedList
  }, [cardList, metricToShow, hasBeenReordered, laneIndex])

  /** Show a button to open 'Add/edit metrics' modal if current report config shows no conversions in lane 3 */
  const showAddConversionsMessage = useMemo(() => {
    if (laneIndex !== 2) return false

    if (sortedCardList.length === 0) return true

    return sortedCardList.every((card) => {
      return card.cardMetrics.every((metric) => metric.metricValue === 0)
    })
  }, [laneIndex, sortedCardList])

  // Only fetch creatives for the first lane - parameter
  useEffect(() => {
    if (laneIndex !== 0 || !stackDimension) return

    fetchParameterCreatives({
      variables: { parameterID: stackDimension.value },
    })
  }, [stackDimension])

  const parameterCreatives = useMemo(() => {
    if (!creativesData) return {}

    return creativesData.report.getCreativeList.reduce(
      (acc, { optionName, creativeID, blobURL }) => {
        if (!optionName) return acc

        if (!acc[optionName]) {
          acc[optionName] = [{ creativeID, blobURL }]
        } else {
          acc[optionName].push({ creativeID, blobURL })
        }

        return acc
      },
      {} as {
        [optionName: string]: { creativeID: string; blobURL?: string | null }[]
      },
    )
  }, [creativesData])

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      try {
        setLaneUpdateError(false)

        if (!boardID || !lane) return

        const { destination, source } = result

        if (!destination) return

        if (
          destination.droppableId === source.droppableId &&
          destination.index === source.index
        ) {
          return
        }

        // Should match the sorted order
        const newOrderFull = Array.from(sortedCardList)
        const cardToMove = newOrderFull.splice(source.index, 1)
        newOrderFull.splice(destination.index, 0, cardToMove[0])

        // Build the optimistic response
        const newOrderFullSorted = newOrderFull.map((card, cardIndex) => ({
          ...card,
          cardOrder: cardIndex,
        }))
        const newOrder = newOrderFullSorted.map(({ cardID }) => cardID)

        const cachedReportData = client.readQuery({
          query: getMarketingFunnelReport,
          variables: { boardID },
        })

        let optimisticResponse: any

        if (cachedReportData) {
          const {
            getMarketingFunnelReport: cachedReport,
          } = cachedReportData.report.marketingFunnel

          const newLanes = _.cloneDeep(cachedReport.laneList)

          const newLane = {
            ...newLanes[laneIndex],
            cardList: newOrderFullSorted,
          }
          newLanes.splice(laneIndex, 1, newLane)

          optimisticResponse = {
            report: {
              updateMarketingFunnelLane: {
                ...cachedReport,
                laneList: newLanes,
              },
            },
          }
        }

        // If on an unsaved report, implicitly save id
        if (saveNewBoard) {
          await saveNewBoard(boardID, {
            boardTitle: 'Board with reordered cards',
          })
        }

        await updateLane({
          variables: {
            boardID,
            laneID,
            cardOrder: newOrder,
          },
          optimisticResponse,
        })

        logAction({
          variables: {
            action: 'marketingJourneys-update-lane-card-order',
            extra: JSON.stringify({
              boardID,
              laneID,
              cardOrder: newOrder,
            }),
            websiteSection: 'report',
            functionName: 'marketingJourneysUpdateLaneCardOrder',
            pagePath: window.location.pathname,
          },
        })
      } catch {
        setLaneUpdateError(true)
      }
    },
    [lane, laneIndex, sortedCardList],
  )

  return (
    <>
      <div className={styles.canvasLane}>
        <div
          className={styles.laneTitleBox}
          style={{
            backgroundColor: `rgba(${laneColour.r},${laneColour.g},${laneColour.b}, 0.3)`,
          }}
        >
          <Heading type={3} className={styles.laneTitle}>
            {laneIndex === 1 && sortedCardList.length < cardList.length
              ? `Top ${maxCardsPerLane} ${laneTitle}`
              : laneTitle}
          </Heading>
          <Button
            className={styles.editLaneButton}
            variant="iconOnly"
            icon={{ alt: 'Edit lane', src: EditIcon }}
            onPress={() => setEditLaneModal(true)}
          />
        </div>
        <DragDropContext
          key={laneID}
          onDragStart={() => setSelectedCard(null)}
          onDragEnd={onDragEnd}
        >
          <Droppable droppableId={laneID}>
            {(droppableProvided) => {
              return (
                <div
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                  style={{
                    // marginTop ensures cards connected to selected (hovered) card (in a different lane) are level with it
                    marginTop:
                      selectedCard &&
                      laneIndex !== connectionRefs[selectedCard.id].laneIndex &&
                      !(laneIndex === 2 && showAddConversionsMessage)
                        ? selectedCard.yOffset
                        : undefined,
                  }}
                >
                  {showAddConversionsMessage ? (
                    <div className={styles.noConversions}>
                      <p>
                        No conversions found.
                        {reportIsFiltered && (
                          <>
                            {' '}
                            <Button
                              variant="text"
                              onPress={() =>
                                reportControlsRef?.current?.toggleFilter(true)
                              }
                            >
                              Edit filters
                            </Button>{' '}
                            or
                          </>
                        )}
                      </p>
                      <Button
                        data-html2canvas-ignore
                        variant="secondary"
                        onPress={() => setShowEditMetricsModal(true)}
                      >
                        Add new conversion metric
                      </Button>
                    </div>
                  ) : (
                    sortedCardList.map((card, cardIndex) => {
                      return (
                        <BoardCard
                          key={card.cardID}
                          selectedCard={selectedCard}
                          setSelectedCard={setSelectedCard}
                          boardID={boardID}
                          laneID={laneID}
                          laneTitle={laneTitle}
                          laneIndex={laneIndex}
                          card={card}
                          cardIndex={cardIndex}
                          cardColour={laneColour}
                          selectedMetricList={selectedMetricList}
                          connectionRefs={connectionRefs}
                          stackDimensionName={stackDimension?.name}
                          landingPageCardRefs={
                            laneIndex === 1 &&
                            sortedCardList.length <= maxCardsPerLane
                              ? landingPageCardRefs
                              : {}
                          }
                          parameterCreatives={
                            laneIndex === 0 ? parameterCreatives : undefined
                          }
                          freezeView={freezeView}
                          setFreezeView={setFreezeView}
                          saveNewBoard={saveNewBoard}
                        />
                      )
                    })
                  )}
                  {droppableProvided.placeholder}
                </div>
              )
            }}
          </Droppable>
        </DragDropContext>
        {/* // ! Unused feature for now */}
        {/* {lane.cardList.length < maxCardsPerLane &&
          !(laneIndex === 2 && sortedCardList.length === 0) && (
            <Button
              variant="plainBox"
              className={styles.addCardButton}
              style={{
                borderColor: `rgb(${laneColour.r},${laneColour.g},${laneColour.b})`,
                backgroundColor: `rgba(${laneColour.r},${laneColour.g},${laneColour.b}, 0.3)`,
              }}
              onPress={() => setAddCardToLane(true)}
            >
              Add card
            </Button>
          )} */}
      </div>
      {editLaneModal && (
        <EditLaneModal
          setEditLaneModal={setEditLaneModal}
          boardID={boardID}
          laneToEdit={lane}
          laneIndex={laneIndex}
          saveNewBoard={saveNewBoard}
        />
      )}
      {/* // ! Unused feature for now */}
      {/* {addCardToLane && (
        <AddCardModal
          setAddCardToLane={setAddCardToLane}
          boardID={boardID}
          lane={lane}
          saveNewBoard={saveNewBoard}
        />
      )} */}
    </>
  )
}

const getFreezeViewMessage = (laneIndex: 0 | 1 | 2) => {
  switch (laneIndex) {
    case 0:
    default:
      return 'Showing journeys from:'
    case 1:
      return 'Showing journeys landing on:'
    case 2:
      return 'Showing conversion journeys for:'
  }
}

interface ReportMarketingJourneysFlowProps {
  reportControlsRef?: React.RefObject<{
    toggleFilter: (state?: boolean) => void
  }>
  currentDataConfig: MarketingJourneyDataConfig
  reportData?: GetMarketingFunnelReportQuery
  loading?: boolean
  error?: boolean
  /** Used to change available action when no data shown */
  isFiltered: boolean
  onFilterChange: (
    dimensionID: string,
    optionID: string,
    isMainFilter?: boolean,
  ) => Promise<void>
  stackDimension?: {
    name: string
    value: string
  }
  /**
   * Used to implicitly save changes made to default report as a new report.
   * Some actions don't save new reports, e.g. Add card to board, so this has to be done to allow editing on unsaved (default) reports.
   */
  saveNewBoard?: (
    boardID: string,
    options: Partial<SavedMarketingJourneyResponse>,
  ) => Promise<void>
  setShowEditMetricsModal: React.Dispatch<React.SetStateAction<boolean>>
  /** Used to show the primary filter dropdown in the 'No data' message */
  children?: React.ReactNode
}

const ReportMarketingJourneysFlow = ({
  reportControlsRef,
  currentDataConfig,
  reportData,
  loading = false,
  error = false,
  isFiltered,
  onFilterChange,
  stackDimension,
  saveNewBoard,
  setShowEditMetricsModal,
  children,
}: ReportMarketingJourneysFlowProps) => {
  const logAction = useLogAction()

  const [getPageScreenshots] = useLazyQuery(fetchPageScreenshots)
  const [getLandingPageValidationStatuses] = useLazyQuery(
    getTrackValidationResultsByLandingPage,
  )

  const [updateSavedReport] = useMutation(updateMarketingFunnelBoard, {
    refetchQueries: [listSavedMarketingFunnelBoards],
  })

  const containerRef = useRef<HTMLDivElement>(null)

  // Used to stop the report canvas height changing on card hover
  const [minHeight, setMinHeight] = useState(400)
  const [selectedCard, setSelectedCard] = useState<SelectedCard | null>(null)
  const [landingPageCardRefs, setLandingPageCardRefs] = useState<
    LandingPageCardRefObject
  >({})
  const [laneUpdateError, setLaneUpdateError] = useState(false)
  // View should freeze when a card is clicked
  const [freezeView, setFreezeView] = useState(false)

  // Used for creating connections
  // ! Unused feature for now
  // const [
  //   connectionSourceCard,
  //   setConnectionSourceCard,
  // ] = useState<ConnectionSourceCard | null>(null)

  const { boardID, boardLanes } = useMemo(() => {
    if (
      !reportData ||
      !reportData?.report?.marketingFunnel?.getMarketingFunnelReport?.boardID
    )
      return { boardID: null, boardLanes: null }

    const {
      report: {
        marketingFunnel: { getMarketingFunnelReport: reportResponse },
      },
    } = reportData

    return {
      boardID: reportResponse.boardID,
      boardLanes: [...reportResponse.laneList].sort(
        (a, b) => a.laneOrder - b.laneOrder,
      ),
    }
  }, [reportData])

  /** Builds valid connections to show on hover over each card */
  const cardConnectionsRefs = useMemo(() => {
    const _cardConnectionsRefs: CardConnectionsRefObject = {}

    if (boardLanes) {
      boardLanes.forEach((lane, laneIndex) => {
        // First pass: Initialise object keys
        // Doing this separately allows us to avoid adding connections to cards that don't exist
        // We can check if the cardID is in the refs object - if not, ignore
        lane.cardList.forEach(({ cardID }) => {
          _cardConnectionsRefs[cardID] = {
            laneIndex,
            connections: [],
            additionalConnections: [],
          }
        })
      })

      boardLanes.forEach((lane, laneIndex) => {
        // Second pass pass: Add r-l connections to cards
        lane.cardList.forEach((card) => {
          const { cardID, cardConnections, additionalConnections } = card

          if (cardConnections && cardConnections.length > 0) {
            cardConnections.forEach((connection) => {
              const {
                connectedFrom,
                connectionMetricName,
                connectionMetricValue,
                hideConnection,
                updatedConnectionMetricName,
                updatedConnectionMetricValue,
              } = connection

              // Falsy check to exclude 0 metrics
              if (!connectionMetricValue && !updatedConnectionMetricValue) {
                return
              }

              // Lane 0 never has connections - they are only ever r-l

              if (laneIndex === 1) {
                _cardConnectionsRefs[cardID].connections.push({
                  connectedFrom: cardID,
                  connectedTo: connectedFrom,
                  connectionMetricName,
                  connectionMetricValue,
                  hideConnection,
                  updatedConnectionMetricName,
                  updatedConnectionMetricValue,
                  connectionType: 'metric',
                })
              } else if (laneIndex === 2) {
                const [idSplit1, idSplit2] = connectedFrom.split('landingPage_')

                const lane1CardID = idSplit1.slice(0, -1)
                const lane2CardID = `landingPage_${idSplit2}`

                _cardConnectionsRefs[cardID].connections.push({
                  connectedFrom: cardID,
                  connectedTo: lane2CardID,
                  secondaryConnectionIDs: _cardConnectionsRefs[lane1CardID]
                    ? [lane1CardID]
                    : undefined,
                  connectionMetricName,
                  connectionMetricValue,
                  hideConnection,
                  updatedConnectionMetricName,
                  updatedConnectionMetricValue,
                  connectionType: 'metric',
                })

                // Add secondaryConnections to middle lane
                // Ensures L1-L2 connections show on hover over an L3 card
                if (_cardConnectionsRefs[lane1CardID]) {
                  _cardConnectionsRefs[lane2CardID].connections.forEach(
                    (lane2Connection, connectionIndex) => {
                      // Check that the lane1 card connected to current lane2 card is a secondary connection of current lane3 card
                      // (Phew!)
                      if (
                        _cardConnectionsRefs[cardID].connections.find(
                          ({ secondaryConnectionIDs }) =>
                            secondaryConnectionIDs &&
                            secondaryConnectionIDs.includes(
                              lane2Connection.connectedTo,
                            ),
                        )
                      ) {
                        _cardConnectionsRefs[lane2CardID].connections.splice(
                          connectionIndex,
                          1,
                          {
                            ...lane2Connection,
                            secondaryConnectionIDs: lane2Connection.secondaryConnectionIDs
                              ? [
                                  ...lane2Connection.secondaryConnectionIDs,
                                  cardID,
                                ]
                              : [cardID],
                          },
                        )
                      }
                    },
                  )
                }
              }
            })
          }

          if (additionalConnections && additionalConnections.length > 0) {
            // Additional connections can be r-l or l-r
            additionalConnections.forEach((additionalConnection) => {
              const {
                connectedFrom,
                connectedTo,
                connectionMetricName,
                connectionMetricValue,
                hideConnection,
                updatedConnectionMetricName,
                updatedConnectionMetricValue,
              } = additionalConnection

              // Add to current card
              _cardConnectionsRefs[cardID].additionalConnections.push({
                connectedFrom,
                connectedTo,
                connectionMetricName,
                connectionMetricValue,
                hideConnection,
                updatedConnectionMetricName,
                updatedConnectionMetricValue,
                connectionType: 'custom',
              })
            })
          }
        })
      })
    }

    return _cardConnectionsRefs
  }, [boardLanes])

  // Board must have at least one card where the selected metric value is non-zero
  const noData = useMemo(() => {
    if (error) return true

    if (!boardLanes) return false

    if (boardLanes.every((lane) => lane.cardList.length === 0)) {
      return true
    }

    let hasNoData = true
    let laneIndex = 0

    while (hasNoData && laneIndex < boardLanes.length) {
      const lane = boardLanes[laneIndex]

      const filteredMetrics = lane.laneMetrics.filter((metric) =>
        lane.selectedMetricList.includes(metric.metricName),
      )

      const metricsToCheck =
        filteredMetrics.length > 0
          ? filteredMetrics
          : [lane.laneMetrics[0] || { metricValue: 0 }]

      if (
        metricsToCheck.find(({ metricValue }) => metricValue && metricValue > 0)
      ) {
        hasNoData = false
      }

      laneIndex += 1
    }

    return hasNoData
  }, [error, boardLanes])

  // Fetch screenshots and validation data for first 10 cards in lane 2
  useEffect(() => {
    const fetchScreenshots = async (urlList: string[]) => {
      try {
        setLandingPageCardRefs((curr) => {
          return {
            ...curr,
            ...urlList.reduce(
              (acc, url) => ({
                ...acc,
                [url]: {
                  ...acc[url],
                  screenshot: { imgSrc: '', loading: true },
                },
              }),
              {},
            ),
          }
        })

        const { data: screenshotData } = await getPageScreenshots({
          variables: {
            urlList,
          },
        })

        if (screenshotData) {
          const screenshots = screenshotData.report.pagePreview.map(
            ({ landingPage, screenshotLocation, screenshotBase64 }) => ({
              landingPage,
              screenshotLocation,
              screenshotBase64,
            }),
          )

          setLandingPageCardRefs((curr) => {
            return {
              ...curr,
              ...screenshots.reduce(
                (
                  acc,
                  { landingPage, screenshotLocation, screenshotBase64 },
                ) => ({
                  ...acc,
                  [landingPage]: {
                    ...acc[landingPage],
                    screenshot: {
                      imgSrc:
                        screenshotLocation ||
                        (screenshotBase64
                          ? `data:image/png;base64, ${screenshotBase64}`
                          : null),
                    },
                  },
                }),
                {},
              ),
            }
          })
        }
      } catch {
        setLandingPageCardRefs((curr) => {
          return {
            ...curr,
            ...urlList.reduce(
              (acc, url) => ({
                ...acc,
                [url]: {
                  ...acc[url],
                  screenshot: { imgSrc: '', loading: false, error: true },
                },
              }),
              {},
            ),
          }
        })
      }
    }

    const getPageValidationStatuses = async (urlList: string[]) => {
      try {
        const { data: validationData } = await getLandingPageValidationStatuses(
          {
            variables: {
              urlList,
              includeQueryPars: false,
            },
          },
        )

        if (validationData) {
          const pageValidationStatuses = validationData.track.getTrackValidationResultsByLandingPage.filter(
            (item) => !!item,
          )

          setLandingPageCardRefs((curr) => {
            return {
              ...curr,
              ...pageValidationStatuses.reduce(
                (acc, validationStatus, index) => {
                  if (!validationStatus) return acc

                  const {
                    statusCode,
                    badUrl,
                    noAnalyticsTag,
                    redirectedLandingPage,
                    slowLandingPage,
                  } = validationStatus

                  return {
                    ...acc,
                    [urlList[index]]: {
                      ...curr[urlList[index]],
                      validationData: {
                        statusCode,
                        badUrl,
                        noAnalyticsTag,
                        redirectedLandingPage,
                        slowLandingPage,
                      },
                    },
                  }
                },
                {},
              ),
            }
          })
        }
      } catch {
        setLandingPageCardRefs((curr) => {
          return {
            ...curr,
            ...urlList.reduce(
              (acc, url) => ({
                ...acc,
                [url]: { ...acc[url], validationStatus: null },
              }),
              {},
            ),
          }
        })
      }
    }

    const fetchLandingPageInfo = async (pageUrls: string[]) => {
      // Fetch 10 screenshots
      if (pageUrls.length <= screenshotFetchLimit) {
        await fetchScreenshots(pageUrls.slice(0, screenshotFetchLimit))
      }

      // Fetch all validation statuses up to maxCardsPerLane
      await getPageValidationStatuses(pageUrls.slice(0, maxCardsPerLane))
    }

    if (
      !reportData ||
      !reportData.report.marketingFunnel.getMarketingFunnelReport ||
      !reportData.report.marketingFunnel.getMarketingFunnelReport.laneList
    ) {
      return
    }

    const landingPageLaneCards =
      reportData.report.marketingFunnel.getMarketingFunnelReport.laneList[1]
        ?.cardList || []

    const pageUrls = landingPageLaneCards
      .map(({ cardTitle }) => cardTitle)
      .filter((title) => isValidUrl(title))

    fetchLandingPageInfo(pageUrls)
  }, [reportData])

  // Set min board height to prevent flicker on hover
  useEffect(() => {
    if (loading) {
      setMinHeight(400)
      return
    }

    if (containerRef.current) {
      const summaryHeight =
        (containerRef.current.querySelector('#reportSummary') as HTMLElement)
          ?.offsetHeight || 0
      const canvasHeight =
        (containerRef.current.querySelector(
          '#reportCanvas > div',
        ) as HTMLElement)?.offsetHeight || 400

      setMinHeight(summaryHeight + canvasHeight)
    }
  }, [
    boardLanes,
    containerRef.current,
    loading,
    landingPageCardRefs,
    freezeView,
  ])

  const dateRange = useMemo(() => {
    const { startDate, endDate } = currentDataConfig

    return getReportDateRange(startDate, endDate)
  }, [currentDataConfig])

  const reportFilters = useMemo(() => {
    const filterList: FilterListItem[] = []

    // Add mainFilter options
    if (
      currentDataConfig.mainFilter &&
      currentDataConfig.mainFilter.length > 0
    ) {
      const {
        dimensionParameterID,
        dimensionName,
        dimensionOptions,
      } = currentDataConfig.mainFilter[0]

      if (dimensionOptions && dimensionOptions.length > 0) {
        dimensionOptions.forEach((option) => {
          filterList.push({
            dimensionName,
            dimensionID: dimensionParameterID,
            optionName: option,
            optionID: option,
            onRemove: (dimensionID, optionID) =>
              onFilterChange(dimensionID, optionID, true),
          })
        })
      }
    }

    // Add other filter options
    if (currentDataConfig.filterList) {
      currentDataConfig.filterList.forEach(
        ({ dimensionName, dimensionParameterID, dimensionOptions }) => {
          if (dimensionOptions && dimensionOptions.length > 0) {
            dimensionOptions.forEach((option) => {
              filterList.push({
                dimensionName,
                dimensionID: dimensionParameterID,
                optionName: option,
                optionID: option,
              })
            })
          }
        },
      )
    }

    return filterList
  }, [currentDataConfig, onFilterChange])

  // Reset view when report config changes
  useEffect(() => {
    setSelectedCard(null)
    setFreezeView(false)
  }, [currentDataConfig])

  return (
    <div
      ref={containerRef}
      className={styles.canvasContainer}
      style={{ minHeight }}
    >
      <ReportSummary
        loading={loading}
        reportTitle={
          reportData?.report.marketingFunnel.getMarketingFunnelReport
            .boardTitle || messages.defaultReportTitle_marketingJourney
        }
        onUpdateTitle={async (newTitle) => {
          if (!boardID) return

          if (saveNewBoard) {
            await saveNewBoard(boardID, { boardTitle: newTitle })
          } else {
            await updateSavedReport({
              variables: {
                boardID,
                boardTitle: newTitle,
              },
            })
          }
        }}
        dateRange={dateRange}
        showDataSource
        filterList={reportFilters}
        onRemoveFilterOption={onFilterChange}
      >
        <>
          {selectedCard && freezeView && (
            <div className={styles.freezeViewContainer}>
              <p className={styles.freezeViewTitle}>
                <strong>{getFreezeViewMessage(selectedCard.laneIndex)}</strong>
              </p>
              <Tag
                className={styles.freezeViewPill}
                compact
                onClick={async () => {
                  setSelectedCard(null)
                  setFreezeView(false)

                  logAction({
                    variables: {
                      action: `unfreeze-view`,
                      extra: JSON.stringify({
                        boardID,
                      }),
                      websiteSection: 'report-marketingJourneys',
                      functionName: 'unfreezeView',
                      pagePath: window.location.pathname,
                    },
                  })
                }}
              >
                {selectedCard.laneIndex === 0 && (
                  <span className={styles.filterDimensionName}>
                    {selectedCard.laneTitle}:{' '}
                  </span>
                )}
                {selectedCard.title}
              </Tag>
            </div>
          )}
        </>
      </ReportSummary>
      {!loading && noData ? (
        <NoDataMessage
          className={styles.canvasNoDataMessage}
          errorMsg={
            laneUpdateError ? (
              'There was an error updating the report. Please reload the page'
            ) : (
              <>
                <p>
                  No data for{' '}
                  {isFiltered
                    ? 'this campaign. Select another:'
                    : 'your dates and filters.'}
                </p>
                {isFiltered ? (
                  children
                ) : (
                  <Button
                    variant="secondary"
                    className={styles.updateFiltersButton}
                    onPress={() =>
                      reportControlsRef?.current?.toggleFilter(true)
                    }
                  >
                    Update filters
                  </Button>
                )}
              </>
            )
          }
        />
      ) : (
        <>
          <div
            id="reportCanvas"
            className={classNames(styles.canvasOuter, {
              [styles.canvasLoading]: !reportData || !boardID || loading,
            })}
          >
            {!reportData || !boardID || loading ? (
              <Preloader className={styles.canvasPreloader} />
            ) : (
              <ArcherContainer
                strokeColor="#edf2f7"
                endMarker={false}
                className={styles.archerContainer}
              >
                <div className={styles.canvas}>
                  {boardLanes
                    ? boardLanes.map((lane, laneIndex) => {
                        return (
                          <BoardLane
                            key={lane.laneID}
                            reportControlsRef={reportControlsRef}
                            boardID={boardID}
                            lane={lane}
                            laneIndex={laneIndex as 0 | 1 | 2}
                            reportIsFiltered={
                              isFiltered ||
                              (!!currentDataConfig.filterList &&
                                currentDataConfig.filterList.length > 0)
                            }
                            selectedCard={selectedCard}
                            setSelectedCard={setSelectedCard}
                            connectionRefs={cardConnectionsRefs}
                            stackDimension={stackDimension}
                            landingPageCardRefs={landingPageCardRefs}
                            freezeView={freezeView}
                            setFreezeView={setFreezeView}
                            saveNewBoard={saveNewBoard}
                            setLaneUpdateError={setLaneUpdateError}
                            setShowEditMetricsModal={setShowEditMetricsModal}
                          />
                        )
                      })
                    : null}
                </div>
              </ArcherContainer>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ReportMarketingJourneysFlow
