import React, { useCallback, useState } from 'react'

import FileDragAndDrop from './file-drag-and-drop'
import Link from './link'
import Modal from './modal'
import { BoxedText, Heading } from './typography'
import { uploadExistingUtmSpreadsheet } from '../api/REST/track-client'
import UpgradeRocket from '../assets/svgs/upgrade-rocket.svg'
import { supportEmail } from '../core/constants'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/upload-existing-utm-spreadsheet-modal.module.scss'

interface UploadExistingUtmSpreadsheetModalProps {
  onHideModal: React.Dispatch<React.SetStateAction<boolean>>
}

export function UploadExistingUtmSpreadsheetModal({
  onHideModal,
}: UploadExistingUtmSpreadsheetModalProps) {
  const logAction = useLogAction()

  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [error, setError] = useState<string | React.ReactElement>('')

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const res = await uploadExistingUtmSpreadsheet(
        acceptedFiles.pop() as File,
      )

      if (res === true) {
        setError('')

        // @ts-ignore
        if (window.dataLayer && window.dataLayer.push) {
          // @ts-ignore
          window.dataLayer.push({
            event: 'upload_existing_utm_sheet',
          })
        }

        logAction({
          variables: {
            action: 'upload-existing-utm-sheet',
            functionName: 'uploadExistingUtmSheet',
            pagePath: window.location.pathname,
            websiteSection: 'welcome',
          },
        })

        setUploadSuccess(true)

        return
      }

      const resJSON = res?.error?.response?.data

      const type = resJSON?.detail || ''

      if (type === 'Only CSV, XLSX or PDF files are acceptable') {
        setError('Please only upload CSV, XLSX or PDF file types.')
      } else if (type === 'FAILED_UPLOAD') {
        setError('File upload has failed, please try again.')
      } else {
        setError(
          <>
            File upload has failed. Please email{' '}
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link> for
            help.
          </>,
        )
      }
    }
  }, [])

  if (uploadSuccess) {
    return (
      <Modal
        setIsOpen={onHideModal}
        modalHeader={
          <Heading type={3} align="left">
            Success! We have your UTM spreadsheet
            <span role="img" aria-label="Upgrade successful">
              🎉
            </span>
          </Heading>
        }
        noText="Close"
        yesText="Explore"
        onYes={() => onHideModal(false)}
      >
        <p>
          We have received your sheet and will now set up your workspace. Keep
          exploring while we import your parameters and dropdown values. We will
          email you when the setup is complete.
        </p>
      </Modal>
    )
  }

  return (
    <Modal
      setIsOpen={onHideModal}
      modalHeader={
        <Heading type={3} align="left">
          Import your existing UTM spreadsheet
          <img
            src={UpgradeRocket}
            alt="Upgrade rocket"
            className={styles.upgradeRocket}
          />
        </Heading>
      }
      noText="Cancel"
      yesText="Skip"
      onYes={() => onHideModal(false)}
    >
      <p>
        Using Microsoft Excel or Google Sheets to create and manage campaign
        links with UTMs?
      </p>
      <p>
        Upload it below and we will set up your UTM parameters and dropdown
        values for you.
      </p>
      <FileDragAndDrop
        className={styles.dragAndDrop}
        onDrop={onDrop}
        uploadError={error}
      />
      <p>
        Otherwise click 'Skip' to customise your parameters and dropdowns
        yourself in{' '}
        <BoxedText>
          <Link href="/track/edit-parameters-and-rules">
            Track &gt; Edit parameters
          </Link>
        </BoxedText>{' '}
        and{' '}
        <BoxedText>
          <Link href="/track/edit-dropdowns">Track &gt; Edit dropdowns</Link>
        </BoxedText>{' '}
        later.
      </p>
    </Modal>
  )
}
