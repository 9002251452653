import _ from 'lodash'

import { getItemByKeyValue } from '.'
import { getLocalItem, setLocalItem } from './local-client'
import {
  defaultValidationChecksValues,
  getAnchorFromString,
} from './track-module'
import {
  CampaignCodeGeneratorStructure,
  GeneratorSelectFields,
  ValidationChecks,
} from '../api/types'

export const trackCreateTabs = {
  single: 'One at a time',
  multi: 'Multiple',
  cloneAndEdit: 'Clone and edit',
  bulkCSV: 'Bulk CSV',
  email: 'Email',
}

export type SavedFormType = 'single' | 'multi' | 'email'

export function isSavedFormType(value: string): value is SavedFormType {
  return value === 'single' || value === 'multi' || value === 'email'
}

export interface GeneratorParameterValues {
  [fieldID: string]: string[]
}

export interface AppValues {
  appGroupID: string
  appScreen: string
}

/** Only used for backwards compatibility with old LS value */
interface OldGeneratorParameterValues {
  [fieldID: string]: {
    optionValue: string[]
  }
}

export interface OldWebLinkForm {
  url: string[]
  linkTo?: 'url' | 'app'
  appValues?: AppValues
  generatorParameterValues?: GeneratorParameterValues
  form: CampaignCodeGeneratorStructure
  data: OldGeneratorParameterValues
}

export interface OldEmailForm {
  emailHtml: string
  generatedEmailHtml?: string
  generatorParameterValues?: GeneratorParameterValues
  form: CampaignCodeGeneratorStructure
  data: OldGeneratorParameterValues
}

interface OldWorkspaceForm {
  single: OldWebLinkForm
  multi: OldWebLinkForm
  email: OldEmailForm
  options: {
    active: SavedFormType
    shortLinkDomain: string
  }
}

export interface WebLinkForm {
  url: string[]
  // TODO: linkTo should not be optional
  linkTo?: 'url' | 'app'
  appValues?: AppValues
  generatorParameterValues: GeneratorParameterValues
  data?: OldGeneratorParameterValues
}

export interface EmailForm {
  emailHtml: string
  generatedEmailHtml?: string
  generatorParameterValues: GeneratorParameterValues
  data?: OldGeneratorParameterValues
}

export interface WorkspaceForm {
  single: WebLinkForm
  multi: WebLinkForm
  email: EmailForm
  options: {
    active: SavedFormType
    shortLinkDomain: string
  }
}

export function formIsWebLinkForm(
  form: WebLinkForm | EmailForm | OldWebLinkForm | OldEmailForm,
): form is WebLinkForm | OldWebLinkForm {
  return Object.prototype.hasOwnProperty.call(form, 'url')
}

export interface UpdateFormValuesVars {
  url?: string[]
  linkTo?: 'url' | 'app'
  appValues?: {
    appGroupID?: string | null
    appScreen?: string | null
  } | null
  emailHtml?: string
  generatedEmailHtml?: string
  generatorParameterValues?: GeneratorParameterValues
  /**
   * Included for backwards compatibility
   * TODO: Remove when old versions of form (multi, email) are gone
   */
  data?: OldGeneratorParameterValues
}

interface TrackCreateLocalStorage {
  [workspaceID: string]: WorkspaceForm | OldWorkspaceForm
}

function isOldTrackCreateForm(
  form: WorkspaceForm | OldWorkspaceForm,
): form is OldWorkspaceForm {
  return (
    Object.prototype.hasOwnProperty.call(form.single, 'data') &&
    !Object.prototype.hasOwnProperty.call(
      form.single,
      'generatorParameterValues',
    )
  )
}

const convertOldValues = (
  oldValues?: OldGeneratorParameterValues,
): GeneratorParameterValues => {
  if (!oldValues) return {}

  return Object.keys(oldValues).reduce((acc, fieldID) => {
    if (
      oldValues[fieldID].optionValue &&
      // 'Empty' value on old form was [''], which should be ignored
      !(
        oldValues[fieldID].optionValue.length === 1 &&
        !oldValues[fieldID].optionValue[0]
      )
    ) {
      acc[fieldID] = oldValues[fieldID].optionValue
    }

    return acc
  }, {} as { [fieldID: string]: string[] })
}

export const defaultFormData: WorkspaceForm = {
  single: { url: [], generatorParameterValues: {} },
  multi: { url: [], generatorParameterValues: {} },
  email: { emailHtml: '', generatorParameterValues: {} },
  options: {
    active: 'single',
    shortLinkDomain: '',
  },
}

export const getTrackCreateFormData = (workspaceID: string): WorkspaceForm => {
  const savedData: TrackCreateLocalStorage =
    getLocalItem('track-create') || null

  if (!savedData) {
    return { ...defaultFormData }
  }

  const workspaceData = savedData[workspaceID]

  if (!workspaceData) return { ...defaultFormData }

  // Backwards compatibility: Set default value for empty object
  if (Object.keys(workspaceData).length === 0) {
    const newData = { ...defaultFormData }

    setLocalItem('track-create', { ...savedData, [workspaceID]: newData })

    return newData
  }

  // Backwards compatibility: convert pre-existing LS objects to new shape
  if (isOldTrackCreateForm(workspaceData)) {
    const { single, multi, email, options } = workspaceData

    const newData: WorkspaceForm = {
      single: {
        ...single,
        url: [...single.url],
        generatorParameterValues: convertOldValues(single.data),
      },
      multi: {
        ...multi,
        url: [...multi.url],
        generatorParameterValues: convertOldValues(multi.data),
      },
      email: {
        ...email,
        emailHtml: email.emailHtml,
        generatedEmailHtml: email.generatedEmailHtml,
        generatorParameterValues: convertOldValues(email.data),
      },
      options,
    }

    // Replace existing value
    setLocalItem('track-create', { ...savedData, [workspaceID]: newData })

    return newData
  }

  return workspaceData
}

/** Update the default tab the user is taken to on page reload */
export const saveTrackCreateTab = (
  workspaceID: string,
  formType: SavedFormType,
) => {
  if (!workspaceID) return

  const newData: TrackCreateLocalStorage = getLocalItem('track-create') || {}

  if (!newData[workspaceID]) {
    newData[workspaceID] = { ...defaultFormData }
  }

  const workspaceData = newData[workspaceID]

  workspaceData.options = {
    ...workspaceData.options,
    active: formType,
  }

  setLocalItem('track-create', newData)
}

export const updateTrackCreateFormData = (
  formData: WebLinkForm | EmailForm | OldWebLinkForm | OldEmailForm,
  options: UpdateFormValuesVars,
) => {
  const newFormData = _.cloneDeep(formData)

  const {
    url,
    linkTo,
    appValues,
    emailHtml,
    generatedEmailHtml,
    generatorParameterValues,
    // TODO: Remove data when old version of form (multi, email) are gone
    data,
  } = options

  if (formIsWebLinkForm(newFormData)) {
    if (url) {
      newFormData.url = url
    }

    if (linkTo) {
      newFormData.linkTo = linkTo
    }

    if (appValues !== undefined) {
      if (appValues === null) {
        newFormData.appValues = undefined
      } else {
        const { appGroupID, appScreen } = appValues

        const newAppValues = {
          appGroupID:
            appGroupID === null
              ? ''
              : appGroupID || newFormData.appValues?.appGroupID || '',
          appScreen:
            appScreen === null
              ? ''
              : appScreen || newFormData.appValues?.appScreen || '',
        }

        newFormData.appValues = newAppValues
      }
    }
  } else {
    if (emailHtml) {
      newFormData.emailHtml = emailHtml
    }

    if (generatedEmailHtml) {
      newFormData.generatedEmailHtml = generatedEmailHtml
    }
  }

  if (generatorParameterValues) {
    newFormData.generatorParameterValues = {
      ...newFormData.generatorParameterValues,
      ...generatorParameterValues,
    }
  }

  // TODO: Remove data when old version of form (multi, email) are gone
  if (data) {
    newFormData.data = {
      ...newFormData.data,
      ...data,
    }
  }

  return newFormData
}

export const saveTrackCreateFormData = (
  workspaceID: string,
  formType: SavedFormType,
  options: UpdateFormValuesVars,
) => {
  if (!workspaceID) return

  const newData: TrackCreateLocalStorage = getLocalItem('track-create') || {}

  if (!newData[workspaceID]) {
    newData[workspaceID] = { ...defaultFormData }
  }

  const formData = newData[workspaceID][formType] as WebLinkForm | EmailForm

  setLocalItem('track-create', {
    ...newData,
    [workspaceID]: {
      ...newData[workspaceID],
      [formType]: updateTrackCreateFormData(formData, options),
      options: {
        ...newData[workspaceID].options,
        active: formType,
      },
    },
  })
}

export const makeLinkSecure = (link: string) => {
  if (
    link &&
    typeof link === 'string' &&
    link.search(/(https:\/\/)|(http:\/\/)/gi) === -1 &&
    link.search(/.\../i) !== -1
  ) {
    return `https://${link}`
  }
  return link
}

export const getExistingQueryString = (url: string) => {
  const existingQueryString = url.match(/\?[^#]+/)

  if (!existingQueryString) return ''

  return existingQueryString[existingQueryString.length - 1].replace('?', '')
}

/* ************************************************************ */
/* Old */
// TODO: Delete/replace
/* ************************************************************ */

interface FormattedFormValues {
  [fieldID: string]: {
    optionValue: string[]
  }
}

function isFormattedForm(
  value: FormattedFormValues | CampaignCodeGeneratorStructure,
): value is FormattedFormValues {
  return !value.paramDefs
}

const getFormValues = (
  form: FormattedFormValues | CampaignCodeGeneratorStructure | null,
  currentAccount: string,
  formType: SavedFormType,
) => {
  if (!form) return null

  if (isFormattedForm(form)) {
    return form
  }

  const savedData: TrackCreateLocalStorage = getLocalItem('track-create') || {}
  const existingForm = savedData[currentAccount]

  const formData = existingForm[formType].data as OldGeneratorParameterValues

  return form.paramDefs.reduce((acc, curr) => {
    const { fieldID, optionValue } = curr

    if (optionValue || (formData && formData[fieldID]?.optionValue)) {
      acc[fieldID] = {
        optionValue: optionValue || formData[fieldID]?.optionValue,
      }
    }

    return acc
  }, {} as FormattedFormValues)
}

export const getFormData = (
  form: CampaignCodeGeneratorStructure,
  urlOrEmail: string | string[],
  currentAccount: string,
  formType: SavedFormType,
): OldWebLinkForm | OldEmailForm => {
  const savedData: TrackCreateLocalStorage = getLocalItem('track-create') || {}
  const existingForm = savedData[currentAccount]

  if (!existingForm) {
    const output = {
      ...defaultFormData[formType],
      form,
      data: {},
    }

    if (formType === 'email') {
      // @ts-ignore
      output.emailHtml = urlOrEmail as string
    } else {
      // @ts-ignore
      output.url = urlOrEmail as string[]
    }

    return output
  }

  const formData = existingForm[formType] as OldWebLinkForm | OldEmailForm

  const newParamDefs = form.paramDefs.map((item) => {
    const { fieldID } = item

    if (
      formData &&
      Object.prototype.hasOwnProperty.call(formData.data || {}, fieldID)
    ) {
      const { optionValue } = formData.data[fieldID]
      return {
        ...item,
        optionValue,
      }
    }
    return {
      ...item,
    }
  })

  const output = {
    ...defaultFormData[formType],
    data: {},
    ...savedData[currentAccount][formType],
    form: {
      ...form,
      paramDefs: newParamDefs,
    },
  }

  if (formType === 'email') {
    // @ts-ignore
    output.emailHtml = formData.emailHtml
  } else {
    // @ts-ignore
    output.url = formData.url
  }

  return output
}

interface SaveFormDataProps {
  currentAccount: string
  formType: SavedFormType
  url?: string[]
  linkTo?: 'url' | 'app'
  appGroupID?: string
  appScreen?: string
  emailHtml?: string
  generatedEmailHtml?: string
  form?: FormattedFormValues | CampaignCodeGeneratorStructure | null
  shortLinkDomain?: string | null
}

export const saveFormDataOld = ({
  currentAccount,
  formType,
  url,
  linkTo,
  appGroupID,
  appScreen,
  emailHtml,
  generatedEmailHtml,
  form,
  shortLinkDomain = null,
}: SaveFormDataProps) => {
  if (!currentAccount) return

  const newData: TrackCreateLocalStorage = getLocalItem('track-create') || {}

  const currentAccountData = newData[currentAccount]

  if (currentAccountData) {
    newData[currentAccount] = {
      ...defaultFormData,
      ...currentAccountData,
    }
  } else {
    newData[currentAccount] = _.cloneDeep(defaultFormData)
  }

  const { options } = newData[currentAccount]

  switch (formType) {
    case 'email':
      if (emailHtml) {
        newData[currentAccount].email.emailHtml = emailHtml
      }
      if (generatedEmailHtml) {
        newData[currentAccount].email.generatedEmailHtml = generatedEmailHtml
      }
      options.active = 'email'
      break
    case 'multi':
      if (url) {
        newData[currentAccount].multi.url = url
      }
      options.active = 'multi'
      break
    case 'single':
      if (url) {
        newData[currentAccount].single.url = url
      }
      options.active = 'single'
      break
    default:
      break
  }

  // Backwards compaitibility
  if (linkTo) {
    // @ts-ignore
    newData[currentAccount].single.linkTo = linkTo
  }
  if (appGroupID) {
    // @ts-ignore
    newData[currentAccount].single.appGroupID = appGroupID
  }
  if (appScreen) {
    // @ts-ignore
    newData[currentAccount].single.appScreen = appScreen
  }

  const useFormData = getFormValues(form || null, currentAccount, formType)

  if (useFormData) {
    // @ts-ignore
    newData[currentAccount][formType || options.active].data = useFormData
  }

  if (shortLinkDomain !== null) {
    options.shortLinkDomain = shortLinkDomain
  }

  newData[currentAccount].options = { ...options }

  setLocalItem('track-create', newData)
}

export const isValidInput = (
  useValue: string | string[],
  validation?: ValidationChecks[] | undefined | null,
): boolean => {
  const useValues = Array.isArray(useValue) ? useValue : [useValue]

  const valid = useValues.filter((value) => {
    if (value && validation) {
      return !validation.some((item) => {
        if (item.enabled) {
          if (
            item.name === 'LIMIT_URL_LENGTH' ||
            item.name === 'LIMIT_QUERY_LENGTH'
          ) {
            const limit =
              Object.prototype.hasOwnProperty.call(item, 'value') &&
              item.value &&
              item.value !== ''
                ? item.value
                : defaultValidationChecksValues[item.name]

            return value.length > parseInt(limit, 10)
          }

          if (
            Object.prototype.hasOwnProperty.call(
              defaultValidationChecksValues,
              item.name,
            )
          ) {
            const pattern = defaultValidationChecksValues[item.name]

            return value.match(new RegExp(pattern)) !== null
          }
        }
        return false
      })
    }
    return true
  })

  return valid.length === useValues.length
}

interface CodeGroup {
  optionName: string
  optionValue: string
  optionID?: string
  urlValue: string
  fieldName: string
  fieldID: string
  copyFrom?: string[]
  isMeta?: boolean
  includeEmpty: boolean
  hasPrefix: boolean
  selectFieldsParent?: {
    parentID: string
    parentValues: string[]
  }
  parameterDependsOn?: {
    parentFieldID: string
    parentOptionIDs: string[]
  } | null
}

export interface GeneratedCode {
  pDfs: [string, string, string, string, string][]
  tC: string
  code: string
  url: string
  selected: boolean
  urlWithHash?: string
}

export const generateCode = (
  urls: string[],
  codeDef: CampaignCodeGeneratorStructure | null,
  moveExistingParams?: boolean,
): GeneratedCode[] => {
  if (codeDef === null) return []

  const { paramDefs, masterPrefix, paramSeparator, validationChecks } = codeDef

  const includePrefixWithCopy = getItemByKeyValue(
    validationChecks,
    'name',
    'INCLUDE_PREFIX_WITH_COPY_FROM',
  )

  const includePrefix = includePrefixWithCopy && includePrefixWithCopy.enabled

  const includeEmptyValues = getItemByKeyValue(
    validationChecks,
    'name',
    'INCLUDE_EMPTY_VALUES',
  )

  const includeEmpty = !!(includeEmptyValues && includeEmptyValues.enabled)

  let results: CodeGroup[][] = [[]]

  for (let i = 0; i < paramDefs.length; i += 1) {
    const currentSubArray = paramDefs[i]

    const {
      fieldName,
      fieldType,
      fixedValue,
      prefix,
      selectFields,
      metaParameter,
      optionValue,
      copyFromField,
      fieldID,
      parameterDependsOn,
    } = currentSubArray

    const isCopyFromField = copyFromField && copyFromField.length > 0

    const useValue =
      isCopyFromField || !optionValue || optionValue.length === 0
        ? ['']
        : optionValue

    const tempCodes: CodeGroup[][] = []

    for (let j = 0; j < results.length; j += 1) {
      for (let k = 0; k < useValue.length; k += 1) {
        const value = fieldType === 'fixed' ? fixedValue || '' : useValue[k]

        if (['input', 'fixed'].indexOf(fieldType) > -1 || !selectFields) {
          tempCodes.push(
            results[j].concat([
              {
                optionName: value,
                optionValue: value,
                urlValue: metaParameter === true ? '' : `${prefix}${value}`,
                fieldName,
                fieldID,
                copyFrom: copyFromField
                  ? copyFromField.map((field) => field.copyFromID)
                  : undefined,
                isMeta: !!metaParameter,
                includeEmpty,
                hasPrefix: prefix !== '',
                parameterDependsOn,
              },
            ]),
          )
        } else {
          // Select fields use IDs instead of values to handle duplicates
          const found: GeneratorSelectFields | -1 = getItemByKeyValue(
            selectFields,
            'optionID',
            value,
          )

          const useName = found === -1 ? '' : found.optionName
          const useOptionValue = found === -1 ? '' : found.optionValue
          const useOptionID = found === -1 ? '' : found.optionID

          const valToPush: CodeGroup = {
            optionName: useName,
            optionValue: useOptionValue,
            optionID: useOptionID,
            urlValue:
              metaParameter === true ? '' : `${prefix}${useOptionValue}`,
            fieldName,
            fieldID,
            copyFrom: copyFromField
              ? copyFromField.map((field) => field.copyFromID)
              : undefined,
            isMeta: !!metaParameter,
            includeEmpty,
            hasPrefix: prefix !== '',
            parameterDependsOn,
          }

          if (
            found !== -1 &&
            found.optionFilter &&
            found.optionFilter.length > 0 &&
            found.optionFilter[0].parentOptionIDs.length > 0
          ) {
            const filter = found.optionFilter[0]

            valToPush.selectFieldsParent = {
              parentID: filter.parentFieldID,
              parentValues: filter.parentOptionIDs,
            }
          }

          tempCodes.push(results[j].concat([valToPush]))
        }
      }
    }

    results = tempCodes
  }

  let validCombinations = results

  // Filter out invalid select field parent-child combinations
  if (
    results.find((result) => result.find((field) => field.selectFieldsParent))
  ) {
    validCombinations = results.filter((result) => {
      const children = result.filter((field) => field.selectFieldsParent)

      return children.every((child) => {
        // Workspace restriction is not an invalid combination
        if (
          child.selectFieldsParent &&
          child.selectFieldsParent.parentID === 'account'
        )
          return true

        const parent = result.find(
          (field) =>
            child.selectFieldsParent &&
            child.selectFieldsParent.parentID === field.fieldID,
        )

        return (
          parent &&
          parent.optionID &&
          child.selectFieldsParent &&
          child.selectFieldsParent.parentValues.indexOf(parent.optionID) > -1
        )
      })
    })
  }

  // Set optionName, optionValue, optionID and urlValue to empty when dependency criteria is not met
  if (
    validCombinations.find((result) =>
      result.find((field) => field.parameterDependsOn),
    )
  ) {
    validCombinations = validCombinations.map((result) => {
      const fieldsWithDependencies = result.filter(
        (resultField) => !!resultField.parameterDependsOn,
      )

      if (fieldsWithDependencies.length === 0) return result

      const removedDependentValues = _.cloneDeep(result)

      fieldsWithDependencies.forEach((fieldWithDependency) => {
        const fieldDepIndex = removedDependentValues.findIndex(
          (val) => val.fieldID === fieldWithDependency.fieldID,
        )

        const {
          parentFieldID,
          parentOptionIDs,
        } = fieldWithDependency.parameterDependsOn || {
          parentFieldID: null,
          parentOptionIDs: [] as string[],
        }

        if (!parentFieldID) return

        const parentField = result.find(
          (field) => field.fieldID === parentFieldID,
        )

        if (!parentField) return

        if (
          parentField.optionID &&
          parentOptionIDs.indexOf(parentField.optionID) === -1
        ) {
          const newFieldWithDep = _.cloneDeep(fieldWithDependency)

          newFieldWithDep.optionID = undefined
          newFieldWithDep.optionName = ''
          newFieldWithDep.optionValue = ''
          newFieldWithDep.urlValue = ''

          removedDependentValues.splice(fieldDepIndex, 1, newFieldWithDep)
        }
      })

      return removedDependentValues
    })
  }

  // Filter out duplicates after dependency is finished
  validCombinations = validCombinations.reduce((acc, curr) => {
    const codeString = JSON.stringify(curr)

    if (acc.find((existing) => JSON.stringify(existing) === codeString))
      return acc

    acc.push(curr)

    return acc
  }, [] as CodeGroup[][])

  const copyFromSeparator = getItemByKeyValue(
    validationChecks,
    'name',
    'COPY_FROM_SEPARATOR',
  )

  const useSeparator = copyFromSeparator !== -1 ? copyFromSeparator.value : '|'

  const addCopiedValues: CodeGroup[][] = validCombinations.map((group) => {
    return group.map((item) => {
      const copyFromField = item.copyFrom

      if (copyFromField) {
        // Do not add copyFrom field if parent criteria are not met
        if (item.parameterDependsOn) {
          const { parentFieldID, parentOptionIDs } = item.parameterDependsOn

          const parentField = group.find(
            (field) => field.fieldID === parentFieldID,
          )

          if (
            parentField &&
            parentField.optionID &&
            parentOptionIDs.indexOf(parentField.optionID) === -1
          ) {
            return { ...item }
          }
        }

        const valuesToCopy = copyFromField
          .map((field, index) => {
            const found: CodeGroup | -1 = getItemByKeyValue(
              group,
              'fieldID',
              field,
            )

            // get the value with the prefix included if that rule is enabled
            const res =
              found !== -1
                ? found[
                    includePrefix && found.optionValue
                      ? 'urlValue'
                      : 'optionValue'
                  ]
                : ''

            // First copied value should not have the copyFrom separator
            if (index === 0 || res === '') {
              return res
            }

            // Special case: do not use copyFrom separator if the copied param does not have a prefix
            // The copied values might combine to a single value for the generator, so should not use copyFrom separator
            if (found !== -1 && !found.hasPrefix && !includePrefix) {
              return res
            }

            return `${useSeparator}${res}`
          })
          .filter((val) => val !== '')
          .join('')

        const copy = { ...item }

        copy.optionName = item.optionName + valuesToCopy
        copy.optionValue = item.optionValue + valuesToCopy

        // Exception for meta parameters so they are not included in the code string
        copy.urlValue = item.isMeta ? '' : item.urlValue + valuesToCopy

        return copy
      }

      return { ...item }
    })
  })

  const finalResult: GeneratedCode[] = []

  urls.forEach((url) => {
    const addCopiedValuesWithUrl = addCopiedValues.map((item) => {
      const pDfs: [
        string,
        string,
        string,
        string,
        string,
      ][] = item.map(
        ({ optionName, optionValue, fieldName, fieldID, optionID }) => [
          optionName,
          optionValue,
          fieldName,
          fieldID,
          optionID || '',
        ],
      )

      const tC = item
        .map((def) => {
          // return empty string for empty values, unless includeEmpty is enabled
          return !def.includeEmpty && def.optionName === '' ? '' : def.urlValue
        })
        .filter((i) => i !== '')
        .join(paramSeparator)

      // Get existing prefix, if any
      const hasPrefix = url.indexOf('?') !== -1

      const useAnchor = getAnchorFromString(url)
      // Add negative lookahead to accomodate hash based routing
      // https://example.com/#/# should not have the first hash removed
      const anchorReplaceRegex = new RegExp(
        `#(?!\/)${useAnchor.replace('#', '')}`,
        'i',
      )
      const useUrl = url.replace(anchorReplaceRegex, '')

      let code: string

      // Check if existing params in URL should be moved
      if (hasPrefix && moveExistingParams) {
        const existingQueryString = getExistingQueryString(useUrl)

        const urlWithoutExistingParams = useUrl
          .replace(existingQueryString, '')
          .replace('?', '')

        code = `${urlWithoutExistingParams}${masterPrefix}${tC}${
          existingQueryString ? `&${existingQueryString}` : ''
        }${useAnchor}`
      } else {
        const isPrefixLastCharacter =
          hasPrefix && url.indexOf('?') === url.length - 1

        const prefixToReplace = isPrefixLastCharacter ? '' : '&'

        code = `${useUrl}${
          hasPrefix ? masterPrefix.replace('?', prefixToReplace) : masterPrefix
        }${tC}${useAnchor}`
      }

      return {
        url: useUrl,
        pDfs,
        tC,
        code,
        selected: true,
        urlWithHash: url,
      }
    })

    finalResult.push(...addCopiedValuesWithUrl)
  })

  return finalResult
}
