import React, { useState } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'
import classNames from 'classnames'

import Accordion from './accordion'
import Button from './button'
import Input, { Label } from './input'
import { FieldSlot, FormRow, LabelSlot } from './row'
import { SelectBoxSimple } from './select-box'
import {
  defaultEnterpriseFields,
  EnterpriseOrgForm,
} from './settings-enterprise-org-form'
import { NoteText } from './typography'
import { currentUserDetails } from '../api/apollo/variables'
import { addSupportUser } from '../api/graphql/support-client'
import { messages } from '../core/constants'
import { isNonWhitelabelSupportUser, isSupportUser } from '../helpers'
import { validateEmail } from '../helpers/forms'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/support-settings.module.scss'
import Modal from './modal'

interface AddSupportUserFormState {
  email: string
  permissionLevel: 'support' | 'whitelabelSupport'
  whitelabelDomain: string | null
  loading: boolean
  success: string
  error: string
}

const SupportSettings = () => {
  const { userPermission, whiteLabelAdminDomain } = useReactiveVar(
    currentUserDetails,
  )

  const logAction = useLogAction()

  const [addAdminUser] = useMutation(addSupportUser)

  const [addSupportUserFormState, setAddSupportUserFormState] = useState<
    AddSupportUserFormState
  >({
    email: '',
    permissionLevel: isNonWhitelabelSupportUser(userPermission)
      ? 'support'
      : 'whitelabelSupport',
    whitelabelDomain: whiteLabelAdminDomain || null,
    loading: false,
    success: '',
    error: '',
  })
  const [createCompanyModalActive, setCreateCompanyModalActive] = useState(
    false,
  )

  if (!userPermission || !isSupportUser(userPermission)) {
    return null
  }

  return (
    <>
      <Accordion
        id="supportSettings"
        className={styles.accordionContainer}
        accordionItems={[
          {
            key: 'billing',
            header: {
              icon: '🏢',
              title: 'Create an enterprise client',
              subtitle:
                'Create a new paying enterprise client with their own workspaces and users.',
            },
            expandedContent: (
              <Button
                variant="secondary"
                onPress={() => setCreateCompanyModalActive(true)}
              >
                Create a new enterprise client
              </Button>
            ),
          },
          {
            key: 'users',
            header: {
              icon: '👥',
              title: 'Add super admin user',
              subtitle:
                'Create a user with access to every organisation and workspace on this white label domain.',
            },
            expandedContent: (
              <>
                <form
                  className={styles.form}
                  onSubmit={async (e) => {
                    e.preventDefault()

                    try {
                      const {
                        email,
                        permissionLevel,
                        whitelabelDomain,
                      } = addSupportUserFormState

                      setAddSupportUserFormState((curr) => ({
                        ...curr,
                        loading: true,
                        success: '',
                        error: '',
                      }))

                      if (!email || !validateEmail(email)) {
                        setAddSupportUserFormState((curr) => ({
                          ...curr,
                          loading: false,
                          error: messages.notValidEmail,
                        }))
                        return
                      }

                      await addAdminUser({
                        variables: {
                          email,
                          whitelabel: whitelabelDomain || undefined,
                          permissionLevel,
                        },
                      })

                      logAction({
                        variables: {
                          action: 'add-support-user',
                          websiteSection: 'settings',
                          pagePath: '/settings',
                          functionName: 'addSupportUser',
                          extra: JSON.stringify({
                            email,
                            permissionLevel,
                            whiteLabelAdminDomain,
                          }),
                        },
                      })

                      setAddSupportUserFormState((curr) => ({
                        ...curr,
                        email: '',
                        success: `${email} has been added as a support user${
                          whitelabelDomain
                            ? ` to the ${whitelabelDomain} domain.`
                            : '.'
                        }`,
                        loading: false,
                      }))
                    } catch {
                      setAddSupportUserFormState((curr) => ({
                        ...curr,
                        error:
                          'Unable to add - please try again or contact support.',
                      }))
                    }
                  }}
                >
                  <FormRow last className={styles.formRow}>
                    <LabelSlot noPadding column>
                      <Label id="addEmail" small>
                        Email
                      </Label>
                    </LabelSlot>
                    <FieldSlot noPadding column>
                      <Input
                        name="addEmail"
                        type="text"
                        label="Email"
                        required
                        value={addSupportUserFormState.email}
                        onValueChange={(nextEmail) =>
                          setAddSupportUserFormState((curr) => ({
                            ...curr,
                            email: nextEmail,
                          }))
                        }
                      />
                    </FieldSlot>
                  </FormRow>
                  {isNonWhitelabelSupportUser(userPermission) && (
                    <FormRow last className={styles.formRow}>
                      <LabelSlot noPadding column>
                        <Label id="permission" small>
                          Whitelabel domain
                        </Label>
                      </LabelSlot>
                      <FieldSlot noPadding column>
                        <SelectBoxSimple
                          name="permission"
                          value={
                            addSupportUserFormState.whitelabelDomain ||
                            'support'
                          }
                          onChange={(domain) => {
                            setAddSupportUserFormState((curr) => ({
                              ...curr,
                              permissionLevel:
                                domain === 'support'
                                  ? 'support'
                                  : 'whitelabelSupport',
                              whitelabelDomain:
                                domain === 'support' ? null : domain,
                            }))
                          }}
                        >
                          {/* Hardcoded options */}
                          <option value="support">Uplifter</option>
                          <option value="uptimal">Uptimal</option>
                        </SelectBoxSimple>
                      </FieldSlot>
                    </FormRow>
                  )}
                  <FormRow
                    className={classNames(styles.formRow, styles.formRowButton)}
                  >
                    <FieldSlot noPadding column>
                      <Button
                        loading={addSupportUserFormState.loading}
                        isDisabled={
                          addSupportUserFormState.email === '' ||
                          addSupportUserFormState.loading
                        }
                        type="submit"
                      >
                        Add super admin user
                      </Button>
                    </FieldSlot>
                  </FormRow>
                </form>
                {!addSupportUserFormState.whitelabelDomain && (
                  <NoteText label="Warning">
                    The user will have full access to all organisations on
                    Uplifter.
                  </NoteText>
                )}
                {addSupportUserFormState.success && (
                  <p className={styles.success}>
                    {addSupportUserFormState.success}
                  </p>
                )}
                {addSupportUserFormState.error && (
                  <p className={styles.error}>
                    {addSupportUserFormState.error}
                  </p>
                )}
              </>
            ),
          },
        ]}
      />
      {createCompanyModalActive && (
        <Modal
          setIsOpen={setCreateCompanyModalActive}
          width="wide"
          // loading={addingEnterpriseAccount}
          footerContent={
            <Button form="enterpriseOrgForm">Create a new client</Button>
          }
        >
          <EnterpriseOrgForm
            fields={defaultEnterpriseFields}
            includeWhitelabelField={isNonWhitelabelSupportUser(userPermission)}
            onCreateNew={() => setCreateCompanyModalActive(false)}
          />
        </Modal>
      )}
    </>
  )
}

export default SupportSettings
