import React, { useState, useEffect, useMemo } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'

import FeaturesSettingsCard from './features-settings-card'
import SelectBox from './select-box'
import { currentUserDetails } from '../api/apollo/variables'
import {
  getCampaignLinkDashboardMeta,
  setDashboardSettings,
} from '../api/graphql/report-client'
import { matchTypesDropDown } from '../core/constants'
import { sortData, isAdminUser } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/workspace-preferences.module.scss'

interface WorkspaceDetailsProps {
  className?: string
}

export default function WorkspacePreferences({
  className,
}: WorkspaceDetailsProps) {
  const { userPermission } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const { data: reportData } = useQuery(getCampaignLinkDashboardMeta)

  const campaignLinkDashboardMeta = useMemo(() => {
    if (!reportData) return null

    return reportData.campaignLinkDashboardMeta
  }, [reportData])

  const [updateDashboardSettings, { loading: loadingSettings }] = useMutation(
    setDashboardSettings,
  )

  const [performance, setPerfomance] = useState<MatchTypeProps | null>(null)

  const isAdmin = isAdminUser(userPermission)

  const matchTypeMerged: MatchTypeProps[] = useMemo(() => {
    if (
      !campaignLinkDashboardMeta ||
      !campaignLinkDashboardMeta.availableDimensions ||
      campaignLinkDashboardMeta.availableDimensions.length === 0
    )
      return [...matchTypesDropDown]

    return [
      ...matchTypesDropDown,
      ...sortData(
        campaignLinkDashboardMeta.availableDimensions.map((item) => {
          const { dimensionParameterID, dimensionName, helpText } = item
          return {
            name: dimensionName,
            shortName: dimensionName,
            value: dimensionName,
            tooltip: helpText,
            id: dimensionParameterID,
          }
        }),
        'name',
        true,
        null,
        true,
      ),
    ]
  }, [campaignLinkDashboardMeta])

  // Update report matchType based on saved preference
  useEffect(() => {
    if (!campaignLinkDashboardMeta || matchTypeMerged.length === 0) return

    const mT = campaignLinkDashboardMeta?.onloadDefaultOptions?.find(
      (item) => item.name === 'matchType',
    )

    if (!mT) return

    const savedValue = matchTypeMerged.find(({ id, value }) => {
      if (
        mT.value !== 'full' &&
        mT.value !== 'partial' &&
        mT.value !== 'lpag' &&
        mT.value !== 'shortuplcode'
      ) {
        return id === mT.value
      }

      return value === mT.value
    })

    setPerfomance(savedValue || matchTypesDropDown[0])
  }, [campaignLinkDashboardMeta, matchTypeMerged])

  return (
    <div className={className}>
      <h3>Features</h3>
      <p className={styles.byline}>
        Change which features your users can access.
      </p>
      <div className={styles.subsection}>
        <FeaturesSettingsCard />
      </div>

      <h3>Report preferences</h3>
      <p className={styles.byline}>Set your default performance report view.</p>
      <div className={styles.subsection} style={{ marginBottom: 16 }}>
        <SelectBox
          className={styles.matchTypeDropdown}
          isLoading={!campaignLinkDashboardMeta || loadingSettings}
          isDisabled={!isAdmin}
          labelKey="name"
          valueKey="value"
          options={matchTypeMerged}
          value={performance}
          onChange={async (newValue) => {
            if (newValue) {
              setPerfomance(newValue)

              await updateDashboardSettings({
                variables: {
                  onloadDefaultOptions: [
                    {
                      name: 'matchType',
                      enabled: false,
                      value: newValue.id || newValue.value,
                    },
                  ],
                },
              })

              logAction({
                variables: {
                  action: 'update-performance-report-default-match-type',
                  functionName: 'updateDashboardSettings',
                  pagePath: '/settings',
                  websiteSection: 'Workspace preferences',
                  extra: newValue.id || newValue.value,
                },
              })
            }
          }}
        />
      </div>
    </div>
  )
}
