export const TOKEN = 'TOKEN'
export const nanoIdCharacters =
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
export const maxCustomLinkAliasLength = 1024

let GQL_API_BASE_DOMAIN = 'https://gql-s.uplifter.tech/'
let GQL_API_BASE_DOMAIN_DOCKER = ''
let SENTRY_ENVIRONMENT = 'local'

switch (process.env.REACT_APP_NODE_ENV) {
  case 'production':
    GQL_API_BASE_DOMAIN = 'https://gql.uplifter.tech/'
    SENTRY_ENVIRONMENT = 'production'
    break
  case 'local':
    GQL_API_BASE_DOMAIN = 'http://localhost:8000/'
    GQL_API_BASE_DOMAIN_DOCKER = 'http://backend:8000/'
    break
  case 'staging':
    SENTRY_ENVIRONMENT = 'staging'
    break
  default:
    break
}

export const GRAPHQL_API_BASE_DOMAIN = GQL_API_BASE_DOMAIN
export const GRAPHQL_API_BASE_DOMAIN_DOCKER = GQL_API_BASE_DOMAIN_DOCKER
export const SENTRY_ENV = SENTRY_ENVIRONMENT

// Reference API V1. Should be removed when Explain module is rebuilt
const API_BASE_DOMAIN =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? 'https://api.uplifter.tech/'
    : 'https://uplifterprod-uplifter-backend-staging.azurewebsites.net/'

export const API_BASE_LOCATION = `${API_BASE_DOMAIN}api/v1/`
