import React, { useState } from 'react'
import classNames from 'classnames'

import { ButtonRow } from './button-row'
import Button, { DeleteButton } from './button'
import styles from '../styles/delete-button-with-confirmation.module.scss'
import { Preloader } from './loader'

interface Props {
  children: React.ReactNode
  onClick: () => Promise<void> | void
  className?: string
  odd?: boolean
  inlineMobile?: boolean
}

export default function DeleteButtonWithConfirmation({
  onClick,
  children,
  odd = false,
  inlineMobile,
  className = '',
}: Props): React.ReactElement {
  const [removeActive, setRemoveActive] = useState(false)

  const [loading, setLoading] = useState(false)

  return (
    <div
      className={classNames(className, styles.deleteAction, {
        [styles.odd]: odd,
        [styles.deleteActionActive]: removeActive,
        [styles.inlineMobile]: inlineMobile,
      })}
    >
      <div className={styles.message}>
        <DeleteButton
          className={styles.delete}
          onPress={() => setRemoveActive(true)}
        />
        {removeActive && (
          <div className={styles.content}>
            {loading ? <Preloader className={styles.loading} /> : children}
          </div>
        )}
      </div>
      {removeActive && (
        <>
          <ButtonRow className={styles.confirmationButtons}>
            <Button
              className={styles.pillButton}
              isDisabled={loading}
              onPress={async () => {
                setLoading(true)

                await onClick()

                setRemoveActive(false)
                setLoading(false)
              }}
            >
              Yes
            </Button>
            <Button
              className={styles.pillButton}
              isDisabled={loading}
              color="blue"
              onPress={() => {
                setRemoveActive(false)
              }}
            >
              No
            </Button>
          </ButtonRow>
        </>
      )}
    </div>
  )
}
