import React, { useMemo, useState } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'

import Input from './input'
import Link from './link'
import Modal from './modal'
import { currentUserDetails } from '../api/apollo/variables'
import {
  cancelMicrosoftMarketplaceSubscription,
  changeMicrosoftMarketplacePlan,
  deleteCompany,
} from '../api/graphql/company-client'
import { sendFeedback } from '../api/graphql/onboarding-client'
import { logout } from '../api/REST/auth-client'
import {
  calendarBookingLink,
  deleteWord,
  supportEmail,
} from '../core/constants'
import useLogAction from '../hooks/useLogAction'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import {
  teamTierMaxUsers,
  teamTierMaxWorkspaces,
} from '../static-copy/subscription-features'
import styles from '../styles/delete-company-modal.module.scss'

const deleteReasons = [
  {
    label: "The product isn't what I expected",
    value: 'notAsExpected',
  },
  {
    label: "I don't use it any more",
    value: 'dontUse',
  },
  {
    label: 'I found a different tool',
    value: 'foundDifferentTool',
  },
  {
    label: "I'm not getting the results I wanted",
    value: 'notGettingResults',
  },
  {
    label: "I don't have the budget",
    value: 'noBudget',
  },
  {
    label: "I'm not happy with the product",
    value: 'notHappy',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

interface DowngradeDeleteBlockerModal {
  type: 'downgrade' | 'downgradeTeam' | 'delete' | null
  onHideModal: React.Dispatch<React.SetStateAction<boolean>>
  currentUserCount: number
  currentWorkspaceCount: number
}

export function DowngradeDeleteBlockerModal({
  type = 'downgrade',
  onHideModal,
  currentUserCount,
  currentWorkspaceCount,
}: DowngradeDeleteBlockerModal) {
  const { companyID } = useReactiveVar(currentUserDetails)

  const {
    isFree,
    isTeam,
    isPaddle,
    isMicrosoftMarketplace,
    isEnterprise,
    paddleData,
    microsoftSubscriptionData,
  } = useSubscriptionLevel()

  const logAction = useLogAction()

  const [sendFeedbackMutation] = useMutation(sendFeedback)
  const [permanentlyDeleteCompany, { error }] = useMutation(deleteCompany)
  const [cancelMicrosoftSubscription] = useMutation(
    cancelMicrosoftMarketplaceSubscription,
  )
  const [downgradeMicrosoftPlan] = useMutation(changeMicrosoftMarketplacePlan)

  const [feedbackInput, setFeedbackInput] = useState('')
  const [feedbackError, setFeedbackError] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [selectedOptionsError, setSelectedOptionsError] = useState(false)
  const [downgradeLoading, setDowngradeLoading] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [deletingAccount, setDeletingAccount] = useState(false)
  const [deleteInputValue, setDeleteInputValue] = useState('')

  const { paddleCancelUrl, paddleSubscriptionId } = useMemo(() => {
    if (!paddleData)
      return {
        paddleCancelUrl: null,
        paddleSubscriptionId: null,
      }

    return paddleData.currentCompany
  }, [paddleData])

  // Trying to downgrade a non-Paddle account
  // Or trying to delete an enterprise account
  if (
    (isEnterprise && !isMicrosoftMarketplace) ||
    (type === 'downgrade' && !isTeam) ||
    (type === 'downgradeTeam' && !isEnterprise)
  )
    return null

  if (
    (isTeam && (currentUserCount > 1 || currentWorkspaceCount > 1)) ||
    (isEnterprise &&
      (currentWorkspaceCount > teamTierMaxWorkspaces ||
        currentUserCount > teamTierMaxUsers))
  ) {
    return (
      <Modal
        setIsOpen={onHideModal}
        modalHeader="Remove users and workspaces"
        noText="Close"
        yesText="Remove users"
        onYes={() => window.location.replace('/settings?show=users')}
      >
        <p>
          In order to cancel your current plan{' '}
          {type === 'delete' ? 'and delete your account' : ''}, please delete
          all but{' '}
          {type === 'downgradeTeam' ? teamTierMaxWorkspaces.toString() : 'one'}{' '}
          of your workspaces and{' '}
          {type === 'downgradeTeam'
            ? `have less than ${teamTierMaxUsers} total`
            : ''}{' '}
          users.
        </p>
      </Modal>
    )
  }

  if (showDeleteConfirm) {
    return (
      <Modal
        setIsOpen={onHideModal}
        modalHeader="Confirm account deletion"
        noText="Cancel"
        yesText="Delete account"
        yesButtonDisabled={deleteInputValue !== deleteWord || !!error}
        yesButtonLoading={deletingAccount && !error}
        onYes={async () => {
          if (deleteInputValue === deleteWord && companyID) {
            setDeletingAccount(true)

            if (isMicrosoftMarketplace) {
              // Cancel subscription
              // Doesn't matter which tier subscription is, including Free
              await cancelMicrosoftSubscription({
                variables: { companyID },
              })
            }

            await permanentlyDeleteCompany({
              variables: {
                companyID,
              },
            })

            setDeletingAccount(false)

            logout()
          }
        }}
      >
        <p>
          This will remove all data and cannot be reversed. Type {deleteWord} to
          confirm account deletion.
        </p>
        <Input
          name="deleteAccount"
          type="text"
          required
          value={deleteInputValue}
          onValueChange={(e) => setDeleteInputValue(e)}
        />
        {error && (
          <p className={styles.deleteError}>
            Failed to delete account. Please contact{' '}
            <Link href={`mailto:${supportEmail}`}>support</Link>.
          </p>
        )}
      </Modal>
    )
  }

  return (
    <Modal
      setIsOpen={onHideModal}
      modalHeader="We're sorry to see you go"
      noText="Cancel"
      yesButtonLoading={downgradeLoading}
      yesText={
        type === 'delete' ? (
          <>{isTeam ? 'Confirm downgrade' : 'Submit feedback'}</>
        ) : (
          `Downgrade to ${type === 'downgradeTeam' ? 'team' : 'free'}`
        )
      }
      onYes={async () => {
        if (!feedbackInput || selectedOptions.length === 0) {
          setFeedbackError(!feedbackInput)
          setSelectedOptionsError(selectedOptions.length === 0)
          return
        }

        setDowngradeLoading(true)

        await sendFeedbackMutation({
          variables: {
            message: `Reasons: ${
              selectedOptions
                .map(
                  (option) =>
                    deleteReasons.find(({ value }) => value === option)?.label,
                )
                .join(', ') || 'Other'
            }.\nDetails: ${feedbackInput}`,
            page: '/upgrade',
            feedbackContext: {
              feedbackPrompt:
                type === 'delete'
                  ? 'Delete account'
                  : `Downgrade to ${
                      type === 'downgradeTeam' ? 'team' : 'free'
                    } account`,
              feedbackResponse: `Why are you ${
                type === 'delete' ? 'deleting your account' : 'downgrading'
              }?`,
            },
          },
        })

        // Cancel Paddle subscription
        if (isTeam && isPaddle && paddleCancelUrl) {
          window.Paddle.Checkout.open({
            override: paddleCancelUrl,
            passthrough: companyID,
            closeCallback: () => setDowngradeLoading(false),
            successCallback: async () => {
              logAction({
                variables: {
                  action: 'cancel-paddle-subscription',
                  extra: JSON.stringify({
                    paddleSubscriptionId,
                  }),
                  websiteSection: 'settings',
                  pagePath: '/settings',
                  functionName: 'cancelPaddleSubscription',
                },
              })

              if (type === 'downgrade') {
                window.location.replace('/settings?show=billing')
              }
            },
          })
        }

        // Downgrade via MS Marketplace
        if (isMicrosoftMarketplace) {
          await downgradeMicrosoftPlan({
            variables: {
              companyID,
              newPlanID:
                type === 'downgradeTeam' ? 'team_edition' : 'free_edition',
            },
          })

          logAction({
            variables: {
              action: 'downgrade-microsoft-subscription',
              extra: JSON.stringify({
                microsoftSubscriptionID:
                  microsoftSubscriptionData?.currentCompany
                    .microsoftSubscriptionData?.subscriptionID,
              }),
              websiteSection: 'settings',
              pagePath: '/settings',
              functionName: 'downgradeMicrosoftSubscription',
            },
          })

          setDowngradeLoading(false)

          if (type !== 'delete') {
            window.location.replace('/settings?show=billing')
          }
        }

        if (type !== 'delete') return

        setDowngradeLoading(false)
        setShowDeleteConfirm(true)
      }}
    >
      <p>
        If something isn't working,{' '}
        <Link href={calendarBookingLink}>book a meeting with us</Link> so we can
        fix it.
      </p>
      <p>
        If you still wish to{' '}
        {type === 'delete'
          ? 'leave'
          : `downgrade to a ${
              type === 'downgradeTeam' ? 'team' : 'free'
            } account`}
        , please share your reasons. We'd really appreciate your honest
        feedback.
      </p>
      <div className={styles.checkboxesContainer}>
        {deleteReasons.map(({ label, value }) => {
          return (
            <Input
              type="checkbox"
              id={value}
              name={value}
              label={label}
              error={selectedOptionsError}
              className={styles.checkboxContainer}
              checked={selectedOptions.includes(value)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { checked } = e.target as HTMLInputElement

                if (checked && selectedOptionsError) {
                  setSelectedOptionsError(false)
                }

                setSelectedOptions((curr) => {
                  const newOptions = [...curr]

                  if (checked) {
                    if (!curr.includes(value)) {
                      newOptions.push(value)
                    }

                    return newOptions
                  }

                  return newOptions.filter((option) => option !== value)
                })
              }}
            />
          )
        })}
      </div>
      {selectedOptionsError && (
        <p className={styles.deleteError} style={{ marginTop: -16 }}>
          Please select at least one option.
        </p>
      )}
      <Input
        name="feedbackInput"
        type="textArea"
        value={feedbackInput}
        onValueChange={(val) => {
          setFeedbackInput(val)
          setFeedbackError(false)
        }}
        required
        placeholder="Please give as much detail as you can"
        multilineInput
        textAreaHeight={80}
        lineBreakAll={false}
        error={feedbackError}
      />
      {feedbackError && (
        <p className={styles.deleteError} style={{ marginTop: -16 }}>
          Please provide feedback before submitting.
        </p>
      )}
      {type === 'delete' && (
        <p>
          Once {isFree ? 'submitted' : 'downgraded'}, you will be asked to
          confirm account deletion.
        </p>
      )}
    </Modal>
  )
}
