import React, { useEffect, useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { PressEvent } from 'react-aria'
import classNames from 'classnames'
import { nanoid } from 'nanoid'

import BurgerIcon from './burger-icon'
import Button, { DeleteButton } from './button'
import ButtonDropdown, { DropdownButtonItem } from './button-dropdown'
import ButtonTabs from './button-tabs'
import { BetaLabel } from './counter'
import HeaderPanel, { Panel } from './header-panel'
import Input, { Label } from './input'
import Modal from './modal'
import { FieldSlot, FormRow, LabelSlot } from './row'
import Table from './table'
import Tooltip from './tooltip'
import { SuccessText } from './typography'
import EditIcon from '../assets/edit.svg'
import EyeOpenIcon from '../assets/icon-eye-open.svg'
import EyeClosedIcon from '../assets/icon-eye-crossed.svg'
import { availableToShareOptions, messages } from '../core/constants'
import { getItemByKeyValue } from '../helpers'
import { AvailableTo } from '../types/report-module'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/report-save.module.scss'

export type ReportType = 'performance' | 'lostLinks' | 'marketingJourney'

type SavedReportListItem<
  ReportIDKey extends string,
  ReportNameKey extends string,
  ShowAvailableTo extends boolean,
  AdditionalKeys extends Record<string, any> = {}
> = ShowAvailableTo extends true
  ? {
      availableTo: AvailableTo
    } & Record<ReportIDKey | ReportNameKey, string> &
      AdditionalKeys
  : Record<ReportIDKey | ReportNameKey, string> & AdditionalKeys

// Type guard to check if availableTo exists
const hasAvailableTo = <
  ReportIDKey extends string,
  ReportNameKey extends string,
  ShowAvailableTo extends boolean,
  AdditionalKeys extends Record<string, any>
>(
  report: SavedReportListItem<
    ReportIDKey,
    ReportNameKey,
    ShowAvailableTo,
    AdditionalKeys
  >,
): report is SavedReportListItem<
  ReportIDKey,
  ReportNameKey,
  true,
  AdditionalKeys
> => {
  return 'availableTo' in report
}

interface ManageSavedReportsModalProps<
  ReportIDKey extends string,
  ReportNameKey extends string,
  ShowAvailableTo extends boolean,
  AdditionalKeys extends Record<string, any> = {}
> {
  toggleActive: React.Dispatch<React.SetStateAction<boolean>>
  reportType?: ReportType
  /** Used to index the saved reports and fetch their details. Defaults to 'savedReportID' */
  reportIDKey?: ReportIDKey
  reportNameKey?: ReportNameKey
  showAvailableTo?: ShowAvailableTo
  /** Report can be hidden for the user without deleting it */
  loading: boolean
  savedReports: SavedReportListItem<
    ReportIDKey,
    ReportNameKey,
    ShowAvailableTo,
    AdditionalKeys
  >[]
  setReportToEdit: React.Dispatch<
    React.SetStateAction<SavedReportListItem<
      ReportIDKey,
      ReportNameKey,
      ShowAvailableTo,
      AdditionalKeys
    > | null>
  >
  setShowSaveReportModal: React.Dispatch<React.SetStateAction<boolean>>
  /** Report order might be sent as an array of IDs instead of the order of reports in the response */
  userPreferredOrder?: string[]
  onUpdateOrder?: (newOrder: string[]) => Promise<void>
  reportHiddenKey?: string
  onHideReport?: (
    reportsOrder: string[],
    hiddenReports: string[],
  ) => Promise<void>
  onDeleteReport: (reportIdToDelete: string) => Promise<void>
}

export function ManageSavedReportsModal<
  ReportIDKey extends string,
  ReportNameKey extends string,
  ShowAvailableTo extends boolean = true,
  AdditionalKeys extends Record<string, any> = {}
>({
  toggleActive,
  reportType = 'performance',
  reportIDKey = 'savedReportID' as ReportIDKey,
  reportNameKey = 'reportName' as ReportNameKey,
  showAvailableTo = true as ShowAvailableTo,
  loading = false,
  savedReports,
  setReportToEdit,
  setShowSaveReportModal,
  userPreferredOrder,
  onUpdateOrder,
  reportHiddenKey = 'reportHidden',
  onHideReport,
  onDeleteReport,
}: ManageSavedReportsModalProps<
  ReportIDKey,
  ReportNameKey,
  ShowAvailableTo,
  AdditionalKeys
>) {
  const logAction = useLogAction()

  const [confirmDeleteId, setConfirmDeleteId] = useState<string | null>(null)
  const [deletingReport, setDeletingReport] = useState(false)
  const [deleteReportSuccess, setDeleteReportSuccess] = useState(false)

  const { savedReportsOrder, hiddenReports } = useMemo(() => {
    return {
      savedReportsOrder:
        userPreferredOrder ||
        savedReports.map((report) => report[reportIDKey] as string),
      hiddenReports: savedReports
        .filter((report) => report[reportHiddenKey])
        .map((report) => report[reportIDKey] as string),
    }
  }, [savedReports, userPreferredOrder])

  return (
    <>
      <Modal
        setIsOpen={toggleActive}
        width="wide"
        modalHeader="Manage reports"
        loading={loading}
        afterClose={() => setReportToEdit(null)}
        yesText="Create new report"
        yesButtonDisabled={loading}
        onYes={() => {
          setReportToEdit(null)
          setShowSaveReportModal(true)
        }}
        footerContent={
          deleteReportSuccess ? (
            <SuccessText>Report has been deleted.</SuccessText>
          ) : undefined
        }
      >
        {savedReports && savedReports.length > 0 ? (
          <Table className={styles.table}>
            <thead>
              <tr>
                {!!onUpdateOrder && (
                  <th className={styles.handleColumn}>Order</th>
                )}
                <th className={styles.reportNameColumn}>Report name</th>
                {showAvailableTo && (
                  <th className={styles.visibilityColumn}>Who can see this</th>
                )}
                <th className={styles.actionsColumn}>Actions</th>
              </tr>
            </thead>
            <DragDropContext
              onDragEnd={async (result) => {
                setDeleteReportSuccess(false)

                if (!onUpdateOrder) return

                const { destination, source, draggableId } = result

                if (!destination) return

                if (
                  destination.droppableId === source.droppableId &&
                  destination.index === source.index
                ) {
                  return
                }

                const newReportsOrder = Array.from(savedReportsOrder)

                newReportsOrder.splice(source.index, 1)

                newReportsOrder.splice(destination.index, 0, draggableId)

                await onUpdateOrder(newReportsOrder)

                logAction({
                  variables: {
                    action: `saved-${reportType}-reports-update-order`,
                    extra: JSON.stringify({
                      oldOrder: savedReportsOrder,
                      newOrder: newReportsOrder,
                    }),
                    websiteSection: 'report',
                    functionName: 'updateSavedReportsOrder',
                    pagePath: window.location.pathname,
                  },
                })
              }}
            >
              <Droppable droppableId="reportSorter">
                {(droppableProvided) => {
                  return (
                    <tbody
                      ref={droppableProvided.innerRef}
                      className={styles.dragAndDropDraggableContainer}
                      {...droppableProvided.droppableProps}
                    >
                      {savedReports.map((report, index) => {
                        const savedReportID = report[reportIDKey]
                        const savedReportName = report[reportNameKey]

                        let availableTo = ''

                        if (showAvailableTo && hasAvailableTo(report)) {
                          // eslint-disable-next-line prefer-destructuring
                          availableTo = report.availableTo
                        }

                        return (
                          <Draggable
                            key={savedReportID}
                            index={index}
                            isDragDisabled={!onUpdateOrder}
                            draggableId={savedReportID}
                          >
                            {(draggableProvided) => {
                              return (
                                <tr
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                >
                                  {!!onUpdateOrder && (
                                    <td className={styles.handleColumn}>
                                      <BurgerIcon
                                        {...draggableProvided.dragHandleProps}
                                      />
                                    </td>
                                  )}
                                  <td className={styles.reportNameColumn}>
                                    {savedReportName}
                                  </td>
                                  {showAvailableTo && (
                                    <td className={styles.visibilityColumn}>
                                      {
                                        availableToShareOptions[availableTo]
                                          .long
                                      }
                                      <b
                                        className={classNames(
                                          styles.availableToIcon,
                                          {
                                            [styles[availableTo]]: true,
                                          },
                                        )}
                                      />
                                    </td>
                                  )}
                                  <td className={styles.actionsColumn}>
                                    {reportHiddenKey && !!onHideReport && (
                                      <Button
                                        variant="iconOnly"
                                        className={styles.editButton}
                                        style={{ marginRight: 8 }}
                                        icon={{
                                          alt: 'Hide report',
                                          src: report[reportHiddenKey]
                                            ? EyeClosedIcon
                                            : EyeOpenIcon,
                                        }}
                                        onPress={async () => {
                                          setDeleteReportSuccess(false)

                                          const newHiddenReports = [
                                            ...hiddenReports,
                                          ]

                                          if (report[reportHiddenKey]) {
                                            // Unhide
                                            const reportIndex = newHiddenReports.indexOf(
                                              report[reportIDKey],
                                            )

                                            newHiddenReports.splice(
                                              reportIndex,
                                              1,
                                            )
                                          } else if (
                                            !newHiddenReports.includes(
                                              report[reportIDKey],
                                            )
                                          ) {
                                            // Hide
                                            newHiddenReports.push(
                                              report[reportIDKey],
                                            )
                                          }

                                          await onHideReport(
                                            savedReportsOrder,
                                            newHiddenReports,
                                          )

                                          logAction({
                                            variables: {
                                              action: `saved-${reportType}-reports-hide-reports`,
                                              extra: JSON.stringify([]),
                                              websiteSection: 'report',
                                              functionName: 'hideSavedReports',
                                              pagePath:
                                                window.location.pathname,
                                            },
                                          })
                                        }}
                                      />
                                    )}
                                    <Button
                                      variant="iconOnly"
                                      className={styles.editButton}
                                      icon={{
                                        alt: 'Edit',
                                        src: EditIcon,
                                      }}
                                      onPress={() => {
                                        setDeleteReportSuccess(false)
                                        setReportToEdit(report)
                                        setShowSaveReportModal(true)
                                      }}
                                    />
                                    <DeleteButton
                                      onPress={() => {
                                        setDeleteReportSuccess(false)
                                        setConfirmDeleteId(savedReportID)
                                      }}
                                    />
                                  </td>
                                </tr>
                              )
                            }}
                          </Draggable>
                        )
                      })}
                      {droppableProvided.placeholder}
                    </tbody>
                  )
                }}
              </Droppable>
            </DragDropContext>
          </Table>
        ) : (
          <p className={styles.noSavedReports}>No saved reports</p>
        )}
      </Modal>
      {confirmDeleteId && (
        <Modal
          setIsOpen={() => setConfirmDeleteId(null)}
          modalHeader="Confirm report deletion"
          isWarning
          headerColor="pink"
          yesButtonLoading={deletingReport}
          yesText="Delete"
          onYes={async () => {
            setDeletingReport(true)

            await onDeleteReport(confirmDeleteId)

            logAction({
              variables: {
                action: `saved-${reportType}-reports-delete-report`,
                extra: confirmDeleteId,
                websiteSection: 'report',
                functionName: 'deleteSavedReport',
                pagePath: window.location.pathname,
              },
            })

            setDeleteReportSuccess(true)
            setDeletingReport(false)
            setConfirmDeleteId(null)
          }}
        >
          <p style={{ margin: 0 }}>
            You will no longer be able to access this report.
          </p>
        </Modal>
      )}
    </>
  )
}

interface SaveEditReportModalProps<
  ReportIDKey extends string,
  ReportNameKey extends string,
  ShowAvailableTo extends boolean,
  AdditionalKeys extends Record<string, any> = {}
> {
  toggleActive: React.Dispatch<React.SetStateAction<boolean>>
  reportType?: ReportType
  reportIDKey?: ReportIDKey
  reportDescriptionKey?: string
  reportNameKey?: ReportNameKey
  reportTitleKey?: string | null
  showAvailableTo?: ShowAvailableTo
  reportToEdit?: SavedReportListItem<
    ReportIDKey,
    ReportNameKey,
    ShowAvailableTo,
    AdditionalKeys
  > | null
  setReportToEdit?: React.Dispatch<
    React.SetStateAction<SavedReportListItem<
      ReportIDKey,
      ReportNameKey,
      ShowAvailableTo,
      AdditionalKeys
    > | null>
  >
  loading?: boolean
  /** Save or update the new report name/availability */
  onSuccess: (
    val: SavedReportListItem<
      ReportIDKey,
      ReportNameKey,
      ShowAvailableTo,
      AdditionalKeys
    >,
  ) => Promise<void>
}

export function SaveEditReportModal<
  ReportIDKey extends string,
  ReportNameKey extends string,
  ShowAvailableTo extends boolean = true,
  AdditionalKeys extends Record<string, any> = {}
>({
  reportType = 'performance',
  reportIDKey = 'savedReportID' as ReportIDKey,
  reportDescriptionKey,
  reportNameKey = 'reportName' as ReportNameKey,
  reportTitleKey = 'reportTitle',
  showAvailableTo = true as ShowAvailableTo,
  reportToEdit,
  setReportToEdit,
  toggleActive,
  loading,
  onSuccess,
}: SaveEditReportModalProps<
  ReportIDKey,
  ReportNameKey,
  ShowAvailableTo,
  AdditionalKeys
>) {
  const logAction = useLogAction()

  const [reportName, setReportName] = useState<string>(
    reportToEdit
      ? reportToEdit[reportNameKey]
      : messages[`defaultReportTitle_${reportType}`],
  )
  const [reportTitle, setReportTitle] = useState<string>(
    reportToEdit && reportTitleKey && reportToEdit[reportTitleKey]
      ? reportToEdit[reportTitleKey]
      : messages[`defaultReportTitle_${reportType}`],
  )
  const [reportDescription, setReportDescription] = useState(
    reportToEdit && reportDescriptionKey
      ? reportToEdit[reportDescriptionKey]
      : null,
  )
  const [availableTo, setAvailableTo] = useState<AvailableTo>(
    reportToEdit?.availableTo || 'user',
  )

  return (
    <Modal
      setIsOpen={toggleActive}
      modalHeader={
        reportToEdit ? 'Update existing report' : 'Create a new report'
      }
      yesText={reportToEdit ? 'Update' : 'Create'}
      yesButtonLoading={loading}
      onYes={async () => {
        const successObj = {
          ...reportToEdit,
          [reportNameKey]: reportName,
        }

        if (reportTitleKey) {
          successObj[reportTitleKey] = reportTitle
        }

        if (reportDescriptionKey) {
          successObj[reportDescriptionKey] = reportDescription
        }

        if (showAvailableTo) {
          successObj.availableTo = availableTo
        }

        await onSuccess(
          successObj as SavedReportListItem<
            ReportIDKey,
            ReportNameKey,
            ShowAvailableTo,
            AdditionalKeys
          >,
        )

        logAction({
          variables: {
            action: `save-${reportType}-${
              reportToEdit ? 'update-existing' : 'add-new'
            }`,
            extra: JSON.stringify(successObj),
            websiteSection: 'report',
            functionName: reportToEdit ? 'updateSavedReport' : 'saveReport',
            pagePath: window.location.pathname,
          },
        })
      }}
      afterClose={() => {
        if (setReportToEdit) {
          setReportToEdit(null)
        }
      }}
    >
      <FormRow>
        <LabelSlot topAlign>
          <Label modalHeading>
            <Tooltip
              id="report-name-tooltip"
              useIcon
              tooltipMessage="Name of report to display in tab."
            >
              Report name
            </Tooltip>
          </Label>
        </LabelSlot>
        <FieldSlot>
          <Input
            name="reportName"
            id="reportName"
            type="text"
            value={reportName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const { value: val } = event.target

              setReportName(val)

              if (reportTitleKey) {
                setReportTitle(val)
              }
            }}
          />
        </FieldSlot>
      </FormRow>
      {reportDescription !== null && (
        <FormRow>
          <LabelSlot topAlign>
            <Label
              modalHeading
              optional="(optional)"
              style={{ lineHeight: 'initial' }}
            >
              <Tooltip
                id="report-description-tooltip"
                useIcon
                tooltipMessage="Provide a brief description of the report."
              >
                Report description
              </Tooltip>
            </Label>
          </LabelSlot>
          <FieldSlot>
            <Input
              name="reportDescription"
              id="reportDescription"
              type="textArea"
              maxLength={500}
              value={reportDescription}
              onValueChange={(value: string) => setReportDescription(value)}
            />
          </FieldSlot>
        </FormRow>
      )}
      {showAvailableTo && (
        <>
          <LabelSlot topAlign className={styles.fullWidth}>
            <Label modalHeading>Who can see this</Label>
          </LabelSlot>
          <div className={styles.radioPanel}>
            <Input
              type="radio"
              id="availableTo-1"
              name="availableTo"
              className={classNames(
                styles.availableToInput,
                styles.availableToInputCompany,
              )}
              label={availableToShareOptions.company.long}
              checked={availableTo === 'company'}
              onClick={() => setAvailableTo('company')}
            >
              <p>{availableToShareOptions.company.desc}</p>
            </Input>
            <Input
              type="radio"
              id="availableTo-3"
              name="availableTo"
              className={classNames(
                styles.availableToInput,
                styles.availableToInputAccount,
              )}
              label={availableToShareOptions.account.long}
              checked={availableTo === 'account'}
              onClick={() => setAvailableTo('account')}
            >
              <p>{availableToShareOptions.account.desc}</p>
            </Input>
            <Input
              type="radio"
              id="availableTo-2"
              name="availableTo"
              className={classNames(styles.availableToInput, {
                [styles.availableToInputUser]: true,
              })}
              label={availableToShareOptions.user.long}
              checked={availableTo === 'user'}
              onClick={() => setAvailableTo('user')}
            >
              <p>{availableToShareOptions.user.desc}</p>
            </Input>
          </div>
        </>
      )}
    </Modal>
  )
}

interface ReportSavedTabsProps<
  CurrentReportConfig extends {},
  ReportIDKey extends string,
  ReportNameKey extends string,
  AdditionalKeys extends Record<string, any> = {}
> {
  reportType?: ReportType
  /** Report title shown in first tab */
  initialReportName?: string
  /** Data config as current on the page */
  currentDataConfig: CurrentReportConfig
  setShowSaveReportModal: React.Dispatch<React.SetStateAction<boolean>>
  loading?: boolean
  /** Used to index the saved reports and fetch their details. Defaults to 'savedReportID' */
  reportIDKey?: ReportIDKey
  reportNameKey?: ReportNameKey
  reportDescriptionKey?: string
  savedReports?: SavedReportListItem<
    ReportIDKey,
    ReportNameKey,
    false,
    AdditionalKeys
  >[]
  /** Used to set current report automatically, based on URLs */
  currentSavedReport: SavedReportListItem<
    ReportIDKey,
    ReportNameKey,
    false,
    AdditionalKeys
  > | null
  /** Force selected tab to be default - allows editing default board before saving */
  showDefaultReport?: boolean
  onResetReport: (
    resetToReport: SavedReportListItem<
      ReportIDKey,
      ReportNameKey,
      false,
      AdditionalKeys
    > | null,
  ) => void
  onSaveReport: (
    vars: CurrentReportConfig &
      SavedReportListItem<ReportIDKey, ReportNameKey, false, AdditionalKeys>,
  ) => Promise<void>
  actionButtons?: {
    key: string
    /** Makes the button look disabled, but still clickable */
    softDisable?: boolean
    isDisabled?: boolean
    loading?: boolean
    onPress: (e: PressEvent) => void | Promise<void>
    buttonText: React.ReactNode
    isBeta?: boolean
    logAction?: {
      action: string
      extra?: string
      functionName: string
    }
  }[]
}

export default function ReportSavedTabs<
  CurrentReportConfig extends {},
  ReportIDKey extends string,
  ReportNameKey extends string,
  AdditionalKeys extends Record<string, any> = {}
>({
  reportType = 'performance',
  initialReportName = 'Unfiltered report',
  currentDataConfig,
  setShowSaveReportModal,
  loading = false,
  reportIDKey = 'savedReportID' as ReportIDKey,
  reportNameKey = 'reportName' as ReportNameKey,
  reportDescriptionKey,
  savedReports = [],
  currentSavedReport,
  showDefaultReport,
  onResetReport,
  onSaveReport,
  actionButtons,
}: ReportSavedTabsProps<
  CurrentReportConfig,
  ReportIDKey,
  ReportNameKey,
  AdditionalKeys
>) {
  const logAction = useLogAction()

  const [currentIndex, setCurrentIndex] = useState(0)
  const [actionButtonsExpanded, setActionButtonsExpanded] = useState(false)

  const savedReportsOrder = useMemo(() => {
    return savedReports.map((report) => report[reportIDKey])
  }, [savedReports])

  const tabsLabels = useMemo(() => {
    const res: (string | React.ReactElement)[] = [initialReportName]

    if (savedReportsOrder.length > 0) {
      if (savedReportsOrder.length > 2) {
        savedReportsOrder.forEach((orderKey) => {
          const found = getItemByKeyValue(savedReports, reportIDKey, orderKey)

          if (found !== -1) {
            const { availableTo } = found

            if (found[reportNameKey]) {
              res.push(
                `${found[reportNameKey]} ${
                  availableTo === 'user' ? `(private)` : ''
                }`,
              )
            }
          }
        })

        return res
      }

      savedReportsOrder.forEach((orderKey) => {
        const found = getItemByKeyValue(savedReports, reportIDKey, orderKey)

        if (found !== -1) {
          const { availableTo } = found

          if (found[reportNameKey]) {
            res.push(
              <em key={nanoid()}>
                <Tooltip
                  id={found[reportIDKey]}
                  className={styles.reportDescriptionTooltip}
                  tooltipMessage={
                    reportDescriptionKey
                      ? found[reportDescriptionKey]
                      : undefined
                  }
                  tooltipPosition="bottom"
                  tooltipPositionStrategy="fixed"
                >
                  {found[reportNameKey]}{' '}
                  <b
                    className={classNames(styles.availableToIcon, {
                      [styles[availableTo]]: true,
                    })}
                  />
                </Tooltip>
              </em>,
            )
          }
        }
      })
    }

    return res
  }, [initialReportName, savedReports, savedReportsOrder, reportIDKey])

  useEffect(() => {
    if (!currentSavedReport || showDefaultReport) {
      setCurrentIndex(0)
      return
    }

    const foundReportIndex = Math.max(
      savedReportsOrder?.findIndex(
        (savedReportId) =>
          // @ts-ignore
          savedReportId === currentSavedReport?.savedReportID ||
          // @ts-ignore
          savedReportId === currentSavedReport.boardID,
      ) || 0,
      0,
    )

    setCurrentIndex(foundReportIndex + 1)
  }, [currentSavedReport, showDefaultReport, savedReportsOrder])

  return (
    <>
      <HeaderPanel className={styles.headerPanel}>
        <Panel className={styles.panel}>
          <ButtonTabs
            className={styles.buttonTabs}
            showOrphansAsTab
            selected={currentIndex}
            isTopOfBox
            showDropDown
            tabsLabels={tabsLabels}
            type="tabs"
            onChange={(index: number) => {
              if (index === currentIndex) {
                onResetReport(currentSavedReport)
              } else if (index === 0) {
                onResetReport(null)
              } else {
                const found = {
                  ...savedReports[index - 1],
                }
                onResetReport(found)
              }
            }}
          >
            {[
              ...tabsLabels.map((item) => {
                return (
                  <span key={nanoid()} className={styles.tabBoxPlaceholder}>
                    {item}
                  </span>
                )
              }),
              <React.Fragment key="modal-edit-button">
                <div className={styles.afterTabsButtons}>
                  <Button
                    variant="secondary"
                    style={{ padding: '2px 12px' }}
                    loading={loading}
                    onPress={async () => {
                      if (currentSavedReport && !showDefaultReport) {
                        await onSaveReport({
                          ...currentSavedReport,
                          ...currentDataConfig,
                        })

                        logAction({
                          variables: {
                            action: `saved-${reportType}-reports-update-existing`,
                            extra: JSON.stringify({
                              ...currentSavedReport,
                              ...currentDataConfig,
                            }),
                            websiteSection: 'report',
                            functionName: 'updateSavedReport',
                            pagePath: window.location.pathname,
                          },
                        })
                      } else {
                        setShowSaveReportModal(true)
                      }
                    }}
                  >
                    {currentSavedReport ? 'Save changes' : 'Save new report'}
                  </Button>
                </div>
              </React.Fragment>,
              <div
                key="Report actions"
                className={styles.afterTabsActionButtons}
              >
                {actionButtons && actionButtons.length > 0 && (
                  <div className={styles.actionButtonsWrapper}>
                    <ButtonDropdown
                      buttonText="Actions"
                      containerClassName={styles.bulkButtons}
                      separateMainAction={false}
                      mainAction={() => {
                        if (!actionButtonsExpanded) {
                          logAction({
                            variables: {
                              action: `action-drop-down-clicked-${reportType}`,
                              extra: '',
                              websiteSection: 'report',
                              functionName: 'clickDropdown',
                              pagePath: window.location.pathname,
                            },
                          })
                        }

                        setActionButtonsExpanded(!actionButtonsExpanded)
                      }}
                    >
                      {actionButtons.map(
                        ({
                          key,
                          loading: buttonLoading,
                          softDisable,
                          isDisabled,
                          onPress,
                          buttonText,
                          isBeta,
                          logAction: buttonAction,
                        }) => {
                          return (
                            <DropdownButtonItem
                              key={key}
                              loading={buttonLoading}
                              className={
                                softDisable ? styles.softDisable : undefined
                              }
                              isDisabled={isDisabled}
                              onPress={async (e) => {
                                await onPress(e)

                                if (buttonAction) {
                                  logAction({
                                    variables: {
                                      action: buttonAction.action,
                                      extra: buttonAction.extra,
                                      websiteSection: 'report',
                                      functionName: buttonAction.functionName,
                                      pagePath: window.location.pathname,
                                    },
                                  })
                                }
                              }}
                            >
                              {buttonText}
                              {isBeta && (
                                <BetaLabel className={styles.betaLabel} />
                              )}
                            </DropdownButtonItem>
                          )
                        },
                      )}
                    </ButtonDropdown>
                  </div>
                )}
              </div>,
            ]}
          </ButtonTabs>
        </Panel>
      </HeaderPanel>
    </>
  )
}
