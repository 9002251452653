import React, { useEffect, useState, useMemo } from 'react'
import { nanoid } from 'nanoid'
import classNames from 'classnames'

import Input from './input'
import SelectBox from './select-box'
import iconPath from '../assets/icon-eye-crossed.svg'
import styles from '../styles/button-tabs.module.scss'

const getText = (children: (string | React.ReactElement)[]) => {
  let label = ''

  children.forEach((child) => {
    if (typeof child === 'string') {
      label += child
    }
  })

  return label
}

export interface ButtonTabsProps {
  tabsLabels: (string | React.ReactElement)[]
  label?: string
  type?: 'buttons' | 'tabs'
  isTopOfBox?: boolean
  selected?: number
  onChange?: (current: number) => void
  fwdRef?: React.ForwardedRef<HTMLDivElement>
  showOrphansAsTab?: boolean
  showOrphansAsContent?: boolean
  className?: string
  showDropDown?: boolean
}

interface ChildrenProps {
  children?: React.ReactElement[]
}

export default function ButtonTabs({
  label,
  children,
  tabsLabels,
  type = 'buttons',
  isTopOfBox = false,
  selected = 0,
  onChange,
  fwdRef = null,
  showOrphansAsTab = false,
  showOrphansAsContent = false,
  className,
  showDropDown = false,
}: ButtonTabsProps & ChildrenProps): React.ReactElement {
  const [currentTab, setCurrentTab] = useState(selected)

  useEffect(() => {
    setCurrentTab(selected)
  }, [selected])

  const useShowDropDown = useMemo(() => {
    return showDropDown && Array.isArray(tabsLabels) && tabsLabels.length > 3
  }, [showDropDown, tabsLabels])

  const dropdownOptions = useMemo(() => {
    return tabsLabels.map((item, index) => {
      let useValue = typeof item === 'string' ? item : ''

      if (typeof item === 'function') {
        const { children: text } = item

        useValue = getText(text)
      }

      return {
        label: useValue.replace('(private)', ''),
        value: `tab-${index}`,
        icon: useValue.indexOf('(private)') !== -1 ? iconPath : '',
      }
    })
  }, [tabsLabels])

  return (
    <div
      ref={fwdRef}
      className={classNames(className, styles.buttonTabs, {
        [styles.styleButtons]: type === 'buttons',
        [styles.styleTabs]: type === 'tabs',
        [styles.isTopOfBox]: isTopOfBox,
        [styles.showDropDown]: useShowDropDown,
      })}
    >
      <div className={styles.tabsOuterWrapper}>
        {label && (
          <p>
            <strong>{label}</strong>
          </p>
        )}
        <div className={styles.tabsWrapper}>
          {Array.isArray(tabsLabels) &&
            tabsLabels
              .slice(0, useShowDropDown ? 1 : tabsLabels.length)
              .map((item, index) => {
                const checked = currentTab === index

                let useValue = typeof item === 'string' ? item : ''
                if (typeof item === 'function') {
                  const { children: text } = item
                  useValue = getText(text)
                }

                return (
                  <Input
                    key={nanoid()}
                    name="select-view"
                    id={nanoid()}
                    label={item}
                    type="radio"
                    value={useValue}
                    checked={checked}
                    onClick={() => {
                      if (children && children.length < index + 1) {
                        if (onChange) {
                          onChange(-1)
                        }
                      } else {
                        setCurrentTab(index)
                        if (onChange) {
                          onChange(index)
                        }
                      }
                    }}
                  />
                )
              })}
        </div>
        {useShowDropDown && (
          <div className={styles.showDropDownWrapper}>
            <SelectBox
              variant="grey"
              className={styles.tabSelectorDropdown}
              placeholder="Saved reports"
              value={currentTab === 0 ? null : dropdownOptions[currentTab]}
              options={dropdownOptions.slice(1)}
              onChange={(newValue) => {
                if (!newValue) {
                  setCurrentTab(0)
                  if (onChange) onChange(0)
                  return
                }

                const { value } = newValue

                if (!value) return

                const useIndex = value.split('tab-')

                const index = parseInt(useIndex[1], 10)

                if (children && children.length < index + 1) {
                  if (onChange) onChange(-1)
                } else {
                  setCurrentTab(index)
                  if (onChange) onChange(index)
                }
              }}
            />
          </div>
        )}
        {showOrphansAsTab &&
          children &&
          children.map((child, index) => {
            if (tabsLabels.length <= index) {
              return child
            }
            return null
          })}
      </div>
      <div className={styles.content}>
        {children &&
          children.map((child, index) => {
            if (index === currentTab) {
              return child
            }
            if (showOrphansAsContent && tabsLabels.length <= index) {
              return child
            }
            return null
          })}
      </div>
    </div>
  )
}
