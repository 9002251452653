import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  useQuery,
  useLazyQuery,
  useReactiveVar,
  useMutation,
} from '@apollo/client'
import moment from 'moment'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import _ from 'lodash'

import { ButtonRow } from './button-row'
import Button from './button'
import { BulletCounter } from './counter'
import HeaderPanel, { Panel } from './header-panel'
import { Heading } from './typography'
import { ArrowNext, ArrowPrev } from './image-svg'
import Input, { Label } from './input'
import Link from './link'
import { LoadingLabel } from './loader'
import Modal from './modal'
import Row, { Slot } from './row'
import { SelectBoxChecklist } from './select-box'
import Tooltip from './tooltip'
import { InnerBox, OuterBox } from './two-columns'
import { currentUserDetails } from '../api/apollo/variables'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import {
  approveDropdownOptionRequest,
  getCampaignCodeGeneratorByID,
  getUpdateRequestList,
  rejectDropdownOptionRequest,
} from '../api/graphql/track-edit-client'
import { getUserAccounts } from '../api/graphql/user-client'
import { GeneratorFields, ValidationChecks } from '../api/types'
import { supportEmail } from '../core/constants'
import { prepareInput, returnUnique, getUrlQuery } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import useMobile from '../hooks/useMobile'
import styles from '../styles/track-dropdown-requests.module.scss'
import {
  GetCampaignCodeGeneratorByAccountIdQuery,
  GetCampaignCodeGeneratorQuery,
} from '../__gql-types__/graphql'

interface Options {
  optionName: string
  optionValue: string
  approved?: boolean
  message?: string
  optionRequestID?: string
  optionRequestIndex?: number | null
  rejected?: boolean
  accountIDList?: string[]
}

interface ParamRequestRowProps {
  isMobile: boolean
  requestID: string
  field: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['paramDefs'][0]
  option: Options
  validation: ValidationChecks[]
  existingValues: { name: string; value: string }[]
  availableAccounts: {
    accountName: string
    accountID: string
  }[]
  originalRequestAccount: string
}

const ParamRequestRow = ({
  isMobile,
  requestID,
  field,
  option,
  validation,
  existingValues,
  availableAccounts,
  originalRequestAccount,
}: ParamRequestRowProps) => {
  const { fieldID, fieldName, forceLowerCase, lengthLimit = 45 } = field

  const { userID } = useReactiveVar(currentUserDetails)
  const logAction = useLogAction()

  const [
    approveUpdateRequest,
    { loading: approving, error: approveError },
  ] = useMutation(approveDropdownOptionRequest)
  const [
    rejectUpdateRequest,
    { loading: rejecting, error: rejectError },
  ] = useMutation(rejectDropdownOptionRequest)

  const [optionName, setOptionName] = useState(option.optionName)
  const [optionValue, setOptionValue] = useState(option.optionValue)
  const [fieldNameError, setFieldNameError] = useState(
    existingValues.findIndex((val) => val.name === option.optionName) > -1,
  )
  const [fieldValueError, setFieldValueError] = useState(
    existingValues.findIndex((val) => val.value === option.optionValue) > -1,
  )
  const [valueTooLong, setValueTooLong] = useState(false)
  const [accountIDs, setAccountIDs] = useState([originalRequestAccount])
  const [approved, setApproved] = useState(false)
  const [approveRejectModalActive, setApproveRejectModalActive] = useState(
    false,
  )
  const [userMessage, setUserMessage] = useState('')
  const [approveRejectError, setApproveRejectError] = useState(false)

  const { similarNames, similarValues } = useMemo(() => {
    const nameSearchPattern = optionName
      .replace(/[^a-zA-Z0-9]/g, '')
      .split('')
      .join('.*')

    const valueSearchPattern = optionValue
      .replace(/[^a-zA-Z0-9]/g, '')
      .split('')
      .join('.*')

    return {
      similarNames: existingValues.filter(
        (item) =>
          item.name.toLowerCase().match(new RegExp(nameSearchPattern, 'i')) !==
          null,
      ),
      similarValues: existingValues.filter(
        (item) =>
          item.value
            .toLowerCase()
            .match(new RegExp(valueSearchPattern, 'i')) !== null,
      ),
    }
  }, [optionName, optionValue, existingValues])

  const query = getUrlQuery()

  useEffect(() => {
    const action = query?.get('action')
    const urlRequestID = query?.get('requestID')

    if (action && urlRequestID === requestID) {
      setApproved(action === 'approve')
      setApproveRejectModalActive(true)
    }
  }, [])

  return (
    <>
      <Row align="flex-start" className={styles.paramRequests}>
        <Slot className={styles.optionNameSlot}>
          {isMobile && (
            <Label id="optionName" className={styles.optionLabel}>
              <span className={styles.title}>
                <Tooltip
                  id={`${fieldID}-option-name-tooltip"`}
                  useIcon
                  tooltipMessage="The name users can select on Track>Create links page dropdown."
                >
                  Option name
                </Tooltip>
              </span>
            </Label>
          )}
          <Input
            id="optionName"
            name="optionName"
            type="text"
            error={
              (optionName !== '' && similarNames.length > 0) || fieldNameError
            }
            value={optionName}
            placeholder="Dropdown option name"
            beforeChange={(inputValue: string) => {
              setFieldNameError(false)

              return inputValue
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = event.target

              setOptionName(value)

              if (
                existingValues.findIndex((item) => item.name === value) > -1
              ) {
                setFieldNameError(true)
              }
            }}
          />
          {optionName !== '' && similarNames.length > 0 && !fieldNameError && (
            <>
              <p className={styles.footNoteError} style={{ marginBottom: 0 }}>
                Similar names found, could they use:{' '}
              </p>
              <p className={styles.footNoteError}>
                {similarNames.map((item, index) => {
                  return `${item.value}${
                    index !== similarNames.length - 1 ? ', ' : ''
                  }`
                })}
              </p>
            </>
          )}
          {fieldNameError && (
            <p className={styles.footNoteError}>
              This name is already being used.
            </p>
          )}
        </Slot>
        <Slot className={styles.optionValueSlot}>
          {isMobile && (
            <Label id="optionName" className={styles.optionLabel}>
              <span className={styles.title}>
                <Tooltip
                  id={`${fieldID}-option-value-tooltip"`}
                  useIcon
                  tooltipMessage="The value used in the campaign link URL."
                >
                  Option value
                </Tooltip>
              </span>
            </Label>
          )}
          <Input
            id="optionValue"
            name="optionValue"
            type="text"
            value={optionValue}
            placeholder="Value"
            error={
              fieldValueError ||
              (optionValue !== '' && similarValues.length > 0)
            }
            beforeChange={(inputValue: string) => {
              setFieldValueError(false)

              let updatedValue = prepareInput(inputValue, validation)

              if (forceLowerCase) {
                updatedValue = updatedValue.toLowerCase()
              }

              return updatedValue
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const { value: val } = event.target

              setOptionValue(val)

              if (existingValues.findIndex((item) => item.value === val) > -1) {
                setFieldValueError(true)
              }

              setValueTooLong(val.length > (lengthLimit as number))
            }}
          />
          {valueTooLong ? (
            <p className={styles.footNoteError}>
              {optionValue.length - (lengthLimit as number)} characters over the
              limit. Reduce it or edit the limit{' '}
              <Link href="/track/edit-parameters-and-rules">here</Link>.
            </p>
          ) : (
            <>
              {optionValue !== '' &&
                !fieldValueError &&
                similarValues.length > 0 && (
                  <>
                    <p
                      className={styles.footNoteError}
                      style={{ marginBottom: 0 }}
                    >
                      Similar values found, could they use:{' '}
                    </p>
                    <p className={styles.footNoteError}>
                      {similarValues.map((item, index) => {
                        return `${item.value}${
                          index !== similarValues.length - 1 ? ', ' : ''
                        }`
                      })}
                    </p>
                  </>
                )}
              {fieldValueError && (
                <p className={styles.footNoteError}>
                  This code is already being used.
                </p>
              )}
            </>
          )}
        </Slot>
        {availableAccounts.length > 1 && (
          <Slot className={styles.optionAccountsSlot}>
            {isMobile && (
              <Label id="optionName" className={styles.optionLabel}>
                <span className={styles.title}>
                  <Tooltip
                    id={`${fieldID}-option-workspaces-tooltip"`}
                    useIcon
                    tooltipMessage="The workspaces that this new option will be added to."
                  >
                    Workspace(s)
                  </Tooltip>
                </span>
              </Label>
            )}
            <SelectBoxChecklist
              id="account-select"
              className={styles.multiSelect}
              isLoading={availableAccounts.length === 0}
              placeholder="Select workspaces"
              allLabel="All workspaces"
              labelKey="accountName"
              valueKey="accountID"
              value={availableAccounts.filter(
                (account) => accountIDs.indexOf(account.accountID) > -1,
              )}
              options={availableAccounts}
              onChange={(newValue) =>
                setAccountIDs(newValue.map(({ accountID }) => accountID))
              }
            />
          </Slot>
        )}
        <Slot className={styles.actionSlot}>
          <ButtonRow className={styles.buttonRow}>
            <Button
              color="green"
              className={styles.manageButton}
              isDisabled={
                // Used to allow empty values if name is given
                !(optionName !== '' && optionValue !== '') ||
                valueTooLong ||
                fieldValueError ||
                accountIDs.length === 0
              }
              onPress={() => {
                setApproved(true)
                setApproveRejectModalActive(true)
              }}
            >
              Approve
            </Button>
            <Button
              color="red"
              className={styles.manageButton}
              onPress={() => {
                setApproved(false)
                setApproveRejectModalActive(true)
              }}
            >
              Reject
            </Button>
          </ButtonRow>
        </Slot>
      </Row>
      {approveRejectModalActive && (
        <Modal
          setIsOpen={setApproveRejectModalActive}
          headerColor={approved ? 'green' : undefined}
          modalHeader={
            approved ? 'Add an approval message' : 'Why are you rejecting this?'
          }
          footerContent={
            approveRejectError || approveError || rejectError ? (
              <p className={styles.modalError}>
                Error {} request. Please contact support at{' '}
                <Link href={supportEmail}>{supportEmail}</Link>
              </p>
            ) : undefined
          }
          yesText={approved ? 'Approve' : 'Reject'}
          yesButtonLoading={approving || rejecting}
          onYes={async () => {
            setApproveRejectError(false)

            try {
              const { optionRequestID, optionRequestIndex } = option

              if (approved) {
                await approveUpdateRequest({
                  variables: {
                    accountIDList: accountIDs,
                    fieldID,
                    fieldName,
                    message: userMessage,
                    newName: optionName,
                    newValue: optionValue,
                    optionRequestID,
                    optionRequestIndex,
                    requestID,
                  },
                  refetchQueries: [
                    getUpdateRequestList,
                    getCampaignCodeGenerator,
                  ],
                })
              } else {
                await rejectUpdateRequest({
                  variables: {
                    fieldID,
                    fieldName,
                    message: userMessage,
                    optionRequestID,
                    optionRequestIndex,
                    requestID,
                  },
                  refetchQueries: [getUpdateRequestList],
                })
              }

              logAction({
                variables: {
                  action: `${approved ? 'approve' : 'reject'}-update-request`,
                  extra: JSON.stringify({
                    newName: approved ? optionName : undefined,
                    newValue: approved ? optionValue : undefined,
                    accountIDList: approved ? accountIDs : undefined,
                    fieldID,
                    fieldName,
                    message: userMessage,
                    optionRequestID,
                    optionRequestIndex,
                    requestID,
                    approverID: userID,
                  }),
                  websiteSection: 'track',
                  functionName: 'approveRejectNewSelectOption',
                  pagePath: '/track/edit-dropdowns',
                },
              })

              setApproveRejectModalActive(false)
            } catch {
              setApproveRejectError(true)
            }
          }}
        >
          <Label modalHeading id="parameter-reason" optional="(optional)">
            Leave a message for the user
          </Label>
          <textarea
            id="parameter-reason"
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              const { value: val } = event.target
              setUserMessage(val)
            }}
            placeholder="Leave comment..."
            value={userMessage}
          />
        </Modal>
      )}
    </>
  )
}

interface InvalidParamRequestRow {
  requestID: string
  fieldID: string
  option: Options
}

const InvalidParamRequestRow = ({
  requestID,
  fieldID,
  option,
}: InvalidParamRequestRow) => {
  const [rejectUpdateRequest] = useMutation(rejectDropdownOptionRequest)

  return (
    <>
      <Row align="flex-start" className={styles.paramRequests}>
        <Slot className={styles.optionNameSlot}>
          <span style={{ lineHeight: '40px' }}>
            This request was made against a parameter that no longer exists in
            your link creator.
          </span>
        </Slot>
        <Slot className={styles.actionSlot}>
          <ButtonRow className={styles.buttonRow}>
            <Button
              color="red"
              className={styles.manageButton}
              onPress={async () => {
                const { optionRequestID, optionRequestIndex } = option

                await rejectUpdateRequest({
                  variables: {
                    fieldID,
                    fieldName: '',
                    message: '',
                    optionRequestID,
                    optionRequestIndex,
                    requestID,
                  },
                  refetchQueries: [getUpdateRequestList],
                })
              }}
            >
              Remove request
            </Button>
          </ButtonRow>
        </Slot>
      </Row>
    </>
  )
}

interface ParamRequestTableProps {
  requestID: string
  param: GeneratorFields | null
  changeRequest: any
  validation: ValidationChecks[]
  availableAccounts: {
    accountName: string
    accountID: string
  }[]
  originalRequestAccount: string
}

const ParamRequestTable = ({
  requestID,
  param,
  changeRequest,
  validation,
  availableAccounts,
  originalRequestAccount,
}: ParamRequestTableProps) => {
  const isMobile = useMobile(769)

  return (
    <>
      {param && (
        <Heading type={3} align="left">
          Parameter:&nbsp;<span>{param.fieldName}</span>
        </Heading>
      )}
      {!isMobile && param && (
        <Row
          align="flex-start"
          vAlign="center"
          className={styles.paramRequests}
        >
          <Slot className={styles.optionNameSlot}>
            <h3 className={styles.title}>
              <Tooltip
                id={`${param.fieldID}-option-name-tooltip"`}
                useIcon
                tooltipMessage="The name users can select on Track>Create links page dropdown."
              >
                Option name
              </Tooltip>
            </h3>
          </Slot>
          <Slot className={styles.optionValueSlot}>
            <h3 className={styles.title}>
              <Tooltip
                id={`${param.fieldID}-option-value-tooltip"`}
                useIcon
                tooltipMessage="The value used in the campaign link URL."
              >
                Option value
              </Tooltip>
            </h3>
          </Slot>
          {availableAccounts.length > 1 && (
            <Slot className={styles.optionAccountsSlot}>
              <h3 className={styles.title}>
                <Tooltip
                  id={`${param.fieldID}-option-workspaces-tooltip"`}
                  useIcon
                  tooltipMessage="The workspaces that this new option will be added to."
                >
                  Workspace(s)
                </Tooltip>
              </h3>
            </Slot>
          )}
          <Slot className={styles.actionSlot} />
        </Row>
      )}
      {changeRequest.newOptions.map((option) => {
        const { approved, rejected } = option
        const isReviewed = !!(approved || rejected)

        if (isReviewed) {
          return null
        }

        if (!param) {
          return (
            <InvalidParamRequestRow
              key={option.optionRequestID}
              requestID={requestID}
              fieldID={changeRequest.fieldID}
              option={option}
            />
          )
        }

        return (
          <ParamRequestRow
            isMobile={isMobile}
            key={option.optionRequestID}
            requestID={requestID}
            field={param}
            option={option}
            validation={validation}
            existingValues={
              param && param.selectFields
                ? param.selectFields.map((field) => ({
                    name: field.optionName,
                    value: field.optionValue,
                  }))
                : []
            }
            availableAccounts={availableAccounts}
            originalRequestAccount={originalRequestAccount}
          />
        )
      })}
    </>
  )
}

interface TrackDropdownRequestsProps {
  currentWorkspaceData: GetCampaignCodeGeneratorByAccountIdQuery['track']['campaignCodeGeneratorAccount']
}

interface AllOrgAcctStructs {
  [
    workspaceID: string
  ]: GetCampaignCodeGeneratorByAccountIdQuery['track']['campaignCodeGeneratorAccount']
}

const TrackDropdownRequests = ({
  currentWorkspaceData,
}: TrackDropdownRequestsProps) => {
  const { workspaceID, companyID } = useReactiveVar(currentUserDetails)

  const carouselContainerRef = useRef<HTMLDivElement>(null)

  const {
    data: pendingRequestsData,
    loading: loadingPendingRequests,
  } = useQuery(getUpdateRequestList)
  const [getUserAccountProfiles, { data: userAccountsData }] = useLazyQuery(
    getUserAccounts,
  )
  const [getAccountGenerator, { loading: loadingGeneratorById }] = useLazyQuery(
    getCampaignCodeGeneratorByID,
    {
      notifyOnNetworkStatusChange: true,
    },
  )

  const [activeRequest, setActiveRequest] = useState(0)
  const [allWorkspacesData, setAllWorkspacesData] = useState<AllOrgAcctStructs>(
    { [workspaceID]: currentWorkspaceData },
  )
  const [carouselHeights, setCarouselHeights] = useState<number[]>([])

  const allPendingRequests = useMemo(() => {
    return pendingRequestsData
      ? pendingRequestsData.track.updateRequestList.pendingRequests
      : []
  }, [pendingRequestsData])

  // Fetch workspaces user has access to
  useEffect(() => {
    if (allPendingRequests.length > 0) getUserAccountProfiles()
  }, [allPendingRequests])

  // Get workspaces that the user has admin access to
  const availableAccounts = useMemo(() => {
    if (!userAccountsData) return []

    const { userAccountProfiles } = userAccountsData.currentUser

    if (userAccountProfiles.length > 0) {
      const selectedAccounts: {
        accountName: string
        accountID: string
      }[] = returnUnique(userAccountProfiles, 'accountID')
        .filter(
          (account: AccountInfo) =>
            account.companyID === companyID &&
            (account.userPermission === 'admin' ||
              account.userPermission === 'support'),
        )
        .map((account: AccountInfo) => {
          return {
            accountName: account.accountName,
            accountID: account.accountID,
          }
        })
      return selectedAccounts
    }

    return []
  }, [userAccountsData])

  const pendingRequests = useMemo(() => {
    if (availableAccounts.length === 0 || allPendingRequests.length === 0)
      return []

    const availableAccountIDs = availableAccounts.map(
      (account) => account.accountID,
    )

    return allPendingRequests.filter((request) => {
      return (
        availableAccountIDs.indexOf(request.updateRequestAccountID) > -1 &&
        request.changeRequests &&
        !request.changeRequests.every((changeRequest) => {
          return (
            changeRequest.newOptions &&
            changeRequest.newOptions.every(
              ({ approved, rejected }) => approved || rejected,
            )
          )
        })
      )
    })
  }, [allPendingRequests, availableAccounts])

  // Workspace generators that need to be fetched
  const workspacesWithpendingRequests = useMemo(() => {
    return [
      ...new Set(
        pendingRequests.map(
          ({ updateRequestAccountID }) => updateRequestAccountID,
        ),
      ),
    ]
  }, [pendingRequests])

  // Fetch all generators
  // Generators the user does not have access to will not be returned
  useEffect(() => {
    if (workspacesWithpendingRequests.length === 0) return

    const fetchAllGenerators = async () => {
      const otherAccountGenerators: AllOrgAcctStructs = {}

      // eslint-disable-next-line no-restricted-syntax
      for (const updateAccountID of workspacesWithpendingRequests) {
        if (updateAccountID !== workspaceID) {
          // eslint-disable-next-line no-await-in-loop
          const { data } = await getAccountGenerator({
            variables: {
              accountID: updateAccountID,
            },
          })

          if (data) {
            otherAccountGenerators[updateAccountID] =
              data.track.campaignCodeGeneratorAccount
          }
        }
      }

      setAllWorkspacesData({ ...allWorkspacesData, ...otherAccountGenerators })
    }

    fetchAllGenerators()
  }, [workspacesWithpendingRequests])

  // Set number of carousel heights
  useEffect(() => {
    setCarouselHeights(pendingRequests.map((item) => 250))
  }, [pendingRequests])

  // When number of pending requests changes, reset active carousel request
  useEffect(() => {
    setActiveRequest(Math.max(0, activeRequest - 1))
  }, [pendingRequests.length])

  // Set active request based on URL query param if present
  useEffect(() => {
    const query = getUrlQuery()

    const requestID = query?.get('requestID')

    if (requestID) {
      const requestIndex = pendingRequests.findIndex(
        (request) => request.requestID === requestID,
      )

      if (requestIndex > -1) {
        setActiveRequest(requestIndex)
      }
    }
  }, [])

  // Set the height of the carousel dynamically based on active item
  // The 'dynamicHeight' prop on Carousel doesn't work
  useEffect(() => {
    if (carouselContainerRef.current) {
      const sliderWrapper = carouselContainerRef.current.querySelector(
        '.carousel-root .slider-wrapper',
      )

      if (sliderWrapper) {
        sliderWrapper.setAttribute(
          'style',
          `height:${carouselHeights[activeRequest]}px;`,
        )
      }
    }
  }, [carouselHeights, carouselContainerRef, activeRequest])

  if (
    !workspaceID ||
    loadingPendingRequests ||
    loadingGeneratorById ||
    !workspacesWithpendingRequests.every((workspace) =>
      Object.prototype.hasOwnProperty.call(allWorkspacesData, workspace),
    )
  ) {
    return <LoadingLabel label="Checking for new requests" />
  }

  if (pendingRequests.length === 0) {
    return null
  }

  return (
    <div ref={carouselContainerRef}>
      <HeaderPanel>
        <Panel className={styles.headerPanelInner}>
          <Heading type={3} align="left">
            New dropdown requests
            <BulletCounter
              count={pendingRequests.length}
              className={styles.counter}
            />
          </Heading>
          {pendingRequests.length > 1 && (
            <div className={styles.requestCycle}>
              <Button
                variant="iconOnly"
                className={styles.cycleArrow}
                isDisabled={activeRequest === 0}
                onPress={() => setActiveRequest(activeRequest - 1)}
              >
                <ArrowPrev
                  color={activeRequest === 0 ? '#cbd5e0' : '#e61969'}
                />
              </Button>
              <p>
                <strong>
                  {activeRequest + 1} of {pendingRequests.length}
                </strong>
              </p>
              <Button
                variant="iconOnly"
                className={styles.cycleArrow}
                isDisabled={activeRequest === pendingRequests.length - 1}
                onPress={() => setActiveRequest(activeRequest + 1)}
              >
                <ArrowNext
                  color={
                    activeRequest === pendingRequests.length - 1
                      ? '#cbd5e0'
                      : '#e61969'
                  }
                />
              </Button>
            </div>
          )}
        </Panel>
      </HeaderPanel>

      <Carousel
        key={JSON.stringify(allWorkspacesData)}
        className={styles.carouselContainer}
        selectedItem={activeRequest}
        showArrows={false}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        swipeable={false}
      >
        {pendingRequests.map((request, requestIndex) => {
          const updateAccountID = request.updateRequestAccountID

          const orgAccStruct = allWorkspacesData[updateAccountID]

          return (
            <OuterBox
              key={request.requestID}
              className={styles.requestBox}
              fwdRef={(ref) => {
                if (
                  ref?.offsetHeight &&
                  ref.offsetHeight !== carouselHeights[requestIndex]
                ) {
                  setCarouselHeights((curr) => {
                    const newHeights = [...curr]

                    newHeights.splice(requestIndex, 1, ref.offsetHeight)

                    return newHeights
                  })
                }
              }}
            >
              <InnerBox>
                <h3>
                  Request:&nbsp;
                  <span>
                    {request.requestedBy} on{' '}
                    {moment(request.requestDate).format('ddd Do MMM YYYY')} for
                    workspace {request.updateRequestAccountName}
                  </span>
                </h3>
                <h3>
                  Note:&nbsp;
                  <span>{request.requestNote}</span>
                </h3>
                {request.changeRequests &&
                  request.changeRequests.map((changeRequest, index) => {
                    // Don't show already approved or rejected requests
                    if (
                      changeRequest.newOptions &&
                      changeRequest.newOptions.every(
                        ({ approved, rejected }) => approved || rejected,
                      )
                    ) {
                      return null
                    }

                    const param = orgAccStruct.paramDefs.find(
                      (paramDef) => paramDef.fieldID === changeRequest.fieldID,
                    )

                    const validation = orgAccStruct.validationChecks

                    const nextRequests =
                      request.changeRequests?.slice(index + 1) || []

                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <React.Fragment key={`${changeRequest.fieldID}-${index}`}>
                        <ParamRequestTable
                          requestID={request.requestID}
                          param={param || null}
                          changeRequest={changeRequest}
                          validation={validation}
                          availableAccounts={availableAccounts}
                          originalRequestAccount={
                            request.updateRequestAccountID
                          }
                        />
                        {request.changeRequests &&
                          index < request.changeRequests.length - 1 &&
                          nextRequests.find(
                            (req) =>
                              req.newOptions &&
                              req.newOptions.find(
                                ({ approved, rejected }) =>
                                  !approved && !rejected,
                              ),
                          ) && <div className={styles.divider} />}
                      </React.Fragment>
                    )
                  })}
              </InnerBox>
            </OuterBox>
          )
        })}
      </Carousel>
    </div>
  )
}

export default TrackDropdownRequests
