import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { logActionMutation } from '../api/graphql/analytics-client'
import { LogActionMutationVariables } from '../__gql-types__/graphql'

const useLogAction = () => {
  const [logAction] = useMutation(logActionMutation)

  // Exclude logging on localhost staging override
  const isOverride =
    process.env.REACT_APP_STAGING_OVERRIDE === 'true' &&
    process.env.REACT_APP_NODE_ENV !== 'production'

  const trackAction = useCallback(
    async ({
      variables,
      gaEvent,
      blockInteractionLog,
    }: {
      variables: LogActionMutationVariables
      /**
       * (Optional) dataLayer object to send to GA
       * Note: You will need to check the event data is collected in GA
       */
      // TODO: Apply correct typing to this
      gaEvent?: Object
      /** Use this if you only want to send data to GA */
      blockInteractionLog?: boolean
    }) => {
      if (!isOverride) {
        if (gaEvent && window.dataLayer && window.dataLayer.push) {
          window.dataLayer.push(gaEvent)
          // window.dataLayer.push({
          //   event: 'click-buy-more-licences',
          // })
        }

        if (!blockInteractionLog) {
          await logAction({ variables })
        }
      }

      return null
    },
    [isOverride],
  )

  return trackAction
}

export default useLogAction
