import React, { useEffect, useMemo, useState } from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'

import ChangeInput from './change-input'
import { ImgUploader } from './img-uploader'
import { Label } from './input'
import Tooltip from './tooltip'
import { currentUserDetails } from '../api/apollo/variables'
import {
  deleteQrLogo,
  getCurrentAccountQRDetails,
  getQrLogoImage,
  updateAccountDescription,
  updateQrSettings,
} from '../api/graphql/company-client'
import { getUserInfo } from '../api/graphql/user-client'
import { uploadAccountLogo, uploadQrLogo } from '../api/REST/account-client'
import { makeHexString, getDefaultOption } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/workspace-details.module.scss'

interface WorkspaceDetailsProps {
  className?: string
}

export default function WorkspaceDetails({ className }: WorkspaceDetailsProps) {
  const { workspaceID, workspaceName } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const { data: homepageData } = useQuery(getUserInfo)
  const { data: qrData, refetch: refetchQrData } = useQuery(
    getCurrentAccountQRDetails,
  )

  const [getQrCodeLogoImage] = useLazyQuery(getQrLogoImage)

  const [updateWorkspaceDescription] = useMutation(updateAccountDescription)
  const [deleteQrCodeLogo] = useMutation(deleteQrLogo)
  const [updateQrCodeSettings] = useMutation(updateQrSettings)

  const [qrCodeImgSources, setQrCodeImgSources] = useState<string[]>([])

  const homepage = useMemo(() => {
    if (!homepageData) return ''

    return homepageData.currentAccount.homepage
  }, [homepageData])

  const logoLink = `${qrData?.currentAccount.logoLink || ''}`.replace(
    '/static/logos/PNG/uplogo.png',
    '',
  )

  const { fgColour, bgColour } = useMemo(() => {
    if (qrData) {
      return {
        fgColour: getDefaultOption(
          qrData.currentAccount,
          'qrSettings',
          'fgColour',
          '000000',
        ).replace('#', ''),
        bgColour: getDefaultOption(
          qrData.currentAccount,
          'qrSettings',
          'bgColour',
          'FFFFFF',
        ).replace('#', ''),
      }
    }

    return {
      fgColour: '#000000',
      bgColour: '#FFFFFF',
      qrTransparentLogo: false,
    }
  }, [qrData])

  const qrCodeLogoList = useMemo(() => {
    return qrData ? qrData.currentAccount.qrCodeLogoList : []
  }, [qrData])

  useEffect(() => {
    const loadImages = async () => {
      const images: string[] = []

      const fetchPromises = qrCodeLogoList.map(async (qrCodeLogo) => {
        const { data } = await getQrCodeLogoImage({
          variables: { blobName: qrCodeLogo },
        })

        if (data) {
          images.push(data.currentAccount.qrCodeLogoSrc)
        }
      })

      await Promise.all(fetchPromises)
      setQrCodeImgSources(images)
    }

    if (qrCodeLogoList.length > 0) {
      loadImages()
    }
  }, [qrCodeLogoList])

  return (
    <div className={className}>
      <div className={styles.subsection}>
        <h3>Details</h3>
        <div className={styles.fieldRow}>
          <ChangeInput
            onClick={async (nextWorkspacename) => {
              const response = nextWorkspacename !== ''

              if (!response || workspaceID === '') return 'Not valid'

              const { data } = await updateWorkspaceDescription({
                variables: {
                  accountID: workspaceID,
                  updatedAccountName: nextWorkspacename,
                },
              })

              if (data) {
                return 'Updated'
              }
              return 'Failed'
            }}
            tooltip="This is the name of your workspace."
            label="Workspace name"
            type="text"
            name="workspace"
            initialValue={workspaceName || ''}
          />
          <ChangeInput
            type="text"
            name="homepage"
            label="Homepage"
            initialValue={homepage || ''}
            tooltip="This is your homepage (use full domain name, e.q. https://uplifter.ai)."
            onClick={async (nextHomepage) => {
              const response = nextHomepage !== ''

              if (!response || workspaceID === '') return 'Not valid'

              const { data } = await updateWorkspaceDescription({
                variables: {
                  accountID: workspaceID,
                  updatedAccountDomain: nextHomepage,
                },
              })

              if (data) {
                return 'Updated'
              }
              return 'Failed'
            }}
          />
        </div>
      </div>

      <span className={styles.spacer} />

      <div className={styles.fieldRow}>
        <div>
          <Label id="logo">
            <Tooltip
              id="company-logo-tooltip"
              useIcon
              tooltipMessage="This logo will be displayed on the top right of reports. We support png, gif or jpeg files up to 1MB."
            >
              Workspace logo
            </Tooltip>
          </Label>
          <ImgUploader
            imgSrc={logoLink}
            uploadFn={async (file: File) => {
              const res = await uploadAccountLogo({ file })

              if (res === true) {
                logAction({
                  variables: {
                    action: 'company-logo-changed',
                    extra: '',
                    websiteSection: 'settings',
                    pagePath: '/settings',
                    functionName: 'uploadAccountLogo',
                  },
                })

                refetchQrData()

                return true
              }
              return false
            }}
          />
        </div>
      </div>

      <span className={styles.spacer} />

      <div className={styles.subsection}>
        <h3>QR code</h3>

        <div className={styles.fieldRow} style={{ marginBottom: 16 }}>
          <div>
            <Label id="qrLogo" className={styles.fieldLabel}>
              <Tooltip
                id="qr-code-logo-tooltip"
                useIcon
                tooltipMessage="Simple versions of your logo work best, ideal size 300px x 300px &amp; less than 1MB."
              >
                Default logo used in the centre of QR codes.
              </Tooltip>
            </Label>
            {qrCodeLogoList.length > 0 ? (
              qrCodeLogoList.map((logoLinkQR, logoLinkIndex) => {
                return (
                  <ImgUploader
                    key={logoLinkQR}
                    imgSrc={qrCodeImgSources[logoLinkIndex]}
                    uploadFn={async (file: File) => {
                      // Delete existing: Remove when allowing multiple logos
                      await deleteQrCodeLogo({
                        variables: { blobName: logoLink },
                      })

                      const res = await uploadQrLogo({ file })

                      if (res === true) {
                        logAction({
                          variables: {
                            action: 'company-qr-logo-changed',
                            extra: '',
                            websiteSection: 'settings',
                            pagePath: '/settings',
                            functionName: 'uploadQrLogo',
                          },
                        })

                        await refetchQrData()

                        return true
                      }
                      return false
                    }}
                    deleteFn={
                      qrCodeLogoList.length > 1
                        ? async () => {
                            await deleteQrCodeLogo({
                              variables: { blobName: logoLinkQR },
                            })

                            logAction({
                              variables: {
                                action: 'company-qr-logo-deleted',
                                extra: '',
                                websiteSection: 'settings',
                                pagePath: '/settings',
                                functionName: 'deleteQrLogo',
                              },
                            })
                          }
                        : undefined
                    }
                  />
                )
              })
            ) : (
              <ImgUploader
                uploadFn={async (file) => {
                  const res = await uploadQrLogo({
                    file,
                  })

                  if (res === true) {
                    logAction({
                      variables: {
                        action: 'company-qr-logo-changed',
                        extra: '',
                        websiteSection: 'settings',
                        pagePath: '/settings',
                        functionName: 'uploadQrLogo',
                      },
                    })

                    await refetchQrData()

                    return true
                  }
                  return false
                }}
              />
            )}
          </div>
        </div>

        <div className={styles.fieldRow}>
          <ChangeInput
            label="Default foreground colour"
            tooltip="Use RGB hex value, e.g. #000000 for black."
            type="text"
            name="fgColour"
            initialValue={fgColour}
            prefix="#"
            onBeforeChange={(val) => {
              return makeHexString(val)
            }}
            onClick={(newValue) => {
              if (newValue !== '') {
                updateQrCodeSettings({
                  variables: {
                    qrSettings: [
                      {
                        name: 'fgColour',
                        enabled: true,
                        value: `#${newValue}`,
                      },
                    ],
                  },
                })
              }
            }}
          />
          <ChangeInput
            label="Default background colour"
            tooltip="Use RGB hex value, e.g. #FFFFFF for white."
            type="text"
            name="bgColour"
            initialValue={bgColour}
            prefix="#"
            onBeforeChange={(val) => {
              return makeHexString(val)
            }}
            onClick={(newValue) => {
              if (newValue !== '') {
                updateQrCodeSettings({
                  variables: {
                    qrSettings: [
                      {
                        name: 'bgColour',
                        enabled: true,
                        value: `#${newValue}`,
                      },
                    ],
                  },
                })
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}
