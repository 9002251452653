import { gql } from '../../__gql-types__/gql'

export const getUpdateRequestList = gql(`
  query GetUpdateRequestList {
		track {
			updateRequestList {
				pendingRequests {
					changeRequests {
						fieldID
						newOptions {
							approved
							message
							optionName
							optionRequestID
							optionValue
							rejected
						}
					}
					completed
					requestDate
					requestID
					requestNote
					requestedBy
					reviewNote
					reviewedBy
					updateRequestAccountName
					updateRequestAccountID
				}
			}
		}
	}
`)

export const CampaignCodeGeneratorParamDefs = gql(`
	fragment CampaignCodeGeneratorParamDefs on CampaignCodeGenerator {
		paramDefs {
			copyFromField {
				copyFromID
			}
			fieldID
			fieldName
			fieldAvailable
			dateFormat
			fieldType
			fixedValue
			forceLowerCase
			helpText
			isCreativeField
			metaParameter
			parameterDependsOn {
				parentFieldID
				parentOptionIDs
			}
			prefix
			required
			selectFields {
				hide
				optionFilter {
					parentFieldID
					parentOptionIDs
				}
				optionID
				optionName
				optionValue
			}
		}
  }
`)

export const updateGeneratorParameterOrder = gql(`
	mutation UpdateGeneratorParameterOrder ($newOrder: [String!]!) {
		track {
			updateGeneratorParameterOrder(newOrder: $newOrder) {
				accountID @client
				...CampaignCodeGeneratorParamDefs
			}
		}
	}
`)

export const addNewGeneratorParameter = gql(`
	mutation AddNewGeneratorParameter (
		$fieldName: String!
		$fieldAvailable: Boolean!
		$fieldType: String!
		$fixedValue: String
		$dateFormat: String
		$required: Boolean!
		$prefix: String!
		$metaParameter: Boolean
		$helpText: String!
		$forceLowerCase: Boolean
		$isCreativeField: Boolean
	) {
		track {
			addGeneratorParameter(
				fieldName: $fieldName
				fieldAvailable: $fieldAvailable
				fieldType: $fieldType
				fixedValue: $fixedValue
				dateFormat: $dateFormat
				required: $required
				prefix: $prefix
				metaParameter: $metaParameter
				helpText: $helpText
				forceLowerCase: $forceLowerCase
				isCreativeField: $isCreativeField
			) {
				copyFromField {
					copyFromID
				}
				fieldID
				fieldName
				fieldAvailable
				fixedValue
				dateFormat
				fieldType
				forceLowerCase
				helpText
				isCreativeField
				metaParameter
				parameterDependsOn {
					parentFieldID
					parentOptionIDs
				}
				prefix
				required
				selectFields {
					hide
					optionFilter {
						parentFieldID
						parentOptionIDs
					}
					optionID
					optionName
					optionValue
				}
			}
		}
	}
`)

export const updateGeneratorParameterByID = gql(`
	mutation UpdateGeneratorParameterByID(
		$updateFieldID: String!
		$copyFromField: [CopyFromFieldInput!]
		$emailDefault: DefaultEmailParameterInput
		$fieldName: String
		$fieldAvailable: Boolean
		$fieldType: String
		$fixedValue: String
		$dateFormat: String
		$required: Boolean
		$prefix: String
		$metaParameter: Boolean
		$helpText: String
		$forceLowerCase: Boolean
		$parameterDependsOn: OptionFilterInput
		$isCampaignField: Boolean
		$isCreativeField: Boolean
		$lengthLimit: Int
	) {
		track {
			updateGeneratorParameter(
				updateFieldID: $updateFieldID
				copyFromField: $copyFromField
				emailDefault: $emailDefault
				dateFormat: $dateFormat
				fieldAvailable: $fieldAvailable
				fieldName: $fieldName
				fieldType: $fieldType
				fixedValue: $fixedValue
				forceLowerCase: $forceLowerCase
				helpText: $helpText
				metaParameter: $metaParameter
				prefix: $prefix
				required: $required
				parameterDependsOn: $parameterDependsOn
				isCampaignField: $isCampaignField
				isCreativeField: $isCreativeField
				lengthLimit: $lengthLimit
			) {
				copyFromField {
					copyFromID
				}
				fieldID
				fieldName
				fieldAvailable
				dateFormat
				fieldType
				fixedValue
				forceLowerCase
				isCampaignField
				helpText
				isCreativeField
				lengthLimit
				metaParameter
				parameterDependsOn {
					parentFieldID
					parentOptionIDs
				}
				prefix
				required
				selectFields {
					hide
					optionFilter {
						parentFieldID
						parentOptionIDs
					}
					optionID
					optionName
					optionValue
				}
			}
		}
	}
`)

export const deleteGeneratorParameterByID = gql(`
	mutation DeleteGeneratorParameterByID (
		$removeFieldID: String!
	) {
		track {
			deleteGeneratorParameter (
				removeFieldID: $removeFieldID
			) {
				accountID @client
				...CampaignCodeGeneratorParamDefs
			}
		}
	}
`)

export const updateParamsMasterRules = gql(`
	mutation UpdateParametersMasterRules(
		$validationChecks: [GenericSettingInput!]
	) {
		track {
			updateGeneratorMasterSettings(
				validationChecks: $validationChecks
			) {
				accountID @client
				validationChecks {
					enabled
					name
					value
				}
			}
		}
	}
`)

export const updateMasterPrefixAndSeparator = gql(`
	mutation UpdateMasterPrefixAndSeparator (
		$masterPrefix: String
		$paramSeparator: String
	) {
		track {
			updateGeneratorMasterSettings(
				masterPrefix: $masterPrefix
				paramSeparator: $paramSeparator
			) {
				accountID @client
				masterPrefix
				paramSeparator
			}
		}
	}
`)

export const updateGeneratorParameterSelectOption = gql(`
	mutation UpdateParameterSelectUpdateOption(
		$fieldID: String!
		$optionID: String!
		$hide: Boolean
		$optionName: String
		$optionValue: String
		$optionFilter: [OptionFilterInput!]
	) {
		track {
			updateGeneratorParameterSelectUpdateOption(
				fieldID: $fieldID
				optionID: $optionID
				hide: $hide
				optionName: $optionName
				optionValue: $optionValue
				optionFilter: $optionFilter
			) {
				accountID @client
				...CampaignCodeGeneratorParamDefs
			}
		}
	}
`)

export const deleteGeneratorParameterSelectOption = gql(`
	mutation UpdateParameterSelectDeleteOption(
		$fieldID: String!
		$optionID: String!
	) {
		track {
			updateGeneratorParameterSelectDeleteOption(
				fieldID: $fieldID
				optionID: $optionID
			) {
				accountID @client
				...CampaignCodeGeneratorParamDefs
			}
		}
	}
`)

export const updateGeneratorParameterSelectResetParent = gql(`
	mutation UpdateParameterSelectResetParent (
		$fieldID: String!
	) {
		track {
			updateGeneratorParameterSelectDropParentChild (
				fieldID: $fieldID
			) {
				accountID @client
				...CampaignCodeGeneratorParamDefs
			}
		}
	}
`)

export const updateGeneratorParameterSelectAddOption = gql(`
	mutation UpdateParameterSelectAddOption(
		$fieldID: String!
		$hide: Boolean!
		$optionName: String!
		$optionValue: String!
	) {
		track {
			updateGeneratorParameterSelectAddOption(
				fieldID: $fieldID
				hide: $hide
				optionName: $optionName
				optionValue: $optionValue
			) {
				accountID @client
				...CampaignCodeGeneratorParamDefs
			}
		}
	}
`)

export const updateGeneratorParamAddMultipleSelectOptions = gql(`
	mutation UpdateParameterSelectAddMultipleOptions(
		$fieldID: String!
		$optionList: [SelectOptionInput!]!
	) {
		track {
			updateGeneratorParameterSelectAddMultipleOptions(
				fieldID: $fieldID
				optionList: $optionList
			) {
				accountID @client
				...CampaignCodeGeneratorParamDefs
			}
		}
	}
`)

export const getCampaignCodeGeneratorByID = gql(`
	query GetCampaignCodeGeneratorByAccountID($accountID: String!) {
		track {
			campaignCodeGeneratorAccount(accountID: $accountID) {
				accountID @client
				masterPrefix
				paramSeparator
				paramDefs {
					copyFromField {
						copyFromID
					}
					fieldID
					fieldName
					fieldAvailable
					fixedValue
					dateFormat
					fieldType
					forceLowerCase
					helpText
					isCreativeField
					metaParameter
					parameterDependsOn {
						parentFieldID
						parentOptionIDs
					}
					prefix
					required
					selectFields {
						hide
						optionFilter {
							parentFieldID
							parentOptionIDs
						}
						optionID
						optionName
						optionValue
					}
				}
				validationChecks {
					enabled
					name
					value
				}
			}
		}
	}
`)

export const rejectDropdownOptionRequest = gql(`
	mutation RejectUpdateRequest(
		$fieldID: String!
		$fieldName: String!
		$message: String
		$optionRequestID: String
		$optionRequestIndex: Int
		$requestID: String!
	)	{
		track {
			rejectUpdateRequest (
				fieldID: $fieldID,
				fieldName: $fieldName,
				message: $message,
				requestID: $requestID,
				optionRequestID: $optionRequestID
				optionRequestIndex: $optionRequestIndex
			) {
				changeRequests {
          fieldID
          newOptions {
            approved
            message
            optionName
            optionRequestID
            optionValue
            rejected
          }
        }
        completed
        requestDate
        requestID
        requestNote
        requestedBy
        reviewNote
        reviewedBy
        updateRequestAccountName
        updateRequestAccountID
			}
		}
	}
`)

export const approveDropdownOptionRequest = gql(`
	mutation ApproveUpdateRequest(
		$accountIDList: [String!]
		$fieldID: String!
		$fieldName: String!
		$message: String
		$newName: String!
		$newValue: String!
		$optionRequestID: String
		$optionRequestIndex: Int
		$requestID: String!
	) {
		track {
			approveUpdateRequest (
				accountIDList: $accountIDList,
				fieldID: $fieldID,
				fieldName: $fieldName,
				message: $message,
				newName: $newName,
				newValue: $newValue,
				requestID: $requestID,
				optionRequestID: $optionRequestID,
				optionRequestIndex: $optionRequestIndex
			) {
				changeRequests {
          fieldID
          newOptions {
            approved
            message
            optionName
            optionRequestID
            optionValue
            rejected
          }
        }
        completed
        requestDate
        requestID
        requestNote
        requestedBy
        reviewNote
        reviewedBy
        updateRequestAccountName
        updateRequestAccountID
			}
		}
	}
`)

export const lockUnlockGenerator = gql(`
	mutation LockUnlockGenerator(
		$isLocked: Boolean
	) {
		track {
			updateGeneratorMasterSettings (isLocked: $isLocked) {
				accountID @client
				isLocked
			}
		}
	}
`)

export const updateExistingParamsAddedToStart = gql(`
	mutation UpdateExistingParamsAddedToStart(
		$existingParametersAddedToStart: Boolean
	) {
		track {
			updateGeneratorMasterSettings (
				existingParametersAddedToStart: $existingParametersAddedToStart
			) {
				accountID @client
				existingParametersAddedToStart
			}
		}
	}
`)

export const updateDefaultPardotBusinessUnit = gql(`
	mutation UpdateDefaultPardotBusinessUnit (
		$defaultPardotBusinessUnit: String!
	) {
		track {
			updateGeneratorMasterSettings(
				defaultPardotBusinessUnit: $defaultPardotBusinessUnit
			) {
				accountID @client
				defaultPardotBusinessUnit
			}
		}
	}
`)

export const getUplifterIDParamState = gql(`
	query GetUplifterIDParamState {
		track {
			currentSequentialCodeID {
				accountID @client
				isEnabled
			}
		}
	}
`)

export const updateUplifterIDEnabledState = gql(`
	mutation UpdateUplifterIDEnabledState (
		$isEnabled: Boolean
	) {
		track {
			updateCurrentSequentialCodeIDObject(
				isEnabled: $isEnabled
			) {
				accountID @client
				isEnabled
			}
		}
	}
`)

export const getAAFTPDetails = gql(`
	query GetAAFTPDetails {
		accountSettings {
			adobeFTPDetails {
				adobeFTPHost
				adobeFTPLogin
				relationID
				reportID
			}
		}
	}
`)

export const updateAAFTPDetails = gql(`
	mutation UpdateAAFTPDetails (
		$reportID: String
		$relationID: String
		$adobeFTPPassword: String
		$adobeFTPLogin: String
		$adobeFTPHost: String
	) {
		userAccountSettings {
			updateAdobeFTPSettings(
				reportID: $reportID
				relationID: $relationID
				adobeFTPPassword: $adobeFTPPassword
				adobeFTPLogin: $adobeFTPLogin
				adobeFTPHost: $adobeFTPHost
			) {
				reportID
				relationID
				adobeFTPLogin
				adobeFTPHost
			}
		}
	}
`)

/* ******************************************************************* */
/* App deeplink endpoints */
/* ******************************************************************* */

export const listDeepLinkServices = gql(`
	query ListDeepLinkServices {
		track {
			deepLinkQueries {
				listDeepLinkServices {
					companyID
					deepLinkServiceID
					deepLinkServiceName
					deepLinkServiceSubdomain
				}
			}
		}
	}
`)

export const listAppGroups = gql(`
	query ListAppGroups {
		track {
			deepLinkQueries {
				listAppGroups {
					accountID
					appGroupDescription
					appGroupID
					appGroupName
					appGroupPath
					appList {
						... on DeepLinkAndroidApp {
							androidAppName
							androidPackage
							redirectAndroidUri
							redirectAndroidPreferred
							fallBackURL
							companyID
							appID
							appGroupID
							androidPlayStoreLink
							assetLinksAndroid
							androidUrl
							deepLinkServiceID
						}
						... on DeepLinkIosApp {
							iosAppName
							appGroupID
							appID
							companyID
							fallBackURL
							iosAppStoreID
							iosBundleID
							iosTeamID
							redirectIosDownload
							redirectIosPreferred
							redirectIosUri
							deepLinkServiceID
						}
					}
					companyID
					dateAdded
					deepLinkServiceID
					isUAT
					redirectWeb
				}
			}
		}
	}
`)

export const createAppGroup = gql(`
	mutation CreateAppGroup (
		$deepLinkServiceID: String!
		$appGroupName: String! 
		$redirectWeb: String!
		$appGroupDescription: String
		$appGroupPath: String
		$isUAT: Boolean
	) {
		track {
			trackDeeplinkingMutations {
				createAppGroup(
					deepLinkServiceID: $deepLinkServiceID
					appGroupName: $appGroupName
					redirectWeb: $redirectWeb 
					appGroupDescription: $appGroupDescription
					appGroupPath: $appGroupPath
					isUAT: $isUAT
				) {
					accountID
					appGroupDescription
					appGroupID
					appGroupName
					appGroupPath
					appList {
						... on DeepLinkAndroidApp {
							androidAppName
							androidPackage
							redirectAndroidUri
							redirectAndroidPreferred
							fallBackURL
							companyID
							appID
							appGroupID
							androidPlayStoreLink
							assetLinksAndroid
							androidUrl
							deepLinkServiceID
						}
						... on DeepLinkIosApp {
							iosAppName
							appGroupID
							appID
							companyID
							fallBackURL
							iosAppStoreID
							iosBundleID
							iosTeamID
							redirectIosDownload
							redirectIosPreferred
							redirectIosUri
							deepLinkServiceID
						}
					}
					redirectWeb
					isUAT
					dateAdded
					companyID
				}
			}
		}
	}
`)

export const updateAppGroup = gql(`
	mutation UpdateAppGroup (
		$appGroupID: String!
		$appGroupDescription: String
		$appGroupName: String
		$appGroupPath: String
		$redirectWeb: String
		$isUAT: Boolean
	) {
		track {
			trackDeeplinkingMutations {
				updateAppGroup(
					appGroupID: $appGroupID
					appGroupDescription: $appGroupDescription
					appGroupName: $appGroupName
					appGroupPath: $appGroupPath
					redirectWeb: $redirectWeb
					isUAT: $isUAT
				) {
					accountID
					appGroupDescription
					appGroupID
					appGroupName
					appGroupPath
					companyID
					dateAdded
					deepLinkServiceID
					isUAT
					redirectWeb
				}
			}
		}
	}
`)

export const deleteAppGroup = gql(`
	mutation DeleteAppGroup (
		$appGroupID: String!
	) {
		track {
			trackDeeplinkingMutations {
				deleteAppGroup(
					appGroupID: $appGroupID
				)
			}
		}
	}
`)

export const registerApp = gql(`
	mutation RegisterApp (
		$appGroupID: String!
		$deepLinkServiceID: String!
		$device: String!
		$redirectMethod: String!
		$fallBackURL: String
		$iosAppStoreID: String
		$iosBundleID: String
		$iosTeamID: String
		$androidPackage: String
		$androidPlayStoreLink: String
		$androidURI: String
		$assetLinksAndroid: String
		$androidAppName: String
		$iosAppName: String
	) {
  track {
    trackDeeplinkingMutations {
      registerApp(
        appGroupID: $appGroupID
        deepLinkServiceID: $deepLinkServiceID
        device: $device
        redirectMethod: $redirectMethod
        fallBackURL: $fallBackURL
        iosAppStoreID: $iosAppStoreID
				iosBundleID: $iosBundleID
				iosTeamID: $iosTeamID
        androidPackage: $androidPackage
        androidPlayStoreLink: $androidPlayStoreLink
        androidURI: $androidURI
        assetLinksAndroid: $assetLinksAndroid
        androidAppName: $androidAppName
        iosAppName: $iosAppName
      ) {
        ... on DeepLinkAndroidApp {
					androidAppName
					androidPackage
					redirectAndroidUri
					redirectAndroidPreferred
					fallBackURL
					companyID
					appID
					appGroupID
					androidPlayStoreLink
					assetLinksAndroid
					androidUrl
					deepLinkServiceID
				}
				... on DeepLinkIosApp {
					iosAppName
					appGroupID
					appID
					companyID
					fallBackURL
					iosAppStoreID
					iosBundleID
					iosTeamID
					redirectIosDownload
					redirectIosPreferred
					redirectIosUri
					deepLinkServiceID
				}
      }
    }
  }
}
`)

export const updateRegisteredApp = gql(`
	mutation UpdateRegisteredApp (
		$appID: String!
		$deepLinkServiceID: String!
		$device: String!
		$androidPackage: String
		$androidPlayStoreLink: String
		$androidURI: String
		$appGroupID: String
		$androidAppName: String
		$iosAppName: String
		$assetLinksAndroid: String
		$iosAppStoreID: String
		$iosBundleID: String
		$iosTeamID: String
		$iosURI: String
	) {
		track {
			trackDeeplinkingMutations {
				updateApp (
					appID: $appID
					deepLinkServiceID: $deepLinkServiceID
					device: $device
					androidPackage: $androidPackage
					androidPlayStoreLink: $androidPlayStoreLink
					androidURI: $androidURI
					appGroupID: $appGroupID
					androidAppName: $androidAppName
					iosAppName: $iosAppName
					assetLinksAndroid: $assetLinksAndroid
					iosAppStoreID: $iosAppStoreID
					iosBundleID: $iosBundleID
					iosTeamID: $iosTeamID
					iosURI: $iosURI
				) {
					... on DeepLinkAndroidApp {
						androidAppName
						androidPackage
						redirectAndroidUri
						redirectAndroidPreferred
						fallBackURL
						companyID
						appID
						appGroupID
						androidPlayStoreLink
						assetLinksAndroid
						androidUrl
						deepLinkServiceID
					}
					... on DeepLinkIosApp {
						iosAppName
						appGroupID
						appID
						companyID
						fallBackURL
						iosAppStoreID
						iosBundleID
						iosTeamID
						redirectIosDownload
						redirectIosPreferred
						redirectIosUri
						deepLinkServiceID
					}
				}
			}
		}
	}
`)

export const listAppContextOptions = gql(`
	query ListAppContextOptions (
		$appGroupID: String!
	) {
		track {
			deepLinkQueries {
				listAppContextOptions (
					appGroupID: $appGroupID
				) {
					accountID
					addedBy
					companyID
					dateAdded
					dateLastUpdated
					lastUpdatedBy
					optionDisplayName
					optionID
					optionKey
					optionValue
					order
				}
			}
		}
	}
`)

export const addAppContextOption = gql(`
	mutation BulkAddAppContext (
		$appGroupID: String!
		$insertContextList: [AppContextInsertInput!]!
	) {
		track {
			trackDeeplinkingMutations {
				bulkAddAppContext(
					appGroupID: $appGroupID
					insertContextList: $insertContextList
				) {
					dateAdded
					addedBy
					lastUpdatedBy
					dateLastUpdated
					optionDisplayName
					optionID
					optionKey
					optionValue
					order
				}
			}
		}
	}
`)

export const updateAppContextList = gql(`
	mutation UpdateAppContextList (
		$appGroupID: String!
		$updateContextList: [AppContextUpdateInput!]!
	) {
		track {
			trackDeeplinkingMutations {
				bulkUpdateAppContext (
					appGroupID: $appGroupID
					updateContextList: $updateContextList
				) {
					addedBy
					dateAdded
					dateLastUpdated
					optionDisplayName
					lastUpdatedBy
					optionKey
					optionID
					optionValue
					order
				}
			}
		}
	}
`)

export const deleteAppContextListOptions = gql(`
	mutation DeleteAppContextListOptions (
		$appGroupID: String!
		$deleteContextList: [String!]!
	) {
		track {
			trackDeeplinkingMutations {
				bulkDeleteAppContext(
					appGroupID: $appGroupID
					deleteContextList: $deleteContextList
				) {
					dateAdded
					addedBy
					dateLastUpdated
					lastUpdatedBy
					optionDisplayName
					optionID
					optionKey
					order
					optionValue
				}
			}
		}
	}
`)
