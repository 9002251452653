import { gql } from '../../__gql-types__/gql'

export const getCampaignLinkDashboardMeta = gql(`
  query GetCampaignLinkDashboardMeta {
    campaignLinkDashboardMeta {
			accountID @client
			availableDimensions {
				dateFormat
				dimensionParameterID
				dimensionName
				dimensionOptions
				helpText
				parameterType
			}
			availableClickMetrics {
				calculation
				description
				displayName
				increasePositive
				isStandard
				isUplifterClickMetric
				metricID
				setupComplete
				units
			}
			availableMetrics {
				isStandard
				increasePositive
				isUplifterClickMetric
				calculation
				description
				displayName
				metricID
				setupComplete
				units
			}
			dashboardTitle
			onloadDefaultOptions {
				enabled
				name
				value
			}
		}
  }
`)

export const getDataSourceMetricList = gql(`
	query GetDataSourceMetricList {
		dataSourceMetricList {
			dataSourceMetricList {
				description
				calculation
				increasePositive
				displayName
				metricID
				requiredMetrics
				units
			}
		}
	}
`)

export const setDashboardSettings = gql(`
	mutation SetDashboardSettings (
		$additionalMetrics: [AdditionalMetric!]
		$onloadDefaultOptions: [GenericSettingInput!]
	) {
		setLinkPerformanceDashboardAccountSettings(
			additionalMetrics: $additionalMetrics
			onloadDefaultOptions: $onloadDefaultOptions
		) {
			accountID @client
			dashboardTitle
			availableMetrics {
				isStandard
				calculation
				description
				displayName
				increasePositive
				metricID
				setupComplete
				units
			}
			onloadDefaultOptions {
				enabled
				name
				value
			}
		}
	}
`)

export const getAccountUsageData = gql(`
	query GetAccountUsageData (
		$activeUsers: Boolean!
		$totalUsers: Boolean!
		$badCodesBlocked: Boolean!
		$codesCreated: Boolean!
		$logins: Boolean!
		$invalidCodesBreakdown: Boolean!
		$startDate: String!
		$endDate: String
		$filterList: DimensionFilterList
		$granularity: String
	) {
		report {
			activeUsers (
				startDate: $startDate,
				endDate: $endDate,
				filterList: $filterList,
				granularity: $granularity
			) @include(if: $activeUsers) {
				graphData {
					dimensionName
					dimensionValues
				}
				dateRange
				dateTotalRows
				graphData {
					dimensionName
					dimensionValues
				}
				queryStartDate
				queryEndDate
				total
			}
			badCodesBlocked (
				startDate: $startDate,
				endDate: $endDate,
				filterList: $filterList,
				granularity: $granularity
			) @include(if: $badCodesBlocked) {
				graphData {
					dimensionName
					dimensionValues
				}
				dateRange
				dateTotalRows
				graphData {
					dimensionName
					dimensionValues
				}
				queryStartDate
				queryEndDate
				total
			}
			codesCreated (
				startDate: $startDate,
				endDate: $endDate,
				filterList: $filterList,
				granularity: $granularity
			) @include(if: $codesCreated) {
				graphData {
					dimensionName
					dimensionValues
				}
				dateRange
				dateTotalRows
				graphData {
					dimensionName
					dimensionValues
				}
				queryStartDate
				queryEndDate
				total
			}
			invalidCodesBreakdown (
				startDate: $startDate,
				endDate: $endDate,
				filterList: $filterList,
				granularity: $granularity
			) @include(if: $invalidCodesBreakdown) {
				graphData {
					dimensionName
					dimensionValues
				}
				dateRange
				dateTotalRows
				graphData {
					dimensionName
					dimensionValues
				}
				queryStartDate
				queryEndDate
				total
			}
			logins (
				startDate: $startDate,
				endDate: $endDate,
				filterList: $filterList,
				granularity: $granularity
			) @include(if: $logins) {
				graphData {
					dimensionName
					dimensionValues
				}
				dateRange
				dateTotalRows
				graphData {
					dimensionName
					dimensionValues
				}
				queryStartDate
				queryEndDate
				total
			}
			totalUsers (
				startDate: $startDate,
				endDate: $endDate,
				filterList: $filterList,
				granularity: $granularity
			) @include(if: $totalUsers) {
				graphData {
					dimensionName
					dimensionValues
				}
				dateRange
				dateTotalRows
				graphData {
					dimensionName
					dimensionValues
				}
				queryStartDate
				queryEndDate
				total
			}
		}
	}
`)

export const getCampaignLinkDashboardMetricClickData = gql(`
	query GetCampaignLinkDashboardMetricDataClickData (
		$endDate: String
		$campaignCodeID: String
		$filterList: DimensionFilterList
		$granularity: String!
		$matchType: String!
		$selectedMetric: String!
		$startDate: String!
	) {
		campaignLinkDashboardMetricDataClickData(
			endDate: $endDate
			campaignCodeID: $campaignCodeID
			filterList: $filterList
			granularity: $granularity
			matchType: $matchType
			selectedMetric: $selectedMetric
			startDate: $startDate
		) {
			increasePositive
			lastPeriodStartDate
			lastYearStartDate
			metricLastPeriod {
				metricDisplay
				metricLastCompleteValue
				metricPrevValue
				metricTitle
			}
			metricLastPeriodComparison {
				metricDisplay
				metricLastCompleteValue
				metricPrevValue
				metricTitle
				metricValue
			}
			metricPeriodOnPeriodComparison {
				metricDisplay
				metricLastCompleteValue
				metricPrevValue
				metricTitle
				metricValue
			}
			periodComparisonVsPeriodAverage {
				metricDisplay
				metricLastCompleteValue
				metricPrevValue
				metricTitle
				metricValue
			}
			prevPeriodStartDate
			queryEndDate
			queryStartDate
			totalMetric {
				metricDisplay
				metricLastCompleteValue
				metricPrevValue
				metricTitle
				metricValue
			}
		}
	}
`)

export const getCampaignLinkDashboardMetricData = gql(`
	query GetCampaignLinkDashboardMetricData (
		$endDate: String
		$campaignCodeID: String
		$filterList: DimensionFilterList
		$granularity: String!
		$matchType: String!
		$selectedMetric: String!
		$startDate: String!
	) {
		campaignLinkDashboardMetricData(
			endDate: $endDate
			campaignCodeID: $campaignCodeID
			filterList: $filterList
			granularity: $granularity
			matchType: $matchType
			selectedMetric: $selectedMetric
			startDate: $startDate
		) {
			increasePositive
			lastPeriodStartDate
			lastYearStartDate
			metricLastPeriod {
				metricDisplay
				metricLastCompleteValue
				metricPrevValue
				metricTitle
			}
			metricLastPeriodComparison {
				metricDisplay
				metricLastCompleteValue
				metricPrevValue
				metricTitle
				metricValue
			}
			metricPeriodOnPeriodComparison {
				metricDisplay
				metricLastCompleteValue
				metricPrevValue
				metricTitle
				metricValue
			}
			periodComparisonVsPeriodAverage {
				metricDisplay
				metricLastCompleteValue
				metricPrevValue
				metricTitle
				metricValue
			}
			prevPeriodStartDate
			queryEndDate
			queryStartDate
			totalMetric {
				metricDisplay
				metricLastCompleteValue
				metricPrevValue
				metricTitle
				metricValue
			}
		}
	}
`)

export const getCampaignLinkDashboardGraphClickData = gql(`
	query GetCampaignLinkDashboardGraphClickData (
		$endDate: String
		$campaignCodeID: String
		$filterList: DimensionFilterList
		$granularity: String!
		$matchType: String!
		$selectedMetric: String!
		$stackDimension: String
		$startDate: String!
	) {
		campaignLinkDashboardGraphClickData (
			endDate: $endDate
			campaignCodeID: $campaignCodeID
			filterList: $filterList
			granularity: $granularity
			matchType: $matchType
			selectedMetric: $selectedMetric
			stackDimension: $stackDimension
			startDate: $startDate
		) {
			dateRange
			dateTotalRows
			graphType
			graphData {
				dimensionName,
				dimensionValues
			}
		}
	}
`)

export const getCampaignLinkDashboardGraphData = gql(`
	query GetCampaignLinkDashboardGraphData (
		$campaignCodeID: String
		$startDate: String!
		$endDate: String
		$filterList: DimensionFilterList
		$granularity: String!
		$matchType: String!
		$selectedMetric: String!
		$stackDimension: String
	) {
		campaignLinkDashboardGraph (
			endDate: $endDate
			campaignCodeID: $campaignCodeID
			filterList: $filterList
			granularity: $granularity
			matchType: $matchType
			selectedMetric: $selectedMetric
			stackDimension: $stackDimension
			startDate: $startDate
		) {
			dateRange
			dateTotalRows
			graphType
			graphData {
				dimensionName,
				dimensionValues
			}
		}
	}
`)

export const getCampaignLinkDashboardTable = gql(`
	query GetCampaignLinkDashboardTable (
		$endDate: String
		$campaignCodeID: String
		$filterList: DimensionFilterList
		$matchType: String!
		$selectedMetric: String!
		$startDate: String!
	) {
		campaignLinkDashboardTable(
			endDate: $endDate
			campaignCodeID: $campaignCodeID
			filterList: $filterList
			startDate: $startDate
			selectedMetric: $selectedMetric
			matchType: $matchType
		) {
			campaignLinks
			tableData {
				helpText
				metricID
				metricName
				metricValue
				units
			}
		}
	}
`)

export const listSavedLinkPerformanceReportsGQL = gql(`
	query ListSavedLinkPerformanceReportsGQL {
		report {
			listSavedLinkPerformanceReports {
				hiddenReportList
				savedReports {
					applyFilters {
						dimensionParameterID
						dimensionName
						dimensionOptions
					}
					availableTo
					createdBy
					createdByID
					dateCreated
					endDate
					lastEditedBy
					lastEditedByID
					lastEditedDate
					matchType
					reportName
					reportTitle
					savedReportID
					selectedMetric
					stackDimension
					startDate
					granularity
					updatedTableColumnIndexOrderList
					renamedColumnNameList
					hideColumnIndexList
				}
				userPreferredOrder
			}
		}
	}
`)

export const saveLinkPerformanceReport = gql(`
	mutation SaveLinkPerformanceReport(
		$reportName: String!
		$reportTitle: String!
		$availableTo: String!
		$matchType: String!
		$selectedMetric: String!
		$startDate: String!
		$endDate: String
		$stackDimension: String!
		$filterList: [DimensionFilter!]
		$granularity: String
	) {
		report {
			saveLinkPerformanceReport(
				reportName: $reportName
				reportTitle: $reportTitle
				availableTo: $availableTo
				matchType: $matchType
				selectedMetric: $selectedMetric
				startDate: $startDate
				endDate: $endDate
				stackDimension: $stackDimension
				filterList: $filterList
				granularity: $granularity
			) {
				applyFilters {
					dimensionParameterID
					dimensionName
					dimensionOptions
				}
				availableTo
				createdBy
				createdByID
				dateCreated
				endDate
				lastEditedBy
				lastEditedByID
				lastEditedDate
				matchType
				reportName
				reportTitle
				savedReportID
				selectedMetric
				stackDimension
				startDate
				granularity
				renamedColumnNameList
				hideColumnIndexList
				updatedTableColumnIndexOrderList
			}
		}
	}
`)

export const updateSavedLinkPerformanceReport = gql(`
	mutation UpdateSavedLinkPerformanceReport(
		$reportName: String
		$reportTitle: String
		$availableTo: String
		$savedReportID: String!
		$selectedMetric: String
		$stackDimension: String
		$startDate: String
		$endDate: String
		$matchType: String
		$filterList: [DimensionFilter!]
		$granularity: String
		$hideColumnIndexList: [Int!]
		$renamedColumnNameList: [String!]
		$updatedTableColumnIndexOrderList: [Int!]
	) {
		report {
			updateSavedLinkPerformanceReport(
				reportName: $reportName
				reportTitle: $reportTitle
				availableTo: $availableTo
				savedReportID: $savedReportID
				selectedMetric: $selectedMetric
				stackDimension: $stackDimension
				startDate: $startDate
				endDate: $endDate
				matchType: $matchType
				filterList: $filterList
				granularity: $granularity
				hideColumnIndexList: $hideColumnIndexList
				renamedColumnNameList: $renamedColumnNameList
				updatedTableColumnIndexOrderList: $updatedTableColumnIndexOrderList
			) {
				applyFilters {
					dimensionParameterID
					dimensionName
					dimensionOptions
				}
				availableTo
				createdBy
				createdByID
				dateCreated
				endDate
				lastEditedBy
				lastEditedByID
				lastEditedDate
				matchType
				reportName
				reportTitle
				savedReportID
				selectedMetric
				stackDimension
				startDate
				granularity
				updatedTableColumnIndexOrderList
				renamedColumnNameList
				hideColumnIndexList
			}
		}
	}
`)

export const removeSavedLinkPerformanceReport = gql(`
	mutation RemoveSavedLinkPerformanceReport(
		$savedReportID: String!
	) {
		report {
				removeSavedLinkPerformanceReport(
				savedReportID: $savedReportID
			) {
				hiddenReportList
				savedReports {
					applyFilters {
            dimensionParameterID
            dimensionName
            dimensionOptions
          }
          availableTo
          createdBy
          createdByID
          dateCreated
          endDate
          lastEditedBy
          lastEditedByID
          lastEditedDate
          matchType
          reportName
          reportTitle
          savedReportID
          selectedMetric
          stackDimension
          startDate
          granularity
				}
				userPreferredOrder
			}
		}
	}
`)

export const updateLinkPerformanceUserPrefs = gql(`
	mutation UpdateLinkPerformanceUserPrefs(
		$newOrder: [String!] 
	) {
		report {
			updateLinkPerformanceUserPrefs(
				newOrder: $newOrder
			) {
				hiddenReportList
				savedReports {
					applyFilters {
            dimensionParameterID
            dimensionName
            dimensionOptions
          }
          availableTo
          createdBy
          createdByID
          dateCreated
          endDate
          lastEditedBy
          lastEditedByID
          lastEditedDate
          matchType
          reportName
          reportTitle
          savedReportID
          selectedMetric
          stackDimension
          startDate
          granularity
				}
				userPreferredOrder
			}
		}
	}
`)

export const getLostLinksGraphData = gql(`
	query GetLostLinksGraphData (
		$startDate: String
		$endDate: String
		$selectedMetric: String
		$granularity: String
		$filterList: DimensionFilterList
		$searchFilter: SearchFilter
	) {
		report {
			linkAudit {
				linkAuditGraph(
					startDate: $startDate
					endDate: $endDate
					selectedMetric: $selectedMetric
					granularity: $granularity
					filterList: $filterList
					searchFilter: $searchFilter
				) {
					dateRange
					dateTotalRows
					graphData {
						dimensionName
						dimensionValues
					}
					queryEndDate
					queryStartDate
					total
				}
			}
		}
	}
`)

export const getLostLinksTableData = gql(`
	query GetLostLinksTableData (
		$endDate: String
		$limit: Int
		$offset: Int
		$filterList: DimensionFilterList
		$searchFilter: SearchFilter
		$selectedMetric: String
		$sortDirection: String
		$startDate: String
		$tempTable: String
		$groupByLandingPage: Boolean
	) {
		report {
			linkAudit {
				accountID @client
				linkAuditTable(
					endDate: $endDate
					limit: $limit
					offset: $offset
					filterList: $filterList
					searchFilter: $searchFilter
					selectedMetric: $selectedMetric
					sortDirection: $sortDirection
					startDate: $startDate
					tempTable: $tempTable
					groupByLandingPage: $groupByLandingPage
				) {
					firstObserved
					landingPageList
					metricFormat
					metricHeadings
					metricValues
					pageData {
						destination
						limit
						offset
						total
						totalPages
					}
					parameterHeadings
					parameterValues
				}
			}
		}
	}
`)

export const getLostLinksFilterOptions = gql(`
	query GetLostLinksFilterOptions (
		$tempTable: String
	) {
		report {
			linkAudit {
				accountID @client
				linkAuditFilters (
					tempTable: $tempTable
				) {
					parameterFilters {
						parameterID
						parameterName
						parameterOptions
					}
				}
			}
		}
	}
`)

export const listSavedLostLinksReports = gql(`
	query ListSavedLostLinksReports {
		report {
			listSavedLinkAuditReports {
				hiddenReportList
				savedReports {
					applyFilters {
						dimensionName
						dimensionOptions
						dimensionParameterID
					}
					availableTo
					createdBy
					createdByID
					dateCreated
					endDate
					granularity
					groupByLandingPage
					hideColumnIndexList
					lastEditedBy
					lastEditedByID
					lastEditedDate
					matchType
					renamedColumnNameList
					reportName
					reportTitle
					savedReportID
					selectedMetric
					stackDimension
					startDate
					updatedTableColumnIndexOrderList
				}
				userPreferredOrder
			}
		}
	}
`)

export const saveNewLostLinkReport = gql(`
	mutation SaveNewLostLinkReport(
		$reportName: String!
		$reportTitle: String!
		$selectedMetric: String!
		$startDate: String!
		$availableTo: String!
		$endDate: String
		$granularity: String!
		$filterList: [DimensionFilter!]
	) {
		report {
			saveLinkAuditReport(
				reportName: $reportName
				reportTitle: $reportTitle
				selectedMetric: $selectedMetric
				startDate: $startDate
				availableTo: $availableTo
				endDate: $endDate
				granularity: $granularity
				filterList: $filterList
			) {
				applyFilters {
						dimensionName
						dimensionOptions
				}
				availableTo
				dateCreated
				createdByID
				endDate
				lastEditedByID
				lastEditedDate
				matchType
				reportTitle
				selectedMetric
				savedReportID
				startDate
				renamedColumnNameList
				hideColumnIndexList
				groupByLandingPage
				updatedTableColumnIndexOrderList
			}
		}
	}
`)

export const updateSavedLostLinkReport = gql(`
	mutation UpdateSavedLostLinkReport(
		$savedReportID: String!
		$reportName: String
		$reportTitle: String
		$availableTo: String
		$startDate: String
		$endDate: String
		$granularity: String
		$selectedMetric: String
		$filterList: [DimensionFilter!]
		$groupByLandingPage: Boolean
		$hideColumnIndexList: [Int!]
		$renamedColumnNameList: [String!]
		$updatedTableColumnIndexOrderList: [Int!]
	) {
		report {
			updateSavedLinkAuditReport(
				savedReportID: $savedReportID
				reportName: $reportName
				reportTitle: $reportTitle
				availableTo: $availableTo
				startDate: $startDate
				endDate: $endDate
				granularity: $granularity
				selectedMetric: $selectedMetric
				filterList: $filterList
				groupByLandingPage: $groupByLandingPage
				hideColumnIndexList: $hideColumnIndexList
				renamedColumnNameList: $renamedColumnNameList
				updatedTableColumnIndexOrderList: $updatedTableColumnIndexOrderList
			) {
				applyFilters {
					dimensionName
					dimensionOptions
					dimensionParameterID
				}
				availableTo
				createdBy
				createdByID
				dateCreated
				endDate
				granularity
				lastEditedBy
				lastEditedByID
				lastEditedDate
				matchType
				reportName
				reportTitle
				savedReportID
				selectedMetric
				stackDimension
				startDate
				renamedColumnNameList
				hideColumnIndexList
				groupByLandingPage
				updatedTableColumnIndexOrderList
			}
		}
	}
`)

export const removeSavedLostLinkReport = gql(`
	mutation RemoveSavedLostLinkReport(
		$savedReportID: String!
	) {
		report {
			removeSavedLinkAuditReport(
				savedReportID: $savedReportID
			) {
				hiddenReportList
				savedReports {
					applyFilters {
						dimensionName
						dimensionOptions
						dimensionParameterID
					}
					availableTo
					createdBy
					createdByID
					dateCreated
					endDate
					granularity
					lastEditedBy
					lastEditedByID
					lastEditedDate
					matchType
					reportName
					reportTitle
					savedReportID
					selectedMetric
					stackDimension
					startDate
				}
				userPreferredOrder
			}
		}
	}
`)

export const updateSavedLostLinkReportPrefs = gql(`
	mutation UpdateSavedLostLinkReportPrefs(
		$hideReports: [String!]
		$newOrder: [String!]
	) {
		report {
			updateLinkAuditUserPrefs(
				hideReports: $hideReports
				newOrder: $newOrder
			) {
				hiddenReportList
				savedReports {
					applyFilters {
						dimensionName
						dimensionOptions
						dimensionParameterID
					}
					availableTo
					createdBy
					createdByID
					dateCreated
					endDate
					granularity
					lastEditedBy
					lastEditedByID
					lastEditedDate
					matchType
					reportName
					reportTitle
					savedReportID
					selectedMetric
					stackDimension
					startDate
				}
				userPreferredOrder
			}
		}
	}
`)

export const listRemoteDashboards = gql(`
	query ListRemoteDashboards {
		report {
			listRemoteDashboards {
				remoteDashboards {
					dashboardDescription
					dashboardID
					dashboardName
					dashboardSource
					dashboardThumbnail
					dateAdded
					embedLink
					lastAccessed
					userAdded
				}
			}
		}
	}
`)

export const addRemoteDashboard = gql(`
	mutation AddRemoteDashboard (
		$dashboardName: String!
		$embedLink: String!
	) {
		report {
			addCustomEmbeddedDashboard(
				dashboardName: $dashboardName, 
				embedLink: $embedLink
			) {
				dashboardDescription
				dashboardID
				dashboardName
				dashboardSource
				dashboardThumbnail
				dateAdded
				embedLink
				lastAccessed
				userAdded
			}
		}
	}
`)

export const deleteRemoteDashboard = gql(`
	mutation DeleteRemoteDashboard (
		$dashboardID: String!
	) {
		report {
			deleteCustomEmbeddedDashboard(
				dashboardID: $dashboardID
			) {
				dashboardDescription
				dashboardID
				dashboardName
				dashboardSource
				dashboardThumbnail
				dateAdded
				embedLink
				lastAccessed
				userAdded
			}
		}
	}
`)

export const updateRemoteDashboard = gql(`
	mutation UpdateRemoteDashboard (
		$dashboardID: String!
		$dashboardName: String
		$dashboardDescription: String
		$embedLink: String
	) {
		report {
			updateCustomEmbeddedDashboard(
				dashboardID: $dashboardID
				dashboardName: $dashboardName
				dashboardDescription: $dashboardDescription
				embedLink: $embedLink
			) {
				dashboardDescription
				dashboardID
				dashboardName
				dashboardSource
				dashboardThumbnail
				dateAdded
				embedLink
				lastAccessed
				userAdded
			}
		}
	}
`)

export const getMarketingFunnelReport = gql(`
	query GetMarketingFunnelReport (
		$awarenessFilter: String
		$boardID: String
		$endDate: String
		$filterList: [DimensionFilter!]
		$mainFilter: [DimensionFilter!]
		$startDate: String
	) {
		report {
			marketingFunnel {
				getMarketingFunnelReport(
					awarenessFilter: $awarenessFilter
					boardID: $boardID
					endDate: $endDate
					filterList: $filterList
					mainFilter: $mainFilter
					startDate: $startDate
				) {
					boardID
					boardTitle
					boardURL
					boardDefinition {
						awarenessFilter
						boardID
						boardTitle
						endDate
						filterList {
							dimensionName
							dimensionOptions
							dimensionParameterID
						}
						mainFilter {
							dimensionOptions
							dimensionName
							dimensionParameterID
						}
						startDate
					}
					laneList {
						laneID
						laneOrder
						laneTitle
						hasBeenReordered
						laneMetrics {
							metricName
							metricValue
						}
						secondaryColour
						primaryColour
						selectedMetricList
						cardList {
							additionalImages
							cardID
							cardMetrics {
								metricName
								metricValue
							}
							cardOrder
							cardTitle
							cardType
							hideCard
							lastEditedBy
							lastEditedDate
							updatedTitle
							cardConnections {
								connectedFrom
								connectedTo
								connectionMetricName
								connectionMetricValue
								connectionType
								hideConnection
								updatedConnectionMetricName
								updatedConnectionMetricValue
							}
							additionalConnections {
								connectedFrom
								connectedTo
								connectionMetricName
								connectionMetricValue
								connectionType
								hideConnection
								updatedConnectionMetricName
								updatedConnectionMetricValue
							}
							additionalMetrics {
								metricName
								metricValue
							}
							cardAnnotation
						}
					}
				}
			}
		}
	}
`)

export const listSavedMarketingFunnelBoards = gql(`
	query GetMarketingFunnelBoardList {
		report {
			marketingFunnel {
				getMarketingFunnelBoardList {
					boardDescription
					boardID
					boardTitle
					boardURL
					boardHidden
					availableTo
				}
			}
		}
	}
`)

export const saveMarketingFunnelBoardInitial = gql(`
	mutation SaveMarketingFunnelBoardInitial (
		$boardID: String!
		$boardTitle: String!
		$boardDescription: String
		$availableTo: String!
		$awarenessFilter: String
		$startDate: String
		$endDate: String
		$filterList: [DimensionFilter!]
		$mainFilter: [DimensionFilter!]
	) {
		report {
			saveMarketingFunnelBoardInitial(
				boardID: $boardID
				boardTitle: $boardTitle
				boardDescription: $boardDescription
				availableTo: $availableTo
				awarenessFilter: $awarenessFilter
				startDate: $startDate
				endDate: $endDate
				filterList: $filterList
				mainFilter: $mainFilter
			) {
				boardDefinition {
					awarenessFilter
					boardID
					boardTitle
					endDate
					filterList {
						dimensionName
						dimensionOptions
						dimensionParameterID
					}
					mainFilter {
						dimensionName
						dimensionOptions
						dimensionParameterID
					}
					startDate
				}
				boardID
				boardTitle
				boardURL
				laneList {
					laneMetrics {
						metricName
						metricValue
					}
					secondaryColour
          primaryColour
          selectedMetricList
					cardList {
						additionalConnections {
							connectedFrom
							connectionMetricName
							connectedTo
							connectionMetricValue
							connectionType
							updatedConnectionMetricName
							hideConnection
							updatedConnectionMetricValue
						}
						additionalImages
						additionalMetrics {
							metricName
							metricValue
						}
						cardAnnotation
						cardConnections {
							connectedFrom
							connectedTo
							connectionMetricName
							connectionMetricValue
							connectionType
							hideConnection
							updatedConnectionMetricName
							updatedConnectionMetricValue
						}
						cardID
						cardMetrics {
							metricName
							metricValue
						}
						cardOrder
						cardTitle
						cardType
						hideCard
						lastEditedBy
						lastEditedDate
						updatedTitle
					}
					laneID
					laneOrder
					laneTitle
					hasBeenReordered
				}
			}
		}
	}
`)

export const updateMarketingFunnelBoard = gql(`
	mutation UpdateMarketingFunnelBoard (
		$boardID: String!
		$boardTitle: String!
		$boardDescription: String
		$availableTo: String
		$awarenessFilter: String
		$startDate: String
		$endDate: String
		$mainFilter: [DimensionFilter!]
		$filterList: [DimensionFilter!]
	) {
		report {
			updateMarketingFunnelBoard(
				boardID: $boardID
				boardTitle: $boardTitle
				boardDescription: $boardDescription
				availableTo: $availableTo
				awarenessFilter: $awarenessFilter
				startDate: $startDate
				endDate: $endDate
				mainFilter: $mainFilter
				filterList: $filterList
			) {
				boardDefinition {
					awarenessFilter
					boardID
					boardTitle
					endDate
					filterList {
						dimensionName
						dimensionOptions
						dimensionParameterID
					}
					mainFilter {
						dimensionName
						dimensionOptions
						dimensionParameterID
					}
					startDate
				}
				boardID
				boardTitle
				boardURL
				laneList {
					cardList {
						additionalConnections {
							connectedFrom
							connectedTo
							connectionMetricName
							connectionMetricValue
							connectionType
							hideConnection
							updatedConnectionMetricName
							updatedConnectionMetricValue
						}
						additionalImages
						cardAnnotation
						cardID
						additionalMetrics {
							metricName
							metricValue
						}
						cardConnections {
							connectedFrom
							connectedTo
							connectionMetricName
							connectionMetricValue
							connectionType
							hideConnection
							updatedConnectionMetricName
							updatedConnectionMetricValue
						}
						cardMetrics {
							metricName
							metricValue
						}
						cardOrder
						cardTitle
						cardType
						hideCard
						lastEditedBy
						lastEditedDate
						updatedTitle
					}
					laneID
					laneMetrics {
						metricName
						metricValue
					}
					laneOrder
					laneTitle
					hasBeenReordered
				}
			}
		}
	}
`)

export const updateMarketingFunnelBoardOrder = gql(`
	mutation UpdateMarketingFunnelBoardOrder (
		$newOrder: [String!]!
		$hideReports: [String!]
	) {
		report {
			updateMarketingFunnelBoardOrder(
				newOrder: $newOrder
				hideReports: $hideReports
			) {
				boardDescription
				boardID
				boardTitle
				boardURL
				boardHidden
				availableTo
			}
		}
	}
`)

export const deleteMarketingFunnelBoard = gql(`
	mutation DeleteMarketingFunnelBoard (
		$boardID: String!
	) {
		report {
			deleteMarketingFunnelBoard(
				boardID: $boardID
			) {
				boardDescription
				boardID
				boardTitle
				boardURL
				boardHidden
				availableTo
			}
		}
	}
`)

export const updateMarketingBoardLane = gql(`
	mutation UpdateMarketingBoardLane (
		$boardID: String!
		$laneID: String!
		$cardOrder: [String!]
		$laneTitle: String
		$primaryColour: String
		$secondaryColour: String
		$selectedMetricList: [String!]
	) {
		report {
			updateMarketingFunnelLane(
				boardID: $boardID
				laneID: $laneID
				cardOrder: $cardOrder
				laneTitle: $laneTitle
				primaryColour: $primaryColour
				secondaryColour: $secondaryColour
				selectedMetricList: $selectedMetricList
			) {
				boardID
				boardTitle
				boardURL
				boardDefinition {
					awarenessFilter
					boardID
					boardTitle
					endDate
					filterList {
						dimensionName
						dimensionOptions
						dimensionParameterID
					}
					mainFilter {
						dimensionName
						dimensionOptions
						dimensionParameterID
					}
					startDate
				}
				laneList {
					laneID
					laneOrder
					laneTitle
					laneMetrics {
						metricName
						metricValue
					}
					hasBeenReordered
					secondaryColour
          primaryColour
          selectedMetricList
					cardList {
						additionalImages
						cardID
						cardMetrics {
							metricName
							metricValue
						}
						cardOrder
						cardTitle
						cardType
						hideCard
						lastEditedBy
						lastEditedDate
						updatedTitle
						cardConnections {
							connectedFrom
							connectionMetricName
							connectedTo
							connectionMetricValue
							connectionType
							hideConnection
							updatedConnectionMetricName
							updatedConnectionMetricValue
						}
						additionalConnections {
							connectedFrom
							connectedTo
							connectionMetricName
							connectionMetricValue
							connectionType
							hideConnection
							updatedConnectionMetricName
							updatedConnectionMetricValue
						}
						additionalMetrics {
							metricName
							metricValue
						}
						cardAnnotation
					}
				}
			}
		}
	}
`)

export const updateMarketingBoardCard = gql(`
	mutation UpdateMarketingBoardCard (
		$boardID: String!
		$cardID: String!
		$laneID: String!
		$additionalConnections: [MarketingFunnelCardConnectionInput!]
		$additionalImages: [String!]
		$additionalMetrics: [FunnelMetricInput!]
		$cardTitle: String
		$hideCard: Boolean
	) {
		report {
			updateMarketingFunnelCard(
				boardID: $boardID
				cardID: $cardID
				laneID: $laneID
				additionalConnections: $additionalConnections
				additionalImages: $additionalImages
				additionalMetrics: $additionalMetrics
				cardTitle: $cardTitle
				hideCard: $hideCard
			) {
				boardDefinition {
					awarenessFilter
					boardID
					boardTitle
					endDate
					filterList {
						dimensionName
						dimensionOptions
						dimensionParameterID
					}
					mainFilter {
						dimensionName
						dimensionOptions
						dimensionParameterID
					}
					startDate
				}
				boardID
				boardTitle
				boardURL
				laneList {
					cardList {
						additionalImages
						updatedTitle
						lastEditedBy
						lastEditedDate
						hideCard
						cardType
						cardTitle
						cardOrder
						cardMetrics {
							metricName
							metricValue
						}
						cardID
						cardConnections {
							connectedFrom
							connectedTo
							connectionMetricName
							connectionMetricValue
							connectionType
							hideConnection
							updatedConnectionMetricName
							updatedConnectionMetricValue
						}
						cardAnnotation
						additionalMetrics {
							metricName
							metricValue
						}
						additionalConnections {
							connectedFrom
							connectedTo
							connectionMetricName
							connectionMetricValue
							hideConnection
							connectionType
							updatedConnectionMetricName
							updatedConnectionMetricValue
						}
					}
					laneTitle
					laneOrder
					hasBeenReordered
					laneMetrics {
						metricValue
						metricName
					}
					laneID
					secondaryColour
          primaryColour
          selectedMetricList
				}
			}
		}
	}
`)

export const addMarketingBoardCard = gql(`
	mutation AddMarketingBoardCard (
		$boardID: String!
		$laneID: String!
		$cardTitle: String!
		$curCardOrder: [String!]!
	) {
		report {
			addMarketingFunnelCard(
				boardID: $boardID
				laneID: $laneID
				cardTitle: $cardTitle
				curCardOrder: $curCardOrder
			) {
				boardDefinition {
					awarenessFilter
					boardID
					boardTitle
					endDate
					filterList {
						dimensionName
						dimensionOptions
						dimensionParameterID
					}
					mainFilter {
						dimensionName
						dimensionOptions
						dimensionParameterID
					}
					startDate
				}
				boardID
				boardTitle
				boardURL
				laneList {
					cardList {
						additionalImages
						updatedTitle
						lastEditedDate
						lastEditedBy
						cardType
						hideCard
						cardTitle
						cardOrder
						cardMetrics {
							metricName
							metricValue
						}
						cardID
						cardConnections {
							connectedFrom
							connectedTo
							connectionMetricName
							connectionMetricValue
							connectionType
							hideConnection
							updatedConnectionMetricName
							updatedConnectionMetricValue
						}
						cardAnnotation
						additionalMetrics {
							metricName
							metricValue
						}
						additionalConnections {
							connectedFrom
							connectionMetricName
							connectedTo
							connectionMetricValue
							connectionType
							hideConnection
							updatedConnectionMetricName
							updatedConnectionMetricValue
						}
					}
					laneTitle
					laneOrder
					hasBeenReordered
					laneMetrics {
						metricName
						metricValue
					}
					laneID
				}
			}
		}
	}
`)

export const deleteMarketingBoardCard = gql(`
	mutation DeleteMarketingBoardCard (
		$boardID: String!
		$cardID: String!
		$laneID: String!
	) {
		report {
			deleteMarketingFunnelCard(
				boardID: $boardID
				cardID: $cardID
				laneID: $laneID
			) {
				boardDefinition {
					awarenessFilter
					boardID
					boardTitle
					endDate
					filterList {
						dimensionName
						dimensionOptions
						dimensionParameterID
					}
					mainFilter {
						dimensionName
						dimensionOptions
						dimensionParameterID
					}
					startDate
				}
				boardID
				boardTitle
				boardURL
				laneList {
					cardList {
						additionalConnections {
							connectedFrom
							connectedTo
							connectionMetricName
							connectionMetricValue
							connectionType
							hideConnection
							updatedConnectionMetricName
							updatedConnectionMetricValue
						}
						additionalImages
						additionalMetrics {
							metricName
							metricValue
						}
						cardAnnotation
						cardConnections {
							connectedFrom
							connectedTo
							connectionMetricName
							connectionMetricValue
							connectionType
							hideConnection
							updatedConnectionMetricName
							updatedConnectionMetricValue
						}
						cardID
						cardMetrics {
							metricName
							metricValue
						}
						cardOrder
						cardTitle
						cardType
						hideCard
						lastEditedBy
						lastEditedDate
						updatedTitle
					}
					laneID
					laneMetrics {
						metricName
						metricValue
					}
					laneOrder
					laneTitle
					hasBeenReordered
					secondaryColour
          primaryColour
          selectedMetricList
				}
			}
		}
	}
`)

export const fetchPageScreenshots = gql(`
	query FetchPageScreenshots (
		$urlList: [String!]!
	) {
		report {
			pagePreview(
				urlList: $urlList
			) {
				landingPage
				screenshotBase64
				screenshotLocation
				screenshotType	
			}
		}
	}
`)

export const getTrackValidationResultsByLandingPage = gql(`
	query GetTrackValidationResultsByLandingPage (
		$urlList: [String!]!
		$includeQueryPars: Boolean
	) {
		track {
			getTrackValidationResultsByLandingPage(
				urlList: $urlList,
				includeQueryPars: $includeQueryPars
		) {
				badUrl
				campaignCodeID
				noAnalyticsTag
				redirectedLandingPage
				slowLandingPage
				statusCode
			}
		}
	}
`)
