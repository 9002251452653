import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import moment from 'moment'
import { nanoid } from 'nanoid'
import numeral from 'numeraljs'

import Button from './button'
import NoDataMessage from './no-data-message'
import Loader, { Preloader } from './loader'
import OrderArrow from './order-arrow'
import Pagination from './pagination'
import Table, { TableLoadingRows } from './table'
import TopScrollbar from './top-scrollbar'
import {
  listSavedLostLinksReports,
  updateSavedLostLinkReport,
} from '../api/graphql/report-client'
import { dateFormatShort } from '../core/constants'
import { paramNameMapper } from '../helpers/report-module'
import styles from '../styles/lost-links-table.module.scss'
import { GetLostLinksTableDataQuery } from '../__gql-types__/graphql'

interface FullRowData {
  firstObserved: string
  landingPage: string
  [metricOrParamName: string]: string | number
}

export interface PaginationData {
  totalRows: number
  rowsPerPage: number
  pages: number
  activePage: number
  sortDirection: 'ASC' | 'DESC'
}

interface LostLinksTableProps {
  savedReportID?: string
  savedReportColumnOrder?: number[]
  data?: GetLostLinksTableDataQuery
  paramsToShow: {
    paramHeadingValue: string
    paramHeadingName: string
    hidden: boolean
  }[]
  groupByLandingPage?: boolean
  loading?: boolean
  error?: boolean
  totalRows?: number
  paginationData: PaginationData | null
  setPaginationData: React.Dispatch<React.SetStateAction<PaginationData | null>>
  refetchData: (pagination: PaginationConfig) => void
  showEmptyColumns?: boolean
}

// Only for GA data for now
// Adobe data is possible, but unused
// https://github.com/uplifter-limited/uplifter-graphql-backend/issues/323
const LostLinksTable = React.forwardRef<HTMLTableElement, LostLinksTableProps>(
  (
    {
      savedReportID,
      savedReportColumnOrder,
      data,
      paramsToShow,
      groupByLandingPage = false,
      loading,
      error,
      totalRows,
      paginationData,
      setPaginationData,
      refetchData,
      showEmptyColumns = true,
    },
    tableRef,
  ) => {
    const [updateSavedReport] = useMutation(updateSavedLostLinkReport, {
      refetchQueries: [listSavedLostLinksReports],
    })

    const tableContainerRef = useRef<HTMLDivElement>(null)

    const tableData = useMemo(() => {
      if (!data) return null

      return data.report.linkAudit.linkAuditTable
    }, [data])

    const metricHeading = useMemo(() => {
      if (!tableData) return ''

      return tableData.metricHeadings[0] || 'Metric'
    }, [tableData])

    const [parameterColumnIndexOrder, setParameterColumnIndexOrder] = useState<
      number[]
    >([])
    const [
      storedParameterColumnIndexOrder,
      setStoredParameterColumnIndexOrder,
    ] = useState<number[]>([])

    // Set the initial parameter column order
    useEffect(() => {
      if (!tableData) return

      if (!savedReportColumnOrder || savedReportColumnOrder.length === 0) {
        setParameterColumnIndexOrder(
          Array.from(
            new Array(tableData.parameterHeadings.length),
            (_, i) => i,
          ),
        )
        setStoredParameterColumnIndexOrder(
          Array.from(
            new Array(tableData.parameterHeadings.length),
            (_, i) => i,
          ),
        )
      } else {
        setParameterColumnIndexOrder(savedReportColumnOrder)
        setStoredParameterColumnIndexOrder(savedReportColumnOrder)
      }
    }, [tableData, savedReportColumnOrder])

    // Table data should be returned in order determined by current parameterColumnIndexOrder
    const { parameterHeadings, rowsData } = useMemo(() => {
      if (!tableData) {
        return { parameterHeadings: [], rowsData: [] }
      }

      const orderedParamsHeadingsData: string[] = []
      const fullRowsData: FullRowData[] = []

      const {
        firstObserved,
        landingPageList,
        metricHeadings,
        metricValues,
        parameterHeadings: _parameterHeadings,
        parameterValues,
      } = tableData

      parameterColumnIndexOrder.forEach((paramIndex) => {
        orderedParamsHeadingsData.push(_parameterHeadings[paramIndex])
      })

      firstObserved.forEach((item, index) => {
        const rowData = {
          firstObserved: item,
          landingPage: landingPageList[index],
          [metricHeadings ? metricHeadings[0] : 'Metric']: metricValues[0][
            index
          ],
        }

        parameterColumnIndexOrder.forEach((paramIndex) => {
          rowData[_parameterHeadings[paramIndex]] =
            parameterValues[paramIndex][index]
        })

        fullRowsData.push(rowData)
      })

      return {
        parameterHeadings: orderedParamsHeadingsData,
        rowsData: fullRowsData,
      }
    }, [tableData, parameterColumnIndexOrder])

    // Reset pagination data when table data changes
    useEffect(() => {
      if (!tableData) return

      const _totalRows = totalRows || tableData?.pageData?.total

      if (_totalRows === undefined) return

      setPaginationData({
        ...(paginationData && _totalRows === paginationData.totalRows
          ? paginationData
          : {
              rowsPerPage: 10,
              activePage: 1,
              sortDirection: 'DESC',
            }),
        totalRows: _totalRows,
        pages: Math.ceil(_totalRows / (paginationData?.rowsPerPage || 10)),
      })
    }, [tableData, totalRows])

    return (
      <>
        <div ref={tableContainerRef} className={styles.tableContainer}>
          <TopScrollbar className={styles.tableContainerInner}>
            <Table ref={tableRef} className={styles.table}>
              <thead>
                <DragDropContext
                  onDragUpdate={(result) => {
                    const { destination, source } = result
                    if (destination) {
                      const newOrder = [...storedParameterColumnIndexOrder]

                      const movedIndex = newOrder.splice(source.index, 1)
                      newOrder.splice(destination.index, 0, movedIndex[0])

                      setParameterColumnIndexOrder(newOrder)
                    }
                  }}
                  onDragEnd={(result) => {
                    setStoredParameterColumnIndexOrder(
                      parameterColumnIndexOrder,
                    )

                    const { destination, source } = result

                    if (!destination) return

                    if (
                      destination.droppableId === source.droppableId &&
                      destination.index === source.index
                    ) {
                      return
                    }

                    if (savedReportID) {
                      updateSavedReport({
                        variables: {
                          savedReportID,
                          updatedTableColumnIndexOrderList: parameterColumnIndexOrder,
                        },
                      })
                    }
                  }}
                >
                  <Droppable
                    droppableId="tableHeaderCols"
                    direction="horizontal"
                  >
                    {(droppableProvided) => {
                      return (
                        <tr
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                        >
                          <th>First observed</th>
                          <th>
                            <Button
                              variant="iconOnly"
                              className={styles.sortButton}
                              onPress={() => {
                                if (data && paginationData) {
                                  const newSortDirection =
                                    paginationData?.sortDirection === 'ASC'
                                      ? 'DESC'
                                      : 'ASC'

                                  setPaginationData({
                                    ...paginationData,
                                    activePage: 1,
                                    sortDirection: newSortDirection,
                                  })

                                  refetchData({
                                    limit: paginationData.rowsPerPage,
                                    offset: 1,
                                    sortDirection: newSortDirection,
                                  })
                                }
                              }}
                            >
                              <span>
                                {metricHeading || (
                                  <Loader className={styles.loadingText} />
                                )}
                              </span>
                              <OrderArrow
                                sortKey={metricHeading}
                                currentKey={metricHeading || 'none'}
                                orderAsc={
                                  paginationData?.sortDirection === 'ASC'
                                }
                                className={styles.orderArrow}
                              />
                            </Button>
                          </th>
                          <th className={styles.landingPage}>
                            Landing page
                            {groupByLandingPage
                              ? ''
                              : ' with non-utm parameters'}
                          </th>
                          {parameterHeadings.length > 0 ? (
                            <>
                              {parameterHeadings.map(
                                (paramHeading, paramIndex) => {
                                  const fullColumnData = rowsData.map(
                                    (r) => r[paramHeading],
                                  )

                                  // Don't show column if it has no data
                                  if (
                                    !showEmptyColumns &&
                                    fullColumnData.every((d) => !d)
                                  ) {
                                    return null
                                  }

                                  // Don't show column if user has hidden it
                                  const showParamValue = paramsToShow.find(
                                    (param) =>
                                      param.paramHeadingValue === paramHeading,
                                  )

                                  if (showParamValue && showParamValue.hidden) {
                                    return null
                                  }

                                  return (
                                    <Draggable
                                      key={paramHeading}
                                      draggableId={paramHeading}
                                      index={paramIndex}
                                    >
                                      {(draggableProvided) => {
                                        return (
                                          <th
                                            key={paramHeading}
                                            ref={draggableProvided.innerRef}
                                            {...draggableProvided.draggableProps}
                                            {...draggableProvided.dragHandleProps}
                                          >
                                            <span>
                                              {paramNameMapper[paramHeading] ||
                                                paramHeading}
                                            </span>
                                          </th>
                                        )
                                      }}
                                    </Draggable>
                                  )
                                },
                              )}
                              {droppableProvided.placeholder}
                            </>
                          ) : (
                            <>
                              {Array.from(
                                new Array(5),
                                (_, index) => index,
                              ).map((i) => {
                                return (
                                  <th key={nanoid()}>
                                    <Loader className={styles.loadingText} />
                                  </th>
                                )
                              })}
                            </>
                          )}
                        </tr>
                      )
                    }}
                  </Droppable>
                </DragDropContext>
              </thead>
              {!error && loading ? (
                <tbody>
                  <TableLoadingRows colCount={8} />
                </tbody>
              ) : (
                <>
                  {error || rowsData.length === 0 ? (
                    <tbody className={styles.tableBody}>
                      <tr>
                        <td
                          colSpan={
                            parameterHeadings.length > 0
                              ? parameterHeadings.length + 3
                              : 8
                          }
                        >
                          <div
                            style={{
                              margin: '16px 0',
                              maxWidth: tableContainerRef.current?.offsetWidth,
                            }}
                          >
                            <NoDataMessage
                              errorMsg={
                                error
                                  ? 'Error fetching data'
                                  : 'No links found.'
                              }
                              showSupportLink={false}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {rowsData.map((row) => {
                        return (
                          <tr key={JSON.stringify(row)}>
                            <td>
                              <span>
                                {moment(row.firstObserved).format(
                                  dateFormatShort,
                                )}
                                {row.sessionSource === 'google' &&
                                  row.sessionMedium === 'cpc' && (
                                    <>
                                      <br />
                                      <span className={styles.specialTag}>
                                        Google Ads?
                                      </span>
                                    </>
                                  )}
                              </span>
                            </td>
                            <td className={styles.metricColumn}>
                              <span>
                                {numeral(row[metricHeading]).format('0,0')}
                              </span>
                            </td>
                            <td className={styles.landingPage}>
                              <span>{row.landingPage}</span>
                            </td>
                            {parameterHeadings.map((paramHeading) => {
                              const fullColumnData = rowsData.map(
                                (r) => r[paramHeading],
                              )

                              // Don't show column if it has no data
                              if (
                                !showEmptyColumns &&
                                fullColumnData.every((d) => !d)
                              ) {
                                return null
                              }

                              // Don't show column if user has hidden it
                              const showParamValue = paramsToShow.find(
                                (param) =>
                                  param.paramHeadingValue === paramHeading,
                              )

                              if (showParamValue && showParamValue.hidden) {
                                return null
                              }

                              return (
                                <td
                                  key={paramHeading}
                                  className={styles.paramColumn}
                                >
                                  <span
                                    className={
                                      row[paramHeading]
                                        ? undefined
                                        : styles.noData
                                    }
                                  >
                                    {row[paramHeading] || ''}
                                  </span>
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </TopScrollbar>
        </div>
        {paginationData && (
          <>
            {loading ? (
              <Preloader />
            ) : (
              <div data-html2canvas-ignore>
                <Pagination
                  pages={paginationData.pages}
                  activePage={paginationData.activePage}
                  onChange={(newPage: number) => {
                    setPaginationData({
                      ...paginationData,
                      activePage: newPage,
                    })

                    refetchData({
                      limit: paginationData.rowsPerPage,
                      offset: (newPage - 1) * paginationData.rowsPerPage + 1,
                      sortDirection: paginationData.sortDirection,
                    })
                  }}
                  rowsPerPageData={{
                    rowsPerPage: paginationData.rowsPerPage,
                    totalRows: paginationData.totalRows,
                    onChange: (newRowsPerPage) => {
                      setPaginationData({
                        ...paginationData,
                        pages: Math.ceil(
                          paginationData.totalRows / newRowsPerPage,
                        ),
                        activePage: 1,
                        rowsPerPage: newRowsPerPage,
                      })

                      refetchData({
                        offset: 1,
                        limit: newRowsPerPage,
                        sortDirection: paginationData.sortDirection,
                      })
                    },
                  }}
                />
              </div>
            )}
          </>
        )}
      </>
    )
  },
)

export default LostLinksTable
