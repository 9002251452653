import _ from 'lodash'
import FileSaver from 'file-saver'
import numeral from 'numeraljs'
import moment from 'moment'

import { getItemByKeyValue, removeByKeyValue, replaceByKeyValue } from './index'
import { RecentlyValidatedUrl } from '../api/apollo/variables'
import {
  CampaignCodeGeneratorStructure,
  GeneratorFields,
  GeneratorSelectFields,
  MinCodesByUserResult,
  ParamDefs,
  ValidationChecks,
} from '../api/types'
import { brandName, messages } from '../core/constants'
import { GetMinCodesByAccountQuery } from '../__gql-types__/graphql'

export const defaultShortLinkDomain = 'upl.inc'
export const defaultAppDeepLinkDomain = 'default'
export const defaultAppLinkDomain = 'app.upl.inc'

export const minBatchShortLinks = 11
export const maxBatchShortLinks = 2500

export interface FullValidationCheck extends ValidationChecks {
  showCheckbox?: boolean
  ruleTitle?: string
  helpText?: string
  requireUpgrade?: boolean
  logAction?: string
  fieldType?: 'input' | 'textarea' | 'select' | 'multiselect'
}

type ValidationCategories =
  | 'Landing page URL'
  | 'Landing page validation'
  | 'Validation options'
  | 'Short link preferences'
  | 'Email preferences'
  | 'Advanced options'

export interface ValidationChecksCategory {
  category: ValidationCategories
  validationChecks: FullValidationCheck[]
}

export const defaultEnabled = {
  REQUIRE_LANDING_PAGE: true,
  SHOW_LANDING_PAGE: true,
  NO_SPECIAL_CHARS_LANDING_PAGE: false,
  CHECK_URL_EXISTS: true,
  CHECK_URL_SPEED: true,
  CHECK_URL_REDIRECT: true,
  CHECK_URL_ANALYTICS: false,
  ALL_LOWER_CASE: false,
  NO_SPECIAL_CHARS: true,
  NO_SPACES: true,
  REPLACE_SPACES_WITH: true,
  LIMIT_URL_LENGTH: true,
  LIMIT_QUERY_LENGTH: true,
  FORCE_SHORT_LINK: false,
  FORCE_CUSTOM_DOMAIN: false,
  INCLUDE_PREFIX_WITH_COPY_FROM: false,
  COPY_FROM_SEPARATOR: true,
  INCLUDE_EMPTY_VALUES: false,
}

export const defaultValidationChecksValues = {
  ALL_LOWER_CASE: /[A-Z]/,
  NO_SPECIAL_CHARS: /[?=&]/,
  NO_SPECIAL_CHARS_LANDING_PAGE: /[?=&]/,
  NO_SPACES: /\s/,
  LIMIT_URL_LENGTH: '1024',
  LIMIT_QUERY_LENGTH: '255',
}

export const defaultValidationChecksFull: ValidationChecksCategory[] = [
  {
    category: 'Landing page URL',
    validationChecks: [
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Require',
        name: 'REQUIRE_LANDING_PAGE',
        logAction: 'update-generator-rules-landing-page-required',
        value: null,
        helpText:
          'If checked, users will have to enter a landing page URL to create a campaign code link.',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Show this field',
        name: 'SHOW_LANDING_PAGE',
        logAction: 'update-generator-rules-landing-page-show-field',
        value: null,
        helpText:
          'If unchecked, the landing page URL input box will be removed (not recommended).',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle:
          'No special characters or existing query string parameters (?=&)',
        name: 'NO_SPECIAL_CHARS_LANDING_PAGE',
        logAction: 'update-generator-rules-landing-page-no-special-characters',
        value: null,
        helpText:
          'If checked, users will not be able to enter a URL with ?=&amp;, including existing query string parameters. Turn this off if deep linking uses extra query string parameters instead of hashes (#).',
      },
    ],
  },
  {
    category: 'Landing page validation',
    validationChecks: [
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Check it exists',
        name: 'CHECK_URL_EXISTS',
        logAction: 'update-generator-rules-landing-page-exists',
        value: null,
        helpText: 'If checked the validation will check if the page exists.',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Check it loads fast',
        name: 'CHECK_URL_SPEED',
        logAction: 'update-generator-rules-landing-page-loads-fast',
        value: null,
        helpText:
          'If checked the validation will check if the page loads under 5 seconds.',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Check it redirects',
        name: 'CHECK_URL_REDIRECT',
        logAction: 'update-generator-rules-landing-page-redirects',
        value: null,
        helpText: 'If checked the validation will check for page redirects.',
      },
      {
        showCheckbox: true,
        enabled: false,
        ruleTitle: 'Check it has analytics',
        name: 'CHECK_URL_ANALYTICS',
        logAction: 'update-generator-rules-landing-page-analytics',
        value: null,
        helpText:
          'If checked the validation will check if the page has Google or Adobe analytics.',
      },
    ],
  },
  {
    category: 'Validation options',
    validationChecks: [
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'All parameters lowercase only',
        name: 'ALL_LOWER_CASE',
        logAction: 'update-generator-rules-lowercase',
        value: null,
        helpText:
          'If checked, all text in links will be automatically lowercased. This makes data easier to read in analytics platforms.\n\n**If unchecked, lowercase validation is controlled individually above for each parameter.**',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'No special characters (?=&)',
        name: 'NO_SPECIAL_CHARS',
        logAction: 'update-generator-rules-no-special-characters',
        value: null,
        helpText:
          "If checked, users' text input cannot contain special characters. This stops users creating campaign codes which interfere with campaign code tracking.",
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'No spaces',
        name: 'NO_SPACES',
        logAction: 'update-generator-rules-no-spaces',
        value: null,
        helpText:
          'If checked, users will not be allowed to enter spaces into free text parameters or dropdown codes. Spaces will be replaced with the value you enter for the "Replace spaces with" rule or "%20".',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Replace spaces with',
        name: 'REPLACE_SPACES_WITH',
        logAction: 'update-generator-rules-replace-spaces-with',
        value: '_',
        helpText:
          'If checked, spaces will be replaced with this character when typing into free text parameters or dropdown codes. The default recommended value is underscore _. This stops ugly %20 characters from showing in your analytics tools.',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Max link length',
        name: 'LIMIT_URL_LENGTH',
        logAction: 'update-generator-rules-max-link-length',
        value: '1024',
        helpText:
          'Maximum number of characters in the landing page and generated tracking code. Recommended value is 1024 characters.',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Max query length',
        name: 'LIMIT_QUERY_LENGTH',
        logAction: 'update-generator-rules-max-query-length',
        value: '255',
        helpText:
          'Maximum number of generated tracking code. Recommended value is 255 characters.',
      },
    ],
  },
  {
    category: 'Short link preferences',
    validationChecks: [
      {
        showCheckbox: false,
        enabled: true,
        ruleTitle: 'Link type preferences',
        name: 'FORCE_SHORT_LINK',
        logAction: 'update-generator-short-link-force-short-link',
        value: `[
          {
            "optionName": "Recommend basic links",
            "optionValue": "recommend-long-links",
            "selected": true
          },
          {
            "optionName": "Recommend short links",
            "optionValue": "recommend-short-links",
            "selected": false
          },
          {
            "optionName": "Force basic links",
            "optionValue": "force-long-links",
            "selected": false
          },
          {
            "optionName": "Force short links",
            "optionValue": "force-short-links",
            "selected": false
          }
        ]`,
        helpText:
          'Shows/hides the short or basic link options or determines which one is pre-selected',
        fieldType: 'select',
      },
      {
        showCheckbox: false,
        enabled: true,
        ruleTitle: 'Only show domains',
        name: 'FORCE_CUSTOM_DOMAIN',
        logAction: 'update-short-link-custom-domain',
        value: `[
          {
            "optionName": "${defaultShortLinkDomain}",
            "optionValue": "${defaultShortLinkDomain}",
            "selected": true
          }
        ]`,
        helpText:
          'Pick which of your short link domains your users will be able to select',
        requireUpgrade: true,
        fieldType: 'multiselect',
      },
    ],
  },
  {
    category: 'Email preferences',
    validationChecks: [
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Only replace email links which contain:',
        name: 'EMAIL_DOMAIN_LIST',
        logAction: 'update-generator-rules-email-default',
        value: '',
        fieldType: 'textarea',
        helpText:
          "When creating links in the 'Email' tab, we will only find and replace links which contain these domains.",
      },
    ],
  },
  {
    category: 'Advanced options',
    validationChecks: [
      {
        showCheckbox: true,
        enabled: false,
        ruleTitle: "Include prefix with 'copy from' fields",
        name: 'INCLUDE_PREFIX_WITH_COPY_FROM',
        logAction: 'update-generator-rules-copy-from-include-prefix',
        value: null,
        helpText:
          'If checked, any parameters copied from another parameter will include both the parameter prefix and the value.',
      },
      {
        showCheckbox: false,
        enabled: true,
        ruleTitle: 'Copy from separator',
        name: 'COPY_FROM_SEPARATOR',
        logAction: 'update-generator-rules-copy-from-separator',
        value: '|',
        helpText:
          "If a parameters copies from multiple other parameters, they will be separated by this value. The default value is a pipe '|'.",
      },
      {
        showCheckbox: true,
        enabled: false,
        ruleTitle: 'Include empty optional values',
        name: 'INCLUDE_EMPTY_VALUES',
        logAction: 'update-generator-rules-include-empty',
        value: null,
        helpText:
          'If checked, empty input values will still be shown in the tool with their prefix.',
      },
    ],
  },
]

export const defaultValidationChecksArray: ValidationChecks[] = defaultValidationChecksFull.flatMap(
  (category) =>
    category.validationChecks.map(({ enabled, name, value }) => ({
      enabled,
      name,
      value,
    })),
)

export const defaultGeneratorObject: CampaignCodeGeneratorStructure = {
  masterPrefix: '?cid=',
  paramDefs: [
    {
      fieldAvailable: true,
      fieldID: '9cde81e8',
      fieldName: 'Channels',
      fieldType: 'select',
      helpText: '',
      prefix: '',
      required: true,
      selectFields: [],
    },
    {
      fieldAvailable: true,
      fieldID: 'd6fa96cf',
      fieldName: 'Post Type',
      fieldType: 'select',
      helpText: '',
      prefix: '',
      required: true,
      selectFields: [],
    },
    {
      fieldAvailable: true,
      fieldID: '40a1c419',
      fieldName: 'Network',
      fieldType: 'select',
      helpText: '',
      prefix: '',
      required: true,
      selectFields: [],
    },
    {
      fieldAvailable: true,
      fieldID: '0fe4215d',
      fieldName: 'Targeting Strategy',
      fieldType: 'select',
      helpText: '',
      prefix: '',
      required: true,
      selectFields: [],
    },
    {
      fieldAvailable: true,
      fieldID: '43cc0115',
      fieldName: 'Campaign Name',
      fieldType: 'select',
      helpText: '',
      prefix: '',
      required: true,
      selectFields: [],
    },
    {
      fieldAvailable: true,
      fieldID: 'a9a48915',
      fieldName: 'Creative Type',
      fieldType: 'select',
      helpText: '',
      prefix: '',
      required: true,
      selectFields: [],
    },
    {
      fieldAvailable: true,
      fieldID: '879726f3',
      fieldName: 'Creative Name',
      fieldType: 'input',
      helpText: '',
      prefix: '',
      required: true,
    },
  ],
  paramSeparator: '&',
  validationChecks: defaultValidationChecksArray,
}

export const getDomain = (str: string) => {
  const domainFound = str.match(/https:\/\/?([^\/]+)/gi)

  if (domainFound !== null && domainFound.length > 0) {
    return domainFound[0]
  }

  return ''
}

// return default domain ('upl.inc') or custom domain ID
export const getCustomDomainID = (customDomainID?: string | null) => {
  return !customDomainID ||
    customDomainID === '' ||
    customDomainID === defaultShortLinkDomain ||
    customDomainID === `https://${defaultShortLinkDomain}`
    ? null
    : customDomainID
}

export const getValidationCheck = (
  validationChecks: FullValidationCheck[] | null,
  key: string,
  defaultValue = false,
): FullValidationCheck => {
  const defaultValidation = {
    enabled: defaultValue,
    name: key,
    value: null,
  }

  if (!validationChecks) {
    return defaultValidation
  }

  const found = validationChecks.find((item) => item.name === key)

  if (key === 'REPLACE_SPACES_WITH') {
    if (!found) {
      return { enabled: true, name: 'REPLACE_SPACES_WITH', value: '_' }
    }
    const value = Object.prototype.hasOwnProperty.call(found, 'value')
      ? found.value
      : '_'
    return { ...found, value }
  }

  if (key === 'LIMIT_URL_LENGTH') {
    if (!found) {
      return {
        enabled: true,
        name: 'LIMIT_URL_LENGTH',
        value: defaultValidationChecksValues.LIMIT_URL_LENGTH.toString(),
      }
    }
    const value = Object.prototype.hasOwnProperty.call(found, 'value')
      ? found.value
      : '_'
    return { ...found, value }
  }

  if (key === 'LIMIT_QUERY_LENGTH') {
    if (!found) {
      return {
        enabled: true,
        name: 'LIMIT_QUERY_LENGTH',
        value: defaultValidationChecksValues.LIMIT_QUERY_LENGTH,
      }
    }
    const value = Object.prototype.hasOwnProperty.call(found, 'value')
      ? found.value
      : '_'
    return { ...found, value }
  }

  if (key === 'COPY_FROM_SEPARATOR') {
    if (!found) {
      return { enabled: true, name: 'COPY_FROM_SEPARATOR', value: '|' }
    }
    const value = Object.prototype.hasOwnProperty.call(found, 'value')
      ? found.value
      : '|'
    return { ...found, value }
  }

  if (!found) {
    return defaultValidation
  }

  return found
}

export const getValidationChecksObject = (
  generatedStructure,
  isHomepage = false,
  override: {
    name: string
    enabled: boolean
    value?: string
  }[] = [],
) => {
  if (!generatedStructure) {
    return null
  }

  const { validationChecks } = generatedStructure

  if (isHomepage) {
    return [
      getValidationCheck(validationChecks, 'NO_SPECIAL_CHARS_LANDING_PAGE'),
    ]
  }

  // remove landing page validation options
  let useChecks = removeByKeyValue(
    validationChecks,
    'name',
    'NO_SPECIAL_CHARS_LANDING_PAGE',
  )

  override.forEach((item) => {
    useChecks = replaceByKeyValue(useChecks, 'name', item.name, item, true)
  })

  const rsw = getValidationCheck(validationChecks, 'REPLACE_SPACES_WITH')

  return replaceByKeyValue(
    useChecks,
    'name',
    'REPLACE_SPACES_WITH',
    rsw,
    true,
  ) as FullValidationCheck[]
}

export function getShareEmails(
  shareEmails: string[],
  typedValue: string,
): string[] {
  if (typedValue === '') return shareEmails

  if (shareEmails.length > 0) {
    if (shareEmails[shareEmails.length - 1] === typedValue) return shareEmails

    return [...shareEmails, ...[typedValue]]
  }

  return [typedValue]
}

export const downloadSpecificCodes = async (
  data: string | ArrayBuffer,
  excelFile = false,
): Promise<any> => {
  const blob = new Blob(
    [excelFile ? new Uint8Array(data as ArrayBuffer) : `\ufeff${data}`],
    {
      type: excelFile
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        : 'text/plain;charset=utf-8-sig',
    },
  )
  const now = new Date(Date.now())

  await FileSaver.saveAs(
    blob,
    `${moment(now).format('YYYY-MM-DD')} ${brandName} Campaign Codes.${
      excelFile ? 'xlsx' : 'csv'
    }`,
  )

  return true
}

export const getUrlMessages = ({
  validatedUrl,
  createdToday,
  hasMetricData,
  isGoodUrl,
  is403Url,
  hasNoLandingPage,
}: {
  validatedUrl?: RecentlyValidatedUrl | null
  createdToday?: boolean
  hasMetricData: boolean
  isGoodUrl: boolean
  is403Url: boolean
  hasNoLandingPage?: boolean
}) => {
  const urlMessages: string[] = []

  if (hasNoLandingPage) {
    urlMessages.push(messages.validateUrlMessage.noUrl)
    return urlMessages.join('')
  }

  if (validatedUrl) {
    if (is403Url) {
      urlMessages.push(messages.validateUrlMessage.forbidden)
    } else if (isGoodUrl) {
      urlMessages.push(messages.validateUrlMessage.goodUrl)
    } else if (validatedUrl.badUrl) {
      urlMessages.push(messages.validateUrlMessage.badUrl)
    } else if (validatedUrl.slowLandingPage) {
      urlMessages.push(messages.validateUrlMessage.slowLandingPage)
    } else if (validatedUrl.noAnalyticsTag) {
      urlMessages.push(messages.validateUrlMessage.noAnalyticsTag)
    } else if (validatedUrl.redirectedLandingPage) {
      urlMessages.push(messages.validateUrlMessage.redirectedLandingPage)
    }
  } else if (createdToday) {
    urlMessages.push(messages.validateUrlMessage.validating)
  } else if (!hasMetricData) {
    urlMessages.push(messages.validateUrlMessage.noData)
  } else {
    urlMessages.push(messages.validateUrlMessage.unknownUrl)
  }

  return urlMessages.join('')
}

export function formatMetricValue(value, units): string {
  let formattedNumber = value

  if (typeof value === 'number') {
    if (units === 'percentage') {
      formattedNumber = value === -1 ? '' : `${numeral(value).format('0,0')}%`
    } else if (value !== 0) {
      if (units === 'duration') {
        const time = moment.utc(value * 1000).format('mm:ss')
        formattedNumber = `${time}s`
      } else {
        formattedNumber = `${numeral(value).format('0,0.[0]a')}`
      }
    }
  }

  return formattedNumber
}

export function getAnchorFromString(url: string): string {
  const hasAnchor = url.lastIndexOf('#') !== -1

  let useAnchor = ''

  if (hasAnchor) {
    // Exclude # used in hash based routing (/#/)
    const searchRegEx = new RegExp(`#(?!\/)([^?/])*`, 'i')

    const urlMatch = url.match(searchRegEx)

    if (urlMatch && urlMatch.length > 0) {
      useAnchor = urlMatch[0].replace('?', '')
    }
  }

  return useAnchor
}

export function isFieldAChild(field: ParamDefs): boolean {
  const { selectFields } = field

  if (selectFields) {
    const haveParents = selectFields
      .filter((item) => !item.hide)
      .filter((item) => {
        const { optionFilter } = item

        if (optionFilter && optionFilter.length > 0) {
          const { parentOptionIDs } = optionFilter[0]
          return !!(parentOptionIDs && parentOptionIDs.length > 0)
        }

        return false
      })

    return !!(haveParents.length > 0)
  }
  return false
}

export function isFieldAParent(
  form: CampaignCodeGeneratorStructure,
  field: ParamDefs,
): boolean {
  const { paramDefs } = form
  const { fieldID } = field

  const allOtherParamDefs = paramDefs.filter(
    (item) => !!(item.fieldID !== fieldID && item.selectFields),
  )

  if (allOtherParamDefs.length > 0) {
    const haveChildren = allOtherParamDefs.filter((item) => {
      const { selectFields } = item

      if (selectFields) {
        const selected = selectFields
          .filter((i) => !i.hide)
          .filter((selectField) => {
            const { optionFilter } = selectField

            if (optionFilter && optionFilter.length > 0) {
              const { parentFieldID, parentOptionIDs } = optionFilter[0]

              return !!(
                parentOptionIDs &&
                parentOptionIDs.length > 0 &&
                parentFieldID === fieldID
              )
            }

            return false
          })

        return !!(selected.length > 0)
      }

      return false
    })

    return !!(haveChildren.length > 0)
  }
  return false
}

export interface ParentSelectFields extends GeneratorSelectFields {
  useLabel: string
}

export function getChildrenValues(
  field: GeneratorFields,
  parent: ParentSelectFields,
) {
  const { selectFields } = field

  const { optionID: parentOptionId } = parent

  const result: GeneratorSelectFields[] = []

  if (selectFields && selectFields.length > 0) {
    selectFields.forEach((item) => {
      if (item.hide) {
        return
      }

      const { optionFilter } = item

      if (optionFilter && optionFilter.length > 0) {
        const { parentOptionIDs } = optionFilter[0]

        if (parentOptionIDs && parentOptionIDs.length > 0) {
          if (parentOptionIDs.indexOf(parentOptionId) !== -1) {
            result.push(item)
          }
        }
      }
    })
  }
  return result
}

export const parentIsWorkspace = (field: GeneratorFields) => {
  if (!field.selectFields) return false

  const hasFilter = field.selectFields.find(
    (selectField) =>
      selectField.optionFilter && selectField.optionFilter.length > 0,
  )?.optionFilter

  if (!hasFilter) return false

  return hasFilter[0].parentFieldID === 'account'
}

export function getParentValue(
  form: CampaignCodeGeneratorStructure,
  field: GeneratorFields,
) {
  const { paramDefs } = form
  const { fieldID, selectFields } = field

  const selectedParentOptions: ParentSelectFields[] = []
  const parentIds: string[] = []

  const allOtherActiveSelectFields = paramDefs.filter(
    (item) =>
      !!(
        item.fieldID !== fieldID &&
        item.selectFields &&
        item.optionValue &&
        item.optionValue.length > 0 &&
        item.optionValue[0] !== ''
      ),
  )

  if (allOtherActiveSelectFields.length > 0 && selectFields) {
    selectFields
      .filter((i) => !i.hide)
      .forEach((item) => {
        const { optionFilter } = item

        if (optionFilter && optionFilter.length > 0) {
          const { parentOptionIDs, parentFieldID } = optionFilter[0]

          if (
            parentOptionIDs &&
            parentOptionIDs.length > 0 &&
            parentIds.indexOf(parentFieldID) === -1
          ) {
            parentIds.push(parentFieldID)
          }
        }
      })

    const parents = allOtherActiveSelectFields.filter(
      (i) => parentIds.indexOf(i.fieldID) !== -1,
    )

    parents.forEach((parent) => {
      const {
        selectFields: parentSelectFields,
        optionValue,
        fieldName,
      } = parent

      if (parentSelectFields) {
        selectedParentOptions.push(
          ...parentSelectFields
            .filter(
              (i) => optionValue && optionValue.indexOf(i.optionID) !== -1,
            )
            .map((i) => {
              return {
                ...i,
                useLabel: `${fieldName}: ${i.optionName}`,
              }
            }),
        )
      }
    })
  }

  return selectedParentOptions
}

export function getChildValue(
  form: CampaignCodeGeneratorStructure,
  field: GeneratorFields,
) {
  const { paramDefs } = form
  const { fieldID } = field

  const selectedChildOptions: any[] = []

  const allOtherParamDefs = paramDefs.filter(
    (item) =>
      !!(
        item.fieldID !== fieldID &&
        item.selectFields &&
        item.optionValue &&
        item.optionValue.length > 0 &&
        item.optionValue[0] !== ''
      ),
  )

  if (allOtherParamDefs.length > 0) {
    allOtherParamDefs.forEach((paramDef) => {
      const { selectFields, optionValue } = paramDef

      if (selectFields) {
        selectedChildOptions.push(
          ...selectFields.filter(
            (i) =>
              !i.hide && optionValue && optionValue.indexOf(i.optionID) !== -1,
          ),
        )
      }
    })
  }

  return selectedChildOptions.filter((item) => {
    const { optionFilter } = item
    if (optionFilter && optionFilter.length > 0) {
      const { parentFieldID, parentOptionIDs } = optionFilter[0]

      return (
        parentFieldID === fieldID &&
        parentOptionIDs &&
        parentOptionIDs.length > 0
      )
    }

    return false
  })
}

export function filterByParentValue(
  filtered: GeneratorSelectFields[],
  parentValue: ParentSelectFields[],
) {
  const parentValueOptionIDs = parentValue.map((i) => i.optionID)

  const result = filtered.filter((item) => {
    const { optionFilter } = item

    if (optionFilter && optionFilter.length > 0) {
      const { parentOptionIDs } = optionFilter[0]

      if (parentOptionIDs && parentOptionIDs.length > 0) {
        const containsParent = parentOptionIDs.filter(
          (i) => parentValueOptionIDs.indexOf(i) !== -1,
        )

        return containsParent.length > 0
      }
    }
    return true
  })

  return result
}

export function filterByChildValue(
  filtered: GeneratorSelectFields[],
  childValue: GeneratorSelectFields[],
) {
  const permittedParentOptionIDs: string[] = []

  childValue.forEach((item) => {
    const { optionFilter } = item

    if (optionFilter && optionFilter.length > 0) {
      const { parentOptionIDs } = optionFilter[0]

      if (parentOptionIDs && parentOptionIDs.length > 0) {
        permittedParentOptionIDs.push(
          ...parentOptionIDs.filter(
            (i) => permittedParentOptionIDs.indexOf(i) === -1,
          ),
        )
      }
    }
  })

  const result = filtered.filter((item) => {
    return permittedParentOptionIDs.indexOf(item.optionID) !== -1
  })

  return result
}

export function filterList(
  form: CampaignCodeGeneratorStructure,
  field: ParamDefs,
  workspaceID: string,
  single = true,
) {
  let filtered = field.selectFields
    ? field.selectFields.filter((item) => !item.hide)
    : []

  const isChild = isFieldAChild(field)

  if (isChild) {
    // Only show values that are allowed for the current workspace
    if (parentIsWorkspace(field)) {
      return filtered.filter((option) => {
        if (!option.optionFilter || option.optionFilter.length === 0)
          return true

        const { parentOptionIDs } = option.optionFilter[0]

        return (
          parentOptionIDs.length === 0 ||
          parentOptionIDs.indexOf(workspaceID) > -1
        )
      })
    }

    const parentValue = getParentValue(form, field)

    if (parentValue.length > 0) {
      // filter by parent value
      filtered = filterByParentValue(filtered, parentValue)
    }

    return filtered
  }

  const isParent = isFieldAParent(form, field)

  // For multi journey we need to exclude this filter
  if (isParent && single) {
    const childValue = getChildValue(form, field)

    if (childValue.length > 0) {
      // filter by child value
      filtered = filterByChildValue(filtered, childValue)
    }
  }

  return filtered
}

export function getCsvHeader(codes: MinCodesByUserResult) {
  const result: string[] = []
  result.push('User')
  result.push('Timestamp')
  result.push('Short link')
  result.push('Landing page with query parameters')

  codes.params.forEach((param) => {
    result.push(param.paramName)
  })

  return result.map((r) => `"${r.replace(/\"/gi, '""')}"`) // escape quotes
}

interface GetCsvRowsProps {
  userEmail: string
  codes: MinCodesByUserResult[]
  selectedCodes: string[]
}

export function getCsvRows({
  userEmail,
  codes,
  selectedCodes,
}: GetCsvRowsProps) {
  let result: string[][] = []

  const getCode = (code: MinCodesByUserResult) => {
    const row: string[] = []
    row.push(userEmail)
    row.push(code.createdTime)
    row.push(code.shortLink ? `${code.shortLink}` : '')
    row.push(code.fullLink)

    code.params.forEach((paramDef) => {
      row.push(paramDef.paramValue)
    })

    return row.map((r) => `"${r.replace(/\"/gi, '""')}"`) // escape quotes
  }

  if (selectedCodes.length === 0) {
    result = codes.map((code) => getCode(code))
  } else {
    codes.forEach((c) => {
      if (c && c.codeID && selectedCodes.indexOf(c.codeID) !== -1) {
        result.push(getCode(c))
      }
    })
  }
  return result
}

interface GetCsvStringProps {
  userEmail: string
  codes: MinCodesByUserResult[]
  selectedCodes: string[]
}

export function getCsvString({
  userEmail,
  codes,
  selectedCodes,
}: GetCsvStringProps): string {
  const csvRows: string[] = []

  csvRows.push(getCsvHeader(codes[0]).join(','))

  const rows = getCsvRows({
    userEmail,
    selectedCodes,
    codes,
  })

  rows.forEach((row) => {
    csvRows.push(row.join(','))
  })

  return csvRows.join('\n')
}

export function checkParamNameIsInvalid(
  name: string,
  allParams: string[],
  callback?: any,
  currName?: string,
): boolean {
  if (name === '') {
    if (callback) {
      callback(true)
    }
    return true
  }

  if (allParams.length > 0) {
    const paramExists =
      allParams.filter((param) => {
        return param === name && (!currName || param !== currName)
      }).length > 0
    if (callback) {
      callback(paramExists)
    }
    return paramExists
  }
  return false
}

// fields validation
export function isAllFormDataValid(data: CampaignCodeGeneratorStructure) {
  // Find all empty values that should not be empty
  const emptyValues = { ...data }.paramDefs.filter((item) => {
    const { fieldType, optionValue, required, parameterDependsOn } = item

    if (required) {
      if (parameterDependsOn) {
        // Only set as required if the parameter's dependency is met
        const { parentFieldID, parentOptionIDs } = parameterDependsOn

        const parentField = getItemByKeyValue(
          data.paramDefs,
          'fieldID',
          parentFieldID,
        )

        let hasValidParentValues = false

        parentOptionIDs.forEach((option) => {
          if (
            parentField.optionValue &&
            parentField.optionValue.indexOf(option) > -1
          )
            hasValidParentValues = true
        })

        // Check if parent field has a valid value
        if (!hasValidParentValues) {
          return false
        }
      }

      if (typeof optionValue !== 'undefined' && optionValue.length > 0) {
        if (fieldType === 'select' && item.selectFields) {
          const fieldsThatExist = optionValue.filter((val: string) => {
            if (item.selectFields) {
              return item.selectFields.some(
                (i: GeneratorSelectFields): boolean =>
                  // Select fields no longer use optionValues for their values, they use IDs
                  // i.optionValue === val ||
                  i.optionID === val,
              )
            }

            return false
          })

          return fieldsThatExist.length !== optionValue.length
        }

        return (
          (fieldType === 'input' || fieldType === 'date') &&
          optionValue.join('') === ''
        )
      }

      return true
    }

    return false
  })

  return emptyValues.length === 0
}

export function getCopyFromValues(paramDefs, paramDef: GeneratorFields) {
  if (paramDef.fieldType === 'input')
    return paramDefs.filter(
      (item: GeneratorFields): boolean => item.fieldID !== paramDef.fieldID,
    )

  return []
}

export const parentOptionsAreEqual = (a: string[], b: string[]) => {
  if (a.length !== b.length) return false

  return !a.find((val) => b.indexOf(val) === -1)
}

// Ensures that parameters can't be dependent on each other
// Dependency chain must start with the ID of the initial parameter being checked
export const checkCircularDependency = (
  paramToCheck: GeneratorFields,
  allDropdownParams: GeneratorFields[],
  dependencyChain: string[],
) => {
  if (!paramToCheck.parameterDependsOn) return false

  const { parentFieldID } = paramToCheck.parameterDependsOn

  if (
    // Check it's not dependent on itself
    parentFieldID === paramToCheck.fieldID ||
    dependencyChain.indexOf(parentFieldID) > -1
  )
    return true

  // paramToCheck has a dependency but not one that's already in the chain
  // Recurse through until base cases are met
  const newDependencyChain = [...dependencyChain, parentFieldID]

  const newParamToCheck = getItemByKeyValue(
    allDropdownParams,
    'fieldID',
    parentFieldID,
  )

  if (newParamToCheck === -1) return false

  return checkCircularDependency(
    newParamToCheck,
    allDropdownParams,
    newDependencyChain,
  )
}

export function getMultiCodesToCopy(item: MinCodesByUserResult[]) {
  if (item.length === 0) {
    return { code: '', all: [] }
  }

  const hasShortLink = item.some((i) => i.shortLink !== '')

  const copyHeader = [
    hasShortLink ? 'Short link' : null,
    'Landing page with query parameters',
    ...item[0].params.map(({ paramName }) => paramName).filter((i) => i !== ''),
  ]
    .filter((i) => i !== null)
    .join('\t')

  const code = item.map(({ fullLink, shortLink: sL, params }) => {
    const shortLink = sL && sL !== '' ? sL : ''

    return [
      hasShortLink ? shortLink : null,
      fullLink,
      params
        .map(({ paramValue }) => paramValue)
        .filter((i) => i !== '')
        .join('\t'),
    ]
      .filter((i) => i !== null)
      .join('\t')
  })

  return { code, all: [copyHeader, ...code] }
}

/** lastIndex needs to be reset if used, because it has global flag! https://stackoverflow.com/questions/15276873/is-javascript-test-saving-state-in-the-regex */
export const hrefRegex = /href=(3D)?((\\)?\'http(s)?:\/\/[^{}\s]+(\\)?\'|(\\)?\"http(s)?:\/\/[^{}\s]+(\\)?\")/g
/** Used for textContent object in Salesforce Marketing Cloud emails. Their API is a mess. Using line breaks (\n) here to separate links */
export const httpRegex = /http(s)?:\/\/[^{}\s\\]+/g

export const htmlHasLinks = (html: string, excludeHref = false) => {
  const out = (excludeHref ? httpRegex : hrefRegex).test(html)

  // Reset regex state
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/test
  hrefRegex.lastIndex = 0
  httpRegex.lastIndex = 0

  return out
}

export const buildMinCodesByUserList = (
  minCodes: GetMinCodesByAccountQuery['minCodesByAccount'],
): MinCodesByUserResult[] => {
  const {
    codeID,
    fullLink,
    shortLink,
    createdTime,
    minGenDef,
    codeDef,
  } = minCodes

  const minCodesByUser: MinCodesByUserResult[] = []

  codeID.forEach((cid, cidIndex) => {
    const minCodeByUser: MinCodesByUserResult = {
      codeID: cid,
      createdTime: moment
        .unix(parseInt(createdTime[cidIndex], 10))
        .toISOString(),
      fullLink: fullLink[cidIndex],
      shortLink: shortLink ? shortLink[cidIndex] : '',
      params: [],
    }

    minGenDef.forEach((paramDef, paramDefIndex) => {
      const { paramID, paramName } = paramDef

      minCodeByUser.params.push({
        paramID,
        paramName,
        paramValue: codeDef[cidIndex][paramDefIndex],
      })
    })

    minCodesByUser.push(minCodeByUser)
  })

  return minCodesByUser
}
