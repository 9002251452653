import React from 'react'

import {
  AdobeAnalyticsConnector,
  GoogleAnalyticsConnector,
  MondayConnector,
  OktaConnector,
  SalesforcePardotConnector,
  SalesforceMCConnector,
  EloquaConnectorSettings,
  EloquaConnectorSupport,
} from '.'
import client from '../api/apollo'
import { currentUserDetails } from '../api/apollo/variables'
import {
  getIntegrationsStatus,
  getMondayIDs,
  getMondayOauthLink,
  getOktaDomains,
  getSalesforceOauthLink,
} from '../api/graphql/integrations-client'
import {
  fetchAdobeAuthLink,
  fetchGaAuthLink,
} from '../api/graphql/oauth-client'
import { getAccountDataSource } from '../api/graphql/workspace-client'
import AdobeAnalyticsLogo from '../assets/logos/adobe-analytics.svg'
import EloquaLogo from '../assets/logos/eloqua-logo.png'
import GoogleAdsLogo from '../assets/logos/google-ads-logo.png'
import GoogleAnalyticsLogo from '../assets/logos/google-analytics-icon.svg'
import GoogleCampaignManagerLogo from '../assets/logos/google-campaign-manager-logo.png'
import GoogleDisplayLogo from '../assets/logos/google-display-and-video-360-logo.png'
import LinkedInLogo from '../assets/logos/linkedin-logo.png'
import MetaLogo from '../assets/logos/Meta_logo.png'
import MicrosoftAdsLogo from '../assets/logos/microsoft-ads-logo.png'
import MondayLogo from '../assets/logos/monday_with_black_text.svg'
import OktaLogo from '../assets/logos/icon-okta.png'
import SalesforcePardot from '../assets/logos/sf-logo.png'
import SalesforceMC from '../assets/logos/salesforce-marketing-cloud.png'
import XLogo from '../assets/logos/x-logo.png'
import YahooAdsLogo from '../assets/logos/yahoo-advertising-logo.png'
import Button from '../components/button'
import Link from '../components/link'
import {
  BoxedText,
  Heading,
  OrderedList,
  TickListItem,
} from '../components/typography'
import { brandName } from '../core/constants'

export const integrationCategories = [
  'Analytics',
  'Single Sign-On',
  'Productivity',
  'Email',
  'Ad platform',
  'Other',
] as const
export type IntegrationCategories = typeof integrationCategories[number]

export interface ConnectionDetails {
  connected: boolean
  requiresReconnect?: boolean
  disabled?: boolean
  disabledMsg?: string
}

interface GenericConnectButton {
  buttonCopy?: string
  tooltipCopy?: string
  connectFn: (workspaceIdToConnect?: string) => Promise<void>
  reconnectFn?: (workspaceIdToConnect?: string) => Promise<void>
  alwaysShowButton?: boolean
}

/** Used if the connector requires form fields to connect, e.g. Salesforce Marketing Cloud/Eloqua */
interface FormConnectButton {
  buttonCopy?: string
  formId: string
  /** Tooltip must explain that fields must be filled in on the Settings tab */
  tooltipCopy: string
  alwaysShowButton?: boolean
}

type ConnectButton = GenericConnectButton | FormConnectButton

export function isGenericConnector(
  connectButton: ConnectButton,
): connectButton is GenericConnectButton {
  return Object.prototype.hasOwnProperty.call(connectButton, 'connectFn')
}

export interface TabsContent {
  'Why connect?'?: React.ReactNode
  'How it works'?: React.ReactNode
  'How to connect'?: React.ReactNode
  Settings?: React.ReactNode
  'Coming soon'?: React.ReactNode
  /** Only Uplifter support users should see this tab. It enables us to set up integrations in the UI */
  Support?: React.ReactNode
}

export interface IntegrationData {
  connectorID: string
  connectorExists: boolean
  availableToFreeTier: boolean
  logo: string
  title: string
  tags: IntegrationCategories[]
  shortDescription: string
  tabsContent: TabsContent
  getConnectionDetails: () => Promise<ConnectionDetails | null>
  /** Main modal action - this will usually open an auth window for the platform but can also submit a form from the connector's Settings tab */
  connectButton: ConnectButton
}

// ! Making changes?
// Do any of them need to be applied to the public site version of this content?
// https://uplifter.ai/integrations
// https://github.com/uplifter-limited/public-site-connect-tiles
const integrationsData: IntegrationData[] = [
  {
    connectorID: 'googleAnalytics',
    connectorExists: true,
    availableToFreeTier: true,
    logo: GoogleAnalyticsLogo,
    title: 'Google Analytics',
    tags: ['Analytics'],
    shortDescription:
      'Import your metrics to make granular, custom reports quickly.',
    tabsContent: {
      'Why connect?': (
        <ul style={{ margin: 0 }}>
          <TickListItem>
            Compare performance using onsite engagement and conversion metrics.
          </TickListItem>
          <TickListItem>
            Let marketeers self-serve quick, easy, accurate reports.
          </TickListItem>
          <TickListItem>
            Audit what platforms are creating links with UTMs and their
            taxonomy.
          </TickListItem>
        </ul>
      ),
      'How it works': (
        <OrderedList>
          <li>
            Connect this workspace to a single Google Analytics Property / View
            and grant us read only access. If you need to connect to multiple
            websites / GA views, create an additional workspace for each one.
          </li>
          <li>
            We import standard metric data daily at 1am UTC. This is displayed
            in the{' '}
            <BoxedText>
              <Link href="/track/view-links">Track &gt; View</Link>
            </BoxedText>{' '}
            table and
            <BoxedText>
              <Link href="/report/performance">Report &gt; Performance</Link>
            </BoxedText>{' '}
            report.
          </li>
          <li>
            (Optional) Import custom GA metrics in{' '}
            <BoxedText>
              <Link href="/report/performance">Report &gt; Performance</Link>
            </BoxedText>{' '}
            by clicking in the pink 'Actions' button clicking 'Add/edit
            metrics'.
          </li>
        </OrderedList>
      ),
      'How to connect': (
        <OrderedList>
          <li>
            Make sure you have admin access to your Google Analytics account to
            grant us access to your website data.
          </li>
          <li>
            Click on 'Connect now' button in the bottom right of this modal.
          </li>
          <li>
            Sign in with your Google account to show your accounts, properties
            and views.
          </li>
          <li>
            In '{brandName} wants additional access to your Google Account'
            click 'Continue' button.
          </li>
          <li>Select your Google Analytics account and view.</li>
        </OrderedList>
      ),
      Settings: <GoogleAnalyticsConnector />,
      'Coming soon': (
        <ul style={{ margin: 0 }}>
          <TickListItem>
            Automatically bring in custom metrics from GA4 on setup.
          </TickListItem>
        </ul>
      ),
    },
    getConnectionDetails: async () => {
      const { data } = await client.query({
        query: getAccountDataSource,
      })

      const details = data.currentAccount.dataSource

      return details
        ? {
            connected: ['GA_VIEW', 'GA4_PROP'].indexOf(details.kind) > -1,
            requiresReconnect: !details.connected,
            disabled: details.kind === 'AA_REPORT',
            disabledMsg:
              'Can only connect to one analytics data source at a time.',
          }
        : null
    },
    connectButton: {
      connectFn: async (workspaceIdToConnect?: string) => {
        const { workspaceID } = currentUserDetails()

        if (!workspaceID) throw new Error('Workspace ID not found')

        const { data } = await client.query({
          query: fetchGaAuthLink,
          variables: {
            data: JSON.stringify({
              connectId: workspaceIdToConnect || workspaceID,
              fromId: workspaceID,
            }),
          },
        })

        if (data) {
          const {
            accountSettings: {
              dataSourceQueries: { gaAuthLink },
            },
          } = data

          window.open(gaAuthLink, '_self')
        }
      },
      reconnectFn: async (workspaceIdToConnect?: string) => {
        const { workspaceID } = currentUserDetails()

        if (!workspaceID) throw new Error('Workspace ID not found')

        const { data } = await client.query({
          query: fetchGaAuthLink,
          variables: {
            data: JSON.stringify({
              connectId: workspaceIdToConnect || workspaceID,
              fromId: workspaceID,
              reconnect: true,
            }),
          },
        })

        if (data) {
          const {
            accountSettings: {
              dataSourceQueries: { gaAuthLink },
            },
          } = data

          window.open(gaAuthLink, '_self')
        }
      },
    },
  },
  {
    connectorID: 'adobeAnalytics',
    connectorExists: true,
    availableToFreeTier: true,
    logo: AdobeAnalyticsLogo,
    title: 'Adobe Analytics',
    tags: ['Analytics'],
    shortDescription:
      'Auto upload your classifications, import your metrics to make granular, custom reports quickly.',
    tabsContent: {
      'Why connect?': (
        <ul style={{ margin: 0 }}>
          <TickListItem>
            Compare performance using onsite engagement and conversion metrics.
          </TickListItem>
          <TickListItem>
            Let marketeers self-serve quick, easy, accurate reports.
          </TickListItem>
          <TickListItem>
            Automate uploading your classification csv with translation tables
            without having to login to Adobe Analytics.
          </TickListItem>
        </ul>
      ),
      'How it works': null,
      'How to connect': null,
      Settings: <AdobeAnalyticsConnector />,
    },
    getConnectionDetails: async () => {
      const { data } = await client.query({
        query: getAccountDataSource,
      })

      const details = data.currentAccount.dataSource

      return details
        ? {
            connected: details.kind === 'AA_REPORT',
            requiresReconnect: !details.connected,
            disabled: ['GA_VIEW', 'GA4_PROP'].indexOf(details.kind) > -1,
            disabledMsg:
              'Can only connect to one analytics data source at a time.',
          }
        : null
    },
    connectButton: {
      connectFn: async (workspaceIdToConnect?: string) => {
        const { workspaceID } = currentUserDetails()

        if (!workspaceID) throw new Error('Workspace ID not found')

        const { data } = await client.query({
          query: fetchAdobeAuthLink,
          variables: {
            data: JSON.stringify({
              connectId: workspaceIdToConnect || workspaceID,
              fromId: workspaceID,
            }),
          },
        })

        if (data) {
          const {
            accountSettings: {
              dataSourceQueries: { adobeAuthLink },
            },
          } = data

          window.open(adobeAuthLink, '_self')
        }
      },
      reconnectFn: async (workspaceIdToConnect?: string) => {
        const { workspaceID } = currentUserDetails()

        if (!workspaceID) throw new Error('Workspace ID not found')

        const { data } = await client.query({
          query: fetchAdobeAuthLink,
          variables: {
            data: JSON.stringify({
              connectId: workspaceIdToConnect || workspaceID,
              fromId: workspaceID,
              reconnect: true,
            }),
          },
        })

        if (data) {
          const {
            accountSettings: {
              dataSourceQueries: { adobeAuthLink },
            },
          } = data

          window.open(adobeAuthLink, '_self')
        }
      },
    },
  },
  {
    connectorID: 'okta',
    connectorExists: true,
    availableToFreeTier: false,
    logo: OktaLogo,
    title: 'Okta',
    tags: ['Single Sign-On'],
    shortDescription:
      'Let everyone sign-in securely and safely using Okta Single-Sign-On.',
    tabsContent: {
      'Why connect?': (
        <ul style={{ margin: 0 }}>
          <TickListItem>
            Save time logging in and remembering passwords.
          </TickListItem>
          <TickListItem>
            Increase login security to match your organisation's needs.
          </TickListItem>
          <TickListItem>Manage users quickly within Okta.</TickListItem>
        </ul>
      ),
      'How to connect': (
        <>
          <p>
            Full details on how to connect can be found{' '}
            <Link href="https://support.uplifter.ai/hc/en-us/articles/10987066252061-Using-Okta-for-Single-Sign-On-SSO-">
              here
            </Link>
            .
          </p>
        </>
      ),
      Settings: <OktaConnector />,
      'Coming soon': (
        <ul style={{ margin: 0 }}>
          <TickListItem>Add and remove users via Okta.</TickListItem>
          <TickListItem>
            Manage user permissions to workspaces via Okta.
          </TickListItem>
        </ul>
      ),
    },
    getConnectionDetails: async () => {
      const { data } = await client.query({
        query: getOktaDomains,
      })

      // Okta does not connect via Oauth
      // The 'Connect' button should never show here
      return {
        connected:
          data.currentCompany.oktaDomainList.oktaDomains.length > 0 || false,
      }
    },
    connectButton: {
      buttonCopy: 'Add a new email domain',
      formId: 'okta-connect-form',
      tooltipCopy:
        "We need the details of the Okta tenant you want to connect to - add these on the 'Settings' tab. More details on the 'How to connect' tab.",
      alwaysShowButton: true,
    },
  },
  {
    connectorID: 'monday',
    connectorExists: true,
    availableToFreeTier: false,
    logo: MondayLogo,
    title: 'monday.com',
    tags: ['Productivity'],
    shortDescription: 'Manage requests for new dropdown options in monday.com.',
    tabsContent: {
      'Why connect?': (
        <ul style={{ margin: 0 }}>
          <TickListItem>
            Automate and speed up workflows in Monday.com.
          </TickListItem>
          <TickListItem>
            Let Admins approve or reject new dropdowns quickly.
          </TickListItem>
        </ul>
      ),
      'How to connect': (
        <>
          <Heading type={3} align="left">
            Enabling this integration means:
          </Heading>
          <ul style={{ marginBottom: 24 }}>
            <li>
              A new board will be created in one of your monday.com workspaces
              (your choice).
            </li>
            <li>
              When regular users make new{' '}
              <Link href="https://support.uplifter.ai/hc/en-us/articles/4413912505233-Requesting-and-adding-new-dropdown-parameters">
                campaign parameter requests
              </Link>
              , they will be added to the new board.
            </li>
            <li>
              Admin users can approve or reject the requests directly from
              monday.com.
            </li>
          </ul>
          <Heading type={3} align="left">
            To manage update requests in monday.com:
          </Heading>
          <OrderedList>
            <li>
              <p>Install the app on your monday.com account:</p>
              <Link
                href="https://auth.monday.com/oauth2/authorize?client_id=8ef1af79f1a8de469dd6717fac05e1c9&amp;response_type=install"
                target="_blank"
              >
                <img
                  alt="Add to monday.com"
                  height="42"
                  src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                />
              </Link>
            </li>
            <li>
              Click 'Connect' to authorize {brandName} to post requests to
              monday.com:
            </li>
          </OrderedList>
        </>
      ),
      Settings: <MondayConnector />,
    },
    getConnectionDetails: async () => {
      const { data } = await client.query({
        query: getIntegrationsStatus,
      })

      const connectionStatus = data.currentCompany.mondayIntegrationStatus

      // Fetch full integration info
      if (connectionStatus === 'active') {
        await client.query({ query: getMondayIDs })
      }

      return {
        connected: connectionStatus === 'active',
      }
    },
    connectButton: {
      connectFn: async () => {
        const { workspaceID } = currentUserDetails()

        if (!workspaceID) throw new Error('Workspace ID not found')

        const { data } = await client.query({
          query: getMondayOauthLink,
          variables: {
            state: workspaceID,
          },
        })

        if (data) {
          const {
            accountSettings: {
              mondayQueries: {
                genMondayoauth2Link: { oauth2Link },
              },
            },
          } = data

          window.open(oauth2Link, '_self')
        }
      },
    },
  },
  {
    connectorID: 'salesforceMarketingCloud',
    connectorExists: true,
    availableToFreeTier: false,
    logo: SalesforceMC,
    title: 'Salesforce Marketing Cloud',
    tags: ['Email'],
    shortDescription: `Automate finding and replacing email links with ${brandName} links.`,
    tabsContent: {
      'Why connect?': (
        <ul style={{ margin: 0 }}>
          <TickListItem>
            Find and replace links in email templates to apply your link
            taxonomy.
          </TickListItem>
          <TickListItem>
            Replace basic links with short links to count short link
            clickthroughs.
          </TickListItem>
          <TickListItem>Monitor performance of all your emails.</TickListItem>
        </ul>
      ),
      'How it works': (
        <OrderedList>
          <li>Connect to each of your Salesforce Marketing cloud instances.</li>
          <li>
            We retrieve the list of email templates available for each Marketing
            cloud instance.
          </li>
          <li>
            In{' '}
            <BoxedText>
              <Link href="/track/create-links">Track &gt; Create</Link>
            </BoxedText>{' '}
            'Email' tab select your Marketing Cloud instance, email and
            parameters.
          </li>
          <li>
            Click 'Find links' to find links in your email and deselect any you
            don't wish to edit.
          </li>
          <li>
            Click 'Replace links' to replace the selected links with {brandName}
            links in Salesforce Marketing Cloud.
          </li>
        </OrderedList>
      ),
      'How to connect': (
        <>
          <p>
            For each Salesforce Marketing Cloud Instance, complete the steps
            below.
          </p>
          <p>
            {brandName} requires a separate connection to each Marketing Cloud
            Instance. You can find the Marketing Cloud Instance Number{' '}
            <strong>(mid)</strong>{' '}
            <Link href="https://salesforce.vidyard.com/watch/WEjEkkwn9onVyPYKyM6h5t">
              here
            </Link>
            .
          </p>
          <OrderedList>
            <li>
              Make sure you have admin access to your Salesforce Marketing Cloud
              account to grant {brandName} access.
            </li>
            <li>
              On the 'Settings' tab, add 'Business unit name' and the{' '}
              <strong>mid</strong> for the Marketing Cloud instance you want to
              connect to, then click{' '}
              <Button
                demoOnly
                style={{ padding: '2px 4px', fontSize: '1.1rem' }}
              >
                Add a new business unit
              </Button>{' '}
              to authorize with Salesforce Marketing Cloud.
            </li>
            <li>
              When you are taken back to {brandName}, you will see a
              confirmation message saying your account has been connected
              successfully.
            </li>
            <li>
              You will now be able to edit your Salesforce Marketing Cloud email
              links via {brandName} on the{' '}
              <BoxedText>
                <Link href="/track/create-links">Track &gt; Create</Link>
              </BoxedText>{' '}
              'Email' tab.
            </li>
          </OrderedList>
        </>
      ),
      Settings: <SalesforceMCConnector />,
    },
    getConnectionDetails: async () => {
      const { data } = await client.query({
        query: getIntegrationsStatus,
      })

      // Values: 'active' | 'inactive'
      const connectionStatus = data.currentCompany.salesforceMcIntegrationStatus

      return {
        connected: connectionStatus === 'active',
        // requiresReconnect: ???
      }
    },
    connectButton: {
      formId: 'salesforce-mc-connect-form',
      buttonCopy: 'Add a new business unit',
      tooltipCopy:
        'Requires a Salesforce Marketing Cloud business unit name and ID - please add these on the Settings tab.',
      alwaysShowButton: true,
    },
  },
  {
    connectorID: 'salesforcePardot',
    connectorExists: true,
    availableToFreeTier: false,
    logo: SalesforcePardot,
    title: 'Salesforce Pardot',
    tags: ['Email'],
    shortDescription: `Automate finding and replacing email links with ${brandName} links.`,
    tabsContent: {
      'Why connect?': (
        <ul style={{ margin: 0 }}>
          <TickListItem>
            Find and replace links in email templates to apply your link
            taxonomy.
          </TickListItem>
          <TickListItem>
            Replace basic links with short links to count short link
            clickthroughs.
          </TickListItem>
          <TickListItem>Monitor performance of all your emails.</TickListItem>
        </ul>
      ),
      'How it works': (
        <OrderedList>
          <li>
            Connect this company to a Salesforce Pardot account and grant us
            edit access. You can tailor settings for each individual workspace.
          </li>
          <li>
            We retrieve a list of your unsent list template emails and published
            email templates.
          </li>
          <li>
            In{' '}
            <BoxedText>
              <Link href="/track/create-links">Track &gt; Create</Link>
            </BoxedText>{' '}
            'Email' tab you can select your business unit, email, and
            parameters.
          </li>
          <li>
            Click 'Find links' to find links in your email and deselect any you
            don't wish to edit.
          </li>
          <li>
            Click 'Replace links' to replace the selected links with {brandName}
            links in Salesforce Pardot.
          </li>
        </OrderedList>
      ),
      'How to connect': (
        <OrderedList>
          <li>
            Make sure you have admin access to your Salesforce Pardot account to
            grant us access.
          </li>
          <li>
            Click 'Connect' below and login with your Salesforce Pardot
            credentials.
          </li>
          <li>
            When you are taken back to {brandName}, select the business units
            you would like to connect and click 'Connect'.
          </li>
          <li>
            You will now be able to edit your Salesforce Pardot email links via
            {brandName} on the{' '}
            <BoxedText>
              <Link href="/track/create-links">Track &gt; Create</Link>
            </BoxedText>{' '}
            'Email' tab.
          </li>
        </OrderedList>
      ),
      Settings: <SalesforcePardotConnector />,
    },
    getConnectionDetails: async () => {
      const { data } = await client.query({
        query: getIntegrationsStatus,
      })

      const connectionStatus = data.currentCompany.pardotIntegrationStatus

      return {
        connected:
          connectionStatus === 'active' ||
          connectionStatus === 'needs_reconnect',
        requiresReconnect: connectionStatus === 'needs_reconnect',
      }
    },
    connectButton: {
      connectFn: async () => {
        const { workspaceID } = currentUserDetails()

        if (!workspaceID) throw new Error('Workspace ID not found')

        const { data: oauthLinkData } = await client.query({
          query: getSalesforceOauthLink,
          variables: {
            state: workspaceID,
          },
        })

        if (!oauthLinkData) return

        const {
          accountSettings: {
            dataSourceQueries: {
              genSalesforceOauthLink: { oauth2Link },
            },
          },
        } = oauthLinkData

        window.open(oauth2Link, '_self')
      },
      reconnectFn: async () => {
        const { workspaceID } = currentUserDetails()

        if (!workspaceID) throw new Error('Workspace ID not found')

        const { data: oauthLinkData } = await client.query({
          query: getSalesforceOauthLink,
          variables: {
            state: workspaceID,
          },
        })

        if (!oauthLinkData) return

        const {
          accountSettings: {
            dataSourceQueries: {
              genSalesforceOauthLink: { oauth2Link },
            },
          },
        } = oauthLinkData

        window.open(oauth2Link, '_self')
      },
    },
  },
  {
    connectorID: 'eloqua',
    connectorExists: true,
    availableToFreeTier: false,
    logo: EloquaLogo,
    title: 'Oracle Eloqua',
    tags: ['Email', 'Other'],
    shortDescription: `Automate finding and replacing email links with ${brandName} links.`,
    tabsContent: {
      'Why connect?': (
        <ul style={{ margin: 0 }}>
          <TickListItem>
            Find and replace links in email templates to apply your link
            taxonomy.
          </TickListItem>
          <TickListItem>
            Replace basic links with short links to count short link
            clickthroughs.
          </TickListItem>
          <TickListItem>
            Monitor performance of all your emails in {brandName}.
          </TickListItem>
        </ul>
      ),
      'How to connect': (
        <OrderedList>
          <li>
            Add integration@uplifter.ai as an Eloqua user with edit access to
            your email templates.
          </li>
          <li>
            Click the 'Connect' button below to open the 'Request a new
            connector' modal.
          </li>
          <li>
            Click the 'Send request' button to notify our team you are ready to
            connect.
          </li>
          <li>We will email you when the integration is complete.</li>
        </OrderedList>
      ),
      Settings: <EloquaConnectorSettings />,
      'Coming soon': (
        <ul style={{ margin: 0 }}>
          <TickListItem>
            Connect securely without needing to add an {brandName} user using
            OAuth 2.0.
          </TickListItem>
        </ul>
      ),
      Support: <EloquaConnectorSupport />,
    },
    getConnectionDetails: async () => {
      const { data } = await client.query({
        query: getIntegrationsStatus,
      })

      const connectionStatus = data.currentCompany.eloquaIntegrationStatus

      return {
        connected: connectionStatus === 'active',
      }
    },
    connectButton: {
      // Eloqua uses basic auth, not oauth. Requires user input
      // This is a special case where only support users can setup the connection on the Settings tab
      connectFn: async () => {
        window.location.replace('/connect?connectorRequest=eloqua')
      },
      tooltipCopy:
        'This connector requires additional setup - click here to submit a report request.',
      alwaysShowButton: true,
    },
  },
  {
    connectorID: 'googleAds',
    connectorExists: false,
    availableToFreeTier: false,
    logo: GoogleAdsLogo,
    title: 'Google Ads',
    tags: ['Ad platform'],
    shortDescription:
      'Import your Ad cost, impression and click metrics to make reports quickly.',
    tabsContent: {},
    getConnectionDetails: async () => {
      return null
    },
    connectButton: {
      connectFn: async () => new Promise(() => null),
      reconnectFn: async () => new Promise(() => null),
    },
  },
  {
    connectorID: 'metaAds',
    connectorExists: false,
    availableToFreeTier: false,
    logo: MetaLogo,
    title: 'Ads Manager',
    tags: ['Ad platform'],
    shortDescription:
      'Import your Ad cost, impression and click metrics to make reports quickly.',
    tabsContent: {},
    getConnectionDetails: async () => {
      return null
    },
    connectButton: {
      connectFn: async () => new Promise(() => null),
      reconnectFn: async () => new Promise(() => null),
    },
  },
  {
    connectorID: 'microsoftAds',
    connectorExists: false,
    availableToFreeTier: false,
    logo: MicrosoftAdsLogo,
    title: 'Microsoft Ads',
    tags: ['Ad platform'],
    shortDescription:
      'Import your Ad cost, impression and click metrics to make reports quickly.',
    tabsContent: {},
    getConnectionDetails: async () => {
      return null
    },
    connectButton: {
      connectFn: async () => new Promise(() => null),
      reconnectFn: async () => new Promise(() => null),
    },
  },
  {
    connectorID: 'googleCampaignManager',
    connectorExists: false,
    availableToFreeTier: false,
    logo: GoogleCampaignManagerLogo,
    title: 'Google Campaign Manager',
    tags: ['Ad platform'],
    shortDescription:
      'Import your Ad cost, impression and click metrics to make reports quickly.',
    tabsContent: {},
    getConnectionDetails: async () => {
      return null
    },
    connectButton: {
      connectFn: async () => new Promise(() => null),
      reconnectFn: async () => new Promise(() => null),
    },
  },
  {
    connectorID: 'googleDisplayVideo360',
    connectorExists: false,
    availableToFreeTier: false,
    logo: GoogleDisplayLogo,
    title: 'Google Display & Video 360',
    tags: ['Ad platform'],
    shortDescription:
      'Import your Ad cost, impression and click metrics to make reports quickly.',
    tabsContent: {},
    getConnectionDetails: async () => {
      return null
    },
    connectButton: {
      connectFn: async () => new Promise(() => null),
      reconnectFn: async () => new Promise(() => null),
    },
  },
  {
    connectorID: 'linkedInAds',
    connectorExists: false,
    availableToFreeTier: false,
    logo: LinkedInLogo,
    title: 'Linkedin Ads',
    tags: ['Ad platform'],
    shortDescription:
      'Import your Ad cost, impression and click metrics to make reports quickly.',
    tabsContent: {},
    getConnectionDetails: async () => {
      return null
    },
    connectButton: {
      connectFn: async () => new Promise(() => null),
      reconnectFn: async () => new Promise(() => null),
    },
  },
  {
    connectorID: 'yahooAds',
    connectorExists: false,
    availableToFreeTier: false,
    logo: YahooAdsLogo,
    title: 'Yahoo Advertising',
    tags: ['Ad platform'],
    shortDescription:
      'Import your Ad cost, impression and click metrics to make reports quickly.',
    tabsContent: {},
    getConnectionDetails: async () => {
      return null
    },
    connectButton: {
      connectFn: async () => new Promise(() => null),
      reconnectFn: async () => new Promise(() => null),
    },
  },
  {
    connectorID: 'twitterXAds',
    connectorExists: false,
    availableToFreeTier: false,
    logo: XLogo,
    title: 'X Ads - Twitter Business',
    tags: ['Ad platform'],
    shortDescription:
      'Import your Ad cost, impression and click metrics to make reports quickly.',
    tabsContent: {},
    getConnectionDetails: async () => null,
    connectButton: {
      connectFn: async () => new Promise(() => null),
      reconnectFn: async () => new Promise(() => null),
    },
  },
]

export default integrationsData
