import { gql as apolloGql } from '@apollo/client'
import { customAlphabet } from 'nanoid'

import { forceCacheRefresh } from './onboarding-client'
import client from '../apollo'
import { nanoIdCharacters } from '../constants'
import {
  User,
  UserAccountProfile,
  UserAccountSettings,
} from '../../__gql-types__/graphql'

/**
 * Aliases can't be used in type gen or hooks.
 * Must mutate directly on the client
 */
export const addUsersToAccountsBulk = async ({
  workspaceID,
  users,
  accountIDs,
  permissionLevelList,
}: {
  workspaceID?: string
  users: string[]
  accountIDs: string[]
  permissionLevelList: PermissionLevel[]
}) => {
  let addMutation = ``
  const alias = customAlphabet(nanoIdCharacters, 10)

  users.forEach((user) => {
    addMutation += `
      ${alias()}:addUserToAccountBulk(
        accountIDList: ${JSON.stringify(accountIDs)},
        email: "${user}",
        permissionLevelList: ${JSON.stringify(permissionLevelList)},
      ) {
          userID
          email
          inviteOpen
          userAccountProfiles {
            accountID
            accountName
            created
            expired
            inviteOpen
            lastLogin
            userEmail
            userID
            userPermission
          }
      }
    `
  })

  return client.mutate({
    mutation: apolloGql`
        mutation AddUsersToAccountsBulk {
          userAccountSettings {
            ${addMutation}
          }
        }
      `,
    // Need to update the existing user list in cache
    update(cache, { data }) {
      if (!workspaceID || !data) return

      const cacheItemID = `Account:{"accountID":"${workspaceID}"}`

      // Get full Account object from cache
      const cachedData: {
        codeID: string[]
      } | null = cache.readFragment({
        id: cacheItemID,
        fragment: apolloGql`
          fragment UserAccountProfiles on Account {
            userAccountProfiles
          }
        `,
      })

      const { userAccountSettings } = data as {
        userAccountSettings: UserAccountSettings
      }

      const addedProfiles: UserAccountProfile[] = []

      Object.values(userAccountSettings).forEach((value) => {
        if (value === 'UserAccountSettings') return

        const currentProfile = (value as User).userAccountProfiles.find(
          (profile) => profile.accountID === workspaceID,
        )

        if (currentProfile) {
          addedProfiles.push(currentProfile)
        }
      })

      if (cachedData) {
        cache.modify({
          id: cacheItemID,
          fields: {
            userAccountProfiles(existingUserAccountProfiles) {
              return [...existingUserAccountProfiles, ...addedProfiles]
            },
          },
        })
      }
    },
  })
}

/**
 * For updating onboarding status, mutations are the same but field names differ slightly
 */
export const updateCachedOnboardingSectionsProgress = async (
  accountID: string,
  type: 'user' | 'account',
  data: {
    onboardingSectionID: string
    sectionCompleted: boolean
    sectionSkipped: boolean
    sectionSkippedBefore: boolean
  }[],
) => {
  const typeName =
    type === 'user' ? 'userOnboardingProgress' : 'accountOnboardingProgress'

  client.writeQuery({
    query: apolloGql`
        mutation UpdateOnboardingSections {
          ${typeName} {
            ${`${typeName}List`} {
              onboardingSectionID
              sectionCompleted
              sectionSkipped
              sectionSkippedBefore
            }
          }
        }
      `,
    data: {
      [typeName]: {
        __typename: type === 'user' ? 'UserOnboarding' : 'AccountOnboarding',
        [`${typeName}List`]: data,
      },
    },
  })

  await forceCacheRefresh(accountID, type === 'account')
}
