import React, { useCallback } from 'react'
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'
import moment from 'moment'
import _ from 'lodash'
import numeral from 'numeraljs'

import { PermissionSelector } from './add-by-email'
import Button from './button'
import DeleteButtonWithConfirmation from './delete-button-with-confirmation'
import HeaderPanel from './header-panel'
import { SearchInput } from './input'
import { Preloader } from './loader'
import NoDataMessage from './no-data-message'
import OrderArrow from './order-arrow'
import Pagination from './pagination'
import ResendInvitationButton from './resend-invitation-button'
import { Table, Body, Head, HeaderCell, Cell, BodyItem } from './row'
import { currentUserDetails } from '../api/apollo/variables'
import {
  getAccountUsers,
  removeExistingUser,
  revokeUserInvite,
  updateUserPermissions,
} from '../api/graphql/workspace-client'
import { getCompanyDetails } from '../api/graphql/company-client'
import { dateFormatShort } from '../core/constants'
import { getCsvString, downloadUsersCsv } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import useTableSortFilter, {
  SearchFn,
  SortFn,
} from '../hooks/useTableSortFilter'
import styles from '../styles/current-team-mates.module.scss'
import {
  GetAccountUsersQuery,
  UserAccountProfile,
} from '../__gql-types__/graphql'

interface TableRowProps {
  email?: string
  lastLogin?: string
  permission: string
  userID: string
  index: number
  onChange: (userID: string, permissionLevel: PermissionLevel) => Promise<void>
  onDelete: (email: string) => Promise<void>
  currentUserID?: string
}

export function TableRow({
  email = '',
  permission,
  lastLogin = '',
  userID,
  index,
  onChange,
  onDelete,
  currentUserID,
}: TableRowProps) {
  return (
    <BodyItem>
      <Cell>
        <span className={styles.emailSummary}>
          <h3>{email}</h3>
          <p>{permission}</p>
        </span>
      </Cell>
      <Cell width={140}>
        <p className={styles.date}>
          {(lastLogin &&
            lastLogin !== '' &&
            moment(lastLogin).format(dateFormatShort)) ||
            'Unknown'}
        </p>
      </Cell>
      <Cell width={156}>
        {currentUserID !== userID &&
          permission !== 'support' &&
          permission !== 'billing' && (
            <PermissionSelector
              className={styles.permissionSelector}
              permission={permission}
              onChange={async (permissionLevel) => {
                await onChange(userID, permissionLevel)
              }}
            />
          )}
      </Cell>
      <Cell width={65}>
        {currentUserID !== userID && permission !== 'support' && (
          <DeleteButtonWithConfirmation
            odd={!(index % 2)}
            onClick={async () => {
              await onDelete(userID)
            }}
          >
            <p>
              Remove <strong>{email}</strong>?
            </p>
          </DeleteButtonWithConfirmation>
        )}
      </Cell>
    </BodyItem>
  )
}

interface TableRowPendingProps {
  email: string
  userID: string
  sentTime?: string
  expired?: boolean
  permissionLevel: PermissionLevel
  index: number
  onDelete: (email: string) => Promise<void>
  accountID: string
}

export function TableRowPending({
  accountID,
  email,
  userID,
  expired,
  sentTime,
  permissionLevel,
  index,
  onDelete,
}: TableRowPendingProps) {
  return (
    <BodyItem>
      <Cell>
        <span className={styles.emailSummary}>
          <h3>{email}</h3>
          <p>{expired ? 'invitation has expired' : 'Pending invitation'}</p>
        </span>
      </Cell>
      <Cell width={140}>
        <p className={styles.date}>
          {(sentTime && moment(sentTime).format(dateFormatShort)) || 'Unknown'}
        </p>
      </Cell>
      <Cell width={156}>
        <ResendInvitationButton
          accountID={accountID}
          email={email}
          permissionLevel={permissionLevel}
        />
      </Cell>
      <Cell width={65}>
        <DeleteButtonWithConfirmation
          odd={!(index % 2)}
          onClick={async () => {
            await onDelete(userID)
          }}
        >
          <p>
            Revoke invitation for <strong>{email}</strong>?
          </p>
        </DeleteButtonWithConfirmation>
      </Cell>
    </BodyItem>
  )
}

interface UsersDownloadInteraction {
  numberOfUsers: number
  numberOfAdmin: number
  numberOfRegular: number
  numberPending: number
  numberExpired: number
}

interface CurrentTeamMatesProps {
  loading: boolean
  currentUsers: GetAccountUsersQuery['account']['userAccountProfiles']
  pendingUsers: GetAccountUsersQuery['account']['userAccountProfiles']
}

export default function CurrentTeamMates({
  loading,
  currentUsers,
  pendingUsers,
}: CurrentTeamMatesProps) {
  const { userID: userId, workspaceID, workspaceName } = useReactiveVar(
    currentUserDetails,
  )

  const [getUsersByAccountID] = useLazyQuery(getAccountUsers)

  const [updatePermissions] = useMutation(updateUserPermissions)
  const [removeUser] = useMutation(removeExistingUser)
  const [revokeInvite] = useMutation(revokeUserInvite)

  const logAction = useLogAction()

  const onChange = async (userID: string, permissionLevel: PermissionLevel) => {
    if (!userID || !permissionLevel || !workspaceID) {
      return
    }

    // Only allow permission to be changed to admin or regular
    if (permissionLevel !== 'admin' && permissionLevel !== 'regular') return

    await updatePermissions({
      variables: {
        accountID: workspaceID,
        permissionLevel,
        userID,
      },
      refetchQueries: [getAccountUsers],
    })

    logAction({
      variables: {
        action: 'update-user-permissions',
        websiteSection: 'settings',
        pagePath: '/settings',
        functionName: 'updatePermissions',
        extra: JSON.stringify({
          updatedUser: userID,
          updatedTo: permissionLevel,
          updatedBy: userId,
        }),
      },
    })
  }

  const onDelete = async (userID: string) => {
    if (!userID || !workspaceID) {
      return
    }

    await removeUser({
      variables: {
        accountID: workspaceID,
        userID,
      },
      refetchQueries: [getCompanyDetails],
    })

    logAction({
      variables: {
        action: 'delete-user',
        websiteSection: 'settings',
        pagePath: '/settings',
        functionName: 'deleteUser',
        extra: JSON.stringify({
          deletedUser: userID,
          deletedBy: userId,
        }),
      },
    })
  }

  const revokeInvitation = async (userID: string) => {
    await revokeInvite({
      variables: {
        userID,
      },
      refetchQueries: [getCompanyDetails],
    })

    await getUsersByAccountID({
      variables: { accountID: workspaceID },
      fetchPolicy: 'network-only',
    })

    logAction({
      variables: {
        action: 'revoke-user-invitation',
        websiteSection: 'settings',
        pagePath: '/settings',
        functionName: 'revokeInvitation',
        extra: JSON.stringify({ revokedUserID: userID, revokedBy: userId }),
      },
    })
  }

  const permissionSort: SortFn<any> = useCallback(
    (user) =>
      !user.inviteOpen && user.lastLogin !== ''
        ? user.userPermission
        : user.userPermission,
    [workspaceID],
  )

  const lastLoginSort: SortFn<any> = useCallback(
    (user) => {
      if (!user.inviteOpen && user.lastLogin !== '' && user.lastLogin) {
        return new Date(user.lastLogin)
      }
      if (user.inviteOpen) {
        return new Date(user.created)
      }
      return ''
    },
    [workspaceID],
  )

  const permissionSearch: SearchFn<any[]> = useCallback(
    (data, search = '') => {
      const searchPattern = search
        .replace(/[^a-zA-Z0-9]/g, '')
        .split('')
        .join('.*')

      return data.concat().filter((user) => {
        return (
          (!user.inviteOpen &&
            user.lastLogin !== '' &&
            user.userPermission
              .toLowerCase()
              .match(new RegExp(searchPattern, 'i')) !== null) ||
          (user.inviteOpen &&
            user.userPermission
              .toLowerCase()
              .match(new RegExp(searchPattern, 'i')) !== null)
        )
      })
    },
    [workspaceID],
  )

  const lastLoginSearch: SearchFn<any[]> = useCallback(
    (data, search = '') => {
      const searchPattern = search
        .replace(/[^a-zA-Z0-9 -\\/]/g, '')
        .split(' ')
        .join('.*')

      return data.concat().filter((user) => {
        let date = ''
        if (user.inviteOpen) {
          date = moment(user.created).format(
            'dddd Do MMMM YYYY, DD-MM-YYYY, DD/MM/YYYY',
          )
        } else {
          date = user.lastLogin
            ? moment(user.lastLogin).format(
                'dddd Do MMMM YYYY, DD-MM-YYYY, DD/MM/YYYY',
              )
            : ''
        }

        return date.toLowerCase().match(new RegExp(searchPattern, 'i')) !== null
      })
    },
    [workspaceID],
  )

  const {
    initialSort,
    setInitialSort,
    orderAsc,
    sortKey,
    setSortOrder,
    pages,
    activePage,
    setActivePage,
    rowsPerPage,
    setRowsPerPage,
    setSelectedSearchType,
    setSearchTerm,
    count,
    orderedList: orderedPermissionList,
    total,
  } = useTableSortFilter({
    startingRowsPerPage: 10,
    inputList: currentUsers,
    startingSortKey: 'lastLogin',
    customSearches: {
      lastLogin: lastLoginSearch,
      userPermission: permissionSearch,
    },
    useFuzzySearch: false,
    customSorts: {
      userPermission: permissionSort,
      lastLogin: lastLoginSort,
    },
    initialSortAsc: false,
    secondaryInputList: pendingUsers,
  })

  const transformUserList = (
    existing: Partial<UserAccountProfile>[],
    pending?: Partial<UserAccountProfile>[],
  ): TransformedUser[] => {
    let fullList = _.cloneDeep(existing)

    if (pending) {
      fullList = fullList.concat(JSON.parse(JSON.stringify(pending)))
    }

    const transformedList = fullList.map((user) => {
      let lastLogin: string | Date = ''
      let regDate: string | Date = ''

      if (!user.inviteOpen && user.lastLogin !== '') {
        if (user.lastLogin) {
          lastLogin = moment(new Date(user.lastLogin)).format(
            'YYYY-MM-DD HH:MM:SS',
          )
        }
        if (user.lastLogin) {
          regDate = moment(new Date(user.lastLogin)).format(
            'YYYY-MM-DD HH:MM:SS',
          )
        }
      }

      return {
        Workspace: workspaceName,
        Email: user.userEmail || '',
        'Workspace permission': user.userPermission as PermissionLevel,
        'Last login date': lastLogin,
        'Date registered': regDate,
        'Pending registration': user.inviteOpen || false,
        'Date registration sent':
          !user.inviteOpen && user.lastLogin !== ''
            ? ''
            : moment(new Date(user.created as string)).format(
                'YYYY-MM-DD HH:MM:SS',
              ),
        'Registration link expired':
          !user.inviteOpen && user.lastLogin === '' ? !!user.created : false,
      }
    })

    return transformedList.filter(
      (user) => user['Workspace permission'] !== 'support',
    )
  }

  const getDetails = (
    userList: TransformedUser[],
  ): UsersDownloadInteraction => {
    return {
      numberOfUsers: userList.length,
      numberOfAdmin: userList.filter(
        (user) => user['Workspace permission'] === 'admin',
      ).length,
      numberOfRegular: userList.filter(
        (user) => user['Workspace permission'] === 'regular',
      ).length,
      numberPending: userList.filter(
        (user) =>
          user['Pending registration'] && !user['Registration link expired'],
      ).length,
      numberExpired: userList.filter(
        (user) => !!user['Registration link expired'],
      ).length,
    }
  }

  return (
    <div className={styles.subsection}>
      <h3>Current users</h3>
      <HeaderPanel className={styles.tableHeader}>
        <div className={styles.searchRowInner}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <SearchInput
              onChange={(value) => {
                setSearchTerm(value || '')
                setActivePage(1)
              }}
              delay={50}
              loading={loading}
              loadingLabel="Counting users"
              searchTypeList={[
                { name: 'All', value: 'all' },
                {
                  name: 'User',
                  value: 'userEmail',
                },
                {
                  name: 'Last used',
                  value: 'lastUsed',
                },
                {
                  name: 'Role',
                  value: 'userPermission',
                },
              ]}
              onChangeSearchType={(type: string) => {
                setSelectedSearchType(type)
              }}
            >
              {count !== total ? `${numeral(count).format('0,0')}/` : ''}
              {numeral(total).format('0,0')} user
              {total === 1 ? '' : 's'}
            </SearchInput>
          </div>
          {[...currentUsers, ...pendingUsers].length > 0 && (
            <Button
              variant="secondary"
              onPress={async () => {
                if (currentUsers.length > 0 || pendingUsers.length > 0) {
                  const userList = transformUserList(currentUsers, pendingUsers)

                  const csv = getCsvString(userList)
                  await downloadUsersCsv(csv, workspaceName)

                  const interactionDetails = JSON.stringify(
                    getDetails(userList),
                  )

                  logAction({
                    variables: {
                      action: 'download-user-list-csv',
                      extra: interactionDetails,
                      websiteSection: 'settings',
                      pagePath: '/settings',
                      functionName: 'downloadUserList',
                    },
                  })
                }
              }}
            >
              Download users
            </Button>
          )}
        </div>
      </HeaderPanel>
      <Table className={styles.table}>
        <Head>
          <HeaderCell className={styles.headerCellWithButton}>
            <Button
              variant="text"
              color="grey"
              className={styles.headerButton}
              onPress={() => {
                if (initialSort) {
                  setInitialSort(false)
                }
                setSortOrder('userEmail')
              }}
            >
              User
              <OrderArrow
                currentKey="userEmail"
                className={styles.orderArrow}
                sortKey={sortKey}
                orderAsc={orderAsc}
              />
            </Button>
          </HeaderCell>
          <HeaderCell width={140}>
            <Button
              variant="text"
              color="grey"
              className={styles.headerButton}
              onPress={() => {
                if (initialSort) {
                  setInitialSort(false)
                }
                setSortOrder('lastLogin')
              }}
            >
              Last login
              <OrderArrow
                currentKey="lastLogin"
                className={styles.orderArrow}
                sortKey={sortKey}
                orderAsc={orderAsc}
              />
            </Button>
          </HeaderCell>
          <HeaderCell width={156}>
            <Button
              variant="text"
              color="grey"
              className={styles.headerButton}
              onPress={() => {
                if (initialSort) {
                  setInitialSort(false)
                }
                setSortOrder('userPermission')
              }}
            >
              Role
              <OrderArrow
                currentKey="userPermission"
                className={styles.orderArrow}
                sortKey={sortKey}
                orderAsc={orderAsc}
              />
            </Button>
          </HeaderCell>
          <HeaderCell width={65} />
        </Head>
        <Body>
          {loading ? (
            <Preloader />
          ) : (
            <>
              {!orderedPermissionList || orderedPermissionList.length === 0 ? (
                <NoDataMessage
                  className={styles.noData}
                  errorMsg="No users found."
                  showSupportLink={false}
                />
              ) : (
                <>
                  {orderedPermissionList[activePage - 1] &&
                    orderedPermissionList[activePage - 1].map(
                      (user, index: number) => {
                        if (!user.inviteOpen) {
                          return (
                            <TableRow
                              key={user.userID}
                              index={index}
                              userID={user.userID}
                              currentUserID={userId}
                              email={user.userEmail}
                              permission={user.userPermission}
                              lastLogin={user.lastLogin || user.created}
                              onChange={onChange}
                              onDelete={onDelete}
                            />
                          )
                        }

                        return (
                          <TableRowPending
                            key={user.userID}
                            index={index}
                            accountID={workspaceID}
                            email={user.userEmail}
                            userID={user.userID}
                            sentTime={user.created}
                            expired={!!user.expired}
                            permissionLevel={
                              user.userPermission as PermissionLevel
                            }
                            onDelete={async (email) => {
                              await revokeInvitation(email)
                            }}
                          />
                        )
                      },
                    )}
                </>
              )}
            </>
          )}
        </Body>
      </Table>
      {pages > 1 && (
        <Pagination
          pages={pages}
          activePage={activePage}
          onChange={(index) => setActivePage(index)}
          rowsPerPageData={{
            rowsPerPage,
            totalRows: count,
            onChange: (newRowsPerPage) => {
              setRowsPerPage(newRowsPerPage)
              setActivePage(1)
            },
          }}
        />
      )}
    </div>
  )
}
