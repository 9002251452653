import FileSaver from 'file-saver'
import moment from 'moment'

import Make from '.'

const make = Make('track')

export interface DownloadCodes {
  codeIDList?: string[]
  dimensionFilter?: {
    dimensionParameterID: string
    dimensionName: string
    dimensionOptions: string[]
  }
  filterByCurrentUser?: boolean
  orderBy?: {
    sortField: string
    sortDirection: 'ASC' | 'DESC'
  }
}

export const downloadCodesByAccount = async (
  data?: DownloadCodes,
  excelFile = false,
): Promise<string | ArrayBuffer | false> => {
  try {
    const req = await make.post<string | ArrayBuffer>({
      endpoint: `/download-codes-by-account?exportType=${
        excelFile ? 'xlsx' : 'csv'
      }`,
      data,
      options: {
        responseType: excelFile ? 'arraybuffer' : 'text',
      },
    })

    if (!req.success || !req.res.data) throw new Error(req.res.error)

    return req.res.data
  } catch {
    return false
  }
}

export const uploadBulkEdit = async (
  file: File,
  applyErrorValidation: boolean,
): Promise<boolean | any> => {
  const formData = new FormData()
  formData.append('file', file)

  const req = await make.post({
    endpoint: `/bulk-edit-codes?applyErrorValidation=${
      applyErrorValidation ? 'true' : 'false'
    }`,
    options: {
      'Content-Type': 'multipart/form-data',
      responseType: 'arraybuffer',
    },
    data: formData,
  })

  if (req.success && req.res.status === 200) return true

  return req.res
}

interface UploadBulkTemplate {
  file: File
  createShortLinks?: boolean
  customDomainID?: string | null
  bulkStart?: string | null
}

export const uploadBulkTemplate = async (
  data: UploadBulkTemplate,
): Promise<boolean | any> => {
  const { file, createShortLinks, customDomainID, bulkStart } = data

  const formData = new FormData()
  formData.append('uplifterBulkUpload.csv', file)
  formData.append('file', file)

  let query = ''

  if (typeof createShortLinks === 'boolean')
    query += `createShortLinks=${createShortLinks}`

  if (customDomainID) query += `&customDomainID=${customDomainID}`
  if (bulkStart) query += `&bulkStart=${bulkStart}`

  const req = await make.post({
    endpoint: `/bulk-upload-codes${query !== '' && `?${query}`}`,
    options: {
      'Content-Type': 'multipart/form-data',
      responseType: 'arraybuffer',
    },
    data: formData,
  })

  if (req.success && req.res.status === 200) {
    return true
  }

  return req.res
}

export const getDownloadTemplateLinkGQL = async (): Promise<boolean> => {
  const req = await make.get({
    endpoint: `/generate-bulk-upload-template`,
  })

  if (!req.success || !req.res.data) {
    return false
  }

  if (req.res.data) {
    const blob = new Blob([req.res.data], { type: 'text/csv;charset=utf-8' })
    await FileSaver.saveAs(blob, 'uplifterBulkUpload')
  }

  return true
}

export const uploadBulkTemplateHistorical = async (
  data: UploadBulkTemplate,
): Promise<boolean | any> => {
  const formData = new FormData()
  formData.append('uplifterBulkUpload.csv', data.file)
  formData.append('file', data.file)

  const req = await make.post({
    endpoint: '/bulk-upload-historical-codes',
    options: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })

  if (req.success && req.res.status === 200) {
    return true
  }

  return req.res
}

export const bulkUploadDropdowns = async ({
  file,
  parameterID,
  mode = 'append',
}: {
  file: File
  parameterID: string
  mode?: 'append' | 'overwrite'
}): Promise<boolean | any> => {
  const formData = new FormData()
  formData.append('uplifterBulkUpload.csv', file)
  formData.append('file', file)

  const req = await make.post({
    endpoint: `/bulk-upload-parameter-options?parameterID=${parameterID}&mode=${mode}`,
    options: {
      'Content-Type': 'multipart/form-data',
      responseType: 'arraybuffer',
    },
    data: formData,
  })

  if (req.success && req.res.status === 200) {
    return true
  }

  return req.res
}

export const uploadExistingUtmSpreadsheet = async (file: File) => {
  const formData = new FormData()
  formData.append('existingUtmSpreadsheet.csv', file)
  formData.append('file', file)

  const req = await make.post({
    endpoint: '/send-existing-utm-sheet',
    options: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })

  if (req.success && req.res.status === 200) {
    return true
  }

  return req.res
}

export const getDropdownUsageReport = async (): Promise<boolean> => {
  const req = await make.get({
    endpoint: `/download-generator-audit?includeNonSelectFields=true`,
    options: {
      headers: {
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    },
  })

  if (!req.success || req.res.status === 404) {
    return false
  }

  if (req.res.data) {
    const blob = new Blob([new Uint8Array(req.res.data)], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    const now = new Date(Date.now())

    await FileSaver.saveAs(
      blob,
      `Uplifter - Dropdown Usage Data - ${moment(now).format(
        'YYYY-MM-DD',
      )}.xlsx`,
    )
  }

  return true
}

export const getAdobeCodesDownloadLink = async () => {
  const req = await make.get({
    endpoint: '/download-saint-file',
  })

  if (req.success && req.res.status === 200) {
    const blob = new Blob([req.res.data], { type: 'text/plain;charset=utf-8' })

    FileSaver.saveAs(blob, 'adobeClassificationFile.txt')

    return true
  }

  return req.res
}

export const uploadParameterCreatives = async ({
  parameterID,
  optionID,
  optionName,
  file,
}: {
  parameterID: string
  optionID?: string
  optionName?: string
  file: File
}) => {
  const formData = new FormData()
  formData.append('file', file)

  const req = await make.post({
    endpoint: `/upload-creative?parameterID=${parameterID}${
      optionID ? `&optionID=${optionID}` : ''
    }${optionName ? `&optionName=${optionName}` : ''}`,
    options: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })

  if (req.success && req.res.status === 200) {
    return true
  }

  return req.res
}
