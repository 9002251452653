import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import classNames from 'classnames'
import { nanoid } from 'nanoid'

import AnomalyDiff from './anomaly-diff'
import Button from './button'
import Loader, { Preloader } from './loader'
import NoDataMessage from './no-data-message'
import OrderArrow from './order-arrow'
import Pagination from './pagination'
import Table, { TableLoadingRows } from './table'
import Tooltip from './tooltip'
import TopScrollbar from './top-scrollbar'
import {
  listSavedLinkPerformanceReportsGQL,
  updateSavedLinkPerformanceReport,
} from '../api/graphql/report-client'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import { messages } from '../core/constants'
import { formatValueForDisplay } from '../helpers'
import { secondsToHms } from '../helpers/dates'
import useTableSortFilter from '../hooks/useTableSortFilter'
import styles from '../styles/performance-report-links-table.module.scss'
import { GetCampaignLinkDashboardTableQuery } from '../__gql-types__/graphql'

interface TableLinkProps {
  campaignLink: string
  separator?: string
  prefix?: string
}

export const TableLink = ({
  campaignLink,
  separator = '&',
  prefix = '?',
}: TableLinkProps) => {
  const searchRegEx = new RegExp(
    `([^=${separator}]+)(=([^${separator}]*))?`,
    'gi',
  )

  const splitLink = campaignLink.split(prefix)
  const useCampaignLink =
    splitLink.length === 1 ? splitLink[0] : splitLink.slice(1).join(prefix)
  const found = useCampaignLink.match(searchRegEx)
  const useHighlight =
    found !== null &&
    (found.length > 1 || (found.length === 1 && splitLink.length > 1))

  return (
    <span className={styles.tableLinkWrapper}>
      <>{`${splitLink.length > 1 ? splitLink[0] + prefix : ''}`}</>
      <span>
        {useHighlight &&
          found &&
          found.map((foundQueryValueString, partsIndex) => {
            const parts = foundQueryValueString.split('=')

            return parts.map((l, indx) => {
              const renderLink = `${
                indx === 0 && partsIndex > 0 ? separator : ''
              }${l}${parts.length > 1 && indx !== parts.length - 1 ? '=' : ''}`

              return (
                <span key={nanoid()} className={styles.tableLink}>
                  {renderLink}
                </span>
              )
            })
          })}
        {!useHighlight && <>{useCampaignLink}</>}
      </span>
    </span>
  )
}

interface MainTableColumnHeaderProps {
  loading: boolean
  totalTableCols: number
  fullMatchType: string | MatchTypeProps
  linksTablePerformanceOrder: 'topToBottom' | 'bottomToTop'
  setLinksTablePerformanceOrder: React.Dispatch<
    React.SetStateAction<'topToBottom' | 'bottomToTop'>
  >
  successMetricDisplayName: string
}

const MainTableColumnHeader = ({
  loading,
  totalTableCols,
  fullMatchType,
  linksTablePerformanceOrder,
  setLinksTablePerformanceOrder,
  successMetricDisplayName,
}: MainTableColumnHeaderProps) => {
  const matchTypeName =
    typeof fullMatchType === 'object' ? fullMatchType.shortName : fullMatchType

  return (
    <th
      rowSpan={totalTableCols > 1 ? 2 : 1}
      className={classNames(styles.linksMainHeaderCell, {
        [styles.fullMatch]:
          typeof fullMatchType === 'object' &&
          (fullMatchType.value === 'full' || fullMatchType.value === 'partial'),
      })}
    >
      {loading ? (
        <Loader className={styles.loadingText} />
      ) : (
        <>
          <Button
            variant="text"
            className={styles.headerButton}
            onPress={() => {
              setLinksTablePerformanceOrder((curr) =>
                curr === 'topToBottom' ? 'bottomToTop' : 'topToBottom',
              )
            }}
          >
            <h2 className={styles.tableTitle}>
              <AnomalyDiff
                arrowOnly
                largeIcon
                compact
                actual={linksTablePerformanceOrder === 'topToBottom' ? 2 : 1}
                expected={linksTablePerformanceOrder === 'topToBottom' ? 1 : 2}
              />
              <span className={styles.titleText}>
                {linksTablePerformanceOrder === 'topToBottom'
                  ? `Top performing ${matchTypeName}`
                  : `Underperforming ${matchTypeName}`}
              </span>
            </h2>
          </Button>
          <Tooltip
            id="table-header-tooltip"
            useIcon
            tooltipIconClassName={styles.headerTooltip}
            tooltipPosition="bottom"
            tooltipMessage={
              linksTablePerformanceOrder === 'topToBottom'
                ? `The top 50% of campaign ${matchTypeName}, ranked by ${successMetricDisplayName}, based on your chosen filters.`
                : `The bottom 50% of campaign ${matchTypeName}, ranked by ${successMetricDisplayName}, based on your chosen filters.`
            }
          />
        </>
      )}
    </th>
  )
}

interface PerformanceReportLinksTableProps {
  fullMatchType: MatchTypeProps | string
  savedReportID?: string
  savedReportColumnOrder?: number[]
  hiddenTableColumns?: string[]
  data: GetCampaignLinkDashboardTableQuery['campaignLinkDashboardTable'] | null
  loading: boolean
  error: boolean
  successMetric?: string
  increasePositive?: boolean
  linksTablePerformanceOrder?: 'topToBottom' | 'bottomToTop'
  setLinksTablePerformanceOrder: React.Dispatch<
    React.SetStateAction<'topToBottom' | 'bottomToTop'>
  >
  successMetricDisplayName: string
}

const PerformanceReportLinksTable = React.forwardRef<
  HTMLTableElement,
  PerformanceReportLinksTableProps
>(
  (
    {
      fullMatchType,
      savedReportID,
      savedReportColumnOrder,
      hiddenTableColumns,
      data,
      loading,
      error,
      successMetric,
      increasePositive,
      linksTablePerformanceOrder = 'topToBottom',
      setLinksTablePerformanceOrder,
      successMetricDisplayName,
    },
    tableRef,
  ) => {
    const { data: campaignCodeGeneratorData } = useQuery(
      getCampaignCodeGenerator,
    )

    const tableContainerRef = useRef<HTMLDivElement>(null)

    const [updateSavedReport] = useMutation(updateSavedLinkPerformanceReport, {
      refetchQueries: [listSavedLinkPerformanceReportsGQL],
    })

    const [columnIndexOrder, setColumnIndexOrder] = useState<number[]>([])
    const [storedColumnIndexOrder, setStoredColumnIndexOrder] = useState<
      number[]
    >([])

    // Set the initial column order
    useEffect(() => {
      if (!data) return

      if (!savedReportColumnOrder || savedReportColumnOrder.length === 0) {
        setColumnIndexOrder(
          Array.from(new Array(data.tableData.length), (_, i) => i),
        )
        setStoredColumnIndexOrder(
          Array.from(new Array(data.tableData.length), (_, i) => i),
        )
      } else {
        // 0-index column should always be at the start to ensure success metric is at the start
        const adjustedOrder = [...savedReportColumnOrder]
        const zeroIndex = adjustedOrder.indexOf(0)

        if (zeroIndex > -1) {
          adjustedOrder.splice(zeroIndex, 1)
          adjustedOrder.unshift(0)
        }

        setColumnIndexOrder(adjustedOrder)
        setStoredColumnIndexOrder(adjustedOrder)
      }
    }, [data, savedReportColumnOrder])

    const { tableCols, tableRows } = useMemo(() => {
      if (!data) {
        return {
          tableCols: null,
          tableRows: [] as ({ campaignLink: string } & {
            [metricID: string]: { units: string; metricValue: number }
          })[],
        }
      }

      const { tableData, campaignLinks } = data

      const orderedColumnsData: {
        metricID: string
        metricName: string
        helpText: string
      }[] = []

      tableData.forEach(({ metricID, metricName, helpText }) => {
        orderedColumnsData.push({ metricID, metricName, helpText })
      })

      const _tableRows: ({ campaignLink: string } & {
        [metricID: string]: { units: string; metricValue: number }
      })[] = []

      campaignLinks.forEach((campaignLink, linkIndex) => {
        // @ts-ignore
        const rowData: { campaignLink: string } & {
          [metricID: string]: { units: string; metricValue: number }
        } = {
          campaignLink,
        }

        tableData.forEach(({ metricID, metricValue, units }) => {
          rowData[metricID] = { units, metricValue: metricValue[linkIndex] }
        })

        _tableRows.push(rowData)
      })

      return { tableCols: orderedColumnsData, tableRows: _tableRows }
    }, [data])

    const { prefix, separator } = useMemo(() => {
      if (!campaignCodeGeneratorData) {
        return { prefix: '?', separator: '&' }
      }

      const {
        masterPrefix,
        paramSeparator,
      } = campaignCodeGeneratorData.campaignCodeGenerator

      return { prefix: masterPrefix, separator: paramSeparator }
    }, [campaignCodeGeneratorData])

    // Sorts table based on metric sentiment and user preference/interaction
    const defaultSortAsc = useMemo(() => {
      return linksTablePerformanceOrder === 'topToBottom'
        ? !increasePositive
        : increasePositive || false
    }, [linksTablePerformanceOrder, increasePositive])

    const {
      orderedList,
      pages,
      activePage,
      setActivePage,
      count,
      rowsPerPage,
      setRowsPerPage,
      orderAsc,
      sortKey,
      setSortOrder,
      setInitialSort,
    } = useTableSortFilter({
      inputList: tableRows,
      initialSortAsc: defaultSortAsc,
      startingRowsPerPage: 10,
      startingSortKey: successMetric,
      customSorts: {
        '*': (row, _, key) => {
          const isSuccessMetric = key === successMetric

          let rowHasValue = false

          if (tableCols) {
            let colIndex = 0

            while (!rowHasValue && colIndex < tableCols.length) {
              const { metricID } = tableCols[colIndex]

              if (row[metricID].metricValue > 0) {
                rowHasValue = true
              }

              colIndex += 1
            }
          }

          if (
            rowHasValue &&
            key &&
            Object.prototype.hasOwnProperty.call(row, key)
          ) {
            return row[key].metricValue
          }

          // Only success metric needs to follow the empty rows sort rule
          if (!rowHasValue) {
            if (!isSuccessMetric) return -Infinity

            return increasePositive ? -Infinity : Infinity
          }
          return 0
        },
      },
    })

    useEffect(() => {
      setInitialSort(true)
    }, [defaultSortAsc])

    const successMetricIsHidden =
      !successMetric || hiddenTableColumns?.includes(successMetric)

    return (
      <>
        <div ref={tableContainerRef} className={styles.tableContainer}>
          <TopScrollbar>
            <Table ref={tableRef} className={styles.linksTable}>
              <thead>
                {/* Need an extra row to show 'Support metrics' cols header */}
                {tableCols &&
                  tableCols.length - (hiddenTableColumns?.length || 0) > 1 && (
                    <tr>
                      <MainTableColumnHeader
                        loading={loading}
                        totalTableCols={
                          tableCols.length - (hiddenTableColumns?.length || 0)
                        }
                        fullMatchType={fullMatchType}
                        linksTablePerformanceOrder={linksTablePerformanceOrder}
                        setLinksTablePerformanceOrder={
                          setLinksTablePerformanceOrder
                        }
                        successMetricDisplayName={successMetricDisplayName}
                      />
                      {!successMetricIsHidden && (
                        <th
                          className={classNames(
                            styles.metricTypeHeaderColumn,
                            styles.successMetric,
                          )}
                        >
                          Success metric
                        </th>
                      )}
                      <th
                        colSpan={
                          tableCols.length -
                          (hiddenTableColumns?.length || 0) -
                          1 +
                          (successMetricIsHidden ? 1 : 0)
                        }
                        className={classNames(
                          styles.metricTypeHeaderColumn,
                          styles.supportMetric,
                        )}
                      >
                        Support Metrics
                      </th>
                    </tr>
                  )}
                <DragDropContext
                  onDragUpdate={(result) => {
                    const { destination, source } = result

                    if (destination) {
                      const newOrder = [...storedColumnIndexOrder]
                      const movedIndex = newOrder.splice(source.index, 1)
                      newOrder.splice(destination.index, 0, movedIndex[0])

                      setColumnIndexOrder(newOrder)
                    }
                  }}
                  onDragEnd={(result) => {
                    setStoredColumnIndexOrder(columnIndexOrder)

                    const { destination, source } = result

                    if (!destination) return

                    if (
                      destination.droppableId === source.droppableId &&
                      destination.index === source.index
                    ) {
                      return
                    }

                    if (savedReportID) {
                      updateSavedReport({
                        variables: {
                          savedReportID,
                          updatedTableColumnIndexOrderList: columnIndexOrder,
                        },
                      })
                    }
                  }}
                >
                  <Droppable
                    droppableId="tableHeaderCols"
                    direction="horizontal"
                  >
                    {(droppableProvided) => {
                      return (
                        <tr
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                        >
                          {/* MainTableColumnHeader should span two rows if support metrics are present, so this one shouldn't show */}
                          {(!tableCols ||
                            tableCols.length -
                              (hiddenTableColumns?.length || 0) <
                              2) && (
                            <MainTableColumnHeader
                              loading={loading}
                              totalTableCols={
                                tableCols
                                  ? tableCols.length -
                                    (hiddenTableColumns?.length || 0)
                                  : 0
                              }
                              fullMatchType={fullMatchType}
                              linksTablePerformanceOrder={
                                linksTablePerformanceOrder
                              }
                              setLinksTablePerformanceOrder={
                                setLinksTablePerformanceOrder
                              }
                              successMetricDisplayName={
                                successMetricDisplayName
                              }
                            />
                          )}
                          {tableCols ? (
                            <>
                              {columnIndexOrder.map((colIndex, arrayIndex) => {
                                const {
                                  metricID,
                                  metricName,
                                  helpText,
                                } = tableCols[colIndex]

                                if (hiddenTableColumns?.includes(metricID)) {
                                  return null
                                }

                                let fullHelpText = helpText

                                if (metricID === 'shortLinkClicks') {
                                  fullHelpText += `\n\n**Apologies:** Due to an internal error, no short link clickthrough data was collected between 5pm 17th Jun 2024 and 4pm 2nd July 2024.`
                                }

                                // First column is main metric: not sortable
                                if (colIndex === 0) {
                                  return (
                                    <th
                                      key={metricID}
                                      className={classNames(
                                        styles.metricColumnHeader,
                                        styles.successMetricColumn,
                                      )}
                                      onClick={() => {
                                        if (increasePositive) {
                                          setLinksTablePerformanceOrder(
                                            orderAsc
                                              ? 'topToBottom'
                                              : 'bottomToTop',
                                          )
                                        } else {
                                          setLinksTablePerformanceOrder(
                                            !orderAsc
                                              ? 'topToBottom'
                                              : 'bottomToTop',
                                          )
                                        }

                                        setSortOrder(metricID)
                                      }}
                                    >
                                      <span className={styles.headerButton}>
                                        {metricName}
                                      </span>
                                      <OrderArrow
                                        className={styles.orderArrow}
                                        currentKey={metricID}
                                        sortKey={sortKey}
                                        orderAsc={orderAsc}
                                      />
                                      <Tooltip
                                        id={`${metricID}-tooltip`}
                                        useIcon
                                        tooltipIconClassName={
                                          styles.headerTooltip
                                        }
                                        tooltipPosition="left"
                                        tooltipMessage={fullHelpText}
                                      />
                                    </th>
                                  )
                                }

                                return (
                                  <Draggable
                                    key={metricID}
                                    draggableId={metricID}
                                    index={arrayIndex}
                                  >
                                    {(draggableProvided) => {
                                      return (
                                        <th
                                          key={metricID}
                                          ref={draggableProvided.innerRef}
                                          {...draggableProvided.draggableProps}
                                          {...draggableProvided.dragHandleProps}
                                          className={styles.metricColumnHeader}
                                          onClick={() => setSortOrder(metricID)}
                                        >
                                          <span className={styles.headerButton}>
                                            {metricName}
                                          </span>
                                          <OrderArrow
                                            className={styles.orderArrow}
                                            currentKey={metricID}
                                            sortKey={sortKey}
                                            orderAsc={orderAsc}
                                          />
                                          <Tooltip
                                            id={`${metricID}-tooltip`}
                                            useIcon
                                            tooltipIconClassName={
                                              styles.headerTooltip
                                            }
                                            tooltipPosition="left"
                                            tooltipMessage={fullHelpText}
                                          />
                                        </th>
                                      )
                                    }}
                                  </Draggable>
                                )
                              })}
                              {droppableProvided.placeholder}
                            </>
                          ) : (
                            <>
                              {Array.from(
                                new Array(5),
                                (_, index) => index,
                              ).map((i) => {
                                return (
                                  <th key={nanoid()}>
                                    <Loader className={styles.loadingText} />
                                  </th>
                                )
                              })}
                            </>
                          )}
                        </tr>
                      )
                    }}
                  </Droppable>
                </DragDropContext>
              </thead>
              {!error && loading ? (
                <tbody>
                  <TableLoadingRows colCount={6} />
                </tbody>
              ) : (
                <>
                  {error || orderedList.length === 0 || !tableCols ? (
                    <tbody className={styles.tableBody}>
                      <tr>
                        <td
                          colSpan={
                            1 +
                            (tableCols ? tableCols.length : 0) +
                            (successMetricIsHidden ? 0 : 1)
                          }
                        >
                          <div
                            style={{
                              margin: '16px 0',
                              maxWidth: tableContainerRef.current?.offsetWidth,
                            }}
                          >
                            <NoDataMessage
                              errorMsg={
                                error
                                  ? 'Error fetching data'
                                  : 'No links found.'
                              }
                              showSupportLink={false}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {orderedList[
                        orderedList.length < activePage - 1 ? 0 : activePage - 1
                      ].map((row) => {
                        const { campaignLink } = row

                        let rowHasValue = false

                        let colIndex = 0

                        while (!rowHasValue && colIndex < tableCols.length) {
                          const { metricID } = tableCols[colIndex]

                          if (row[metricID].metricValue > 0) {
                            rowHasValue = true
                          }

                          colIndex += 1
                        }

                        return (
                          <tr key={campaignLink}>
                            <td className={styles.tdCode}>
                              {campaignLink === '' ? (
                                <div className={styles.notSetRow}>
                                  <span className={styles.notSet}>
                                    (not set)
                                  </span>
                                  <Tooltip
                                    id="value-not-set"
                                    tooltipPosition="right"
                                    className={styles.notSetTooltip}
                                    tooltipMessage={messages.notSet}
                                  >
                                    <span
                                      className={classNames(
                                        styles.hint,
                                        styles.hintPartial,
                                      )}
                                    >
                                      !
                                    </span>
                                  </Tooltip>
                                </div>
                              ) : (
                                <TableLink
                                  prefix={prefix}
                                  separator={separator}
                                  campaignLink={campaignLink as string}
                                />
                              )}
                            </td>
                            {columnIndexOrder.map((columnIndex) => {
                              const { metricID } = tableCols[columnIndex]

                              if (hiddenTableColumns?.includes(metricID)) {
                                return null
                              }

                              const { units, metricValue } = row[metricID]

                              const showDurationUnits =
                                units === 'float' &&
                                metricID.toLowerCase().indexOf('duration') > -1

                              const displayValue = showDurationUnits
                                ? secondsToHms(metricValue) || '0s'
                                : formatValueForDisplay(
                                    metricValue,
                                    units,
                                    !rowHasValue,
                                    true,
                                  )

                              return (
                                <td
                                  key={`${campaignLink}-${metricID}`}
                                  className={classNames(styles.valueCell, {
                                    [styles.successMetricColumn]:
                                      successMetric === metricID,
                                  })}
                                >
                                  {displayValue}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </TopScrollbar>
        </div>
        {loading ? (
          <Preloader />
        ) : (
          <div data-html2canvas-ignore>
            <Pagination
              pages={pages}
              activePage={activePage}
              onChange={(index) => setActivePage(index)}
              rowsPerPageData={{
                rowsPerPage,
                totalRows: count,
                onChange: (newRowsPerPage) => {
                  setRowsPerPage(newRowsPerPage)
                  setActivePage(1)
                },
              }}
            />
          </div>
        )}
      </>
    )
  },
)

export default PerformanceReportLinksTable
