import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Button, { ClearButton } from './button'
import { FormField, FormLabel, FormLoading, FormRow } from './form'
import { BetaLabel } from './counter'
import { RadioGroup } from './input-v2'
import Link from './link'
import Modal from './modal'
import NoDataMessage from './no-data-message'
import Tooltip from './tooltip'
import AppFields from './track-create-app-fields'
import LandingPageField from './track-create-landing-page-field'
import GeneratorParameterFields from './track-create-parameter-fields'
import TrackCreateSubmitFields, { SubmitLinkType } from './track-create-submit'
import { InnerBox, OuterBox } from './two-columns'
import { BoxedText } from './typography'
import { RequestAppLinksModal } from './upgrade-modals'
import { supportEmail } from '../core/constants'
import { UpdateFormValuesVars, WebLinkForm } from '../helpers/track-create'
import useLogAction from '../hooks/useLogAction'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import { UpdateFormOptions } from '../hooks/useTrackCreateSavedValues'
import useTrackCreateFormSubmit, {
  HardWarningModalType,
  SoftWarningModalType,
} from '../hooks/useTrackCreateSubmit'
import styles from '../styles/track-create-form-single.module.scss'
import { GetCampaignCodeGeneratorQuery } from '../__gql-types__/graphql'

interface CreateLinkWarningModalProps {
  setCreateLinkWarning: React.Dispatch<
    React.SetStateAction<SoftWarningModalType | HardWarningModalType | null>
  >
}

interface SoftWarningModalProps extends CreateLinkWarningModalProps {
  createLinkWarning: SoftWarningModalType
  buildLinks: () => Promise<void>
}

interface HardWarningModalProps extends CreateLinkWarningModalProps {
  createLinkWarning: HardWarningModalType
}

const CreateLinkAnchorWarningModal = ({
  setCreateLinkWarning,
  buildLinks,
}: SoftWarningModalProps) => {
  const logAction = useLogAction()

  const [loading, setLoading] = useState(false)

  return (
    <Modal
      setIsOpen={() => setCreateLinkWarning(null)}
      modalHeader="Page anchor detected"
      noText="Cancel"
      yesText="Continue creating link"
      yesButtonLoading={loading}
      onYes={async () => {
        setLoading(true)

        await logAction({
          variables: {
            action: 'build-links-ignore-anchor-warning',
            websiteSection: 'track-create',
            functionName: 'ignoreWarning',
            pagePath: '/track/create',
          },
        })

        await buildLinks()

        setLoading(false)

        setCreateLinkWarning(null)
      }}
    >
      <p>
        Your link contains a page anchor '#' which will be moved to the end of
        your link. This is required to make sure campaign parameters are
        captured and your link can auto scroll.{' '}
        <Link href="https://support.uplifter.ai/hc/en-us/articles/4469479289873-Can-I-use-anchor-tags-or-in-my-landing-page-URL-with-query-string-parameters-utms-">
          Find out more
        </Link>
        .
      </p>
    </Modal>
  )
}

const CreateLinkNoUrlModal = ({
  createLinkWarning,
  setCreateLinkWarning,
  buildLinks,
}: SoftWarningModalProps) => {
  const { fullLinks } = createLinkWarning

  const logAction = useLogAction()

  const hasMultipleLinks = fullLinks.length > 1

  const [loading, setLoading] = useState(false)

  return (
    <Modal
      setIsOpen={() => setCreateLinkWarning(null)}
      modalHeader={`Invalid landing page URL${hasMultipleLinks ? '(s)' : ''}`}
      noText={`Back and update landing page URL${
        hasMultipleLinks ? '(s)' : ''
      }`}
      yesText="Yes, create"
      yesButtonLoading={loading}
      onYes={async () => {
        setLoading(true)

        await logAction({
          variables: {
            action: 'build-links-ignore-no-url-warning',
            websiteSection: 'track-create',
            functionName: 'ignoreWarning',
            pagePath: '/track/create',
          },
        })

        await buildLinks()

        setLoading(false)

        setCreateLinkWarning(null)
      }}
    >
      <p>
        {hasMultipleLinks ? 'One of y' : 'Y'}our landing page URL
        {hasMultipleLinks && 's'} is either empty or invalid - users could be
        directed to an error page.
      </p>
      <p>
        You should only create {hasMultipleLinks ? 'these links' : 'this link'}{' '}
        if you plan on editing the landing page URL{hasMultipleLinks && '(s)'}{' '}
        in the <BoxedText>Track &gt; View links</BoxedText> table before the
        campaign goes live.
      </p>
    </Modal>
  )
}

const CreateShortLinkNoUrlModal = ({
  createLinkWarning,
  setCreateLinkWarning,
  buildLinks,
}: SoftWarningModalProps) => {
  const { fullLinks } = createLinkWarning

  const logAction = useLogAction()

  const hasMultipleLinks = fullLinks.length > 1

  const [loading, setLoading] = useState(false)

  return (
    <Modal
      setIsOpen={() => setCreateLinkWarning(null)}
      modalHeader={`Short link${
        hasMultipleLinks ? '(s)' : ''
      } with no landing page URL`}
      noText={`Back and use full link${hasMultipleLinks ? '(s)' : ''}`}
      yesText="Yes, create"
      yesButtonLoading={loading}
      onYes={async () => {
        setLoading(true)

        await logAction({
          variables: {
            action: 'build-links-ignore-shortlink-no-url-warning',
            websiteSection: 'track-create',
            functionName: 'ignoreWarning',
            pagePath: '/track/create',
          },
        })

        await buildLinks()

        setLoading(false)

        setCreateLinkWarning(null)
      }}
    >
      <p>
        You are using {hasMultipleLinks ? 'short links' : 'a short link'} but
        have not set a landing page URL.
      </p>
      <p>
        You should only create {hasMultipleLinks ? 'these links' : 'this link'}{' '}
        if you plan on adding the landing page URL{hasMultipleLinks && '(s)'} in
        the <BoxedText>Track &gt; View links</BoxedText> table before the
        campaign goes live.
      </p>
    </Modal>
  )
}

const CreateLinkInvalidLengthModal = ({
  createLinkWarning,
  setCreateLinkWarning,
}: HardWarningModalProps) => {
  const {
    type,
    fullLinks,
    characterLimit,
    charactersOverLimit,
  } = createLinkWarning

  const hasMultipleLinks = fullLinks.length > 1

  return (
    <Modal
      setIsOpen={() => setCreateLinkWarning(null)}
      isWarning
      modalHeader={`Link${hasMultipleLinks ? '(s) are' : ' is'} too long`}
      noText="Back and edit parameters"
      yesText="Yes, create"
    >
      <p>
        The resulting{' '}
        {type === 'invalid-landing-page-length' ? 'link' : 'query string'}
        {hasMultipleLinks && 's'} will be too long to work in most web browsers
        or marketing platforms.
      </p>
      <p>
        Your{hasMultipleLinks ? ' longest' : ''}{' '}
        {type === 'invalid-landing-page-length' ? 'link' : 'query string'} is{' '}
        {charactersOverLimit} characters longer than the maximum recommended{' '}
        {characterLimit} characters.
      </p>
      {type === 'invalid-landing-page-length' ? (
        <p>
          Try <strong>changing the landing page</strong> to a shorter URL.
        </p>
      ) : (
        <>
          <p>
            Type <strong>fewer characters into the parameter values</strong> for
            a shorter string.
          </p>
          <p>
            For help email{' '}
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
          </p>
        </>
      )}
    </Modal>
  )
}

interface TrackCreateFormSingleProps {
  formIsInitialised?: boolean
  generatedStructure:
    | GetCampaignCodeGeneratorQuery['campaignCodeGenerator']
    | null
  loadingGenerator?: boolean
  generatorError?: boolean
  formValues: WebLinkForm
  updateFormValues: (
    newValues: UpdateFormValuesVars,
    options?: UpdateFormOptions,
  ) => void
  formSubmissionState: {
    softDisable: boolean
    fieldsWithErrors: string[]
    showErrorMessages: boolean
  }
  setFormSubmissionState: React.Dispatch<
    React.SetStateAction<{
      softDisable: boolean
      fieldsWithErrors: string[]
      showErrorMessages: boolean
    }>
  >
  setNewLinks: React.Dispatch<React.SetStateAction<string | string[]>>
}

const TrackCreateFormSingle = ({
  formIsInitialised,
  generatedStructure,
  loadingGenerator,
  generatorError,
  formValues,
  updateFormValues,
  formSubmissionState,
  setFormSubmissionState,
  setNewLinks,
}: TrackCreateFormSingleProps) => {
  const { validationChecks } = generatedStructure || {}

  const { isEnterprise } = useSubscriptionLevel()

  const {
    linkType,
    setLinkType,
    canUseCustomLinks,
    customLinkAlias,
    setCustomLinkAlias,
    fetchingAliases,
    customLinkKey,
    customLinkDomainDetails,
    setCustomLinkDomainDetails,
    customLinkError,
    submitForm,
    createLinkWarning,
    setCreateLinkWarning,
    createLinkLoading,
    uplifterIdLoading,
    uplifterIdError,
    createLinkError,
  } = useTrackCreateFormSubmit({
    formType: 'single',
    formSubmissionState,
    setFormSubmissionState,
    generatedStructure,
    useAppLinks: formValues.linkTo === 'app',
  })

  // Landing page field should never be optional if isApp
  const showLandingPageField = useMemo(() => {
    if (formValues.linkTo === 'app') {
      return true
    }

    const showLandingCheck = validationChecks?.find(
      (check) => check.name === 'SHOW_LANDING_PAGE',
    )?.enabled

    return typeof showLandingCheck === 'boolean' ? showLandingCheck : true
  }, [formValues.linkTo, validationChecks])

  const submitLinkType = useMemo(() => {
    let _submitLinkType: SubmitLinkType

    if (formValues.linkTo === 'app') {
      _submitLinkType = 'appLink'
    } else if (linkType === 'full') {
      _submitLinkType = 'basic'
    } else if (linkType === 'short') {
      _submitLinkType = 'shortLink'
    } else {
      const forceShortLinkRule = validationChecks?.find(
        (check) => check.name === 'FORCE_SHORT_LINK',
      )

      if (
        forceShortLinkRule &&
        forceShortLinkRule.enabled &&
        forceShortLinkRule.value
      ) {
        const shortLinkValue = JSON.parse(forceShortLinkRule.value)

        const selectedRule = shortLinkValue.find(
          (option: any) => option.selected,
        ).optionValue

        switch (selectedRule) {
          case 'force-short-links':
          case 'recommend-short-links':
            _submitLinkType = 'shortLink'
            break
          case 'force-long-links':
          case 'recommend-long-links':
          default:
            _submitLinkType = 'basic'
            break
        }
      } else {
        _submitLinkType = 'basic'
      }
    }

    return _submitLinkType
  }, [formValues.linkTo, linkType, validationChecks])

  const {
    softDisable,
    fieldsWithErrors,
    showErrorMessages,
  } = formSubmissionState

  // Used to ensure submit button isn't triggered by multiple clicks in quick succession
  const [codeCreatedTimeout, setCodeCreatedTimeout] = useState(false)
  const [showAppLinksUpgradeModal, setShowAppLinksUpgradeModal] = useState(
    false,
  )

  // Set linkType when linkTo is not 'app'
  useEffect(() => {
    if (!formIsInitialised) return

    if (formValues.linkTo === 'app') {
      setLinkType(null)
      return
    }

    if (!formValues.linkTo) {
      updateFormValues({
        linkTo: 'url',
      })
    }
  }, [formIsInitialised, formValues.linkTo])

  const showClearButton = useMemo(() => {
    if (formValues.url.length > 0 && formValues.url[0] !== '') {
      return true
    }

    if (
      formValues.linkTo === 'app' &&
      (formValues.appValues?.appGroupID || formValues.appValues?.appScreen)
    ) {
      return true
    }

    return Object.values(formValues.generatorParameterValues).some(
      (paramValue) => paramValue && paramValue.length > 0,
    )
  }, [formValues])

  /**
   * Only one link is being created in this form.
   * May change in the future when multi-journey is integrated
   */
  const createLink = useCallback(
    async (formVals: WebLinkForm, forceBuild?: boolean) => {
      const { success, fullLinks, customLinkDomain } = await submitForm(
        formVals,
        forceBuild,
      )

      if (success && fullLinks.length > 0) {
        const firstLink = fullLinks[0]

        setNewLinks(
          formVals.linkTo === 'app' || linkType === 'short'
            ? `https://${customLinkDomain}/${firstLink.shortLinkID}`
            : firstLink.fC,
        )
      }
    },
    [linkType, submitForm],
  )

  if (generatorError) {
    return (
      <OuterBox className={styles.outerBox}>
        <InnerBox>
          <NoDataMessage
            errorMsg={
              <>
                <p>Failed to retrieve link generator parameters.</p>
                <p>
                  <Link type="arrowForward" href={`mailto:${supportEmail}`}>
                    Contact {supportEmail}
                  </Link>
                </p>
              </>
            }
            showSupportLink={false}
          />
        </InnerBox>
      </OuterBox>
    )
  }

  if (loadingGenerator || uplifterIdLoading) {
    return (
      <OuterBox className={styles.outerBox}>
        <InnerBox>
          <p className={styles.intro} style={{ margin: 0 }}>
            Enter or select values and then click on 'Create campaign link'
            button.
          </p>
          <FormLoading rowCount={6}>
            <Button isDisabled style={{ width: 'fit-content' }}>
              Fetching link builder
            </Button>
          </FormLoading>
        </InnerBox>
      </OuterBox>
    )
  }

  return (
    <>
      <OuterBox className={styles.outerBox}>
        <InnerBox className={styles.innerBox}>
          {showClearButton && (
            <ClearButton
              className={styles.clearAllButton}
              iconAfter
              onPress={() => {
                // Set all form values back to empty
                const generatorParameterValues = {}

                Object.keys(formValues.generatorParameterValues).forEach(
                  (param) => {
                    generatorParameterValues[param] = []
                  },
                )

                updateFormValues(
                  {
                    linkTo: formValues.linkTo,
                    url: [],
                    appValues: null,
                    generatorParameterValues,
                  },
                  { resetSubmissionState: true },
                )

                // Remove app domain details if they exist
                setCustomLinkDomainDetails((curr) => ({
                  ...curr,
                  appLinkDomainID: '',
                  appLinkDomainLabel: '',
                }))
              }}
            >
              Clear all
            </ClearButton>
          )}
          <form
            className={styles.trackCreateForm}
            onSubmit={async (e) => {
              e.preventDefault()

              if (codeCreatedTimeout) return

              setCodeCreatedTimeout(true)

              await createLink(formValues)

              window.setTimeout(() => setCodeCreatedTimeout(false), 800)
            }}
          >
            <FormRow className={styles.topFormRow} bottomBorder={false}>
              <FormLabel
                id="link-to"
                tooltip={
                  <>
                    <p>What is your link going to open on the users device?</p>
                    <p>
                      <strong>Website URL:</strong> A URL in their browser.
                    </p>
                    <p>
                      <strong>App:</strong> If the user is on an Android/iOS
                      device, the link will take them to your app. If they don't
                      have the app installed, they will be taken to the app's
                      page on the app store.
                    </p>
                  </>
                }
              >
                Link to
              </FormLabel>
              <FormField>
                <RadioGroup
                  horizontal
                  options={[
                    {
                      label: 'Website URL',
                      value: 'url',
                    },
                    {
                      label: (
                        <div className={styles.appRadio}>
                          App{' '}
                          <Tooltip
                            id="app-link-beta-tooltip"
                            className={styles.betaTooltip}
                            clickable
                            tooltipMessage={
                              <p>
                                New feature in testing. Email{' '}
                                <Link href={supportEmail}>{supportEmail}</Link>{' '}
                                if you spot a bug or have feedback.
                              </p>
                            }
                          >
                            <BetaLabel className={styles.betaPill} />
                          </Tooltip>
                        </div>
                      ),
                      value: 'app',
                    },
                  ]}
                  optionsClassName={styles.radioButton}
                  selectedValue={formValues.linkTo || 'url'}
                  onChange={(option) => {
                    // App links are enterprise only
                    if (!isEnterprise && option === 'app') {
                      setShowAppLinksUpgradeModal(true)

                      return
                    }

                    if (option === 'url') {
                      // Remove app domain details if they exist
                      setCustomLinkDomainDetails((curr) => ({
                        ...curr,
                        appLinkDomainID: '',
                        appLinkDomainLabel: '',
                      }))
                    }

                    // The URL value must be reset when it's hidden from the form on 'url' version
                    // It should not be a part of the submission form
                    const showLandingCheck = generatedStructure?.validationChecks?.find(
                      (check) => check.name === 'SHOW_LANDING_PAGE',
                    )?.enabled

                    updateFormValues(
                      {
                        linkTo: option as 'url' | 'app',
                        // Remove URL value for web form if hidden
                        url:
                          option === 'url' && showLandingCheck === false
                            ? []
                            : formValues.url,
                        // Remove app values for URL form, else don't update
                        appValues: option === 'url' ? null : undefined,
                      },
                      { resetSubmissionState: true },
                    )
                  }}
                />
              </FormField>
            </FormRow>
            {formValues.linkTo === 'app' && (
              <AppFields
                setCustomLinkDomainDetails={setCustomLinkDomainDetails}
                appValues={formValues.appValues}
                appGroupIDError={
                  showErrorMessages &&
                  formSubmissionState.fieldsWithErrors.includes('appGroupID')
                }
                appScreenError={
                  showErrorMessages &&
                  formSubmissionState.fieldsWithErrors.includes('appScreen')
                }
                updateFormValues={updateFormValues}
              />
            )}
            <LandingPageField
              showField={showLandingPageField}
              isApp={formValues.linkTo === 'app'}
              bottomBorder={false}
              validationChecks={generatedStructure?.validationChecks || null}
              savedValue={formValues.url}
              onChange={(newVal, options) => {
                updateFormValues({ url: newVal ? [newVal] : [] }, options)
              }}
              submitError={
                showErrorMessages && fieldsWithErrors.includes('landingPage')
              }
              submitOnEnterKey={!softDisable}
            />
            <div style={{ paddingBottom: 16 }}>
              <GeneratorParameterFields
                generatedStructure={generatedStructure}
                formValues={formValues.generatorParameterValues}
                onChange={(fieldID, newVal, options) => {
                  updateFormValues(
                    {
                      generatorParameterValues: {
                        [fieldID]: newVal ? [newVal] : [],
                      },
                    },
                    options,
                  )
                }}
                submitOnEnterKey={!softDisable}
                showErrorMessages={showErrorMessages}
                fieldsWithErrors={fieldsWithErrors}
              />
            </div>
            <TrackCreateSubmitFields
              submitLinkType={submitLinkType}
              canUseLinkType={canUseCustomLinks}
              customLinkAlias={customLinkAlias}
              setCustomLinkAlias={setCustomLinkAlias}
              fetchingAliases={fetchingAliases}
              validationChecks={generatedStructure?.validationChecks || null}
              setLinkType={setLinkType}
              customLinkKey={customLinkKey}
              customLinkDomainDetails={customLinkDomainDetails}
              setCustomLinkDomainDetails={setCustomLinkDomainDetails}
              customLinkError={customLinkError}
              submitLoading={createLinkLoading}
              softDisable={
                !createLinkLoading && (softDisable || codeCreatedTimeout)
              }
              submitDisabled={!!uplifterIdError}
            >
              {showErrorMessages && fieldsWithErrors.length > 0 && (
                <p className={styles.inputError}>
                  Please have a value for every required parameter.
                </p>
              )}
              {uplifterIdError && (
                <p className={styles.inputError}>
                  Error generating a unique link ID. Please reload the page and
                  try again, or contact{' '}
                  <Link href={supportEmail}>{supportEmail}</Link>.
                </p>
              )}
              {createLinkError && (
                <p className={styles.inputError}>
                  Error creating link, please refresh and try again.
                </p>
              )}
            </TrackCreateSubmitFields>
          </form>
        </InnerBox>
      </OuterBox>
      {createLinkWarning?.type === 'has-anchor' && (
        <CreateLinkAnchorWarningModal
          createLinkWarning={createLinkWarning}
          setCreateLinkWarning={setCreateLinkWarning}
          buildLinks={async () => {
            await createLink(formValues, true)
          }}
        />
      )}
      {createLinkWarning?.type === 'no-url' && (
        <CreateLinkNoUrlModal
          createLinkWarning={createLinkWarning}
          setCreateLinkWarning={setCreateLinkWarning}
          buildLinks={async () => {
            await createLink(formValues, true)
          }}
        />
      )}
      {createLinkWarning?.type === 'no-url-shortlink' && (
        <CreateShortLinkNoUrlModal
          createLinkWarning={createLinkWarning}
          setCreateLinkWarning={setCreateLinkWarning}
          buildLinks={async () => {
            await createLink(formValues, true)
          }}
        />
      )}
      {['invalid-query-length', 'invalid-landing-page-length'].indexOf(
        createLinkWarning?.type || '',
      ) > -1 && (
        <CreateLinkInvalidLengthModal
          createLinkWarning={createLinkWarning as HardWarningModalType}
          setCreateLinkWarning={setCreateLinkWarning}
        />
      )}
      {showAppLinksUpgradeModal && (
        <RequestAppLinksModal onHideModal={setShowAppLinksUpgradeModal} />
      )}
    </>
  )
}

export default TrackCreateFormSingle
