import React, { useEffect, useMemo, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import classNames from 'classnames'
import moment from 'moment'
import _ from 'lodash'

import Link from './link'
import Modal from './modal'
import Pagination from './pagination'
import Table from './table'
import TopScrollbar from './top-scrollbar'
import { fetchLinkEditHistory } from '../api/graphql/track-view-client'
import { dateFormatShort, supportEmail } from '../core/constants'
import useTableSortFilter from '../hooks/useTableSortFilter'
import styles from '../styles/track-view-edit-history.module.scss'

interface EditedParamDetails {
  paramID: string
  paramName: string
  value: string
  changed: boolean
}

interface LinkVersionRow {
  versionNumber: number
  editedBy: string
  editedTime: string
  fullLink: EditedParamDetails
  params: EditedParamDetails[]
}

interface EditHistoryModalProps {
  onToggle: () => void
  linkID?: string | null
}

export const EditHistoryModal = ({
  onToggle,
  linkID,
}: EditHistoryModalProps) => {
  const [
    getLinkEditHistory,
    { data: linkHistoryData, loading: loadingLinkHistory },
  ] = useLazyQuery(fetchLinkEditHistory, { fetchPolicy: 'network-only' })

  const scrollRef = useRef<HTMLDivElement>(null)
  const ref = useRef(null)

  useEffect(() => {
    if (!linkID) return

    getLinkEditHistory({
      variables: {
        codeID: linkID,
      },
    })
  }, [linkID])

  const transformedData: LinkVersionRow[] | null = useMemo(() => {
    if (!linkHistoryData) return null

    const _transformedData: LinkVersionRow[] = []

    const { codeVersionHistory } = linkHistoryData.track

    codeVersionHistory.changeMap.forEach((version, versionIndex) => {
      const codeParams = codeVersionHistory.versionHistory.codeDef[versionIndex]

      _transformedData.unshift({
        versionNumber:
          codeVersionHistory.versionHistory.versionNumber[versionIndex],
        editedBy:
          versionIndex === 0
            ? codeVersionHistory.author
            : codeVersionHistory.editedBy[versionIndex],
        editedTime:
          versionIndex === 0
            ? codeVersionHistory.createdTime
            : codeVersionHistory.editedTime[versionIndex],
        fullLink: {
          paramID: 'fullLink',
          paramName: 'Destination with parameters',
          changed: version[0],
          value: codeVersionHistory.versionHistory.fullLink[versionIndex],
        },
        params: codeParams.map((param, paramIndex) => {
          return {
            paramID:
              codeVersionHistory.versionHistory.minGenDef[paramIndex].paramID,
            paramName:
              codeVersionHistory.versionHistory.minGenDef[paramIndex].paramName,
            changed: version[paramIndex + 1],
            value: param,
          }
        }),
      })
    })

    return _transformedData
  }, [linkHistoryData])

  const {
    pages,
    rowsPerPage,
    setRowsPerPage,
    activePage,
    setActivePage,
    orderedList,
    count,
  } = useTableSortFilter({
    inputList: transformedData,
    initialSortAsc: false,
  })

  const shortLink = useMemo(() => {
    if (!linkHistoryData) return null

    const shortLinks =
      linkHistoryData.track.codeVersionHistory.versionHistory.shortLink

    return shortLinks ? shortLinks[shortLinks.length - 1] : ''
  }, [linkHistoryData])

  if (!linkID) return null

  return (
    <Modal
      setIsOpen={() => onToggle()}
      width="superWide"
      modalHeader={`Edit history${shortLink ? `: ${shortLink}` : ''}`}
      loading={loadingLinkHistory}
    >
      {orderedList.length > 0 ? (
        <>
          <p>
            Changed fields are highlighted in{' '}
            <span className={styles.highlight}>green</span>.
          </p>
          <div className={styles.editTableContainer}>
            <TopScrollbar ref={scrollRef}>
              <Table ref={ref} className={styles.table}>
                <thead>
                  <tr>
                    <th>Version</th>
                    <th>Destination with parameters</th>
                    {orderedList[0][0].params.map(({ paramID, paramName }) => {
                      return <th key={paramID}>{paramName}</th>
                    })}
                  </tr>
                </thead>
                <tbody>
                  {orderedList[activePage - 1].map((link) => {
                    return (
                      <tr key={link.versionNumber}>
                        <td className={styles.dateCell}>
                          <span className={styles.createdWrapper}>
                            <span className={styles.created}>
                              <span className={styles.versionNumber}>
                                v{link.versionNumber}
                              </span>
                              <span className={styles.date}>
                                {moment(link.editedTime).format(
                                  dateFormatShort,
                                )}
                              </span>
                              <span className={styles.timeSince}>
                                {moment(link.editedTime).format('HH:mm')}
                              </span>
                            </span>
                            <span className={styles.user}>{link.editedBy}</span>
                          </span>
                        </td>
                        <td
                          className={classNames(styles.codeCell, {
                            [styles.hasChanged]: link.fullLink.changed,
                          })}
                        >
                          <span className={styles.codeText}>
                            {link.fullLink.value}
                          </span>
                        </td>
                        {link.params.map((linkParam) => {
                          return (
                            <td
                              key={`${link.versionNumber}-${linkParam.paramID}-${linkParam.value}`}
                              className={classNames({
                                [styles.hasChanged]: linkParam.changed,
                              })}
                            >
                              {linkParam.value}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </TopScrollbar>
          </div>
          <Pagination
            pages={pages}
            activePage={activePage}
            onChange={(index) => setActivePage(index)}
            rowsPerPageData={{
              rowsPerPage,
              maxRowsPerPage: 50,
              totalRows: count,
              onChange: (newRowsPerPage) => {
                setRowsPerPage(newRowsPerPage)
                setActivePage(1)
              },
            }}
          />
        </>
      ) : (
        <p className={styles.errorMsg}>
          Unable to retrieve link history. Please try again later or contact
          support: <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
        </p>
      )}
    </Modal>
  )
}
