import React, { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'

import AddByEmail from './add-by-email'
import CurrentTeamMates from './current-team-mates'
import { Preloader } from './loader'
import { UpdateSubscriptionUsersModal } from './subscription-comparison'
import { RequestMoreUsersModal } from './upgrade-modals'
import { currentUserDetails } from '../api/apollo/variables'
import { getAccountUsers } from '../api/graphql/workspace-client'
import { getCompanyDetails } from '../api/graphql/company-client'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'

interface TeamMatesProps {
  className?: string
}

export default function TeamMatesSettings({ className }: TeamMatesProps) {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const {
    isEnterprise,
    paddleData,
    loadingPaddleData,
    microsoftSubscriptionData,
    loadingMicrosoftData,
  } = useSubscriptionLevel()

  const { data: companyData } = useQuery(getCompanyDetails)
  const [getAccountProfiles, { data: accountProfilesData }] = useLazyQuery(
    getAccountUsers,
  )

  const [showUsersModal, setShowUsersModal] = useState(false)
  const [showUserUpgradeModal, setShowUserUpgradeModal] = useState(false)
  const [warningMessage, setWarningMessage] = useState('')

  // Fetch all users
  useEffect(() => {
    if (workspaceID) {
      getAccountProfiles({
        variables: {
          accountID: workspaceID,
        },
      })
    }
  }, [workspaceID])

  const { showUsers, showPendingUsers } = useMemo(() => {
    if (!accountProfilesData)
      return {
        showUsers: [],
        showPendingUsers: [],
      }

    return {
      showUsers: accountProfilesData.account.userAccountProfiles.filter(
        ({ userPermission, inviteOpen }) =>
          userPermission !== 'support' && !inviteOpen,
      ),
      showPendingUsers: accountProfilesData.account.userAccountProfiles.filter(
        ({ userPermission, inviteOpen }) =>
          userPermission !== 'support' && inviteOpen,
      ),
    }
  }, [accountProfilesData])

  const userCount = useMemo(() => {
    if (!companyData) return 0

    return companyData.currentCompany.userCount
  }, [companyData])

  const maxUsers = useMemo(() => {
    if (!paddleData && !microsoftSubscriptionData) return 0

    if (paddleData?.currentCompany.paddleSubscriptionQuantity) {
      return parseInt(paddleData.currentCompany.paddleSubscriptionQuantity, 10)
    }

    if (
      microsoftSubscriptionData?.currentCompany.microsoftSubscriptionData
        ?.quantity
    ) {
      return microsoftSubscriptionData.currentCompany.microsoftSubscriptionData
        .quantity
    }

    return 0
  }, [paddleData, microsoftSubscriptionData])

  return (
    <>
      <div className={className}>
        {loadingPaddleData || loadingMicrosoftData ? (
          <Preloader />
        ) : (
          <AddByEmail
            accountUsers={[...showUsers, ...showPendingUsers]}
            currentLicenceCount={maxUsers}
            maxToAdd={isEnterprise ? null : maxUsers - userCount}
            setShowAddUsersModal={setShowUsersModal}
            setShowUpgradeUsersModal={setShowUserUpgradeModal}
            setWarningMessage={setWarningMessage}
          />
        )}
        <CurrentTeamMates
          loading={!accountProfilesData}
          currentUsers={showUsers}
          pendingUsers={showPendingUsers}
        />
      </div>
      {showUsersModal && (
        <UpdateSubscriptionUsersModal
          onHideModal={setShowUsersModal}
          showWarningMessage={warningMessage}
        />
      )}
      {showUserUpgradeModal && (
        <RequestMoreUsersModal onHideModal={setShowUserUpgradeModal} />
      )}
    </>
  )
}
